import { joinClasses } from 'utils/joinClasses'
import { CheckIcon, InfoIcon } from '../../icons'
import { FormAlertLabelProps } from './types'
import { Typography } from '../Typography'
import styles from './styles.module.scss'

const labelIcons = {
  error: (
    <InfoIcon
      className={styles['form-alert-label-svg-alert']}
      color="red600Master"
    />
  ),
  warning: (
    <InfoIcon
      className={styles['form-alert-label-svg-alert']}
      color="yellow700"
    />
  ),
  success: (
    <CheckIcon
      className={styles['form-alert-label-svg-alert']}
      color="green700Master"
    />
  ),
  hint: (
    <InfoIcon
      className={styles['form-alert-label-svg-alert']}
      color="grey500"
    />
  ),
}

export const FormAlertLabel = ({
  label,
  type = 'error',
  className,
  position = 'absolute',
  isBottomOffset = true,
  typographyName = 'body2WMedium',
}: FormAlertLabelProps) => (
  <div
    className={joinClasses(
      styles['form-alert-label'],
      styles[`form-alert-label-${type}`],
      [styles['bottom-offset'], isBottomOffset],
      className || ''
    )}
    style={{ position }}
  >
    {labelIcons[type]}
    <Typography name={typographyName} color="red600Master">
      {label}
    </Typography>
  </div>
)
