import { useAppDispatch } from 'redux/hooks'
import { useNavigate } from 'react-router-dom'
import { authApi, profileApi } from 'api'
import { ErrorResponse } from 'api/types'
import { PROFILE_ADD_TITLE } from 'texts/profileDetails'
import { Typography } from 'ui/atoms/Typography'
import { breadcrumbs } from 'constants/breadcrumbs'
import { CONFIRM_INVITE_MODAL } from 'texts/profileConfirmModal'
import { Container } from 'ui/molecules/Container'
import { initialFormValues } from '../ProfileDetailsForm/constants'
import { ProfileDetailsForm } from '../ProfileDetailsForm'
import { FormValues } from '../ProfileDetailsForm/types'
import { setNotificationMessage } from 'redux/notifications/slice'
import { URLS } from 'constants/urls'
import { useApiRequest } from 'hooks/useApiRequest'

export const ProfileAdd = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { apiRequest, loading } = useApiRequest(
    (data) =>
      profileApi.createNewCompany({
        ...data,
        logo: data.logo?.[0],
      }),
    undefined,
    true,
    undefined,
    false
  )

  const breadcrumbList = [breadcrumbs.profileList]

  const handleSubmit = async (data: Partial<FormValues>) => {
    try {
      delete data.phoneCor
      const response = await apiRequest(data)
      if (response?.data.companyInfo.companyID) {
        await authApi.sendRegistrationCode(response.data.companyInfo.companyID)
        navigate(URLS.PROFILE + URLS.PROFILE_LIST)
        dispatch(
          setNotificationMessage({
            notificationMessage: CONFIRM_INVITE_MODAL.SUCCESS_NOTIFICATION,
          })
        )
      }
    } catch (e) {
      dispatch(
        setNotificationMessage({
          notificationMessage: (e as ErrorResponse).responseStatus.message,
          type: 'error',
        })
      )
    }
  }

  const header = (
    <Typography Tag="h5" name="H5">
      {PROFILE_ADD_TITLE}
    </Typography>
  )
  return (
    <Container
      breadcrumbList={breadcrumbList}
      currentPageLabel={PROFILE_ADD_TITLE}
      title={header}
      withCompanyName={false}
    >
      <ProfileDetailsForm
        withActionButtons={true}
        initialValues={initialFormValues}
        handleSubmit={handleSubmit}
        isLoading={loading}
      />
    </Container>
  )
}
