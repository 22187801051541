import { ContentType, EmptyResponse } from './../types'
import { ErrorResponse } from 'api/types'
import { Filter, addQueryString } from 'utils/addQueryString'
import { request } from './../utils'
import { PATH } from './constants'
import {
  AddOrEditPriceList,
  AddVehicleRequest,
  EquipmentAdd,
  FullCompanyEquipmentDto,
  FullCompanyStopSalesDto,
  FullPriceList,
  FullVehicle,
  GetEquipmentsListResponse,
  GetStopSalesListResponse,
  GetVehiclesListResponse,
  PriceListAdd,
  PriceListRateEdit,
  PriceListRates,
  PriceListResponse,
  PriceListVehicle,
  StopSalesAdd,
  VehicleInfo,
} from './types'

class Api {
  public baseUrl: string = process.env.REACT_APP_API_URL || ''

  async getVehiclesList(
    pageIndex: number,
    pageSize: number,
    companyId: number
  ) {
    return request<GetVehiclesListResponse, ErrorResponse>(
      this.baseUrl,
      addQueryString(PATH.VEHICLE_LIST, {
        companyId,
        pageSize,
        pageIndex,
      }),
      { method: 'GET' }
    )
  }

  async addVehicle(companyId: number, vehicleData: AddVehicleRequest) {
    return request<FullVehicle, ErrorResponse>(
      this.baseUrl,
      PATH.VEHICLE_ADD,
      { method: 'POST' },
      { companyId, ...vehicleData }
    )
  }

  async deleteVehicle(vehicleId: string) {
    return request<GetVehiclesListResponse, ErrorResponse>(
      this.baseUrl,
      `vehicle/${vehicleId}`,
      { method: 'DELETE' }
    )
  }

  async getStopSalesList(
    pageIndex: number,
    pageSize: number,
    companyId: number,
    filters?: Filter[]
  ) {
    return request<GetStopSalesListResponse, ErrorResponse>(
      this.baseUrl,
      addQueryString(
        PATH.STOP_SELLS_LIST,
        {
          companyId,
          pageSize,
          pageIndex,
        },
        filters
      ),
      { method: 'GET' }
    )
  }

  async deleteStopSale(stopSaleId: number) {
    return request<EmptyResponse, ErrorResponse>(
      this.baseUrl,
      `stopsell/${stopSaleId}`,
      { method: 'DELETE' }
    )
  }

  async getStopSalesFilters(companyId: number) {
    return request<{ years: string[] }, ErrorResponse>(
      this.baseUrl,
      addQueryString(PATH.STOP_SALE_FILTERS, { companyId })
    )
  }

  async getAvailableVehicleList(
    pageIndex: number,
    pageSize: number,
    companyId: number,
    hirePointId = []
  ) {
    return request<GetVehiclesListResponse, ErrorResponse>(
      this.baseUrl,
      addQueryString(PATH.AVAILABLE_VEHICLE_LIST, {
        companyId,
        pageSize,
        pageIndex,
        hirePointId,
      }),
      { method: 'GET' }
    )
  }

  async getVehicleInfo(id: string) {
    return request<FullVehicle, ErrorResponse>(this.baseUrl, `vehicle/${id}`, {
      method: 'GET',
    })
  }

  async updateVehicleInfo(id: string, data: AddVehicleRequest) {
    return request<FullVehicle, ErrorResponse>(
      this.baseUrl,
      `vehicle/${id}`,
      {
        method: 'PUT',
      },
      data
    )
  }

  async addEquipment(equipment: EquipmentAdd) {
    return request<{ extraInfo: FullCompanyEquipmentDto }, ErrorResponse>(
      this.baseUrl,
      PATH.ADD_EQUIPMENTS,
      { method: 'POST' },
      equipment
    )
  }

  async getEquipmentInfo(equipmentId: string) {
    return request<{ extraInfo: FullCompanyEquipmentDto }, ErrorResponse>(
      this.baseUrl,
      `extra/${equipmentId}`,
      { method: 'GET' }
    )
  }

  async getEquipmetsList(
    pageIndex: number,
    pageSize: number,
    companyId: number
  ) {
    return request<GetEquipmentsListResponse, ErrorResponse>(
      this.baseUrl,
      addQueryString(PATH.EQUIPMENTS_LIST, {
        companyId,
        pageSize,
        pageIndex,
      }),
      { method: 'GET' }
    )
  }

  async updateEquipment(equipmentId: string, equipment: EquipmentAdd) {
    return request<FullCompanyEquipmentDto, ErrorResponse>(
      this.baseUrl,
      `extra/${equipmentId}`,
      { method: 'PUT' },
      equipment
    )
  }

  async deleteEquipment(equipmentId: number) {
    return request<EmptyResponse, ErrorResponse>(
      this.baseUrl,
      `extra/${equipmentId}`,
      { method: 'DELETE' }
    )
  }

  async getPriceLists(
    companyId: number,
    pageIndex: number,
    pageSize: number,
    filters?: Filter[]
  ) {
    return request<PriceListResponse, ErrorResponse>(
      this.baseUrl,
      addQueryString(
        PATH.PRICE_LISTS,
        {
          companyId,
          pageIndex,
          pageSize,
        },
        filters
      ),
      {
        method: 'GET',
      }
    )
  }

  async deletePriceList(priceListId: number) {
    return request<EmptyResponse, ErrorResponse>(
      this.baseUrl,
      `${PATH.PRICE_LISTS}/${priceListId}`,
      { method: 'DELETE' }
    )
  }

  async updatePriceList(
    priceListId: string,
    companyId: string,
    priceList: AddOrEditPriceList
  ) {
    return request<EmptyResponse, ErrorResponse>(
      this.baseUrl,
      `${PATH.PRICE_LISTS}/${priceListId}`,
      { method: 'PUT' },
      { companyId, ...priceList }
    )
  }

  async getPriceListDetails(priceListId: string) {
    return request<{ priceListInfo: FullPriceList }, ErrorResponse>(
      this.baseUrl,
      `${PATH.PRICE_LISTS}/${priceListId}`,
      { method: 'GET' }
    )
  }

  async getPriceListRates(
    priceListId: string,
    pageSize: number,
    pageIndex: number
  ) {
    return request<PriceListRates, ErrorResponse>(
      this.baseUrl,
      addQueryString(`${PATH.PRICE_LISTS}/${priceListId}/rates`, {
        pageIndex,
        pageSize,
      }),
      { method: 'GET' }
    )
  }

  async setPriceListRates(priceListId: string, rates: PriceListRateEdit[]) {
    return request<PriceListRates, ErrorResponse>(
      this.baseUrl,
      `/rates/priceList/${priceListId}/rates`,
      { method: 'POST' },
      { rates, priceListId }
    )
  }

  async addPriceList(priceListData: PriceListAdd) {
    return request<{ priceListInfo: FullPriceList }, ErrorResponse>(
      this.baseUrl,
      PATH.PRICE_LIST_ADD,
      { method: 'POST' },
      { ...priceListData }
    )
  }

  async getPriceListFilters(companyId: number) {
    return request<{ years: string[] }, ErrorResponse>(
      this.baseUrl,
      addQueryString(PATH.PRICE_LIST_FILTERS, { companyId })
    )
  }

  async getPriceListVehicles({
    companyId,
    locations = [],
    countries = [],
  }: {
    companyId: number
    locations?: string[]
    countries?: string[]
  }) {
    return request<{ classes: PriceListVehicle[] }, ErrorResponse>(
      this.baseUrl,
      addQueryString(PATH.PRICE_LIST_VEHICLE_CLASSES, {
        companyId,
        locations,
        countries,
      })
    )
  }

  async getStopSaleInfo(stopSaleId: number) {
    return request<{ stopSellInfo: FullCompanyStopSalesDto }, ErrorResponse>(
      this.baseUrl,
      `stopsell/${stopSaleId}`
    )
  }

  async addStopSale(stopSaleData: StopSalesAdd) {
    return request<FullCompanyStopSalesDto, ErrorResponse>(
      this.baseUrl,
      PATH.STOP_SALE_ADD,
      { method: 'POST' },
      stopSaleData
    )
  }

  async updateStopSale(stopSaleId: string, stopSaleData: StopSalesAdd) {
    return request<FullCompanyStopSalesDto, ErrorResponse>(
      this.baseUrl,
      `stopsell/${stopSaleId}`,
      { method: 'PUT' },
      stopSaleData
    )
  }

  async getVehicleInfoBySipp(sipp: string) {
    return request<VehicleInfo, ErrorResponse>(
      this.baseUrl,
      addQueryString(PATH.VEHICLE_INFO, { sipp })
    )
  }

  async importRate(priceListId: string, formData: { file: Blob }) {
    return request<
      { priceList?: FullPriceList; success: boolean; fileName?: string },
      ErrorResponse
    >(
      this.baseUrl,
      `/rates/priceList/${priceListId}/rates/import`,
      { method: 'POST' },
      formData,
      'json',
      ContentType.FORM_DATA
    )
  }

  async exportRate(priceListId: string) {
    return request<string, ErrorResponse>(
      this.baseUrl,
      `rates/priceList/${priceListId}/rates/template`,
      { method: 'GET' },
      undefined,
      'blob'
    )
  }
}

export const fleetApi = new Api()
