import { request } from '../utils'
import { ErrorResponse } from '../types'
import { Filter, addQueryString } from 'utils/addQueryString'
import { PATH } from './constants'
import {
  AuditFilters,
  AuditListResponse,
  AuditLog,
  AuditRates,
  ChangedStructureRateFileState,
} from './types'

class Api {
  public baseUrl: string = process.env.REACT_APP_API_URL || ''

  async getAuditList(
    pageIndex = 0,
    pageSize = 10,
    companyId = '',
    filters?: Filter[],
    sorting?: string
  ) {
    return request<AuditListResponse, ErrorResponse>(
      this.baseUrl,
      addQueryString(
        PATH.AUDIT,
        {
          pageSize,
          pageIndex,
          companyId,
        },
        filters,
        sorting
      ),
      { method: 'GET' }
    )
  }

  getAuditDetailsInfo(id: string) {
    return request<AuditLog, ErrorResponse>(this.baseUrl, `${PATH.AUDIT}/${id}`)
  }

  getAuditFiltersData(companyId = '', fromDate = '', toDate = '') {
    return request<AuditFilters, ErrorResponse>(
      this.baseUrl,
      addQueryString(PATH.AUDIT_FILTERS_DATA, {
        companyId,
        fromDate,
        toDate,
      })
    )
  }

  getAuditRates(
    id: string,
    {
      pageIndex,
      pageSize,
      seasonRange,
    }: {
      pageIndex: number
      pageSize: string
      seasonRange: string
    }
  ) {
    return request<AuditRates, ErrorResponse>(
      this.baseUrl,

      addQueryString(`${PATH.AUDIT}/${id}/${PATH.RATES}`, {
        pageSize,
        pageIndex,
        seasonRange,
      })
    )
  }

  getChangedStructureRates(
    auditId: string,
    state: ChangedStructureRateFileState
  ) {
    return request<string, ErrorResponse>(
      this.baseUrl,
      `${PATH.AUDIT}/${auditId}/${PATH.RATES}/file?state=${state}`,
      { method: 'GET' },
      undefined,
      'blob'
    )
  }
}

export const auditApi = new Api()
