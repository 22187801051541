import { forwardRef } from 'react'
import { joinClasses } from 'utils/joinClasses'
import { TypographyProps } from './types'
import styles from './styles.module.scss'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Typography = forwardRef<any, TypographyProps>( //TODO: add type for ref
  (
    {
      Tag = 'span',
      children,
      name = 'Body1',
      color = 'blackMaster',
      markColor,
      className,
      html,
      onClick,
      href,
      markName,
      style,
      target,
      id,
      itemProp,
      itemScope,
      itemType,
    },
    ref
  ) => {
    const args: { dangerouslySetInnerHTML?: { __html: string } } = {}

    if (html) {
      args.dangerouslySetInnerHTML = {
        __html: html,
      }
    }

    return (
      <Tag
        {...args}
        className={joinClasses(
          styles['typography-wrapper'],
          styles[name],
          styles[color],
          [styles[`highlight-${markColor}`], markColor],
          [styles[`highlight-${markName}`], markName],
          className || ''
        )}
        ref={ref}
        onClick={onClick}
        href={href}
        style={style}
        target={target}
        id={id}
        itemProp={itemProp}
        itemScope={itemScope}
        itemType={itemType}
      >
        {children}
      </Tag>
    )
  }
)
