import { createSlice } from '@reduxjs/toolkit'
import { login, fetchUserData } from './thunks'
import { LoginState } from './types'

const initialState: LoginState = {
  userData: {
    displayName: '',
    roles: [],
    companyId: 0,
    profileUrl: '',
    sessionId: '',
    userName: '',
  },
  isLoading: false,
  errorMessage: '',
  isLoaded: false,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setErrorMessage(state, action) {
      state.errorMessage = action.payload
    },
    resetUserData(state) {
      state.userData = initialState.userData
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true
        state.errorMessage = ''
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false
        state.isLoaded = true
        const { meta, ...userData } = action.payload
        state.userData = {
          ...userData,
          companyId: meta.companyId || 0,
        }
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false
        state.errorMessage = action.payload as string
      })
      .addCase(fetchUserData.pending, (state) => {
        state.isLoading = true
        state.isLoaded = false
        state.errorMessage = ''
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.isLoading = false
        state.isLoaded = true
        state.userData = { ...action.payload, userName: action.payload.login }
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.isLoading = false
        state.isLoaded = true
        state.errorMessage = action.payload as string
      })
  },
})

export const authReducer = authSlice.reducer
export const { setErrorMessage, resetUserData } = authSlice.actions
