import { useState, useMemo, useCallback } from 'react'
import { Input } from '../Input'
import { Dropdown } from './components/Dropdown'
import { ItemType, SelectProps } from './types'
import { useOutsideClick } from 'ui/hooks/useOutsideClick'
import { joinClasses } from 'utils/joinClasses'
import styles from './styles.module.scss'

export const Select = ({
  placeholder,
  size,
  items,
  selectedValue,
  onChange,
  label,
  isRequired,
  error,
  noSpaceForError,
  className = '',
  tooltip,
  search,
  loading,
  disabled,
  notFoundTitle,
}: SelectProps) => {
  const [selectEl, setSelectEl] = useState<HTMLDivElement | null>(null)
  const [showDropdown, setShowDropdown] = useState(false)

  const closeDropdown = useCallback(() => setShowDropdown(false), [])

  const toggleDropdown = () => {
    setShowDropdown((prevShowDropdown) => !prevShowDropdown)
  }

  const handleChange = (value: string, fullData: ItemType) => {
    closeDropdown()
    onChange(value, fullData)
  }

  useOutsideClick(selectEl, closeDropdown)

  const selectedLabel = useMemo(
    () => items.find(({ value }) => value === selectedValue)?.label || '',
    [selectedValue, items]
  )

  const renderDropdown = () => {
    if (!showDropdown) {
      return null
    }

    return (
      <Dropdown
        items={items}
        selectedValue={selectedValue}
        onChange={handleChange}
        noSpaceForError={noSpaceForError}
        search={search}
        loading={loading}
        notFoundTitle={notFoundTitle}
      />
    )
  }

  const getIconRight = () => {
    if (disabled) {
      return ''
    }
    return showDropdown ? 'ArrowTopIcon' : 'ArrowBottomIcon'
  }

  return (
    <div ref={setSelectEl} className={joinClasses(styles.select, className)}>
      <Input
        readonly
        iconRight={getIconRight()}
        iconColor="blackMaster"
        iconSize="small"
        typographyName="Button2"
        onClick={toggleDropdown}
        iconOnClickRight={toggleDropdown}
        placeholder={placeholder}
        tooltip={tooltip}
        size={size}
        value={selectedLabel}
        label={label}
        isRequired={isRequired}
        error={error}
        disabled={disabled}
        spaceForError={noSpaceForError ? 'none' : 'auto'}
      />
      {renderDropdown()}
    </div>
  )
}
