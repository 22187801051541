import { passwordRegex } from 'constants/regex'
import { FIELDS, FORM_FIELDS_RULES, FORM_FIELDS_VALUES } from 'texts/formFields'
import { Rule } from 'ui/molecules/Form/components/FormItem/types'
import { getRequiredMessage } from 'utils/getRequiredMessage'

export const emailRules: Rule[] = [
  {
    type: 'required',
    message: getRequiredMessage(FIELDS.EMAIL),
  },
  {
    type: 'email',
    message: FORM_FIELDS_RULES.EMAIL,
  },
]

export const passwordRules: Rule[] = [
  {
    type: 'required',
    message: getRequiredMessage(FIELDS.PASSWORD),
  },
]

export const newPasswordRules: Rule[] = [
  {
    type: 'required',
    message: getRequiredMessage(FIELDS.PASSWORD),
  },
  {
    type: 'pattern',
    value: passwordRegex,
    message: FORM_FIELDS_RULES.PASSWORD.INVALID,
  },
]

export const confirmPasswordRules: Rule[] = [
  {
    type: 'required',
    message: getRequiredMessage(FIELDS.PASSWORD),
  },
  {
    type: 'pattern',
    value: passwordRegex,
    message: FORM_FIELDS_RULES.PASSWORD.INVALID,
  },
  {
    type: 'equal',
    value: FORM_FIELDS_VALUES.PASSWORD.ID,
    message: FORM_FIELDS_RULES.PASSWORD.MATCH,
  },
]

export const confirmationCodeRules: Rule[] = [
  {
    type: 'required',
    message: getRequiredMessage(FIELDS.CODE),
  },
]
