import { useEffect, useState, useRef } from 'react'

export const useDetectClickOutside = () => {
  const ref = useRef<HTMLInputElement>(null)
  const [openDropdown, setOpenDropdown] = useState(false)

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setOpenDropdown(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return { ref, openDropdown, setOpenDropdown }
}
