export const USER_ID_INDEX = 0
export const CATEGORY_INDEX = 2
export const ACTION_TYPE_INDEX = 5
export const ENTITY_ID_INDEX = 4
export const COMPANY_NAME_INDEX = 1
export const TIME_STAMP_INDEX = 6

export const ACTION_TYPE_EDIT = 'Edit'
export const ACTION_TYPE_IMPORT = 'Import'

export const FILTER_KEYS: Record<string, string> = {
  categories: 'category',
  users: 'userId',
  activityTypes: 'actionType',
  elements: 'entityId',
  auditDate: 'auditDate',
  companies: 'companyName',
}

export const SORTING_KEYS: Record<string, string> = {
  categories: 'Category',
  users: 'UserId',
  activityTypes: 'ActionType',
  elements: 'EntityId',
  auditDate: 'AuditDate',
  companies: 'CompanyName',
}

export const FILTERS_WITH_SEARCH = ['users', 'companies', 'elements']
export const FILTERS_WITH_SORTING = ['users', 'companies', 'auditDate']
