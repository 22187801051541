import { memo, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Props } from './types'
import { Typography } from 'ui/atoms/Typography'
import { joinClasses } from 'utils/joinClasses'
import { ArrowBottomIcon } from 'ui/icons'
import styles from './styles.module.scss'

export const MenuItem = memo(
  ({
    icon: Icon,
    name,
    activeItem,
    onMenuItemClick,
    path,
    children,
    withSubmenu = false,
    disabled,
    openedMenuItem,
  }: Props) => {
    const navigate = useNavigate()
    const [isSubmenuVisible, setSubmenuVisible] = useState(true)

    const isMenuItemActive = activeItem === name

    useEffect(() => {
      if (openedMenuItem) {
        setSubmenuVisible(openedMenuItem === name)
      }
    }, [openedMenuItem])

    const handleClick = () => {
      if (withSubmenu) {
        setSubmenuVisible(!isSubmenuVisible)
      } else {
        onMenuItemClick(name)
      }

      if (path && !withSubmenu) {
        navigate(path)
      }
    }

    return (
      <div className={styles['menu-item-wrapper']}>
        <div
          className={joinClasses(
            styles['menu-item'],
            [styles.active, isMenuItemActive],
            [styles['with-submenu'], withSubmenu],
            [styles.disabled, disabled]
          )}
          onClick={handleClick}
          role="menuitem"
        >
          {Icon && <Icon size="small" />}
          <Typography
            className={styles.title}
            color={isMenuItemActive ? 'blue700' : 'grey900'}
            name="Button2"
          >
            {name}
          </Typography>
          {withSubmenu && (
            <ArrowBottomIcon
              color={isSubmenuVisible ? 'blue700' : 'grey400'}
              className={joinClasses(styles['arrow-icon'], [
                styles['submenu-opened'],
                isSubmenuVisible,
              ])}
            />
          )}
        </div>
        {isSubmenuVisible && <div className={styles.submenu}>{children}</div>}
      </div>
    )
  }
)
