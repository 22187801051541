import { profileApi, authApi } from 'api'
import { ErrorResponse } from 'api/types'
import { URLS } from 'constants/urls'
import { Confirmation } from 'ui/molecules/Confirmation'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { PROFILE_ARCHIVE } from 'texts/profileArchive'
import { SEND_CODE, SEND_CODE_CONFIRMATION } from 'texts/profileSendCode'
import { PROFILE_DETAILS, PROFILE_DETAILS_TITLE } from 'texts/profileDetails'
import { PROFILE_LIST, PROFILE_LIST_CONFIRMATION } from 'texts/profileList'
import { Button } from 'ui/atoms/Button'
import { Container } from 'ui/molecules/Container'
import { ArchiveIcon, StreamlineIcon } from 'ui/icons'
import { breadcrumbs } from 'constants/breadcrumbs'
import { setNotificationMessage } from 'redux/notifications/slice'
import { ProfileDetailsForm } from '../ProfileDetailsForm'
import { useAppSelector } from 'redux/hooks'
import {
  isOperatorSelector,
  supplierCompanyIdSelector,
} from 'redux/login/selectors'
import { FormValues } from '../ProfileDetailsForm/types'
import { Typography } from 'ui/atoms/Typography'
import countryOfResidence from 'constants/countryOfResidence'
import { initialFormValues } from '../ProfileDetailsForm/constants'
import { useApiRequest } from 'hooks/useApiRequest'
import { scrollTop } from 'utils/scrollTop'
import { deactivateProfileSubtitle } from 'texts/profileConfirmModal'
import styles from './styles.module.scss'

export const ProfileEdit = () => {
  const [initialData, setInitialData] = useState<Partial<FormValues>>({})
  const [isFetching, setIsFetching] = useState(false)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [isSendCodeModalOpen, setIsSendCodeModalOpen] = useState(false)
  const supplierCompanyId = useSelector(supplierCompanyIdSelector)
  const [companyName, setCompanyName] = useState('')
  const [showSendCode, setShowSendCode] = useState(false)
  const isOperator = useAppSelector(isOperatorSelector)
  const params = useParams()

  const breadcrumbList = [breadcrumbs.profileList]

  const companyId = params.companyId || supplierCompanyId
  const { apiRequest, loading } = useApiRequest(
    (data) =>
      profileApi.updateCompany(Number(companyId), {
        ...data,
        logo: data.logo?.[0],
      }),
    undefined,
    true,
    undefined,
    false
  )

  const { apiRequest: getCompanyInfo } = useApiRequest(
    (companyId) => profileApi.getCompanyInfo(companyId),
    undefined,
    undefined,
    { isThrowError: true, withoutNotification: true }
  )

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleSendCode = async () => {
    try {
      await authApi.sendRegistrationCode(Number(companyId))
      dispatch(
        setNotificationMessage({
          notificationMessage:
            SEND_CODE_CONFIRMATION.CONFIRMATION_MODAL.SUCCESS_MESSAGE,
        })
      )
    } catch (e) {
      dispatch(
        setNotificationMessage({
          notificationMessage: (e as ErrorResponse).responseStatus.message,
          type: 'error',
        })
      )
    }
    setIsSendCodeModalOpen(false)
  }

  const handleArchiveCompany = () => {
    setIsConfirmationModalOpen(true)
  }

  const handleModalClose = () => {
    setIsConfirmationModalOpen(false)
  }

  const handleSendModalOpen = () => {
    setIsSendCodeModalOpen(true)
  }

  const handleSendModalClose = () => {
    setIsSendCodeModalOpen(false)
  }

  const handleConfirm = async () => {
    try {
      await profileApi.closeCompany(Number(companyId))
    } catch (e) {
      dispatch(
        setNotificationMessage({
          notificationMessage: (e as ErrorResponse).responseStatus.message,
          type: 'error',
        })
      )
      setIsConfirmationModalOpen(false)
    }
  }

  const handleRedirectToArchive = () => {
    navigate(`..${URLS.ARCHIVE}`)
  }

  useEffect(() => {
    const getInfo = async () => {
      if (companyId) {
        const response = await getCompanyInfo(Number(companyId))

        if (!response) {
          return null
        }

        const { companyInfo } = response.data

        setCompanyName(
          (isOperator
            ? companyInfo.companyName
            : companyInfo.companyName?.split('#')[0]) || ''
        )
        if (!companyInfo.hasAccount) {
          setShowSendCode(true)
        }

        setInitialData({
          ...initialFormValues,
          ...companyInfo,
          companyName: isOperator
            ? companyInfo.companyName
            : companyInfo.companyName?.split('#')[0],
          phoneCor: countryOfResidence.find(
            ({ code }) => code === companyInfo.phoneCountryCode
          ),
          companyCountryID: String(companyInfo.companyCountryID),
          companyCurrencyID: String(companyInfo.companyCurrencyID),
          logo: companyInfo.logo
            ? [
                {
                  ...new File(['file'], 'logo'),
                  url: companyInfo.logo,
                },
              ]
            : [],
        })
        setIsFetching(true)
      }
    }
    getInfo()
  }, [companyId])

  const handleSubmit = async (data: Partial<FormValues>) => {
    delete data.phoneCor
    const response = await apiRequest(data)
    if (response) {
      setCompanyName(response.data.companyInfo.companyName || '')
      dispatch(
        setNotificationMessage({
          notificationMessage: PROFILE_DETAILS.SUCCESS_UPDATE_MESSAGE,
        })
      )
      scrollTop()
    }
  }

  const header = (
    <>
      {isConfirmationModalOpen && (
        <Confirmation
          confirmModalProps={{
            question: `${PROFILE_LIST_CONFIRMATION.CONFIRMATION_MODAL.QUESTION} ${initialData?.companyName}?`,
            onConfirm: handleConfirm,
            onClose: handleModalClose,
            title: `${PROFILE_LIST.ARCHIVE} ${companyName}`,
          }}
          successModalProps={{
            onClose: handleModalClose,
            title: PROFILE_LIST_CONFIRMATION.SUCCESS_MODAL.TITLE,
            buttonLabel: PROFILE_LIST_CONFIRMATION.SUCCESS_MODAL.BUTTON_LABEL,
            onConfirm: handleRedirectToArchive,
            subTitle: deactivateProfileSubtitle(initialData?.companyName),
          }}
        />
      )}
      {isSendCodeModalOpen && (
        <Confirmation
          confirmModalProps={{
            question: (
              <>
                {SEND_CODE_CONFIRMATION.CONFIRMATION_MODAL.QUESTION}
                <Typography name="Button1" color="grey900">
                  {initialData?.email}
                </Typography>
                ?
              </>
            ),
            onConfirm: handleSendCode,
            onClose: handleSendModalClose,
            title: `${SEND_CODE_CONFIRMATION.CONFIRMATION_MODAL.TITLE}`,
            confirmLabel: SEND_CODE.SEND,
          }}
        />
      )}
      <Typography Tag="h5" name="H5">
        {PROFILE_DETAILS_TITLE}
      </Typography>
    </>
  )

  const containerProps = isOperator
    ? {
        breadcrumbList: breadcrumbList,
        currentPageLabel: PROFILE_DETAILS_TITLE,
        title: header,
        companyName: companyName,
        withNavigation: true,
        rightBlock: (
          <div className={styles['right-block']}>
            {showSendCode && (
              <Button
                label={SEND_CODE.BUTTON}
                size="large"
                typographyName="Button2"
                variant="link"
                icon={<StreamlineIcon color="blue700" />}
                iconPosition="right"
                onClick={handleSendModalOpen}
              />
            )}
            <Button
              label={PROFILE_ARCHIVE.ADD}
              size="large"
              typographyName="Button2"
              variant="link"
              icon={<ArchiveIcon color="blue700" />}
              iconPosition="right"
              onClick={handleArchiveCompany}
            />
          </div>
        ),
      }
    : {
        breadcrumbList: [],
        title: PROFILE_DETAILS_TITLE,
        tooltip: PROFILE_LIST.TOOLTIP_CONTENT,
      }

  return isFetching ? (
    <Container {...containerProps}>
      <ProfileDetailsForm
        initialValues={initialData}
        handleSubmit={handleSubmit}
        withActionButtons
        readOnly={false}
        isLoading={loading}
      />
    </Container>
  ) : null
}
