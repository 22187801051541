export const minMaxPeriodDayValidation = (
  _value: string | number,
  values: Record<string, unknown>
) => {
  if (
    values.maximumPeriodDay &&
    values.minimumPeriodDay &&
    Number(values.maximumPeriodDay) < Number(values.minimumPeriodDay)
  ) {
    return true
  }
  return false
}
