import { getRequiredMessage } from 'utils/getRequiredMessage'
import { LATIN_LETTER_RULE } from './uiTexts'

export const PROFILE_DETAILS = {
  INFO_TITLE: 'Profile information',
  ACCOUNT_INFO: 'Account info',
  ACCOUNT_INFO_CONTENT:
    "This information is only used to auto fill your details and make it quicker for you to book. Your details will be stored securely and won't be shared publicy.",
  UPLOAD_FILE: {
    TITLE: 'Logo <mark>*</mark>',
    SIZE_TITLE: 'Image size:',
    SIZE_CONTENT: '100x40 px and 30 MB',
    FORMAT_TITLE: 'Image format:',
    FORMAT_CONTENT: '.jpeg, .png, .jpg',
    LABEL: 'Drag & drop or click to upload logo',
    SIZE_ERROR: 'The picture exceeds maximum file size limit: 30MB.',
    DELETE_CONFIRMATION: 'Are you sure that you want to delete company logo?',
    DELETE_MODAL_TITLE: 'Delete company logo',
  },
  FORM: {
    CONTACT_FORM_TITLE: 'Contact information',
    PAYMENT_FORM_TITLE: 'Payment information',
  },
  BUTTONS: {
    CANCEL_BUTTON_LABEL: 'Cancel',
    SAVE_BUTTON_LABEL: 'Save',
  },
  DISCLAIMER: 'Disclaimer: Indicates a required field.',
  REVIEWS: 'reviews',
  SUCCESS_UPDATE_MESSAGE: 'Your changes have been successfully saved',
}

export const PROFILE_ADD_TITLE = 'Profile adding'
export const PROFILE_DETAILS_TITLE = 'Profile details'

export const CONTACT_INFORMATION_FORM_VALUES = {
  NAME: {
    ID: 'companyName',
    PLACEHOLDER: 'Please enter a company name',
    LABEL: 'Company name',
    REQUIRED_MESSAGE: getRequiredMessage('company name'),
    VALIDATION_MESSAGE: LATIN_LETTER_RULE,
  },
  POSTAL_CODE: {
    ID: 'postalCode',
    PLACEHOLDER: 'Please enter a postal code / postcode / ZIP code',
    LABEL: 'Postal code / Postcode / ZIP code',
    REQUIRED_MESSAGE: getRequiredMessage('Postal code / Postcode / ZIP code'),
    VALIDATION_MESSAGE: LATIN_LETTER_RULE,
  },
  PHONE_COR: {
    ID: 'phoneCor',
    REQUIRED_MESSAGE: 'Please select your country code',
  },
  LOGO: {
    ID: 'logo',
    REQUIRED_MESSAGE: 'Please upload a logo',
  },
  COUNTRY: {
    ID: 'companyCountryID',
    PLACEHOLDER: 'Please select a country',
    LABEL: 'Country',
    REQUIRED_MESSAGE: 'Please select a country.',
    SEARCH_PLACEHOLDER: 'Search for a country',
  },
  STREET: {
    ID: 'street',
    PLACEHOLDER: 'Please enter a street',
    LABEL: 'Street',
    REQUIRED_MESSAGE: getRequiredMessage('street'),
    VALIDATION_MESSAGE: LATIN_LETTER_RULE,
  },
  BUILDING: {
    ID: 'building',
    PLACEHOLDER: 'Please enter a building',
    LABEL: 'Building',
    REQUIRED_MESSAGE: getRequiredMessage('building'),
    VALIDATION_MESSAGE: LATIN_LETTER_RULE,
  },
  CITY: {
    ID: 'city',
    PLACEHOLDER: 'Please select a city',
    LABEL: 'City',
    REQUIRED_MESSAGE: 'Please select a city.',
    VALIDATION_MESSAGE: LATIN_LETTER_RULE,
    SEARCH_PLACEHOLDER: 'Search for a city',
  },
  URL: {
    ID: 'url',
    PLACEHOLDER: 'Please enter your website',
    LABEL: 'Website',
    VALIDATION_MESSAGE: LATIN_LETTER_RULE,
  },
  EMAIL: {
    ID: 'email',
    PLACEHOLDER: 'Please enter your email',
    LABEL: 'Email',
    REQUIRED_MESSAGE: 'Please enter your email.',
    VALIDATION_MESSAGE: 'Please enter a valid email.',
    MAX_VALIDATION_MESSAGE: "Can't contain more than 90 characters.",
  },
  STATE: {
    ID: 'state',
    PLACEHOLDER: 'Please enter a state / region',
    LABEL: 'State / Region',
    VALIDATION_MESSAGE: LATIN_LETTER_RULE,
  },
  PHONE: {
    ID: 'phone',
    PLACEHOLDER: 'Please enter your phone number',
    LABEL: 'Phone number',
    REQUIRED_MESSAGE: getRequiredMessage('phone number'),
    VALIDATION_MESSAGE_MIN: 'Phone number cannot contain less than 3 digits.',
    VALIDATION_MESSAGE_MAX: 'Phone number cannot contain more than 20 digits.',
  },
}

export const PAYMENT_INFORMATION_FORM_VALUES = {
  LEGAL_NAME: {
    ID: 'legalName',
    PLACEHOLDER: "Please enter a company's legal name",
    LABEL: "Company's legal name",
    REQUIRED_MESSAGE: getRequiredMessage("company's legal name"),
    VALIDATION_MESSAGE: LATIN_LETTER_RULE,
  },
  REGISTRATION_NUMBER: {
    ID: 'registrationNumber',
    PLACEHOLDER: 'Please enter a registration number',
    LABEL: 'Registration number',
    REQUIRED_MESSAGE: getRequiredMessage('registration number'),
    VALIDATION_MESSAGE: LATIN_LETTER_RULE,
    MAX_CHARACTERS_MESSAGE:
      "Registration number can't contain more than 15 characters.",
  },
  BANK_NAME: {
    ID: 'bankName',
    PLACEHOLDER: 'Please enter a bank name',
    LABEL: 'Bank name',
    VALIDATION_MESSAGE: LATIN_LETTER_RULE,
    MAX_CHARACTERS_MESSAGE: "Bank name can't contain more than 30 characters.",
  },
  BANK_ADDRESS: {
    ID: 'bankAddress',
    PLACEHOLDER: 'Please enter a bank address',
    LABEL: 'Bank address',
    VALIDATION_MESSAGE: LATIN_LETTER_RULE,
    MAX_CHARACTERS_MESSAGE:
      "Bank address can't contain more than 100 characters.",
  },
  SWIFT: {
    ID: 'swiftCode',
    PLACEHOLDER: 'Please enter a SWIFT code',
    LABEL: 'SWIFT code',
    REQUIRED_MESSAGE: getRequiredMessage('SWIFT code'),
    VALIDATION_MESSAGE: LATIN_LETTER_RULE,
    MAX_CHARACTERS_MESSAGE: "SWIFT code can't contain more than 15 characters.",
  },
  IBAN: {
    ID: 'bankAccountNumber',
    PLACEHOLDER: 'Please enter an account number / IBAN',
    LABEL: 'Account number / IBAN',
    REQUIRED_MESSAGE: 'Please enter an account number / IBAN.',
    VALIDATION_MESSAGE: LATIN_LETTER_RULE,
    MAX_CHARACTERS_MESSAGE:
      "Account number / IBAN can't contain more than 35 characters.",
  },
  ABA: {
    ID: 'abaRoutingNumber',
    PLACEHOLDER: 'Please enter an ABA Routing number',
    LABEL: 'ABA Routing number',
    VALIDATION_MESSAGE: LATIN_LETTER_RULE,
    MAX_CHARACTERS_MESSAGE:
      "ABA Routing number can't contain more than 9 characters.",
  },
  EMAIL: {
    ID: 'accountingEmailAddress',
    PLACEHOLDER: 'Please enter an accounting email address',
    LABEL: 'Accounting email address',
    VALIDATION_MESSAGE: 'Please enter a valid email.',
    MAX_VALIDATION_MESSAGE: "Can't contain more than 90 characters.",
  },
  CURRENCY: {
    ID: 'companyCurrencyID',
    PLACEHOLDER: 'Please select a currency',
    LABEL: 'Currency',
    REQUIRED_MESSAGE: 'Please select a currency.',
    SEARCH_PLACEHOLDER: 'Search for a currency',
  },
  VAT: {
    ID: 'vatNumber',
    PLACEHOLDER: 'Please enter a VAT number',
    LABEL: 'VAT number',
    REQUIRED_MESSAGE: getRequiredMessage('VAT number'),
    VALIDATION_MESSAGE: LATIN_LETTER_RULE,
    MAX_CHARACTERS_MESSAGE: "VAT number can't contain more than 20 characters.",
  },
}

export const FIELDS = {
  EMAIL: 'email',
  PASSWORD: 'password',
  CONFIRM_PASSWORD: 'confirm password',
  CODE: 'code',
}
