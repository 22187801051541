import { TYPOGRAPHY_COLORS } from 'constants/TypographyColors'
import { SVGIcon } from './SvgIcon/SvgIcon'
import { IconProps } from './types'

export const CircleMinusIcon = ({
  size,
  color = 'grey400',
  className,
  rotate,
}: IconProps) => (
  <SVGIcon
    size={size}
    rotate={rotate}
    viewBox="0 0 20 20"
    color={color}
    className={className}
  >
    <path
      d="M9.9974 18.3332C14.5998 18.3332 18.3307 14.6022 18.3307 9.99984C18.3307 5.39746 14.5998 1.6665 9.9974 1.6665C5.39502 1.6665 1.66406 5.39746 1.66406 9.99984C1.66406 14.6022 5.39502 18.3332 9.9974 18.3332Z"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66406 10H13.3307"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVGIcon>
)
