import { fleetApi, FleetApiTypes } from 'api'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { VEHICLE_ADD_NOTIFICATION } from 'texts/vehicleConfirmModal'
import { VEHICLE_ADDING_BREADCRUMB, VEHICLE_TITLE } from 'texts/vehicleDetails'
import { Container } from 'ui/molecules/Container'
import { VehicleDetailsForm } from '../VehicleDetailsForm'
import { getDetailsBreadcrumbsList } from 'utils/getDetailsBreadcrumbsList'
import { isOperatorSelector } from 'redux/login/selectors'
import { useCompanyInfo } from 'ui/hooks/useCompanyInfo'
import { setNotificationMessage } from 'redux/notifications/slice'
import { useNavigate } from 'react-router-dom'
import { useApiRequest } from 'hooks/useApiRequest'
import { GTM } from 'utils/gtm'
import { DATA_LAYER_EVENT } from 'constants/dataLayerEvents'

export const VehicleAdd = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { apiRequest, loading } = useApiRequest(
    (companyId, data) => fleetApi.addVehicle(Number(companyId), data),
    undefined,
    true,
    undefined,
    false
  )

  const isOperator = useAppSelector(isOperatorSelector)

  const { companyId } = useCompanyInfo(isOperator)

  const breadcrumbList = getDetailsBreadcrumbsList(
    isOperator,
    String(companyId),
    'vehicles'
  )

  const handleSubmit = async (data: FleetApiTypes.AddVehicleRequest) => {
    const response = await apiRequest(Number(companyId), data)
    if (response) {
      dispatch(
        setNotificationMessage({
          notificationMessage: VEHICLE_ADD_NOTIFICATION.TITLE,
        })
      )
      GTM.dataLayer({
        event: DATA_LAYER_EVENT.CAR_ADD,
        car_type: response.data.vehicleInfo.carGroupName,
        car_sipp: response.data.vehicleInfo.sippCode,
      })
      navigate(-1)
    }
  }

  return (
    <Container
      breadcrumbList={breadcrumbList}
      currentPageLabel={VEHICLE_ADDING_BREADCRUMB}
      title={VEHICLE_TITLE}
      withNavigation={isOperator}
    >
      <VehicleDetailsForm handleSubmit={handleSubmit} isLoading={loading} />
    </Container>
  )
}
