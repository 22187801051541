import { useEffect, useMemo, useState } from 'react'
import { Typography } from '@frontend/design_system'
import { STOP_SELLS_DETAILS_FORM_VALUES } from 'texts/stopSellsDetails'
import { FormItem } from 'ui/molecules/Form/components/FormItem'
import { Multiselect } from 'ui/atoms/Multiselect'
import { findSelectAllItem } from 'ui/atoms/Multiselect/utils'
import { fleetApi, referencesApi } from 'api'
import { City, Location } from 'api/references/types'
import { StopSaleLocationFormProps } from './types'
import { Rule } from 'ui/molecules/Form/components/FormItem/types'
import { DatePicker } from 'ui/molecules/DatePicker'
import { EMPTY_RULES } from 'constants/form'
import { useSelectValues } from 'hooks/useSelectValues'
import { useApiRequest } from 'hooks/useApiRequest'
import { PriceListVehicle } from 'api/fleet/types'
import { MultiselectItem } from 'ui/atoms/Multiselect/types'
import { STOP_SALES } from 'texts/stopSells'
import styles from './styles.module.scss'

export const StopSaleLocationForm = ({
  values,
  countries,
  isAddForm,
  companyId,
  isReadonlyMode,
}: StopSaleLocationFormProps) => {
  const [cities, setCities] = useState<City[]>([])
  const [locations, setLocations] = useState<Location[]>([])
  const [initDataLoad, setInitDataLoad] = useState(false)
  const [carClasses, setCarClasses] = useState<PriceListVehicle[]>([])

  const locationsResponse = useApiRequest((companyId, citiesId) =>
    referencesApi.getLocations({
      companyId,
      cities: citiesId,
    })
  )

  const citiesResponse = useApiRequest((countryId) =>
    referencesApi.getCities(Number(countryId), companyId)
  )

  const carClassesResponse = useApiRequest((countries, locations) =>
    fleetApi.getPriceListVehicles({
      companyId: Number(companyId),
      locations,
      countries,
    })
  )

  const currentCities = values.cities as MultiselectItem[]
  const currentCountries = values.countries as MultiselectItem[]
  const currentLocations = values.locations as MultiselectItem[]
  const currentCarClasses = values.carClasses as MultiselectItem[]

  const selectedAllCountries = findSelectAllItem(currentCountries)
  const selectedAllCities = findSelectAllItem(currentCities)
  const selectedAllLocations = findSelectAllItem(currentLocations)
  const selectedAllCarClasses = findSelectAllItem(currentCarClasses)

  const handleOnChange = (
    newValue: MultiselectItem[],
    onChange: (newValue: MultiselectItem[]) => void,
    onChangeField: (newValue: MultiselectItem[], fieldId: string) => void,
    fieldId: string
  ) => {
    const isClean = initDataLoad || isAddForm

    if (
      fieldId === STOP_SELLS_DETAILS_FORM_VALUES.COUNTRY.ID &&
      (isClean || !newValue.length)
    ) {
      onChangeField([], STOP_SELLS_DETAILS_FORM_VALUES.CITY.ID)
      onChangeField([], STOP_SELLS_DETAILS_FORM_VALUES.LOCATION.ID)
      onChangeField([], STOP_SELLS_DETAILS_FORM_VALUES.SIPP.ID)
    }

    if (
      fieldId === STOP_SELLS_DETAILS_FORM_VALUES.CITY.ID &&
      (isClean || !newValue.length)
    ) {
      onChangeField([], STOP_SELLS_DETAILS_FORM_VALUES.LOCATION.ID)
      onChangeField([], STOP_SELLS_DETAILS_FORM_VALUES.SIPP.ID)
    }

    if (
      fieldId === STOP_SELLS_DETAILS_FORM_VALUES.LOCATION.ID &&
      (isClean || !newValue.length)
    ) {
      onChangeField([], STOP_SELLS_DETAILS_FORM_VALUES.SIPP.ID)
    }

    onChange(newValue)
  }

  useEffect(() => {
    if (currentCountries.length === 1) {
      const countryId = countries.find(
        (el) => el.name === currentCountries[0].label
      )?.id

      if (countryId) {
        const getCities = async () => {
          const response = await citiesResponse.apiRequest(countryId)
          if (response) {
            setCities(response.data.items)
          }
        }
        getCities()
      }
    }
  }, [currentCountries.length])

  useEffect(() => {
    if (currentCities.length) {
      const citiesId: string[] = []
      cities.forEach((item) => {
        if (
          selectedAllCities ||
          currentCities.find((el) => el.label === item.name)
        ) {
          citiesId.push(item.id)
        }
      })

      if (citiesId.length) {
        const getLocations = async () => {
          const response = await locationsResponse.apiRequest(
            companyId,
            citiesId
          )
          if (response) {
            setLocations(response.data.items)
          }
        }
        getLocations()
      }
    }
  }, [currentCities.length, cities.length])

  useEffect(() => {
    if (currentLocations.length || currentCountries.length) {
      const locationIds: string[] = []
      const countryIds: string[] = []

      if (currentLocations.length) {
        locations.forEach((item) => {
          if (
            selectedAllLocations ||
            currentLocations.find((el) => el.label === item.name)
          ) {
            locationIds.push(item.id)
          }
        })
      }

      if (currentCountries.length > 1) {
        countries.forEach((item) => {
          if (currentCountries.find((el) => el.label === item.name)) {
            countryIds.push(item.id)
          }
        })
      }

      if ((locationIds.length || countryIds.length) && !selectedAllCountries) {
        setInitDataLoad(true)
        const getClasses = async () => {
          const response = await carClassesResponse.apiRequest(
            countryIds,
            locationIds
          )
          if (response) {
            setCarClasses(response.data.classes)
          }
        }
        getClasses()
      }
    }
  }, [currentLocations.length, locations.length, currentCountries.length])

  const rules: { [key: string]: Rule[] } = useMemo(
    () => ({
      country: [
        {
          type: 'required',
          message: STOP_SELLS_DETAILS_FORM_VALUES.COUNTRY.REQUIRED_MESSAGE,
        },
      ],
      city: [
        {
          type: 'required',
          message: STOP_SELLS_DETAILS_FORM_VALUES.CITY.REQUIRED_MESSAGE,
        },
      ],
      locations: [
        {
          type: 'required',
          message: STOP_SELLS_DETAILS_FORM_VALUES.LOCATION.REQUIRED_MESSAGE,
        },
      ],
      sipp: [
        {
          type: 'required',
          message: STOP_SELLS_DETAILS_FORM_VALUES.SIPP.REQUIRED_MESSAGE,
        },
      ],
      from: [
        {
          type: 'required',
          message: STOP_SELLS_DETAILS_FORM_VALUES.START_DATE.REQUIRED_MESSAGE,
        },
      ],
      to: [
        {
          type: 'required',
          message: STOP_SELLS_DETAILS_FORM_VALUES.END_DATE.REQUIRED_MESSAGE,
        },
      ],
    }),
    []
  )

  const countriesSelectValues = useSelectValues(countries)
  const citiesSelectValues = useSelectValues(cities)
  const locationSelectValues = useSelectValues(locations)
  const carClassesSelectValues = useSelectValues(
    carClasses.map((el) => ({
      id: `${el.sipp}-${el.class}`,
      name: `${el.sipp}-${el.class}`,
    }))
  )

  return (
    <>
      <div className={styles['form-items-row']}>
        <FormItem
          id={STOP_SELLS_DETAILS_FORM_VALUES.COUNTRY.ID}
          className={styles['form-item']}
          rules={rules.country}
        >
          {({ value, error, onChange, onChangeField }) => (
            <Multiselect
              error={error}
              onChange={(newValue) =>
                handleOnChange(
                  newValue,
                  onChange,
                  onChangeField,
                  STOP_SELLS_DETAILS_FORM_VALUES.COUNTRY.ID
                )
              }
              value={value}
              unitName={{
                plural: STOP_SELLS_DETAILS_FORM_VALUES.COUNTRY.UNIT_NAME,
              }}
              label={STOP_SELLS_DETAILS_FORM_VALUES.COUNTRY.LABEL}
              placeholder={STOP_SELLS_DETAILS_FORM_VALUES.COUNTRY.PLACEHOLDER}
              items={countriesSelectValues}
              readonly={isReadonlyMode}
              withAllSelect
              withSelectAllItem
              selectAllByDefault={selectedAllCountries}
              autoSelectAll={selectedAllCountries}
              isRequired
            />
          )}
        </FormItem>
        <FormItem
          id={STOP_SELLS_DETAILS_FORM_VALUES.CITY.ID}
          className={styles['form-item']}
          rules={
            selectedAllCountries || currentCountries.length > 1
              ? EMPTY_RULES
              : rules.city
          }
        >
          {({ value, error, onChange, onChangeField }) => (
            <Multiselect
              error={error}
              onChange={(newValue) =>
                handleOnChange(
                  newValue,
                  onChange,
                  onChangeField,
                  STOP_SELLS_DETAILS_FORM_VALUES.CITY.ID
                )
              }
              value={value}
              label={STOP_SELLS_DETAILS_FORM_VALUES.CITY.LABEL}
              placeholder={STOP_SELLS_DETAILS_FORM_VALUES.CITY.PLACEHOLDER}
              items={citiesSelectValues}
              readonly={
                (!currentCountries.length && !!countries.length) ||
                (currentCountries.length > 1 && !selectedAllCountries) ||
                isReadonlyMode
              }
              unitName={{
                plural: STOP_SELLS_DETAILS_FORM_VALUES.CITY.UNIT_NAME,
              }}
              withAllSelect
              withSelectAllItem
              selectAllByDefault={selectedAllCities}
              autoSelectAll={selectedAllCities}
              disableWithSelectAllItem={
                selectedAllCountries || currentCountries.length > 1
              }
              loading={citiesResponse.loading}
              isRequired
            />
          )}
        </FormItem>
      </div>
      <div className={styles['form-items-row']}>
        <FormItem
          id={STOP_SELLS_DETAILS_FORM_VALUES.LOCATION.ID}
          className={styles['form-item']}
          rules={
            selectedAllCountries || currentCountries.length > 1
              ? EMPTY_RULES
              : rules.locations
          }
        >
          {({ value, error, onChange, onChangeField }) => (
            <Multiselect
              error={error}
              onChange={(newValue) =>
                handleOnChange(
                  newValue,
                  onChange,
                  onChangeField,
                  STOP_SELLS_DETAILS_FORM_VALUES.LOCATION.ID
                )
              }
              value={value}
              unitName={{
                plural: STOP_SELLS_DETAILS_FORM_VALUES.LOCATION.UNIT_NAME,
              }}
              label={STOP_SELLS_DETAILS_FORM_VALUES.LOCATION.LABEL}
              placeholder={STOP_SELLS_DETAILS_FORM_VALUES.LOCATION.PLACEHOLDER}
              items={locationSelectValues}
              readonly={!currentCities.length || isReadonlyMode}
              withAllSelect
              withSelectAllItem
              selectAllByDefault={selectedAllLocations}
              autoSelectAll={selectedAllLocations}
              disableWithSelectAllItem={
                selectedAllCountries || currentCountries.length > 1
              }
              loading={locationsResponse.loading}
              isRequired
            />
          )}
        </FormItem>
        <FormItem
          id={STOP_SELLS_DETAILS_FORM_VALUES.SIPP.ID}
          className={styles['form-item']}
          rules={selectedAllCountries ? EMPTY_RULES : rules.sipp}
        >
          {({ value, error, onChange, onChangeField }) => (
            <Multiselect
              error={error}
              onChange={(newValue) =>
                handleOnChange(
                  newValue,
                  onChange,
                  onChangeField,
                  STOP_SELLS_DETAILS_FORM_VALUES.SIPP.ID
                )
              }
              value={value}
              unitName={{
                plural: STOP_SELLS_DETAILS_FORM_VALUES.SIPP.UNIT_NAME,
              }}
              label={STOP_SELLS_DETAILS_FORM_VALUES.SIPP.LABEL}
              placeholder={STOP_SELLS_DETAILS_FORM_VALUES.SIPP.PLACEHOLDER}
              items={carClassesSelectValues}
              readonly={
                (!currentLocations.length && currentCountries.length === 1) ||
                !currentCountries.length ||
                isReadonlyMode
              }
              withAllSelect
              withSelectAllItem
              disableWithSelectAllItem={selectedAllCountries}
              selectAllByDefault={selectedAllCarClasses}
              autoSelectAll={selectedAllCarClasses}
              loading={carClassesResponse.loading}
              isRequired
              tooltip={
                <Typography name="caption1WMedium">
                  {STOP_SALES.CAR_CLASS_TOOLTIP}
                </Typography>
              }
            />
          )}
        </FormItem>
      </div>
      <div className={styles['form-items-row']}>
        <FormItem
          id={STOP_SELLS_DETAILS_FORM_VALUES.START_DATE.ID}
          className={styles['form-item']}
          rules={rules.from}
        >
          {({ value, onChange, error }) => (
            <DatePicker
              size="large"
              handleDayChange={onChange}
              isRequired
              error={error}
              placeholder={
                STOP_SELLS_DETAILS_FORM_VALUES.START_DATE.PLACEHOLDER
              }
              label={STOP_SELLS_DETAILS_FORM_VALUES.START_DATE.LABEL}
              selectedDay={value}
              disabled={isReadonlyMode}
            />
          )}
        </FormItem>
        <FormItem
          id={STOP_SELLS_DETAILS_FORM_VALUES.END_DATE.ID}
          className={styles['form-item']}
          rules={rules.to}
        >
          {({ value, onChange, error }) => (
            <DatePicker
              size="large"
              handleDayChange={onChange}
              isRequired
              error={error}
              placeholder={STOP_SELLS_DETAILS_FORM_VALUES.END_DATE.PLACEHOLDER}
              label={STOP_SELLS_DETAILS_FORM_VALUES.END_DATE.LABEL}
              selectedDay={value}
              disabled={isReadonlyMode}
            />
          )}
        </FormItem>
      </div>
    </>
  )
}
