import { ModalCloseIcon } from 'ui/icons/ModalCloseIcon'
import { Typography } from '@frontend/design_system'
import { TipCarClassProps } from './types'
import styles from './styles.module.scss'

export const TipCarClass = ({ onClick, title }: TipCarClassProps) => (
  <div className={styles['modal-info-tip']}>
    <Typography name="Subtitle7" className={styles['modal-info-tip-title']}>
      {title}
    </Typography>

    <ModalCloseIcon
      size="medium"
      color="grey400"
      className={styles['modal-side-tip-close']}
      onClick={onClick}
    />
  </div>
)
