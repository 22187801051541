export const RULE_TYPES = {
  required: 'required',
  email: 'email',
  number: 'number',
  min: 'min',
  max: 'max',
  equal: 'equal',
  custom: 'custom',
  pattern: 'pattern',
}
