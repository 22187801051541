/* eslint-disable @typescript-eslint/no-magic-numbers */
import { format } from 'date-fns'

type SeasonRanges = {
  start: { day: number; month: number }
  end: { day: number; month: number }
}[]

export const getSeasonFromSeasonRanges = (seasonRanges: SeasonRanges) => {
  function getSeason(month: number) {
    if (month === 12 || month === 1 || month === 2) {
      return 'winter'
    } else if (month === 3 || month === 4 || month === 5) {
      return 'spring'
    } else if (month === 6 || month === 7 || month === 8) {
      return 'summer'
    }
    return 'autumn'
  }

  const seasons: string[] = []

  seasonRanges.forEach((range) => {
    const { start, end } = range

    seasons.push(getSeason(start.month))
    seasons.push(getSeason(end.month))
  })

  return Array.from(new Set(seasons))
}

export const formatSeasonRangesFromString = (seasonRanges: string) => {
  const ranges = seasonRanges.split(', ')
  return ranges.map((el) => {
    const [start, end] = el.split('-')
    const [startDay, startMonth] = format(new Date(start), 'dd-MM').split('-')
    const [endDay, endMonth] = format(new Date(end), 'dd-MM').split('-')

    return {
      start: { day: Number(startDay), month: Number(startMonth) },
      end: { day: Number(endDay), month: Number(endMonth) },
    }
  })
}
