import { useMemo } from 'react'
import { EQUIPMENT_DETAILS_FORM_VALUES } from 'texts/equipmentDetails'
import { Rule } from 'ui/molecules/Form/components/FormItem/types'

export const useValidationRules = () => {
  const validationRules: { [key: string]: Rule[] } = useMemo(
    () => ({
      extraId: [
        {
          type: 'required',
          message:
            EQUIPMENT_DETAILS_FORM_VALUES.EQUIPMENT_NAME.REQUIRED_MESSAGE,
        },
      ],
      maxCount: [
        {
          type: 'required',
          message: EQUIPMENT_DETAILS_FORM_VALUES.MAX_COUNT.REQUIRED_MESSAGE,
        },
        {
          type: 'custom',
          value: (value: string | number) => Number(value) <= 0,
          message: EQUIPMENT_DETAILS_FORM_VALUES.MAX_COUNT.VALIDATION_MESSAGE,
        },
      ],
      hirePoints: [
        {
          type: 'required',
          message: EQUIPMENT_DETAILS_FORM_VALUES.LOCATION.REQUIRED_MESSAGE,
        },
      ],
    }),
    []
  )

  return validationRules
}
