import { Link } from 'react-router-dom'
import { joinClasses } from 'utils/joinClasses'
import { Typography } from '../Typography'
import { LinkProps } from './types'

export const CustomLink = ({
  href,
  typographyName = 'Button1',
  color = 'blue700',
  hover = 'primary',
  className,
  target,
  onClick,
  itemProp,
  itemScope,
  itemType,
  children,
}: LinkProps) => (
  <Link to={href}>
    <Typography
      Tag="span"
      name={typographyName}
      color={color}
      className={joinClasses('link', `link-hover-${hover}`, className || '')}
      target={target}
      onClick={onClick}
      itemProp={itemProp}
      itemScope={itemScope}
      itemType={itemType}
    >
      {children}
    </Typography>
  </Link>
)
