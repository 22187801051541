import { TYPOGRAPHY_COLORS } from 'constants/TypographyColors'
import { SVGIcon } from './SvgIcon/SvgIcon'
import { IconProps } from './types'

export const MailIcon = ({ size, color = 'grey400', className }: IconProps) => (
  <SVGIcon size={size} color={color} className={className}>
    <path
      fill="none"
      d="M2.66652 2.66669H13.3332C14.0665 2.66669 14.6665 3.26669 14.6665 4.00002V12C14.6665 12.7334 14.0665 13.3334 13.3332 13.3334H2.66652C1.93319 13.3334 1.33319 12.7334 1.33319 12V4.00002C1.33319 3.26669 1.93319 2.66669 2.66652 2.66669Z"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fill="none"
      d="M14.6665 4L7.99986 8.66667L1.33319 4"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVGIcon>
)
