import { getRequiredMessage } from 'utils/getRequiredMessage'
import { DIGITS_RULE, DIGITS_WITH_DOT_RULE, LATIN_LETTER_RULE } from './uiTexts'

export const LOCATION_DETAILS = {
  FORM: {
    CONTACT_FORM_TITLE: 'Contact information',
    WORKING_HOURS_TITLE: 'Working hours <mark>*</mark>',
    RESERVATION_TITLE: 'Reservation',
    WAITING_HOURS_TITLE: 'Waiting hours',
  },
  BUTTONS: {
    CANCEL_BUTTON_LABEL: 'Cancel',
    SAVE_BUTTON_LABEL: 'Save',
  },
}

export const WAITING_HOURS_PERIOD = 'Waiting hours period: '

export const LOCATION_ADDING_FORM_VALUES = {
  HIRE_POINT_NAME: {
    ID: 'hirePointName',
    PLACEHOLDER: 'Please enter a hire point name',
    LABEL: 'Hire point name',
    REQUIRED_MESSAGE: getRequiredMessage('hire point name'),
    VALIDATION_MESSAGE: LATIN_LETTER_RULE,
  },
  LOCATION: {
    ID: 'locationId',
    PLACEHOLDER: 'Please select a location',
    LABEL: 'Location',
    REQUIRED_MESSAGE: 'Please select a location',
    SEARCH_PLACEHOLDER: 'Search for a location',
  },
  WORKING_HOURS: {
    ID: 'workingHours',
    PLACEHOLDER: 'Please enter working hours',
    LABEL: 'Working hours',
    REQUIRED_MESSAGE: 'Enter working hours, at least one day is required',
    VALIDATION_MESSAGE: 'Close hour should be more than Open hour',
    OVERLAP_MESSAGE: 'Working hours cannot overlap. Please edit your schedule.',
  },
  DROPOFF_HOURS: {
    ID: 'dropoffHours',
    PLACEHOLDER: 'Please enter dropoff hours',
    LABEL: 'Dropoff hours',
    REQUIRED_MESSAGE: getRequiredMessage('dropoff hours'),
  },
  LATITUDE: {
    ID: 'latitude',
    PLACEHOLDER: 'Enter latitude value (e.g., 56.92628)',
    LABEL: 'Latitude',
    REQUIRED_MESSAGE: getRequiredMessage('latitude'),
    VALIDATION_MESSAGE: 'Wrong latitude value',
  },
  LONGITUDE: {
    ID: 'longitude',
    PLACEHOLDER: 'Enter longitude value (e.g., 23.99475)',
    LABEL: 'Longitude',
    REQUIRED_MESSAGE: getRequiredMessage('longitude'),
    VALIDATION_MESSAGE: 'Wrong longitude value',
  },
  CURRENCY: {
    ID: 'currencyID',
    PLACEHOLDER: 'Please select a currency',
    LABEL: 'Currency',
    REQUIRED_MESSAGE: getRequiredMessage('currency'),
    SEARCH_PLACEHOLDER: 'Search for a currency',
  },
  PHONE_NUMBER: {
    ID: 'phone',
    PLACEHOLDER: 'Please enter a phone number',
    LABEL: 'Phone number',
    REQUIRED_MESSAGE: getRequiredMessage('phone number'),
  },
  PHONE_COR: {
    ID: 'phoneCor',
  },
  COUNTRY: {
    ID: 'countryId',
    PLACEHOLDER: 'Please select a country',
    LABEL: 'Country',
    REQUIRED_MESSAGE: 'Please select a country',
    SEARCH_PLACEHOLDER: 'Search for a country',
  },
  CITY: {
    ID: 'cityId',
    PLACEHOLDER: 'Please select a city',
    LABEL: 'City',
    REQUIRED_MESSAGE: getRequiredMessage('city'),
    SEARCH_PLACEHOLDER: 'Search for a city',
  },
  APARTMENT: {
    ID: 'apartment',
    PLACEHOLDER: 'Please enter apartment',
    LABEL: 'Apartment',
  },
  STREET: {
    ID: 'street',
    PLACEHOLDER: 'Please enter a street',
    LABEL: 'Street',
    REQUIRED_MESSAGE: getRequiredMessage('street'),
    VALIDATION_MESSAGE: LATIN_LETTER_RULE,
  },
  STATE: {
    ID: 'region',
    PLACEHOLDER: 'Please enter a state / region',
    LABEL: 'State / Region',
    REQUIRED_MESSAGE: getRequiredMessage('state / region'),
    VALIDATION_MESSAGE: LATIN_LETTER_RULE,
  },
  BUILDING: {
    ID: 'building',
    PLACEHOLDER: 'Please enter a building',
    LABEL: 'Building',
    REQUIRED_MESSAGE: getRequiredMessage('building'),
    VALIDATION_MESSAGE: LATIN_LETTER_RULE,
  },
  WAITING_HOURS: {
    ID: 'waitingHours',
    PLACEHOLDER: '0',
    LABEL: 'Waiting hours',
    REQUIRED_MESSAGE: getRequiredMessage('waiting hours'),
    VALIDATION_MESSAGE: DIGITS_RULE,
  },
  WAITING_HOURS_RANGE: {
    ID: 'waitingHoursPeriod',
    LABEL: 'Waiting hours period',
  },
  POSTAL_CODE: {
    ID: 'postalCode',
    PLACEHOLDER: 'Please enter a postal code',
    LABEL: 'Postal code / Postcode / ZIP code',
    REQUIRED_MESSAGE: getRequiredMessage('Postal code / Postcode / ZIP code'),
    VALIDATION_MESSAGE: LATIN_LETTER_RULE,
  },
  PRICE: {
    ID: 'waitingHourPrice',
    PLACEHOLDER: '0.00',
    LABEL: 'Price',
    VALIDATION_MESSAGE: DIGITS_WITH_DOT_RULE,
  },
  EMAIL: {
    ID: 'email',
    PLACEHOLDER: 'Please enter your email',
    LABEL: 'Email',
    REQUIRED_MESSAGE: 'Please enter your email.',
    VALIDATION_MESSAGE: 'Please enter a valid email.',
  },
  MINIMUM_PERIOD_DAY: {
    ID: 'minimumPeriodDay',
    PLACEHOLDER: '0',
    LABEL: 'Minimum number of rental days',
    VALIDATION_MESSAGE:
      'Minimum number of rental days must be greater or equal to 1 day.',
  },
  MAXIMUM_PERIOD_DAY: {
    ID: 'maximumPeriodDay',
    PLACEHOLDER: '0',
    LABEL: 'Maximum number of rental days',
  },
  PICKUP_OPTION: {
    ID: 'pickupOption',
    LABEL: 'Pick-up option',
  },
  SPECIAL_INSTRUCTIONS: {
    ID: 'specialInstructions',
    LABEL: 'Special instructions',
    PLACEHOLDER: 'Please enter special instructions',
    VALIDATION_MESSAGE: LATIN_LETTER_RULE,
  },
  START_RESERVATION_HOUR: {
    ID: 'startReservationHour',
    LABEL: 'Max. advance reservation time for last minute bookings',
    PLACEHOLDER: 'Please enter the number of hours',
    REQUIRED_MESSAGE: 'Please enter start reservation hour.',
    TOOLTIP:
      'Specify the number of hours prior to last minute pickup when the car will no longer be available for booking and should not be displayed on the website.',
  },
  DRIVER_AGE_RANGE: {
    ID: 'driverAgeRange',
    REQUIRED_MESSAGE: 'Please complete all the fields.',
  },
  MIN_MAX_RENTAL_DAYS: {
    ID: 'minMaxPeriodDay',
    VALIDATION_MESSAGE:
      'Maximum number of rental days must be greater or equal to Minimum days.',
  },
}

export const LOCATION_WEEK_DAY = {
  START_WORK: 'Open hour',
  START_LUNCH: 'Start lunch',
  FINISH_LUNCH: 'Finish lunch',
  FINISH_WORK: 'Close hour',
}
