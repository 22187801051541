import { useState } from 'react'
import { Input } from '../Input'
import { InputPasswordProps, InitSettingType, StrengthData } from './types'
import { Typography } from '../Typography'
import {
  mustLowerUpperDigital11MinimumRegex,
  mustLowerUpperDigitalSpecSymbol8MinimumRegex,
} from 'constants/regex'
import { joinClasses } from 'utils/joinClasses'
import { PasswordChecklist } from './components/PasswordChecklist'
import styles from './styles.module.scss'

const STRONG_LENGTH = 11

const initSetting: InitSettingType = {
  type: 'password',
  iconRight: 'EyeClearIcon',
}

export const InputPassword = ({
  placeholder,
  error,
  onChange,
  size,
  label,
  value,
  passwordScale,
  withCompare = false,
  isError,
}: InputPasswordProps) => {
  const [settings, setSettings] = useState(initSetting)
  let renderScale = null

  const onToggle = () => {
    setSettings((prevSettings) => {
      if (prevSettings.type === 'password') {
        return { type: 'text', iconRight: 'EyeClearOffIcon' }
      }

      return initSetting
    })
  }

  if (passwordScale) {
    if (value) {
      const STRENGTH_DATA: StrengthData = {
        weak: {
          text: passwordScale.labelWeak,
          textColor: 'red600Master',
          class: 'password-scale-strength-weak',
        },
        medium: {
          text: passwordScale.labelMedium,
          textColor: 'yellow900',
          class: 'password-scale-strength-medium',
        },
        strong: {
          text: passwordScale.labelStrong,
          textColor: 'green700Master',
          class: 'password-scale-strength-strong',
        },
      }

      let strength = 'weak'
      if (new RegExp(mustLowerUpperDigital11MinimumRegex).test(value)) {
        strength = 'medium'
      }

      if (
        new RegExp(mustLowerUpperDigitalSpecSymbol8MinimumRegex).test(value)
      ) {
        strength = 'medium'

        if (value.length >= STRONG_LENGTH) {
          strength = 'strong'
        }
      }

      renderScale = (
        <div className={styles['password-scale']}>
          <div
            className={joinClasses(
              styles['password-scale-strength'],
              styles[STRENGTH_DATA[strength].class]
            )}
          >
            <Typography
              Tag="div"
              name="Subtitle2"
              color={STRENGTH_DATA[strength].textColor}
            >
              {STRENGTH_DATA[strength].text}
            </Typography>
          </div>
        </div>
      )
    }
  }

  return (
    <>
      <Input
        onChange={onChange}
        placeholder={placeholder}
        type={settings.type}
        error={error}
        iconLeft="LockIcon"
        iconRight={settings.iconRight}
        iconOnClickRight={onToggle}
        iconSize="small"
        size={size}
        isError={isError}
        label={label}
        typographyName="Button2"
        value={value}
        labelRight={renderScale}
      />
      {!withCompare && passwordScale && (
        <PasswordChecklist passwordScale={passwordScale} value={value} />
      )}
    </>
  )
}
