import { useCallback, useEffect, useMemo, useState } from 'react'
import { MENU_NAMES } from 'texts/menuNames'
import { Typography } from 'ui/atoms/Typography'
import { DaysPicker, OriginButton, Pagination } from '@frontend/design_system'
import { Table } from 'ui/molecules/Table'
import { format, addDays } from 'date-fns'
import {
  DATEPICKER_DEFAULT_FORMAT,
  STOP_SALES_DATE_FORMAT,
} from 'constants/date'
import { PAGINATION_DROPDOWN_LABEL } from 'texts/uiTexts'
import { ACTIVITY_HISTORY, listHeadItems } from 'texts/activityHistory'
import { PROFILE_LIST } from 'texts/profileList'
import { Container } from 'ui/molecules/Container'
import {
  DEFAULT_INITIAL_PAGE,
  DEFAULT_INITIAL_PAGE_SIZE,
} from 'constants/pagination'
import { AuditApiTypes, auditApi } from 'api'
import { useDispatch, useSelector } from 'react-redux'
import { TableHeadProps } from 'ui/molecules/Table/TableHead/types'
import { resetSelectedFilters, setFilterItems } from 'redux/filters/slice'
import {
  filterItemsSelector,
  selectedFiltersCountSelector,
  selectedFiltersSelector,
} from 'redux/filters/selectors'
import { Filter } from 'utils/addQueryString'
import { useFilterSettings } from './hooks/useFilterSettings'
import {
  ACTION_TYPE_EDIT,
  ACTION_TYPE_IMPORT,
  ACTION_TYPE_INDEX,
  CATEGORY_INDEX,
  COMPANY_NAME_INDEX,
  ENTITY_ID_INDEX,
  FILTER_KEYS,
  SORTING_KEYS,
  TIME_STAMP_INDEX,
  USER_ID_INDEX,
} from './constants'
import { useNavigate } from 'react-router-dom'
import { TableTooltip } from 'ui/molecules/TableTooltip'
import { useApiRequest } from 'hooks/useApiRequest'
import {
  resetSorting,
  setSelectedActivityHistoryRange,
} from 'redux/activityHistory/slice'
import {
  selectedRangeSelector,
  sortingSelector,
} from 'redux/activityHistory/selectors'
import { ResetIcon } from 'ui/icons/ResetIcon'
import styles from './styles.module.scss'
import { lastPageSelector } from 'redux/common/selectors'
import { PAGE_NAMES } from 'texts/common'
import { setLastPage } from 'redux/common/slice'

export const ActivityHistoryList = () => {
  const [page, setPage] = useState(DEFAULT_INITIAL_PAGE)
  const [pageSize, setPageSize] = useState(DEFAULT_INITIAL_PAGE_SIZE)
  const filterItems = useSelector(filterItemsSelector)
  const selectedFiltersCount = useSelector(selectedFiltersCountSelector)
  const selectedFilters = useSelector(selectedFiltersSelector)
  const selectedRange = useSelector(selectedRangeSelector)
  const sortingData = useSelector(sortingSelector)
  const lastPage = useSelector(lastPageSelector)
  const [auditList, setAuditList] =
    useState<AuditApiTypes.AuditListResponse | null>(null)

  const getFilterSettings = useFilterSettings(setPage)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const auditFiltersRequest = useApiRequest((from, to) =>
    auditApi.getAuditFiltersData('', from, to)
  )
  const filteredAuditListRequest = useApiRequest((filters, sorting) =>
    auditApi.getAuditList(
      page - 1,
      Number(pageSize),
      undefined,
      filters,
      sorting
    )
  )

  const handleSelectedRange = useCallback(
    (newValues: { from: Date; to: Date }) => {
      if (newValues.to) {
        setPage(DEFAULT_INITIAL_PAGE)
      }

      dispatch(
        setSelectedActivityHistoryRange({
          from: format(newValues.from, STOP_SALES_DATE_FORMAT),
          to: newValues.to ? format(newValues.to, STOP_SALES_DATE_FORMAT) : '',
        })
      )
    },
    []
  )

  useEffect(() => {
    if (
      lastPage &&
      [PAGE_NAMES.STOP_SALE, PAGE_NAMES.PRICE_LIST].includes(lastPage)
    ) {
      dispatch(resetSelectedFilters())
      dispatch(setLastPage(''))
      return
    }
    const fetchFilteredCompanyList = async () => {
      let filters: Filter[] = []
      let dateFilter: Filter[] = []

      if (selectedRange.from && selectedRange.to) {
        const toDate = new Date(selectedRange.to)
        const nextDay = addDays(toDate, 1)
        dateFilter = [
          {
            name: FILTER_KEYS.auditDate,
            type: 'Ge',
            value: format(
              new Date(selectedRange.from),
              DATEPICKER_DEFAULT_FORMAT
            ),
          },
          {
            name: FILTER_KEYS.auditDate,
            type: 'Lt',
            value: format(nextDay, DATEPICKER_DEFAULT_FORMAT),
          },
        ]
      }

      if (selectedFiltersCount) {
        filters = Object.entries(selectedFilters).map(([key, value]) => ({
          name: FILTER_KEYS[key],
          type: 'In',
          value: value.join(','),
        }))
      }

      if (filters.length || dateFilter.length) {
        const sortingStr = sortingData
          .map((el) => `[${SORTING_KEYS[el.key]}]${el.type}`)
          .join(',')
        const auditListResponse = await filteredAuditListRequest.apiRequest(
          [...filters, ...dateFilter],
          sortingStr
        )
        if (auditListResponse) {
          setAuditList(auditListResponse.data)
        }
      }
    }
    fetchFilteredCompanyList()
  }, [
    page,
    pageSize,
    selectedFiltersCount,
    selectedRange,
    sortingData,
    lastPage,
  ])

  const fetchCompanyList = async () => {
    let from = ''
    let to = ''
    if (selectedRange.from && selectedRange.to) {
      from = selectedRange.from
      to = selectedRange.to
    }

    const auditListFilters = await auditFiltersRequest.apiRequest(from, to)

    if (auditListFilters) {
      const filterItems = Object.entries(auditListFilters.data).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: value.map((el) => ({ value: el, selected: false })),
        }),
        {}
      )
      dispatch(setFilterItems(filterItems))
    }
  }

  useEffect(() => {
    !selectedFiltersCount && fetchCompanyList()
  }, [selectedRange, selectedFiltersCount])

  const handleChange = (pageNumber: number) => {
    setPage(pageNumber)
  }

  const changePageSize = (value: string) => {
    setPageSize(value)
    setPage(DEFAULT_INITIAL_PAGE)
  }

  const formattedHeadItems = useMemo(() => {
    const newHeadItems: TableHeadProps['item'][] = listHeadItems.map(
      (header) => ({
        value: header,
      })
    )
    newHeadItems[USER_ID_INDEX].filterSettings = getFilterSettings('users')
    newHeadItems[COMPANY_NAME_INDEX].filterSettings =
      getFilterSettings('companies')
    newHeadItems[CATEGORY_INDEX].filterSettings =
      getFilterSettings('categories')
    newHeadItems[ACTION_TYPE_INDEX].filterSettings =
      getFilterSettings('activityTypes')
    newHeadItems[ENTITY_ID_INDEX].filterSettings = getFilterSettings('elements')
    newHeadItems[TIME_STAMP_INDEX].filterSettings =
      getFilterSettings('auditDate')
    return newHeadItems
  }, [filterItems, getFilterSettings])

  const handleRowClick = (id: number) => navigate(`${id}/details`)

  const formattedBodyItems = useMemo(
    () =>
      auditList?.auditLogs.pageItems.map((audit) => ({
        items: [
          audit.userId,
          audit.companyName,
          audit.category,
          <TableTooltip
            onClick={
              audit.actionType === ACTION_TYPE_EDIT ||
              audit.actionType === ACTION_TYPE_IMPORT
                ? () => handleRowClick(audit.id)
                : undefined
            }
            tooltip={audit.element.replaceAll(',', ', ')}
            title={audit.element}
          />,

          audit.entityId,
          audit.actionType,
          audit.auditDate,
        ],
        onClick:
          audit.actionType === ACTION_TYPE_EDIT ||
          audit.actionType === ACTION_TYPE_IMPORT
            ? () => handleRowClick(audit.id)
            : undefined,
      })),
    [auditList]
  )

  const daysPickerRange = useMemo(
    () => ({
      from: selectedRange.from ? new Date(selectedRange.from) : null,
      to: selectedRange.to ? new Date(selectedRange.to) : null,
    }),
    [selectedRange]
  )

  const handleResetAllFilters = () => {
    dispatch(resetSelectedFilters())
    dispatch(resetSorting())
  }

  return (
    <Container
      title={MENU_NAMES.ACTIVITY_HISTORY}
      tooltip={PROFILE_LIST.TOOLTIP_CONTENT}
      withCompanyName={false}
    >
      <div className={styles.list}>
        <div className={styles.header}>
          <Typography color="grey400" name="Button2" markName="Subtitle2">
            {ACTIVITY_HISTORY.ACTIVITY_COUNTER}
            <mark>{auditList?.auditLogs.totalItems}</mark>
          </Typography>
          <div className={styles.right}>
            {!!selectedFiltersCount && (
              <div className={styles['button-wrapper']}>
                <OriginButton
                  label={ACTIVITY_HISTORY.RESET_ALL}
                  variant="secondary"
                  typographyName="Subtitle3"
                  onClick={handleResetAllFilters}
                  size="small"
                  iconLeft={<ResetIcon color="blue700" size="small" />}
                />
              </div>
            )}
            <div className={styles['date-picker']}>
              <DaysPicker
                range={daysPickerRange}
                initialFrom={selectedRange.from}
                initialTo={selectedRange.to}
                size="small"
                handleRangeSelect={handleSelectedRange}
                variant="secondary"
              />
            </div>
          </div>
        </div>
        <div className={styles.table}>
          <Table
            headItems={formattedHeadItems}
            bodyItems={formattedBodyItems}
            className={styles['table-content']}
          />
        </div>
      </div>
      <footer className={styles.pagination}>
        <Pagination
          current={page}
          total={auditList?.auditLogs.totalItems || 0}
          pageSize={pageSize}
          changePageSize={changePageSize}
          changePage={handleChange}
          pageSizeLabel={PAGINATION_DROPDOWN_LABEL}
        />
      </footer>
    </Container>
  )
}
