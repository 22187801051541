import { useState } from 'react'
import { ValueType } from '../types'

export const useForm = ({
  initValues,
}: {
  initValues: Record<string, ValueType>
}) => {
  const [errors, setErrors] = useState({})
  const [values, setValues] = useState<Record<string, ValueType>>(initValues)
  const [isDirty, setIsDirty] = useState(false)

  const changeErrors = (
    callback: (prevErrors: { [key: string]: string }) => void
  ) => {
    setErrors(callback)
  }

  const changeIsDirty = (newIsDirty: boolean) => {
    setIsDirty(newIsDirty)
  }

  const changeValues = (
    callback: React.SetStateAction<Record<string, ValueType>>,
    isDirtyChanged = true
  ) => {
    setValues(callback)

    if (isDirtyChanged) {
      changeIsDirty(true)
    }
  }

  return {
    changeValues,
    values,
    changeErrors,
    errors,
    isDirty,
    setDirty: changeIsDirty,
  }
}
