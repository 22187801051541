import { locationsApi, LocationsApiTypes } from 'api'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'redux/hooks'
import { LOCATION_ADD } from 'texts/locationAdd'
import { Typography } from 'ui/atoms/Typography'
import { Container } from 'ui/molecules/Container'
import { FormValues } from '../LocationDetailsForm/types'
import { LocationDetailsForm } from '../LocationDetailsForm'
import { useCompanyInfo } from 'ui/hooks/useCompanyInfo'
import { isOperatorSelector } from 'redux/login/selectors'
import { getDetailsBreadcrumbsList } from 'utils/getDetailsBreadcrumbsList'
import { setNotificationMessage } from 'redux/notifications/slice'
import { useNavigate } from 'react-router-dom'
import { useApiRequest } from 'hooks/useApiRequest'
import { GTM } from 'utils/gtm'
import { DATA_LAYER_EVENT } from 'constants/dataLayerEvents'

export const LocationAdd = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { apiRequest, loading } = useApiRequest(
    (values) => locationsApi.addHirePoint(values),
    undefined,
    undefined,
    undefined,
    false
  )

  const isOperator = useAppSelector(isOperatorSelector)

  const { companyId } = useCompanyInfo(isOperator)

  const breadcrumbList = getDetailsBreadcrumbsList(
    isOperator,
    String(companyId),
    'locations'
  )

  const handleSubmit = async (data: Partial<FormValues>) => {
    const values = {
      ...data,
      waitingHours: {
        waitingHours: data.waitingHours,
        waitingHourPrice: data.waitingHourPrice,
        waitingHourRange:
          data.waitingHoursPeriod as LocationsApiTypes.WaitingHourRange,
      },
      companyId: Number(companyId),
      currencyID: Number(data.currencyID),
    }
    delete values.phoneCor

    const response = await apiRequest(values)
    if (response) {
      dispatch(
        setNotificationMessage({
          notificationMessage: LOCATION_ADD.MODAL_TITLE,
        })
      )
      GTM.dataLayer({
        event: DATA_LAYER_EVENT.LOCATION_ADD,
        location_name: response.data.hirePointInfo.locationName,
        location_country: response.data.hirePointInfo.countryName,
        location_city: response.data.hirePointInfo.cityName,
      })
      navigate(-1)
    }
  }

  return (
    <Container
      title={<Typography name="H5">{LOCATION_ADD.TITLE}</Typography>}
      breadcrumbList={breadcrumbList}
      currentPageLabel={LOCATION_ADD.BREADCRUMB}
      withNavigation={isOperator}
    >
      <LocationDetailsForm handleSubmit={handleSubmit} isLoading={loading} />
    </Container>
  )
}
