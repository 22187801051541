import { useState } from 'react'
import { MENU_NAMES } from 'texts/menuNames'
import { PROFILE_LIST } from 'texts/profileList'
import { Container } from 'ui/molecules/Container'
import { Tabs } from 'ui/molecules/Tabs/Tabs'
import { TabPane } from 'ui/molecules/Tabs/components/TabPane'
import { APPLICATIONS_TAB_KEYS, SEARCH_VALUES } from 'texts/applications'
import { ApplicationList } from '../ApplicationList'
import { APPLICATION_STATUS } from 'modules/Applications/constants'
import { SearchBy } from '../SearchBy'
import styles from './styles.module.scss'

export const AllApplications = () => {
  const [activeKey, setActiveKey] = useState(APPLICATIONS_TAB_KEYS.WAITING_LIST)

  const renderTab = (key: string, count: number) => `${key} (${count})`

  const tabs = [
    {
      tab: renderTab(APPLICATIONS_TAB_KEYS.WAITING_LIST, 0),
      key: APPLICATIONS_TAB_KEYS.WAITING_LIST,
      children: <ApplicationList status={APPLICATION_STATUS.WAITING_LIST} />,
    },
    {
      tab: renderTab(APPLICATIONS_TAB_KEYS.WAITING_CALL, 0),
      key: APPLICATIONS_TAB_KEYS.WAITING_CALL,
      children: (
        <ApplicationList status={APPLICATION_STATUS.WAITING_FOR_CALL} />
      ),
    },
    {
      tab: renderTab(APPLICATIONS_TAB_KEYS.WAITING_SIGN, 0),
      key: APPLICATIONS_TAB_KEYS.WAITING_SIGN,
      children: (
        <ApplicationList status={APPLICATION_STATUS.WAITING_FOR_SIGN} />
      ),
    },
    {
      tab: renderTab(APPLICATIONS_TAB_KEYS.APPROVE, 0),
      key: APPLICATIONS_TAB_KEYS.APPROVE,
      children: <ApplicationList status={APPLICATION_STATUS.APPROVE} />,
    },
    {
      tab: renderTab(APPLICATIONS_TAB_KEYS.REJECTED, 0),
      key: APPLICATIONS_TAB_KEYS.REJECTED,
      children: <ApplicationList status={APPLICATION_STATUS.REJECTED} />,
    },
  ] // TODO: logic will be change after implement backend

  return (
    <Container
      title={MENU_NAMES.APPLICATIONS.SUBMENU_NAMES.APPLICATIONS}
      rightBlock={
        <SearchBy
          searchKeys={SEARCH_VALUES}
          setData={() => {}}
          onSearch={() => {}}
          handleSelect={() => {}}
        />
      }
      tooltip={PROFILE_LIST.TOOLTIP_CONTENT}
    >
      <Tabs activeKey={activeKey} onChange={setActiveKey} type="quaternary">
        {tabs.map((tab) => (
          <TabPane id={tab.tab} key={tab.key} tab={tab.tab}>
            <div className={styles['tab-children']}>{tab.children}</div>
          </TabPane>
        ))}
      </Tabs>
    </Container>
  )
}
