import { useLocation, useNavigate } from 'react-router-dom'
import { NavigationItem } from './components/NavigationItem/NavigationItem'
import { useNavigationItems } from './hooks/useNavigationItems'
import styles from './styles.module.scss'
import { NavigationProps } from './types'

export const Navigation = ({ id }: NavigationProps) => {
  const items = useNavigationItems()
  const navigate = useNavigate()
  const location = useLocation()

  const activeItem = items.find((el) =>
    location.pathname.includes(el.getPath(id))
  )
  return (
    <div className={styles.navigation}>
      {items.map(({ label, getPath }) => (
        <NavigationItem
          key={label}
          isActive={activeItem?.label === label}
          label={label}
          onClick={() => navigate(getPath(id))}
        />
      ))}
    </div>
  )
}
