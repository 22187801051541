import { TYPOGRAPHY_COLORS } from 'constants/TypographyColors'
import { SVGIcon } from './SvgIcon/SvgIcon'
import { IconProps } from './types'

export const ProfileArchiveIcon = ({
  size,
  color = 'grey400',
  className,
}: IconProps) => (
  <SVGIcon size={size} color={color} viewBox="0 0 16 16" className={className}>
    <path
      d="M2.47372 13L4.50524 7.60927C4.55788 7.46951 4.65211 7.34907 4.77535 7.26403C4.8986 7.17898 5.04498 7.13339 5.19494 7.13333H14.2635M2.47372 13H12.8089C13.1518 12.9999 13.4838 12.8809 13.748 12.6634C14.0121 12.4458 14.1918 12.1435 14.2561 11.8083L14.99 7.98693C15.0076 7.88192 15.0019 7.77437 14.9735 7.67175C14.9451 7.56913 14.8945 7.4739 14.8254 7.39267C14.7563 7.31145 14.6702 7.24618 14.5731 7.20139C14.4761 7.15661 14.3704 7.13338 14.2635 7.13333M2.47372 13C2.08287 13 1.70802 12.8455 1.43164 12.5704C1.15527 12.2954 1 11.9223 1 11.5333V3.46667C1 3.07768 1.15527 2.70463 1.43164 2.42958C1.70802 2.15452 2.08287 2 2.47372 2H5.42116L7.63174 4.2H12.7898C13.1806 4.2 13.5555 4.35452 13.8318 4.62958C14.1082 4.90463 14.2635 5.27768 14.2635 5.66667V7.13333"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SVGIcon>
)
