export const getTranslateXValue = (el: string) =>
  parseInt(el.replace('translateX(', '').replace('px)', ''), 10)

/* eslint-disable no-param-reassign */
export const goToSlide = ({
  container,
  speed,
  position,
}: {
  container: HTMLDivElement | null
  speed: number
  position: number
}) => {
  if (container) {
    container.style.transition = `transform ${speed}ms cubic-bezier(.25,.72,.51,.96)`
    container.style.transform = `translateX(${-position}px)`
  }
}

export const removeTransform = (container: HTMLDivElement | null) => {
  if (container) {
    container.style.transition = 'transform 0.0s ease-in-out'
  }
  return
}
/* eslint-enable no-param-reassign */

export const nodeDimensions = (slide: HTMLDivElement) => {
  if (!slide) {
    return { width: 0 }
  }
  const { width } = slide.getBoundingClientRect()

  return { width }
}

export const getCurrentPage = ({
  slidesWithoutNotSelected,
  shownSlides,
  page,
}: Record<string, number>) => {
  const lastPage = slidesWithoutNotSelected - 1 - shownSlides
  const leftLimit = page - shownSlides
  const rightLimit = page - lastPage

  if (leftLimit < 0) {
    return lastPage + leftLimit + 1
  }

  if (rightLimit > 0) {
    return rightLimit + shownSlides - 1
  }

  return page
}

export const getPageX = (e: React.MouseEvent | React.TouchEvent) => {
  if ('pageX' in e) {
    return e.pageX
  } else {
    return e.changedTouches[0].pageX
  }
}

export const copyItems = (items: JSX.Element[], copies: number) => {
  const newItems: JSX.Element[] = []
  let leftCopies = copies

  while (leftCopies) {
    const subItems = items.slice(0, leftCopies)
    newItems.push(...subItems)
    leftCopies -= subItems.length
  }

  return newItems
}
