import React from 'react'
import { joinClasses } from 'utils/joinClasses'
import { FormAlertLabel } from '../FormAlertLabel'
import { Label } from '@frontend/design_system'
import { CheckboxProps } from './types'
import styles from './styles.module.scss'

export const Checkbox = ({
  label,
  variant = 'default',
  size = 'default',
  checked,
  onChange,
  error,
  errorMessage,
  borderVariant = 'grey',
  labelColor,
  isRequired,
  labelHtml,
  disabled,
}: CheckboxProps) => {
  const onToggle = () => {
    if (!disabled) {
      onChange(!checked)
    }
  }

  return (
    <>
      <div
        className={joinClasses(
          styles.checkbox,
          styles[`checkbox-size-${size}`],
          styles[`checkbox-variant-${variant}`],
          styles[`checkbox-variant-border-${borderVariant}`],
          [styles['checkbox-disabled'], disabled]
        )}
        onClick={onToggle}
        role="checkbox"
      >
        <input
          type="checkbox"
          className={styles['checkbox-hidden']}
          checked={checked}
          disabled={disabled}
          onChange={onToggle}
        />
        <div
          className={joinClasses(
            styles['checkbox-box'],
            [styles['checkbox-checked'], checked],
            [styles['checkbox-box-error'], error]
          )}
        >
          <div className={styles['checkbox-icon']} />
        </div>
        {(label || labelHtml) && (
          <Label
            typographyName="Button2"
            color={labelColor}
            label={label}
            labelHtml={labelHtml}
            isRequired={isRequired}
            position="left"
          />
        )}
      </div>
      {errorMessage && (
        <FormAlertLabel label={errorMessage} position="static" />
      )}
    </>
  )
}
