import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { referencesApi, ReferencesApiTypes } from 'api'
import { useAppSelector } from 'redux/hooks'
import { UserRole } from 'modules/Profile/types'
import { userDataSelector } from 'redux/login/selectors'
import {
  CONTACT_INFORMATION_FORM_VALUES,
  PROFILE_DETAILS,
} from 'texts/profileDetails'
import { Button } from 'ui/atoms/Button'
import { ContentWrapper } from 'ui/atoms/ContentWrapper'
import { Typography } from 'ui/atoms/Typography'
import { UploadFile } from 'ui/atoms/UploadFile/UploadFile'
import { Form } from 'ui/molecules/Form'
import StarSrc from 'assets/img/Star.svg'
import { FormValues, ProfileDetailsFormProps } from './types'
import { ContactInformationForm } from './components/ContactInformationForm'
import { PaymentInformationForm } from './components/PaymentInformationForm'
import { formats, initialFormValues } from './constants'
import { FormItem } from 'ui/molecules/Form/components/FormItem'
import { Rule } from 'ui/molecules/Form/components/FormItem/types'
import { Badge } from 'ui/atoms/Badge'
import { Modal } from 'ui/molecules/Modal'
import { CANCEL, CONFIRM } from 'texts/uiTexts'
import { PlusIcon } from 'ui/icons'
import styles from './styles.module.scss'

export const ProfileDetailsForm = ({
  handleSubmit,
  initialValues = initialFormValues,
  withActionButtons,
  readOnly,
  isLoading,
}: ProfileDetailsFormProps) => {
  const [currencies, setCurrencies] =
    useState<ReferencesApiTypes.CurrenciesResponse>({ items: [] })
  const [countries, setCountries] =
    useState<ReferencesApiTypes.CountriesResponse>({ items: [] })
  const [isFetching, setIsFetching] = useState(false)

  const role = useAppSelector(
    userDataSelector
  ).roles[0]?.toLowerCase() as UserRole

  const navigate = useNavigate()

  const handleBack = () => navigate(-1)

  useEffect(() => {
    const fetchCurrencies = async () => {
      const fetchCurrenciesResponse = await referencesApi.getCurrencies()
      setCurrencies(fetchCurrenciesResponse.data)
      if (initialValues.companyCurrencyName === '') {
        initialValues.companyCurrencyName =
          fetchCurrenciesResponse.data.items[0].name
      }
    }
    const fetchCountries = async () => {
      const fetchCountriesResponse = await referencesApi.getCountries()
      setCountries(fetchCountriesResponse.data)
      if (initialValues.companyCountryName === '') {
        initialValues.companyCountryName =
          fetchCountriesResponse.data.items[0].name
      }
      setIsFetching(true)
    }
    fetchCurrencies()
    fetchCountries()
  }, [initialValues])

  const onSubmit = (data: Partial<FormValues>, validate: boolean) => {
    if (validate && handleSubmit) {
      handleSubmit({
        ...data,
        phoneAreaCode: data.phoneCor?.code
          ? String(data.phoneCor?.phoneCode)
          : '',
        phoneCountryCode: data.phoneCor?.code
          ? String(data.phoneCor?.code)
          : '',
      })
    }
  }

  const renderSupplierHeaderBlock = () => (
    <div className={styles['supplier-info']}>
      <Typography name="H5">{initialValues.companyName}</Typography>
      <div className={styles['right-block']}>
        <img src={initialValues.logo?.[0]?.url} width="68px" height="28px" />
        <div className={styles['review-block']}>
          <div className={styles.rating}>
            <img
              src={StarSrc}
              className={styles['rating-icon']}
              width="10px"
              height="9.8px"
            />
            <Typography name="Subtitle3">{initialValues.rating}</Typography>
          </div>
          <Typography name="Subtitle3" color="grey500">
            {initialValues.reviewCount} {PROFILE_DETAILS.REVIEWS}
          </Typography>
        </div>
      </div>
    </div>
  )

  const logoRule: Rule[] = useMemo(
    () => [
      {
        type: 'required',
        message: CONTACT_INFORMATION_FORM_VALUES.LOGO.REQUIRED_MESSAGE,
      },
    ],
    []
  )

  const renderHeaderBlock = (error?: string) =>
    role === 'operator' ? (
      <>
        <ContentWrapper
          className={styles.form}
          title={PROFILE_DETAILS.UPLOAD_FILE.TITLE}
        >
          <div className={styles['upload-files']}>
            <div className={styles['upload-files-content']}>
              <FormItem
                id={CONTACT_INFORMATION_FORM_VALUES.LOGO.ID}
                className={styles['form-item']}
                rules={logoRule}
              >
                {({ value, onChange, error }) => (
                  <UploadFile
                    renderConfirmationModal={(onClose, onConfirm) => (
                      <Modal
                        onClose={onClose}
                        position="center-horizontal"
                        title={PROFILE_DETAILS.UPLOAD_FILE.DELETE_MODAL_TITLE}
                        headerSize="large"
                        isDelimiter
                      >
                        <div className={styles.modal}>
                          <Typography
                            name="Button1"
                            color="grey500"
                            Tag="div"
                            className={styles.description}
                          >
                            {PROFILE_DETAILS.UPLOAD_FILE.DELETE_CONFIRMATION}
                          </Typography>
                          <div className={styles.buttons}>
                            <Button
                              label={CANCEL}
                              size="large"
                              variant="outline"
                              onClick={onClose}
                            />
                            <Button
                              label={CONFIRM}
                              size="large"
                              onClick={onConfirm}
                            />
                          </div>
                        </div>
                      </Modal>
                    )}
                    initFiles={value}
                    onUpload={onChange}
                    error={error}
                    label={<PlusIcon size="large" />}
                    formats={formats}
                    disabled={readOnly}
                  />
                )}
              </FormItem>
              {!readOnly && (
                <div className={styles['file-format-block']}>
                  <div className={styles['file-format-rule']}>
                    <Typography name="Button2">
                      {PROFILE_DETAILS.UPLOAD_FILE.SIZE_TITLE}
                    </Typography>
                    <Typography name="Button2" color="grey500">
                      {PROFILE_DETAILS.UPLOAD_FILE.SIZE_CONTENT}
                    </Typography>
                  </div>
                  <div className={styles['file-format-rule']}>
                    <Typography name="Button2">
                      {PROFILE_DETAILS.UPLOAD_FILE.FORMAT_TITLE}
                    </Typography>
                    <Typography name="Button2" color="grey500">
                      {PROFILE_DETAILS.UPLOAD_FILE.FORMAT_CONTENT}
                    </Typography>
                  </div>
                </div>
              )}
            </div>
            {error && (
              <Badge
                message={error}
                type="error"
                iconSize="small"
                typographyName="body2WMedium"
                className={styles['logo-error']}
              />
            )}
          </div>
        </ContentWrapper>
      </>
    ) : (
      renderSupplierHeaderBlock()
    )

  return isFetching ? (
    <Form
      initValues={initialValues}
      onSubmit={onSubmit}
      className={styles['form-wrapper']}
    >
      {({ isDirty, errors, values }) => (
        <>
          {renderHeaderBlock(errors.logo)}
          <ContentWrapper
            className={styles.form}
            title={PROFILE_DETAILS.FORM.CONTACT_FORM_TITLE}
          >
            <ContactInformationForm
              countries={countries}
              role={role}
              readOnly={readOnly}
              selectedCountryId={Number(values.companyCountryID)}
            />
          </ContentWrapper>
          <ContentWrapper
            className={styles.form}
            title={PROFILE_DETAILS.FORM.PAYMENT_FORM_TITLE}
          >
            <PaymentInformationForm
              currencies={currencies}
              role={role}
              readOnly={readOnly}
            />
          </ContentWrapper>
          {withActionButtons && (
            <div className={styles.buttons}>
              <div className={styles['button-wrapper']}>
                <Button
                  variant="outline"
                  onClick={handleBack}
                  label={PROFILE_DETAILS.BUTTONS.CANCEL_BUTTON_LABEL}
                />
              </div>
              <div className={styles['button-wrapper']}>
                <Button
                  htmlType="submit"
                  disabled={!isDirty}
                  label={PROFILE_DETAILS.BUTTONS.SAVE_BUTTON_LABEL}
                  loading={isLoading}
                />
              </div>
            </div>
          )}
        </>
      )}
    </Form>
  ) : null
}
