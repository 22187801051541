import { Typography } from 'ui/atoms/Typography'
import { NavigationItemProps } from './types'
import { joinClasses } from 'utils/joinClasses'
import styles from './styles.module.scss'

export const NavigationItem = ({
  label,
  onClick,
  isActive,
}: NavigationItemProps) => (
  <Typography
    Tag="div"
    name="body2WBold"
    onClick={onClick}
    color={isActive ? 'blackMaster' : 'blue700'}
    className={joinClasses(styles.item, [styles.active, isActive])}
  >
    {label}
  </Typography>
)
