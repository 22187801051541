import React, { useEffect, useState } from 'react'
import { FilterItemProps } from './types'
import { Typography } from 'ui/atoms/Typography'
import { Checkbox } from '@frontend/design_system'
import styles from './styles.module.scss'

export const FilterItem = ({
  handleSelect,
  value,
  counter,
  selected,
}: FilterItemProps) => {
  const [isChecked, setIsChecked] = useState(selected)

  useEffect(() => {
    setIsChecked(!!selected)
  }, [selected])

  const handleClick = (isChecked: boolean) => {
    handleSelect()
    setIsChecked(!isChecked)
  }

  return (
    <div className={styles.item}>
      <Checkbox
        checked={isChecked}
        onChange={handleClick}
        borderVariant="grey"
        labelColor="grey500"
        label={
          <>
            <Typography
              className={styles['item-value']}
              name="Button2"
              color={isChecked ? 'blue700' : 'blackMaster'}
            >
              {value}
            </Typography>
            <Typography
              className={styles.counter}
              name="Subtitle4"
              color="grey400"
            >
              {counter}
            </Typography>
          </>
        }
      />
    </div>
  )
}
