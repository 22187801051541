import { TableRowProps } from './types'
import { Typography } from 'ui/atoms/Typography'
import { TrashIcon } from 'ui/icons'
import styles from './styles.module.scss'
import { joinClasses } from 'utils/joinClasses'

export const TableRow = ({
  rowItems,
  indexRow,
  onClick,
  removeRowClick,
}: TableRowProps) => {
  const handleRemoveRow = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.stopPropagation()
    if (removeRowClick) {
      removeRowClick(indexRow)
    }
  }

  return (
    <tr className={joinClasses(styles['table-row'])} onClick={onClick}>
      {rowItems.map((item, index) => {
        const showRemoveIcon = removeRowClick && rowItems.length - 1 === index

        return (
          <td
            key={index}
            className={joinClasses(styles.item, [
              styles['row-with-icon'],
              showRemoveIcon,
            ])}
          >
            {typeof item !== 'object' ? (
              <Typography name="Subtitle7" Tag="div" className={styles.content}>
                {item}
              </Typography>
            ) : (
              item
            )}
            {showRemoveIcon && (
              <div className={styles['remove-icon-block']}>
                <TrashIcon
                  color="red600Master"
                  onClick={handleRemoveRow}
                  className={styles['remove-icon']}
                />
              </div>
            )}
          </td>
        )
      })}
    </tr>
  )
}
