import { Typography } from 'ui/atoms/Typography'
import { ListItemProps } from './types'
import styles from './styles.module.scss'

export const EquipmentItem = ({ item }: ListItemProps) => (
  <div className={styles['item-wrapper']}>
    <Typography className={styles.item} name="Button2">
      {item}
    </Typography>
  </div>
)
