import { useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { FleetApiTypes, fleetApi } from 'api'
import { isOperatorSelector } from 'redux/login/selectors'
import { SELECT_ALL_ITEM } from 'ui/atoms/Multiselect'
import {
  STOP_SALE_TITLE,
  STOP_SALE_EDIT_BREADCRUMB,
} from 'texts/stopSellsDetails'
import { StopSalesAdd, StopSalesRange } from 'api/fleet/types'
import { Container } from 'ui/molecules/Container'
import { useCompanyInfo } from 'ui/hooks/useCompanyInfo'
import { StopSaleDetailsForm } from '../StopSaleDetailsForm'
import { useNavigate, useParams } from 'react-router-dom'
import { useApiRequest } from 'hooks/useApiRequest'
import { setNotificationMessage } from 'redux/notifications/slice'
import { SUCCESS_UPDATE_MESSAGE } from 'texts/common'
import { getDetailsBreadcrumbsList } from 'utils/getDetailsBreadcrumbsList'
import { formatStopSaleDate } from '../../utils'

export const StopSaleEdit = () => {
  const [stopSaleInfo, setStopSaleInfo] =
    useState<FleetApiTypes.FullCompanyStopSalesDto>()
  const dispatch = useAppDispatch()
  const { id } = useParams()
  const navigate = useNavigate()
  const { apiRequest, loading } = useApiRequest((id) =>
    fleetApi.getStopSaleInfo(Number(id))
  )
  const { apiRequest: updateStopSale, loading: updateStopSaleLoading } =
    useApiRequest(
      (id, values) => fleetApi.updateStopSale(id, values),
      undefined,
      undefined,
      undefined,
      false
    )
  const isOperator = useAppSelector(isOperatorSelector)
  const { companyId } = useCompanyInfo(isOperator)

  const breadcrumbList = getDetailsBreadcrumbsList(
    isOperator,
    String(companyId),
    'stopSells'
  )

  useEffect(() => {
    const getStopSaleInfo = async () => {
      if (id) {
        const response = await apiRequest(id)
        if (response) {
          setStopSaleInfo(response.data.stopSellInfo)
        }
      }
    }

    getStopSaleInfo()
  }, [])

  const handleSubmit = async (data: StopSalesAdd) => {
    const values = {
      ...data,
      from: formatStopSaleDate(data.from),
      to: formatStopSaleDate(data.to),
      companyID: Number(data.companyID),
      applicable:
        StopSalesRange[data.applicable as keyof typeof StopSalesRange],
    }
    const response = await updateStopSale(String(id), values)
    if (response) {
      dispatch(
        setNotificationMessage({
          notificationMessage: SUCCESS_UPDATE_MESSAGE,
        })
      )
      navigate(-1)
    }
  }

  const initValues = useMemo(
    () => ({
      countries: stopSaleInfo?.countryName
        ? [
            {
              label: stopSaleInfo.countryName,
              value: stopSaleInfo.countryId
                ? String(stopSaleInfo.countryId)
                : '0',
            },
          ]
        : [],
      cities:
        stopSaleInfo?.cities?.map((el) => ({
          label: el.name,
          value: `${el.id}`,
        })) || [],
      carClasses:
        stopSaleInfo?.carClasses?.map((el) => ({
          label: el,
          value: el === SELECT_ALL_ITEM.label ? '0' : `${el}`,
        })) || [],
      locations:
        stopSaleInfo?.locations?.map((el) => ({
          label: el.name,
          value: `${el.id}`,
        })) || [],
      from: stopSaleInfo?.from,
      to: stopSaleInfo?.to,
      applicable: stopSaleInfo?.applicable,
      comments: stopSaleInfo?.comments,
    }),
    [stopSaleInfo]
  )

  return !loading ? (
    <Container
      title={STOP_SALE_TITLE}
      breadcrumbList={breadcrumbList}
      currentPageLabel={STOP_SALE_EDIT_BREADCRUMB}
      withNavigation={isOperator}
    >
      <StopSaleDetailsForm
        initialValues={initValues}
        handleSubmit={handleSubmit}
        isLoading={updateStopSaleLoading}
      />
    </Container>
  ) : null
}
