import { joinClasses } from 'utils/joinClasses'
import { Label } from '@frontend/design_system'
import { RadioButtonProps } from './types'
import styles from './styles.module.scss'

export const RadioButton = ({
  id,
  labelColor,
  label,
  labelHtml,
  isRequired,
  checked,
  onChange,
  labelTypographyName = 'Body1',
  buttonPosition = 'right',
  disabled,
}: RadioButtonProps) => {
  const onClick = () => {
    if (onChange && !disabled) {
      onChange(id)
    }
  }

  return (
    <div
      className={joinClasses(
        styles.radio,
        [styles['radio-checked'], checked],
        [styles[`radio-${buttonPosition}`], buttonPosition]
      )}
      onClick={onClick}
    >
      {(label || labelHtml) && (
        <Label
          typographyName={labelTypographyName}
          color={labelColor}
          label={label}
          labelHtml={labelHtml}
          isRequired={isRequired}
          position="left"
        />
      )}
      <input
        type="radio"
        className={styles['radio-hidden']}
        checked={checked}
        disabled={disabled}
        onChange={onClick}
      />
      <div className={styles['radio-box']}>
        <div className={styles['radio-icon']} />
      </div>
    </div>
  )
}
