import { allSymbolsAndEnglishLettersRegex, mailRegex } from 'constants/regex'
import { PAYMENT_INFORMATION_FORM_VALUES } from 'texts/profileDetails'
import { FormValuesSetting } from 'types/form'

export const paymentInformationFormValuesSetting: FormValuesSetting = {
  legalName: {
    required: {
      supplier: true,
      operator: true,
    },
    disabled: {
      supplier: true,
      operator: false,
    },
    validationRules: [
      {
        type: 'required',
        message: PAYMENT_INFORMATION_FORM_VALUES.LEGAL_NAME.REQUIRED_MESSAGE,
      },
      {
        type: 'pattern',
        value: allSymbolsAndEnglishLettersRegex,
        message: PAYMENT_INFORMATION_FORM_VALUES.LEGAL_NAME.VALIDATION_MESSAGE,
      },
    ],
  },
  registrationNumber: {
    required: {
      supplier: false,
      operator: false,
    },
    disabled: {
      supplier: true,
      operator: false,
    },
    validationRules: [
      {
        type: 'required',
        message:
          PAYMENT_INFORMATION_FORM_VALUES.REGISTRATION_NUMBER.REQUIRED_MESSAGE,
      },
      {
        type: 'pattern',
        value: allSymbolsAndEnglishLettersRegex,
        message:
          PAYMENT_INFORMATION_FORM_VALUES.REGISTRATION_NUMBER
            .VALIDATION_MESSAGE,
      },
      {
        type: 'max',
        value: 15,
        message:
          PAYMENT_INFORMATION_FORM_VALUES.REGISTRATION_NUMBER
            .MAX_CHARACTERS_MESSAGE,
      },
    ],
  },
  swift: {
    required: {
      supplier: true,
      operator: true,
    },
    disabled: {
      supplier: true,
      operator: false,
    },
    validationRules: [
      {
        type: 'required',
        message: PAYMENT_INFORMATION_FORM_VALUES.SWIFT.REQUIRED_MESSAGE,
      },
      {
        type: 'pattern',
        value: allSymbolsAndEnglishLettersRegex,
        message: PAYMENT_INFORMATION_FORM_VALUES.SWIFT.VALIDATION_MESSAGE,
      },
      {
        type: 'max',
        value: 15,
        message: PAYMENT_INFORMATION_FORM_VALUES.SWIFT.MAX_CHARACTERS_MESSAGE,
      },
    ],
  },
  iban: {
    required: {
      supplier: true,
      operator: true,
    },
    disabled: {
      supplier: true,
      operator: false,
    },
    validationRules: [
      {
        type: 'required',
        message: PAYMENT_INFORMATION_FORM_VALUES.IBAN.REQUIRED_MESSAGE,
      },
      {
        type: 'pattern',
        value: allSymbolsAndEnglishLettersRegex,
        message: PAYMENT_INFORMATION_FORM_VALUES.IBAN.VALIDATION_MESSAGE,
      },
      {
        type: 'max',
        value: 35,
        message: PAYMENT_INFORMATION_FORM_VALUES.IBAN.MAX_CHARACTERS_MESSAGE,
      },
    ],
  },
  bankName: {
    required: {
      supplier: false,
      operator: false,
    },
    disabled: {
      supplier: true,
      operator: false,
    },
    validationRules: [
      {
        type: 'pattern',
        value: allSymbolsAndEnglishLettersRegex,
        message: PAYMENT_INFORMATION_FORM_VALUES.BANK_NAME.VALIDATION_MESSAGE,
      },
      {
        type: 'max',
        value: 30,
        message:
          PAYMENT_INFORMATION_FORM_VALUES.BANK_NAME.MAX_CHARACTERS_MESSAGE,
      },
    ],
  },
  vat: {
    required: {
      supplier: true,
      operator: true,
    },
    disabled: {
      supplier: true,
      operator: false,
    },
    validationRules: [
      {
        type: 'required',
        message: PAYMENT_INFORMATION_FORM_VALUES.VAT.REQUIRED_MESSAGE,
      },
      {
        type: 'pattern',
        value: allSymbolsAndEnglishLettersRegex,
        message: PAYMENT_INFORMATION_FORM_VALUES.VAT.VALIDATION_MESSAGE,
      },
      {
        type: 'max',
        value: 20,
        message: PAYMENT_INFORMATION_FORM_VALUES.VAT.MAX_CHARACTERS_MESSAGE,
      },
    ],
  },
  currency: {
    required: {
      supplier: true,
      operator: true,
    },
    disabled: {
      supplier: true,
      operator: false,
    },
    validationRules: [
      {
        type: 'required',
        message: PAYMENT_INFORMATION_FORM_VALUES.CURRENCY.REQUIRED_MESSAGE,
      },
    ],
  },
  bankAddress: {
    required: {
      supplier: false,
      operator: false,
    },
    disabled: {
      supplier: true,
      operator: false,
    },
    validationRules: [
      {
        type: 'pattern',
        value: allSymbolsAndEnglishLettersRegex,
        message:
          PAYMENT_INFORMATION_FORM_VALUES.BANK_ADDRESS.VALIDATION_MESSAGE,
      },
      {
        type: 'max',
        value: 100,
        message:
          PAYMENT_INFORMATION_FORM_VALUES.BANK_ADDRESS.MAX_CHARACTERS_MESSAGE,
      },
    ],
  },
  aba: {
    required: {
      supplier: false,
      operator: false,
    },
    disabled: {
      supplier: true,
      operator: false,
    },
    validationRules: [
      {
        type: 'pattern',
        value: allSymbolsAndEnglishLettersRegex,
        message: PAYMENT_INFORMATION_FORM_VALUES.ABA.VALIDATION_MESSAGE,
      },
      {
        type: 'max',
        value: 9,
        message: PAYMENT_INFORMATION_FORM_VALUES.ABA.MAX_CHARACTERS_MESSAGE,
      },
    ],
  },
  accountingEmail: {
    required: {
      supplier: false,
      operator: false,
    },
    disabled: {
      supplier: true,
      operator: false,
    },
    validationRules: [
      {
        type: 'pattern',
        value: mailRegex,
        message: PAYMENT_INFORMATION_FORM_VALUES.EMAIL.VALIDATION_MESSAGE,
      },
      {
        type: 'min',
        value: 5,
        message: PAYMENT_INFORMATION_FORM_VALUES.EMAIL.VALIDATION_MESSAGE,
      },
      {
        type: 'max',
        value: 90,
        message: PAYMENT_INFORMATION_FORM_VALUES.EMAIL.MAX_VALIDATION_MESSAGE,
      },
    ],
  },
}
