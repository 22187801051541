import { UserRoles } from 'constants/roles'
import { URLS } from 'constants/urls'
import { NavigateFunction } from 'react-router-dom'

export const handleRedirectToProfileList = (navigate: NavigateFunction) =>
  navigate(`${URLS.PROFILE}${URLS.PROFILE_LIST}`)

export const handleRedirectToMain = (
  role: UserRoles,
  navigate: NavigateFunction
) =>
  role === UserRoles.Supplier
    ? navigate(`${URLS.PROFILE}/details`)
    : handleRedirectToProfileList(navigate)
