import { Container } from 'ui/molecules/Container'
import { MENU_NAMES } from 'texts/menuNames'
import {
  isOperatorSelector,
  supplierCompanyIdSelector,
} from 'redux/login/selectors'
import { useAppSelector } from 'redux/hooks'
import { useEffect, useState } from 'react'
import { termsApi } from 'api'
import { useParams } from 'react-router-dom'
import { allSpacesRegex } from 'constants/regex'
import { Accordion } from '@frontend/design_system'
import { AccordionItem } from 'types/accordion'
import { Breadcrumb } from 'ui/atoms/Breadcrumbs/types'
import noDataSrc from 'assets/img/noDataImage.png'
import styles from './styles.module.scss'
import { Typography } from 'ui/atoms/Typography'
import { NO_DATA } from 'texts/uiTexts'
import { useApiRequest } from 'hooks/useApiRequest'
import { HEADER_HEIGHT } from 'constants/screen'

export const RENTAL_INCLUDES_TYPE = 2

interface TermsAndConditionsProps {
  breadcrumbs?: Breadcrumb[]
}

export const TermsAndConditions = ({
  breadcrumbs,
}: TermsAndConditionsProps) => {
  const { companyId: queryCompanyId } = useParams()
  const isOperator = useAppSelector(isOperatorSelector)
  const supplierCompanyId = useAppSelector(supplierCompanyIdSelector)
  const [termsAndConditions, setTermsAndConditions] = useState<AccordionItem[]>(
    []
  )

  const companyId = queryCompanyId ? +queryCompanyId : supplierCompanyId

  const { apiRequest, loading } = useApiRequest(async () => {
    const response = await termsApi.termsAndConditions(companyId)

    return response?.data.termConditions.reduce<AccordionItem[]>(
      (res, item) => {
        const tmp: string[] = []
        const descriptionItems = item.text.split('\n')

        descriptionItems.forEach((part, id) => {
          if (part.trim().replace(allSpacesRegex, '') !== '') {
            const isList = [RENTAL_INCLUDES_TYPE].includes(item.type)

            tmp.push(
              `<p key="${id}" class=${isList ? 'checked-list' : ''}>${
                isList ? '<span class="checked-item"></span>' : ''
              }<span>${part.trim()}</span></p>`
            )
          }
        })

        res.push({
          title: item.caption.toUpperCase(),
          contentHtml: tmp.join(''),
        })

        return res
      },
      []
    )
  })

  useEffect(() => {
    const fetchTermsAndConditions = async () => {
      const list = await apiRequest()

      if (list) {
        setTermsAndConditions(list)
      }
    }

    if (companyId) {
      fetchTermsAndConditions()
    }
  }, [companyId])

  return (
    <Container
      title={MENU_NAMES.TERMS_AND_CONDITIONS}
      breadcrumbList={breadcrumbs}
      currentPageLabel={MENU_NAMES.TERMS_AND_CONDITIONS}
      withNavigation={isOperator}
    >
      {termsAndConditions.length || loading ? (
        <Accordion
          items={termsAndConditions}
          isMulti
          variant="secondary"
          stickyOffset={HEADER_HEIGHT}
        />
      ) : (
        <div className={styles['no-data']}>
          <img src={noDataSrc} className={styles['no-data-image']} />
          <Typography name="subtitleWBold">{NO_DATA}</Typography>
        </div>
      )}
    </Container>
  )
}
