import { addQueryString } from 'utils/addQueryString'
import { ErrorResponse } from '../types'
import { request } from '../utils'
import { PATH } from './constants'
import { TermsAndConditions } from './types'

class Api {
  public baseUrl: string = process.env.REACT_APP_API_URL || ''

  async termsAndConditions(companyId = 0) {
    return request<TermsAndConditions, ErrorResponse>(
      this.baseUrl,
      addQueryString(PATH.termsAndConditions, { companyId }),
      { method: 'GET' }
    )
  }
}

export const termsApi = new Api()
