const SECONDS_IN_ONE_MINUTE = 60
const MAX_REMAINING_SECONDS = 10

export const secondToTimer = (seconds: number) => {
  const minutes = Math.floor(seconds / SECONDS_IN_ONE_MINUTE)
  let remainingSeconds: number | string = seconds % SECONDS_IN_ONE_MINUTE

  if (remainingSeconds < MAX_REMAINING_SECONDS) {
    remainingSeconds = '0' + remainingSeconds
  }

  const timerString = `${
    minutes < MAX_REMAINING_SECONDS ? '0' : ''
  }${minutes}:${remainingSeconds}`

  return timerString
}
