import { ModalCloseIcon } from 'ui/icons'
import { Typography } from '../../../Typography'
import { MultiselectChipProps } from './types'
import styles from './styles.module.scss'

export const MultiselectChip = ({
  item,
  onClick,
  title,
  color = 'blackMaster',
  name = 'Subtitle7',
  readonly,
}: MultiselectChipProps) => {
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    onClick(item)
  }

  return (
    <div className={styles['multiselect-chip']}>
      <Typography color={color} name={name}>
        {title}
      </Typography>
      {!readonly && (
        <div className={styles['multiselect-chip-icon']}>
          <ModalCloseIcon color="grey400" size="small" onClick={handleClick} />
        </div>
      )}
    </div>
  )
}
