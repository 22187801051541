import { TYPOGRAPHY_COLORS } from 'constants/TypographyColors'
import { SVGIcon } from './SvgIcon/SvgIcon'
import { IconProps } from './types'

export const ArrowRightIcon = ({
  color = 'grey400',
  size,
  className,
  rotate,
  onClick,
}: IconProps) => (
  <SVGIcon
    color={color}
    viewBox="0 0 16 16"
    size={size}
    className={className}
    rotate={rotate}
    onClick={onClick}
  >
    <path
      d="M5.6665 3.33335L10.3332 8.00002L5.6665 12.6667"
      stroke={TYPOGRAPHY_COLORS[color]}
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVGIcon>
)
