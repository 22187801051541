/**
 * Simple utility for merging many classes to one string
 *
 * simple example:
 * ```
 * const classes = joinClasses('foo', 'bar', 'baz');
 * console.log(classes); // 'foo bar baz'
 * ```
 * example with flags:
 * ```
 * const classes = joinClasses(
 *   'foo',
 *   undefined,
 *   ['bar', false],
 *   ['baz', true],
 *   [undefined, true]
 * );
 * console.log(classes); // 'foo baz'
 * ```
 */

export const joinClasses = (
  ...classNameDefs: Array<string | [string, SecondTupleElement]>
) => {
  const classNames = classNameDefs.reduce(
    (
      classNamesAcc: string[],
      classNameDef: string | [string, SecondTupleElement]
    ) => {
      if (Array.isArray(classNameDef)) {
        const [className, shouldAdd] = classNameDef
        if (className && shouldAdd) {
          classNamesAcc.push(className)
        }
      } else if (classNameDef) {
        classNamesAcc.push(classNameDef)
      }
      return classNamesAcc
    },
    []
  )
  return classNames.join(' ') || undefined
}

type SecondTupleElement = string | boolean | VoidFunction | undefined
