import { URLS } from 'constants/urls'
import { Route, Routes } from 'react-router-dom'
import { ProfileList } from './ProfileList'
import { ProfileAdd } from './ProfileList/components/ProfileAdd'
import { ProfileEdit } from './ProfileList/components/ProfileEdit'
import { ArchiveCompanyList } from './ArchiveCompanyList'
import { ProfileRestore } from './ArchiveCompanyList/components/ProfileRestore'
import { NotFound } from 'ui/molecules/NotFound/NotFound'
import { TermsAndConditions } from 'modules/TermsAndConditions'
import { breadcrumbs } from 'constants/breadcrumbs'

export const Profile = () => (
  <Routes>
    <Route path={URLS.PROFILE_LIST} element={<ProfileList />} />
    <Route path={URLS.PROFILE_ADD} element={<ProfileAdd />} />
    <Route path={URLS.DETAILS} element={<ProfileEdit />} />
    <Route
      path={`${URLS.PROFILE_LIST}/:companyId/edit`}
      element={<ProfileEdit />}
    />
    <Route path={URLS.ARCHIVE} element={<ArchiveCompanyList />} />
    <Route path={`${URLS.ARCHIVE}/:companyId`} element={<ProfileRestore />} />
    <Route
      path={`${URLS.TERMS_AND_CONDITIONS}/:companyId`}
      element={<TermsAndConditions breadcrumbs={[breadcrumbs.profileList]} />}
    />
    <Route path="*" element={<NotFound />} />
  </Routes>
)
