import { TYPOGRAPHY_COLORS } from 'constants/TypographyColors'
import { SVGIcon } from './SvgIcon/SvgIcon'
import { IconProps } from './types'

export const TrashIconWithBg = ({
  size,
  color = 'grey400',
  className,
  onClick,
}: IconProps) => (
  <SVGIcon
    size={size}
    viewBox="0 0 24 24"
    color="whiteMaster"
    className={className}
    onClick={onClick}
  >
    <rect width="24" height="24" rx="4" fill="#F5F9FF" />
    <path
      d="M7 8.39941H8.11115H17.0003"
      stroke={TYPOGRAPHY_COLORS[color]}
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.8894 8.39959V16.7999C15.8894 17.1181 15.7723 17.4234 15.5639 17.6484C15.3555 17.8735 15.0729 17.9999 14.7782 17.9999H9.22248C8.92778 17.9999 8.64516 17.8735 8.43678 17.6484C8.2284 17.4234 8.11133 17.1181 8.11133 16.7999V8.39959M9.77805 8.39959V7.19955C9.77805 6.88128 9.89512 6.57605 10.1035 6.35099C10.3119 6.12594 10.5945 5.99951 10.8892 5.99951H13.1115C13.4062 5.99951 13.6888 6.12594 13.8972 6.35099C14.1056 6.57605 14.2226 6.88128 14.2226 7.19955V8.39959"
      stroke={TYPOGRAPHY_COLORS[color]}
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVGIcon>
)
