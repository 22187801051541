import { TYPOGRAPHY_COLORS } from 'constants/TypographyColors'
import { SVGIcon } from './SvgIcon/SvgIcon'
import { IconProps } from './types'

export const CheckIcon = ({
  size,
  color = 'grey400',
  className,
}: IconProps) => (
  <SVGIcon size={size} color={color} className={className}>
    <path
      d="M13.3332 4L5.99984 11.3333L2.6665 8"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVGIcon>
)
