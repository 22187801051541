import { createSlice } from '@reduxjs/toolkit'
import { FleetState } from './types'

const initialState: FleetState = {
  errorMessage: '',
}

export const fleetSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setFleetErrorMessage(state, action) {
      state.errorMessage = action.payload
    },
  },
})

export const fleetReducer = fleetSlice.reducer
export const { setFleetErrorMessage } = fleetSlice.actions
