import { useMemo } from 'react'
import { EQUIPMENT_DETAILS_FORM_VALUES } from 'texts/equipmentDetails'
import { minMaxPriceValidation } from '../utils'
import { Rule } from 'ui/molecules/Form/components/FormItem/types'

export const usePriceValidation = (formValues: Record<string, unknown>) => {
  const rules: { [key: string]: Rule[] } = useMemo(
    () => ({
      maxPrice: [
        {
          type: 'required',
          message: EQUIPMENT_DETAILS_FORM_VALUES.MAX_AMOUNT.REQUIRED_MESSAGE,
        },
        {
          type: 'custom',
          message: EQUIPMENT_DETAILS_FORM_VALUES.MAX_AMOUNT.VALIDATION_MESSAGE,
          value: (value: string) => minMaxPriceValidation(value, formValues),
        },
      ],
      price: [
        {
          type: 'required',
          message: EQUIPMENT_DETAILS_FORM_VALUES.PRICE.REQUIRED_MESSAGE,
        },
      ],
    }),
    [formValues]
  )

  return rules
}
