export const minMaxPriceValidation = (
  _value: string | number,
  values: Record<string, unknown>
) => {
  if (
    values.price &&
    values.maxAmount &&
    Number(values.price) > Number(values.maxAmount)
  ) {
    return true
  }
  return false
}
