import { useState } from 'react'
import { EditableRateProps } from './types'
import { useOutsideClick } from 'ui/hooks/useOutsideClick'
import { Typography } from 'ui/atoms/Typography'
import { joinClasses } from 'utils/joinClasses'
import styles from './styles.module.scss'
import { onlyNumbersWithOneDotRegex } from 'constants/regex'

export const EditableRate = ({
  rate,
  readOnly,
  handleUpdate,
  onChange,
}: EditableRateProps) => {
  const [clicked, setClicked] = useState(false)
  const [rateRef, setRateRef] = useState<HTMLDivElement | null>(null)
  const [rateValue, setRateValue] = useState<string | number>(rate)

  useOutsideClick(rateRef, () => {
    setClicked(false)
    if (!rateValue) {
      setRateValue(0)
    }
    onChange(Number(rateValue || 0))
  })

  const handleChangeValue = (value: string) => {
    const newValue = value.replace(onlyNumbersWithOneDotRegex, '')
    if (rateValue !== newValue) {
      handleUpdate()
    }
    setRateValue(newValue)
  }

  return (
    <div>
      {clicked && !readOnly ? (
        <input
          ref={setRateRef}
          value={rateValue}
          className={joinClasses(styles['rate-input'], styles['Subtitle7'])}
          onChange={(e) => handleChangeValue(e.target.value)}
          autoFocus
        />
      ) : (
        <Typography
          onClick={() => setClicked(!clicked)}
          name="Subtitle7"
          Tag="div"
          className={styles['rate-value']}
          color={readOnly ? 'blackMaster' : 'blue700'}
        >
          {rateValue}
        </Typography>
      )}
    </div>
  )
}
