import { TYPOGRAPHY_COLORS } from 'constants/TypographyColors'
import { SVGIcon } from './SvgIcon/SvgIcon'
import { IconProps } from './types'

export const LogoutIcon = ({ size, color = 'grey400' }: IconProps) => (
  <SVGIcon size={size} color={color} viewBox="0 0 20 20">
    <path
      d="M7.5 2.5H4.16667C3.72464 2.5 3.30072 2.67559 2.98816 2.98816C2.6756 3.30072 2.5 3.72464 2.5 4.16667V15.8333C2.5 16.2754 2.6756 16.6993 2.98816 17.0118C3.30072 17.3244 3.72464 17.5 4.16667 17.5H7.5"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.75"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M11.6667 14.1667L7.50002 10L11.6667 5.83334"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.75"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M7.5 10H17.5"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.75"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SVGIcon>
)
