import { TYPOGRAPHY_COLORS } from 'constants/TypographyColors'
import { SVGIcon } from './SvgIcon/SvgIcon'
import { IconProps } from './types'

export const SortDescendingIcon = ({
  size,
  color = 'blue700',
  className,
  onClick,
}: IconProps) => (
  <SVGIcon
    size={size}
    color={color}
    className={className}
    viewBox="0 0 21 20"
    onClick={onClick}
  >
    <path
      d="M3.14282 3C2.87761 3 2.62325 3.10536 2.43572 3.29289C2.24818 3.48043 2.14282 3.73478 2.14282 4C2.14282 4.26522 2.24818 4.51957 2.43572 4.70711C2.62325 4.89464 2.87761 5 3.14282 5H14.1428C14.408 5 14.6624 4.89464 14.8499 4.70711C15.0375 4.51957 15.1428 4.26522 15.1428 4C15.1428 3.73478 15.0375 3.48043 14.8499 3.29289C14.6624 3.10536 14.408 3 14.1428 3H3.14282ZM3.14282 7C2.87761 7 2.62325 7.10536 2.43572 7.29289C2.24818 7.48043 2.14282 7.73478 2.14282 8C2.14282 8.26522 2.24818 8.51957 2.43572 8.70711C2.62325 8.89464 2.87761 9 3.14282 9H10.1428C10.408 9 10.6624 8.89464 10.8499 8.70711C11.0375 8.51957 11.1428 8.26522 11.1428 8C11.1428 7.73478 11.0375 7.48043 10.8499 7.29289C10.6624 7.10536 10.408 7 10.1428 7H3.14282ZM3.14282 11C2.87761 11 2.62325 11.1054 2.43572 11.2929C2.24818 11.4804 2.14282 11.7348 2.14282 12C2.14282 12.2652 2.24818 12.5196 2.43572 12.7071C2.62325 12.8946 2.87761 13 3.14282 13H7.14282C7.40804 13 7.66239 12.8946 7.84993 12.7071C8.03747 12.5196 8.14282 12.2652 8.14282 12C8.14282 11.7348 8.03747 11.4804 7.84993 11.2929C7.66239 11.1054 7.40804 11 7.14282 11H3.14282ZM15.1428 8C15.1428 7.73478 15.0375 7.48043 14.8499 7.29289C14.6624 7.10536 14.408 7 14.1428 7C13.8776 7 13.6233 7.10536 13.4357 7.29289C13.2482 7.48043 13.1428 7.73478 13.1428 8V13.586L11.8498 12.293C11.6612 12.1108 11.4086 12.01 11.1464 12.0123C10.8842 12.0146 10.6334 12.1198 10.448 12.3052C10.2626 12.4906 10.1574 12.7414 10.1551 13.0036C10.1529 13.2658 10.2537 13.5184 10.4358 13.707L13.4358 16.707C13.6234 16.8945 13.8777 16.9998 14.1428 16.9998C14.408 16.9998 14.6623 16.8945 14.8498 16.707L17.8498 13.707C18.032 13.5184 18.1328 13.2658 18.1305 13.0036C18.1282 12.7414 18.023 12.4906 17.8376 12.3052C17.6522 12.1198 17.4014 12.0146 17.1392 12.0123C16.877 12.01 16.6244 12.1108 16.4358 12.293L15.1428 13.586V8Z"
      fill={TYPOGRAPHY_COLORS[color]}
    />
  </SVGIcon>
)
