export interface HttpResponse<T, E = ErrorResponse> extends Response {
  data: T
  error: E
}

export enum ContentType {
  JSON = 'application/json',
  FORM_DATA = 'multipart/form-data',
}

export type EmptyResponse = object

export type ErrorResponse = {
  responseStatus: ResponseStatus
}

type ResponseStatus = {
  errorCode: string
  message: string
  stackStrace: string
}

export type ListItemType<T> = {
  pageSize: number
  currentPage: number
  totalItems: number
  totalPages: number
  pageItems: T[]
}

export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>
