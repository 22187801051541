import { Typography } from 'ui/atoms/Typography'
import { SortAscendingIcon, SortDescendingIcon } from 'ui/icons'
import { HeadItemProps } from './types'
import { useState } from 'react'
import styles from './styles.module.scss'

export const HeadItem = ({ label, onClick }: HeadItemProps) => {
  const [isSortAscending, setIsSortAscending] = useState(true)

  const Icon = isSortAscending ? SortAscendingIcon : SortDescendingIcon

  const handleClick = () => {
    onClick?.(isSortAscending)
    setIsSortAscending(!isSortAscending)
  }

  return (
    <div className={styles['head-item']}>
      <Typography name="Subtitle3">{label}</Typography>
      {onClick && <Icon onClick={handleClick} className={styles.icon} />}
    </div>
  )
}
