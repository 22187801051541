import { APPLICATIONS_TAB_KEYS } from 'texts/applications'

export const APPLICATION_STATUS = {
  WAITING_LIST: 'waiting-list',
  WAITING_FOR_CALL: 'waiting-for-call',
  WAITING_FOR_SIGN: 'waiting-for-sign',
  APPROVE: 'approve',
  REJECTED: 'rejected',
  ARCHIVE: 'archive',
} as const

export const APPLICATION_SHOWN_STATUS: {
  [key: string]: string
} = {
  [APPLICATION_STATUS.WAITING_LIST]: APPLICATIONS_TAB_KEYS.WAITING_LIST,
  [APPLICATION_STATUS.WAITING_FOR_CALL]: APPLICATIONS_TAB_KEYS.WAITING_CALL,
  [APPLICATION_STATUS.WAITING_FOR_SIGN]: APPLICATIONS_TAB_KEYS.WAITING_SIGN,
  [APPLICATION_STATUS.APPROVE]: APPLICATIONS_TAB_KEYS.APPROVE,
  [APPLICATION_STATUS.REJECTED]: APPLICATIONS_TAB_KEYS.REJECTED,
}
