import { RootState } from 'redux/store'

export const ratesSelector = (state: RootState) =>
  state.activityHistoryReducer.rates

export const selectedRangeSelector = (state: RootState) =>
  state.activityHistoryReducer.selectedActivityHistoryRange

export const sortingSelector = (state: RootState) =>
  state.activityHistoryReducer.sortingData
