import { useState, useEffect, ReactNode } from 'react'

const ARROW_WIDTH = 17
const ARROW_HEIGHT = 8
const HALF = 2
const BORDER_WIDTH = 1

export const useArrowPosition = (
  element: Element | null,
  modalNode: ReactNode,
  placement?: string,
  arrowPosition?: 'center' | 'left' | 'right'
) => {
  const [arrowLeft, setArrowLeft] = useState(0)
  const [arrowTop, setArrowTop] = useState(0)
  const [arrowTransform, setArrowTransform] = useState<string>('0')

  useEffect(() => {
    if (element) {
      const setArrowPositions = () => {
        const { left, width, top, height } = element.getBoundingClientRect()
        if (arrowPosition === 'left') {
          setArrowLeft(left + ARROW_WIDTH)
        } else if (arrowPosition === 'right') {
          setArrowLeft(left + ARROW_WIDTH - width)
        } else {
          setArrowLeft(left + width / HALF - ARROW_WIDTH / HALF)
        }

        if (placement === 'top') {
          setArrowTop(top - (ARROW_HEIGHT + BORDER_WIDTH))
          setArrowTransform('rotate(180deg)')
        } else {
          setArrowTop(top + height + BORDER_WIDTH)
        }
      }

      setArrowPositions()

      window.addEventListener('resize', setArrowPositions)

      return () => {
        window.removeEventListener('resize', setArrowPositions)
      }
    }

    return () => {}
  }, [modalNode])

  return { arrowLeft, arrowTop, arrowTransform }
}
