import { SVGIcon } from './SvgIcon/SvgIcon'
import { IconProps } from './types'

export const EllipsisHorizontalIcon = ({
  size,
  color = 'grey400',
  rotate,
}: IconProps) => (
  <SVGIcon size={size} color={color} rotate={rotate} viewBox="0 0 24 10">
    <rect
      x="24"
      width="10"
      height="24"
      rx="5"
      transform="rotate(90 24 0)"
      fill="#F5F9FF"
    />
    <path
      d="M15.5 5C15.5 5.82843 16.1716 6.5 17 6.5C17.8284 6.5 18.5 5.82843 18.5 5C18.5 4.17157 17.8284 3.5 17 3.5C16.1716 3.5 15.5 4.17157 15.5 5Z"
      fill="#2468E5"
    />
    <path
      d="M10.5 5C10.5 5.82843 11.1716 6.5 12 6.5C12.8284 6.5 13.5 5.82843 13.5 5C13.5 4.17157 12.8284 3.5 12 3.5C11.1716 3.5 10.5 4.17157 10.5 5Z"
      fill="#2468E5"
    />
    <path
      d="M5.5 5C5.5 5.82843 6.17157 6.5 7 6.5C7.82843 6.5 8.5 5.82843 8.5 5C8.5 4.17157 7.82843 3.5 7 3.5C6.17157 3.5 5.5 4.17157 5.5 5Z"
      fill="#2468E5"
    />
  </SVGIcon>
)
