import { Typography } from 'ui/atoms/Typography'
import { ArrowCircleIcon } from 'ui/icons'
import { PaginationProps } from './types'
import { DEFAULT_PAGE_START } from './constants'
import styles from './styles.module.scss'

export const Pagination = ({
  changePage,
  page,
  ofLabel,
  pages,
}: PaginationProps) => {
  if (pages <= 1) {
    return null
  }

  const handleIncrease = () => {
    if (page !== pages) {
      changePage(page + 1)
    }
  }

  const handleDecrease = () => {
    if (page > DEFAULT_PAGE_START) {
      changePage(page - 1)
    }
  }

  return (
    <div className={styles['simple-pagination']}>
      <ArrowCircleIcon
        onClick={handleDecrease}
        className={styles['simple-pagination-arrow-left']}
        size="medium"
        color={page === 1 ? 'blueSecondary' : 'blue700'}
      />
      <Typography name="body2WMedium">
        {page} {ofLabel} {pages}
      </Typography>
      <ArrowCircleIcon
        onClick={handleIncrease}
        className={styles['simple-pagination-arrow-right']}
        size="medium"
        color={page === pages ? 'blueSecondary' : 'blue700'}
      />
    </div>
  )
}
