import { fleetApi, FleetApiTypes } from 'api'
import { breadcrumbs } from 'constants/breadcrumbs'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from 'redux/hooks'
import { isOperatorSelector } from 'redux/login/selectors'
import { MENU_NAMES } from 'texts/menuNames'
import { DATEPICKER_DEFAULT_FORMAT } from 'constants/date'
import { formatDateFromIso } from 'utils/dateFormat'
import { STOP_SALES, STOP_SALES_ADD_CONFIRMATION } from 'texts/stopSells'
import { Button } from 'ui/atoms/Button'
import { PlusIcon } from 'ui/icons'
import { Confirmation } from 'ui/molecules/Confirmation'
import { Table } from 'ui/molecules/Table'
import { StopSale } from './types'
import { FILTER_KEY, headItems } from './constants'
import { useDispatch } from 'react-redux'
import { setNotificationMessage } from 'redux/notifications/slice'
import {
  DEFAULT_INITIAL_PAGE,
  DEFAULT_INITIAL_PAGE_SIZE,
} from 'constants/pagination'
import { Container } from 'ui/molecules/Container'
import { useCompanyInfo } from 'ui/hooks/useCompanyInfo'
import { Label, Typography, Pagination } from '@frontend/design_system'
import { TableTooltip } from 'ui/molecules/TableTooltip'
import { PAGINATION_DROPDOWN_LABEL } from 'texts/uiTexts'
import styles from './styles.module.scss'
import { URLS } from 'constants/urls'
import { useApiRequest } from 'hooks/useApiRequest'
import { resetSelectedFilters, setFilterItems } from 'redux/filters/slice'
import { isNotEmptyValueObject } from 'utils/object'
import { selectedFiltersSelector } from 'redux/filters/selectors'
import { Filter as FilterType } from 'utils/addQueryString'
import { Filter } from 'ui/atoms/Filter'
import { useFilterSettings } from 'modules/ActivityHistory/ActivityHistoryList/hooks/useFilterSettings'
import { setLastPage } from 'redux/common/slice'
import { PAGE_NAMES } from 'texts/common'

export const StopSales = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(DEFAULT_INITIAL_PAGE)
  const [pageSize, setPageSize] = useState(DEFAULT_INITIAL_PAGE_SIZE)
  const [stopSalesList, setStopSalesList] =
    useState<FleetApiTypes.GetStopSalesListResponse | null>(null)
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const [selectedStopSale, setSelectedStopSale] = useState<StopSale>()
  const selectedFilters = useAppSelector(selectedFiltersSelector)

  const navigate = useNavigate()

  const isOperator = useAppSelector(isOperatorSelector)

  const breadcrumbsList = [breadcrumbs.profileList]

  const { companyId } = useCompanyInfo(isOperator)

  const deleteStopSaleResponse = useApiRequest((id) =>
    fleetApi.deleteStopSale(Number(id))
  )
  const fetchStopSalesResponse = useApiRequest((companyId) =>
    fleetApi.getStopSalesList(page - 1, Number(pageSize), companyId)
  )
  const fetchStopSaleFiltersResponse = useApiRequest((companyId) =>
    fleetApi.getStopSalesFilters(companyId)
  )
  const fetchFilteredStopSalesResponse = useApiRequest((companyId, filters) =>
    fleetApi.getStopSalesList(page - 1, Number(pageSize), companyId, filters)
  )

  const getFilterSettings = useFilterSettings(setPage)

  useEffect(() => {
    dispatch(setLastPage(PAGE_NAMES.STOP_SALE))
    dispatch(resetSelectedFilters())
  }, [])

  const handleChange = (page: number) => {
    setPage(page)
  }

  const changePageSize = (value: string) => {
    setPageSize(value)
    setPage(DEFAULT_INITIAL_PAGE)
  }

  const fetchStopSales = (companyId: number) => {
    const fetchStopSalesList = async () => {
      const [stopSaleList, stopSaleFilters] = await Promise.all([
        await fetchStopSalesResponse.apiRequest(companyId),
        await fetchStopSaleFiltersResponse.apiRequest(companyId),
      ])
      if (stopSaleList) {
        setStopSalesList(stopSaleList.data)
      }
      if (stopSaleFilters) {
        const filterItems = stopSaleFilters.data.years.map((year) => ({
          value: year,
          selected: Number(year) === new Date().getFullYear(),
        }))
        dispatch(setFilterItems({ years: filterItems }))
      }
    }
    const fetchFilteredStopSalesList = async () => {
      const filters: FilterType[] = Object.entries(selectedFilters).map(
        ([, value]) => ({
          name: FILTER_KEY,
          type: 'In',
          value: value.join(','),
        })
      )
      const response = await fetchFilteredStopSalesResponse.apiRequest(
        companyId,
        filters
      )

      if (response) {
        setStopSalesList(response.data)
      }
    }

    isNotEmptyValueObject(selectedFilters)
      ? fetchFilteredStopSalesList()
      : fetchStopSalesList()
  }

  useEffect(() => {
    if (companyId) fetchStopSales(Number(companyId))
  }, [page, pageSize, selectedFilters, companyId])

  const handleDeleteStopSale = (stopSale: StopSale) => {
    setConfirmationModalOpen(true)
    setSelectedStopSale(stopSale)
  }

  const handleAddStopSale = () => navigate('add')

  const handleConfirm = async () => {
    if (selectedStopSale) {
      const response = await deleteStopSaleResponse.apiRequest(
        selectedStopSale.id
      )
      if (response) {
        dispatch(
          setNotificationMessage({
            notificationMessage:
              STOP_SALES_ADD_CONFIRMATION.SUCCESS_MODAL.TITLE,
          })
        )
        fetchStopSales(Number(companyId))
        setConfirmationModalOpen(false)
      }
    }
  }

  const handleModalClose = () => {
    setConfirmationModalOpen(false)
  }

  const handleRowClick = (id: string) => {
    navigate(`${URLS.FLEET}${URLS.STOP_SALES}/${companyId}/${id}/edit`)
  }

  const formattedHeadItems = headItems.map((header, i) => {
    const CAR_CLASS_COLUMN_INDEX = 3
    if (i === CAR_CLASS_COLUMN_INDEX) {
      return {
        value: (
          <Label
            typographyName="Subtitle3"
            position="bottom"
            label={header}
            tooltip={
              <Typography name="caption1WMedium">
                {STOP_SALES.CAR_CLASS_TOOLTIP}
              </Typography>
            }
          />
        ),
      }
    }
    return { value: header }
  })

  const formattedBodyItems = stopSalesList?.stopSells.pageItems?.map(
    (stopSale) => ({
      items: [
        stopSale.country,
        <TableTooltip
          title={stopSale.city}
          onClick={() => handleRowClick(stopSale.id)}
        />,
        <TableTooltip
          title={stopSale.location}
          onClick={() => handleRowClick(stopSale.id)}
        />,
        stopSale.carClass,
        formatDateFromIso(stopSale.created, DATEPICKER_DEFAULT_FORMAT),
        formatDateFromIso(stopSale.from, DATEPICKER_DEFAULT_FORMAT),
        formatDateFromIso(stopSale.to, DATEPICKER_DEFAULT_FORMAT),
      ],
      onClick: () => handleRowClick(stopSale.id),
      removeRowClick: () => handleDeleteStopSale(stopSale),
    })
  )

  return (
    <>
      {isConfirmationModalOpen && (
        <Confirmation
          confirmModalProps={{
            question: STOP_SALES_ADD_CONFIRMATION.CONFIRMATION_MODAL.QUESTION,
            onConfirm: handleConfirm,
            onClose: handleModalClose,
            title: STOP_SALES.DELETE,
          }}
          successModalProps={{
            onClose: handleModalClose,
            title: STOP_SALES_ADD_CONFIRMATION.SUCCESS_MODAL.TITLE,
          }}
        />
      )}
      <Container
        breadcrumbList={isOperator ? breadcrumbsList : []}
        currentPageLabel={MENU_NAMES.FLEET.SUBMENU_NAMES.STOP_SALES}
        title={MENU_NAMES.FLEET.SUBMENU_NAMES.STOP_SALES}
        tooltip={STOP_SALES.TOOLTIP}
        withNavigation={isOperator}
      >
        <div className={styles.list}>
          <div className={styles.header}>
            <div className={styles['button-wrapper']}>
              <Button
                size="small"
                typographyName="Button2"
                variant="outline"
                label={STOP_SALES.ADD_BUTTON}
                onClick={handleAddStopSale}
                iconPosition="right"
                icon={<PlusIcon color="blue700" />}
              />
            </div>
            <div className={styles['filter-icon']}>
              <Filter
                variant="secondary"
                items={getFilterSettings('years').items}
                onSelectAllItems={getFilterSettings('years').onSelectAllItems}
                onReset={getFilterSettings('years').onReset}
              />
            </div>
          </div>
          {formattedBodyItems && (
            <div className={styles.table}>
              <Table
                headItems={formattedHeadItems}
                bodyItems={formattedBodyItems}
              />
            </div>
          )}
        </div>
        <footer className={styles.pagination}>
          <Pagination
            current={page}
            pageItemCount={stopSalesList?.stopSells.pageItems.length}
            total={stopSalesList?.stopSells.totalItems || 0}
            pageSize={pageSize}
            changePageSize={changePageSize}
            changePage={handleChange}
            pageSizeLabel={PAGINATION_DROPDOWN_LABEL}
          />
        </footer>
      </Container>
    </>
  )
}
