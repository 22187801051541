import { createSlice } from '@reduxjs/toolkit'
import { ProfileState } from './types'

const initialState: ProfileState = {
  shortSupplierData: {
    id: 0,
    country: '',
    name: '',
    currency: '',
    isRegistered: false,
  },
  isLoading: false,
  supplierCompanyName: '',
}

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setShortSupplierData(state, action) {
      state.shortSupplierData = action.payload
    },
    setCompanyName(state, action) {
      state.supplierCompanyName = action.payload
    },
    resetShortSupplierData(state) {
      state.shortSupplierData = initialState.shortSupplierData
    },
  },
})

export const profileReducer = profileSlice.reducer
export const { setShortSupplierData, setCompanyName, resetShortSupplierData } =
  profileSlice.actions
