import { Typography } from 'ui/atoms/Typography'
import { VehicleProps } from './types'
import styles from './styles.module.scss'

export const Vehicle = ({ item, imageSrc }: VehicleProps) => (
  <div className={styles['item-wrapper']}>
    <div className={styles.item}>
      <img src={imageSrc} className={styles.image} />
      <Typography name="Button2">{item}</Typography>
    </div>
  </div>
)
