import { Route, Routes } from 'react-router-dom'
import { NotFound } from 'ui/molecules/NotFound'
import { ActivityHistoryList } from './ActivityHistoryList'
import { ActivityHistoryDetails } from './ActivityHistoryDetails/ActivityHistoryDetails'
import { Rates } from './ActivityHistoryDetails/components/Rates/Rates'

export const ActivityHistory = () => (
  <Routes>
    <Route path="/" element={<ActivityHistoryList />} />
    <Route path="/:auditId/details" element={<ActivityHistoryDetails />} />
    <Route path="/:auditId/details/rates" element={<Rates />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
)
