import { joinClasses } from 'utils/joinClasses'
import { TabPaneProps } from './type'
import styles from './styles.module.scss'

export const TabPane = (props: TabPaneProps) => {
  const isActive = props.activeKey?.indexOf(props.id) !== -1
  return (
    <div
      className={joinClasses(styles['tabs-tabpane'], [
        styles['tabs-tabpane-active'],
        isActive,
      ])}
    >
      {props.children}
    </div>
  )
}
