import { useAppSelector } from 'redux/hooks'
import { fleetApi } from 'api'
import { isOperatorSelector } from 'redux/login/selectors'
import {
  STOP_SALE_TITLE,
  STOP_SALE_ADD_BREADCRUMB,
} from 'texts/stopSellsDetails'
import { Container } from 'ui/molecules/Container'
import { initialFormValues } from '../StopSaleDetailsForm/constants'
import { useCompanyInfo } from 'ui/hooks/useCompanyInfo'
import { StopSaleDetailsForm } from '../StopSaleDetailsForm'
import { StopSalesAdd, StopSalesRange } from 'api/fleet/types'
import { useApiRequest } from 'hooks/useApiRequest'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setNotificationMessage } from 'redux/notifications/slice'
import { STOP_SALE_SUCCESS_ADD } from 'texts/stopSells'
import { getDetailsBreadcrumbsList } from 'utils/getDetailsBreadcrumbsList'
import { formatStopSaleDate } from '../../utils'

export const StopSaleAdd = () => {
  const isOperator = useAppSelector(isOperatorSelector)

  const { companyId } = useCompanyInfo(isOperator)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { apiRequest, loading } = useApiRequest(
    (values) => fleetApi.addStopSale(values),
    undefined,
    undefined,
    undefined,
    false
  )

  const breadcrumbList = getDetailsBreadcrumbsList(
    isOperator,
    String(companyId),
    'stopSells'
  )

  const handleSubmit = async (data: StopSalesAdd) => {
    const values = {
      ...data,
      from: formatStopSaleDate(data.from),
      to: formatStopSaleDate(data.to),
      applicable:
        StopSalesRange[data.applicable as keyof typeof StopSalesRange],
    }
    const response = await apiRequest(values)
    if (response) {
      navigate(-1)
      dispatch(
        setNotificationMessage({
          notificationMessage: STOP_SALE_SUCCESS_ADD,
        })
      )
    }
  }

  return (
    <Container
      title={STOP_SALE_TITLE}
      breadcrumbList={breadcrumbList}
      currentPageLabel={STOP_SALE_ADD_BREADCRUMB}
      withNavigation={isOperator}
    >
      <StopSaleDetailsForm
        initialValues={initialFormValues}
        handleSubmit={handleSubmit}
        isLoading={loading}
        isAddForm
      />
    </Container>
  )
}
