import { RangeType, RangesProps } from './types'
import { OriginButton } from '@frontend/design_system'
import { Badge } from 'ui/atoms/Badge'
import { FleetApiTypes } from 'api'
import { generateSimpleUniqueId } from 'utils/generateUniqueId'
import { PRICE_LIST_DETAILS } from 'texts/priceListDetails'
import { SeasonRange } from './components/SeasonRange'
import { DailyRange } from './components/DailyRange'
import { initialValues, DAILY_TYPE, INFINITY } from './constants'
import { PlusIcon } from 'ui/icons'
import styles from './styles.module.scss'
import { FormAlertLabel } from 'ui/atoms/FormAlertLabel'

export const Ranges = <T extends RangeType>({
  type,
  onRangesChange,
  ranges,
  readOnly,
  error,
}: RangesProps<T>) => {
  const handleChange = (index: number, value: T) => {
    const newRanges = [...ranges]
    newRanges.splice(index, 1, value)
    onRangesChange(newRanges)
  }

  const handleAddRange = () => {
    const newRanges = [...ranges]

    if (type === DAILY_TYPE) {
      const lastItem = newRanges[
        newRanges.length - 1
      ] as FleetApiTypes.DailyRange
      if (lastItem?.rangeEnd && lastItem?.rangeEnd === INFINITY) {
        const newLastItem = { ...lastItem, rangeEnd: '' }
        newRanges[newRanges.length - 1] = newLastItem as T
      }
    }

    const newItem = initialValues[type] as T
    newRanges.push({ ...newItem, uniqueId: generateSimpleUniqueId() })
    onRangesChange(newRanges)
  }

  const handleRemoveRange = (index: number) => {
    const newRanges = [...ranges]
    newRanges.splice(index, 1)
    onRangesChange(newRanges)
  }

  const Range = type === 'season' ? SeasonRange : DailyRange

  return (
    <div>
      {ranges.length ? (
        ranges.map((range, i) => (
          <Range<T>
            handleRemoveRange={() => handleRemoveRange(i)}
            readOnly={readOnly}
            key={range.uniqueId}
            index={i}
            handleChange={handleChange}
            range={range}
          />
        ))
      ) : (
        <Badge
          className={styles.badge}
          message={
            type === 'season'
              ? PRICE_LIST_DETAILS.SEASON_RANGE_BADGE
              : PRICE_LIST_DETAILS.DAILY_RANGE_BADGE
          }
        />
      )}
      {error && <FormAlertLabel label={error} type="error" position="static" />}
      {!readOnly && (
        <div className={styles['button-wrapper']}>
          <OriginButton
            label={PRICE_LIST_DETAILS.ADD_RANGE}
            size="large"
            iconRight={<PlusIcon color="whiteMaster" size="medium" />}
            onClick={handleAddRange}
          />
        </div>
      )}
    </div>
  )
}
