import { useState } from 'react'
import { Typography } from '../Typography'
import { EllipsisHorizontalIcon } from 'ui/icons'
import { ActionItemsProps } from './types'
import { joinClasses } from 'utils/joinClasses'
import { useOutsideClick } from 'ui/hooks/useOutsideClick'
import styles from './styles.module.scss'

export const ActionItems = ({ items, listItemWidth }: ActionItemsProps) => {
  const [isListItemOpen, setListItemOpen] = useState(false)
  const [actionItemsElement, setActionItemsElement] =
    useState<HTMLDivElement | null>(null)

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    setListItemOpen((isListItemOpen) => !isListItemOpen)
  }

  const handleClose = () => setListItemOpen(false)

  useOutsideClick(actionItemsElement, handleClose)

  return (
    <div
      ref={setActionItemsElement}
      className={styles['action-items']}
      onClick={handleClick}
      role="button"
    >
      <EllipsisHorizontalIcon size="large" />
      {isListItemOpen && !!items.length && (
        <div className={styles['list-item']} style={{ width: listItemWidth }}>
          {items.map(({ value, type = 'default', onClick }, index) => (
            <Typography
              key={index}
              Tag="div"
              className={joinClasses(styles.item, [styles[type], type])}
              name="Button2"
              color="blackMaster"
              onClick={onClick}
            >
              {value}
            </Typography>
          ))}
        </div>
      )}
    </div>
  )
}
