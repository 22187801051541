import { Props } from './types'
import { Tooltip } from 'ui/atoms/Tooltip'
import { Typography } from 'ui/atoms/Typography'
import { MAX_STRING_LENGTH } from './constants'
import styles from './styles.module.scss'

export const TableTooltip = ({ title, tooltip, onClick }: Props) => {
  const lengthString = title.trim().length

  if (lengthString < MAX_STRING_LENGTH) {
    return (
      <div className={styles['tooltip-wrapper']}>
        <Typography
          name="Subtitle7"
          onClick={onClick ? onClick : undefined}
          className={styles['tooltip-content']}
        >
          {title}
        </Typography>
      </div>
    )
  }

  return (
    <Tooltip
      popupContent={
        <Typography name="Subtitle7" Tag="div">
          {tooltip || title}
        </Typography>
      }
      placement="top"
      size="auto"
    >
      <Typography
        name="Subtitle7"
        className={styles['tooltip-content']}
        onClick={onClick ? onClick : undefined}
      >
        {title}
      </Typography>
    </Tooltip>
  )
}
