import { Input } from 'ui/atoms/Input'
import { SearchCodesProps } from './types'

export const SearchCodes = ({
  placeholder,
  onChange,
  value,
}: SearchCodesProps) => (
  <Input
    noBorder="all"
    spaceForError="none"
    typographyName="Button2"
    iconLeft="SearchIcon"
    iconSize="small"
    onChange={onChange}
    value={value}
    size="large"
    placeholder={placeholder}
    caretColorVariant="secondary"
  />
)
