import { MENU_NAMES } from 'texts/menuNames'
import { URLS } from 'constants/urls'

export const breadcrumbs = {
  profileList: {
    link: URLS.PROFILE + URLS.PROFILE_LIST,
    label: MENU_NAMES.PROFILE_LIST.NAME,
  },
  applications: {
    link: URLS.APPLICATIONS + URLS.APPLICATIONS_LIST,
    label: MENU_NAMES.APPLICATIONS.SUBMENU_NAMES.APPLICATIONS,
  },
  archive: {
    link: URLS.PROFILE + URLS.ARCHIVE,
    label: MENU_NAMES.PROFILE_ARCHIVE.NAME,
  },
  locations: {
    link: URLS.LOCATIONS + URLS.LIST_OF_LOCATIONS,
    label: MENU_NAMES.LOCATIONS.NAME,
  },
  stopSells: {
    link: URLS.FLEET + URLS.STOP_SALES,
    label: MENU_NAMES.FLEET.SUBMENU_NAMES.STOP_SALES,
  },
  vehicles: {
    link: URLS.FLEET + URLS.VEHICLES,
    label: MENU_NAMES.FLEET.SUBMENU_NAMES.VEHICLES,
  },
  equipments: {
    link: URLS.FLEET + URLS.EQUIPMENTS,
    label: MENU_NAMES.FLEET.SUBMENU_NAMES.EQUIPMENTS,
  },
  priceLists: {
    link: URLS.FLEET + URLS.PRICE_LIST,
    label: MENU_NAMES.FLEET.SUBMENU_NAMES.PRICE_LISTS,
  },
  activityHistory: {
    link: URLS.ACTIVITY_HISTORY,
    label: MENU_NAMES.ACTIVITY_HISTORY,
  },
}
