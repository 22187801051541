import { CONTACT_INFORMATION_FORM_VALUES } from 'texts/profileDetails'
import { Rule } from 'ui/molecules/Form/components/FormItem/types'

export const EMPTY_RULES = []

export const phoneNumberRules: Rule[] = [
  {
    type: 'required',
    message: CONTACT_INFORMATION_FORM_VALUES.PHONE.REQUIRED_MESSAGE,
  },
  {
    type: 'min',
    value: 3,
    message: CONTACT_INFORMATION_FORM_VALUES.PHONE.VALIDATION_MESSAGE_MIN,
  },
  {
    type: 'max',
    value: 20,
    message: CONTACT_INFORMATION_FORM_VALUES.PHONE.VALIDATION_MESSAGE_MAX,
  },
]
