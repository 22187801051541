import { Typography } from 'ui/atoms/Typography'
import { Props } from './types'
import styles from './styles.module.scss'

export const Header = ({ title, markTitle, subtitle }: Props) => (
  <>
    <Typography
      color="whiteMaster"
      markColor="yellow400Master"
      Tag="h3"
      name="H4"
      className={styles.title}
    >
      {title}
      <mark>{markTitle}</mark>
    </Typography>
    {subtitle && (
      <Typography
        color="whiteMaster"
        name="body2WMedium"
        Tag="p"
        className={styles.subtitle}
      >
        {subtitle}
      </Typography>
    )}
  </>
)
