import { TYPOGRAPHY_COLORS } from 'constants/TypographyColors'
import { SVGIcon } from './SvgIcon/SvgIcon'
import { IconProps } from './types'

export const StreamlineIcon = ({
  color = 'blue700',
  size,
  className,
  onClick,
}: IconProps) => (
  <SVGIcon
    color={color}
    viewBox="0 0 16 16"
    size={size}
    className={className}
    onClick={onClick}
  >
    <path
      d="M6.68359 9.81865L14.5667 1.93555"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M14.5632 1.93555L9.90498 14.8351C9.77855 15.111 9.40092 15.1465 9.22526 14.8991C9.21096 14.879 9.19857 14.8576 9.1883 14.8351L6.68005 9.81865L1.66356 7.31041C1.38773 7.18398 1.35219 6.80635 1.5996 6.63069C1.61973 6.61638 1.64115 6.604 1.66356 6.59372L14.5632 1.93555Z"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SVGIcon>
)
