import { TYPOGRAPHY_COLORS } from 'constants/TypographyColors'
import { SVGIcon } from './SvgIcon/SvgIcon'
import { IconProps } from './types'

export const FilterIcon = ({
  size,
  color = 'blue700',
  rotate,
  className,
}: IconProps) => (
  <SVGIcon
    size={size}
    color={color}
    rotate={rotate}
    viewBox="0 0 16 16"
    className={className}
  >
    <path
      d="M2 2.66667C2 2.48986 2.07024 2.32029 2.19526 2.19526C2.32029 2.07024 2.48986 2 2.66667 2H13.3333C13.5101 2 13.6797 2.07024 13.8047 2.19526C13.9298 2.32029 14 2.48986 14 2.66667V4.39067C14 4.56746 13.9297 4.73701 13.8047 4.862L9.52867 9.138C9.40363 9.263 9.33337 9.43254 9.33333 9.60933V11.3333L6.66667 14V9.60933C6.66663 9.43254 6.59637 9.263 6.47133 9.138L2.19533 4.862C2.0703 4.73701 2.00004 4.56746 2 4.39067V2.66667Z"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SVGIcon>
)
