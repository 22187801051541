import { Autocomplete } from 'ui/atoms/Autocomplete'
import { Typography } from 'ui/atoms/Typography'
import { AUTOCOMPLETE_LABEL } from 'texts/uiTexts'
import { useState } from 'react'
import { AUTOCOMPLETE_PLACEHOLDERS, SEARCH_BY } from 'texts/applications'
import { SearchByProps } from './types'
import { Dropdown } from 'ui/atoms/Dropdown'
import styles from './styles.module.scss'

export const SearchBy = ({
  searchKeys,
  setData,
  onSearch,
  handleSelect,
}: SearchByProps) => {
  const [activeFilter, setActiveFilter] = useState(searchKeys[0] || '')

  const handleChange = (value: string) => {
    setActiveFilter(value)
  }

  const handleSearch = (value: string) => {
    onSearch(value, activeFilter)
  }

  return (
    <div className={styles['search-by']}>
      <div className={styles.dropdown}>
        <Dropdown
          defaultValue={searchKeys[0]}
          items={searchKeys}
          renderLabel={(value) => (
            <Typography
              name="Button2"
              html={`${SEARCH_BY} <mark>${value}</mark>`}
              markColor="blue700"
            />
          )}
          onChange={handleChange}
          borderRadius="8px 0 0 8px"
          size="small"
        />
      </div>
      <Autocomplete
        size="small"
        dropdownEmptyText={
          AUTOCOMPLETE_PLACEHOLDERS[activeFilter].dropdownEmpty
        }
        handleSelect={handleSelect}
        placeholder={AUTOCOMPLETE_PLACEHOLDERS[activeFilter].placeholder}
        data={[]}
        borderRadius="0 8px 8px 0"
        setData={setData}
        ofLabel={AUTOCOMPLETE_LABEL}
        handleSearch={handleSearch}
      />
    </div>
  )
}
