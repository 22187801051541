import { Input } from 'ui/atoms/Input'
import { TrashIconWithBg } from 'ui/icons'
import { DriverAgeRangeType } from 'api/fleet/types'
import { DriverAgeRangeProps } from './types'
import { onlyNumbersWithOneDotRegex } from 'constants/regex'
import styles from './styles.module.scss'
import { DRIVER_AGE_RANGE } from 'texts/driverAgeRange'

export const DriverAgeRange = ({
  range,
  handleChange,
  index,
  rangeType,
  handleRemoveRange,
}: DriverAgeRangeProps) => {
  const onChange = (value: string, key: string) => {
    const newValue = value.replace(onlyNumbersWithOneDotRegex, '')
    handleChange(index, { ...range, [key]: newValue })
  }

  return (
    <div className={styles.range}>
      <Input
        size="large"
        placeholder={DRIVER_AGE_RANGE.START.PLACEHOLDER}
        value={String(range.ageStart)}
        onChange={(value) => onChange(value, 'ageStart')}
        spaceForError="auto"
        label={DRIVER_AGE_RANGE.START.LABEL}
      />
      <Input
        size="large"
        placeholder={DRIVER_AGE_RANGE.END.PLACEHOLDER}
        value={String(range.ageEnd)}
        onChange={(value) => onChange(value, 'ageEnd')}
        spaceForError="auto"
        label={DRIVER_AGE_RANGE.END.LABEL}
      />
      <Input
        size="large"
        placeholder={DRIVER_AGE_RANGE.FEE.PLACEHOLDER}
        value={String(range.price)}
        onChange={(value) => onChange(value, 'price')}
        spaceForError="auto"
        label={DRIVER_AGE_RANGE.FEE.LABEL}
      />
      <Input
        size="large"
        value={rangeType === DriverAgeRangeType.PerRental ? '' : range.maxPrice}
        placeholder={DRIVER_AGE_RANGE.MAX_PRICE.PLACEHOLDER}
        onChange={(value) => onChange(value, 'maxPrice')}
        spaceForError="auto"
        disabled={rangeType === DriverAgeRangeType.PerRental}
        label={DRIVER_AGE_RANGE.MAX_PRICE.LABEL}
      />
      <TrashIconWithBg
        color="blue700"
        size="large"
        className={styles.icon}
        onClick={handleRemoveRange}
      />
    </div>
  )
}
