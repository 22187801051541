import { TYPOGRAPHY_COLORS } from 'constants/TypographyColors'
import { SVGIcon } from './SvgIcon/SvgIcon'
import { IconProps } from './types'

export const EyeClearOffIcon = ({
  size,
  color = 'grey400',
  className,
}: IconProps) => (
  <SVGIcon size={size} color={color} className={className}>
    <g clipPath="url(#clip0_175_16108)">
      <path
        fill="none"
        d="M9.41341 9.41331C9.23032 9.60981 9.00951 9.76741 8.76418 9.87672C8.51885 9.98604 8.25402 10.0448 7.98547 10.0496C7.71693 10.0543 7.45019 10.0049 7.20115 9.9043C6.95212 9.80371 6.7259 9.65399 6.53598 9.46408C6.34606 9.27416 6.19634 9.04794 6.09575 8.7989C5.99516 8.54987 5.94577 8.28312 5.9505 8.01458C5.95524 7.74604 6.01402 7.48121 6.12333 7.23587C6.23264 6.99054 6.39025 6.76974 6.58675 6.58664M11.9601 11.96C10.8205 12.8286 9.43282 13.3099 8.00008 13.3333C3.33341 13.3333 0.666748 7.99998 0.666748 7.99998C1.49601 6.45457 2.64617 5.10438 4.04008 4.03998L11.9601 11.96ZM6.60008 2.82664C7.05897 2.71923 7.52879 2.66554 8.00008 2.66664C12.6667 2.66664 15.3334 7.99998 15.3334 7.99998C14.9287 8.75705 14.4461 9.4698 13.8934 10.1266L6.60008 2.82664Z"
        stroke={TYPOGRAPHY_COLORS[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="none"
        d="M0.666748 0.666626L15.3334 15.3333"
        stroke={TYPOGRAPHY_COLORS[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_175_16108">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </SVGIcon>
)
