import { useMemo, useState } from 'react'
import { Typography } from 'ui/atoms/Typography'
import { ModalCloseIcon } from 'ui/icons'
import { LightIcon } from 'ui/icons/LightIcon'
import { MAIN_LAYOUT } from 'texts/mainLayout'
import { TipsProps } from './types'
import { joinClasses } from 'utils/joinClasses'
import { Dropdown } from 'ui/atoms/Dropdown'
import { Tip } from './components/Tip/Tip'
import { ALL, TIPS_TOPICS, MOCK_TIPS as TIPS } from 'texts/tips'
import styles from './styles.module.scss'

export const Tips = ({ className = '' }: TipsProps) => {
  const [isTipsOpen, setTipsOpen] = useState(false)
  const [activeTopic, setActiveTopic] = useState(ALL)

  const onToggle = () => setTipsOpen(!isTipsOpen)
  const handleCloseTips = () => setTipsOpen(false)

  const filteredTips = useMemo(
    () =>
      activeTopic === ALL
        ? TIPS
        : TIPS.filter((el) => el.group === activeTopic),
    [activeTopic]
  )

  return (
    <>
      <div
        className={joinClasses(
          styles['tips-button'],
          [styles.active, isTipsOpen],
          className
        )}
        onClick={onToggle}
      >
        <LightIcon size="large" />
      </div>
      {isTipsOpen && (
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <Typography name="H5">{MAIN_LAYOUT.HEADER.TIPS}</Typography>
            <ModalCloseIcon
              size="large"
              className={styles['close-icon']}
              onClick={handleCloseTips}
            />
          </div>
          <div className={styles.dropdown}>
            <Dropdown
              value={activeTopic}
              defaultValue={ALL}
              renderLabel={(value) => (
                <Typography name="Button1" color="blue700">
                  {value}
                </Typography>
              )}
              items={TIPS_TOPICS}
              onChange={setActiveTopic}
              noBorder
            />
          </div>
          {filteredTips.map(
            ({
              topic,
              buttonLabel,
              startDescription,
              endDescription,
              subTitle,
              title,
              linkForButton,
            }) => (
              <Tip
                topic={topic}
                key={topic}
                buttonLabel={buttonLabel}
                startDescription={startDescription}
                endDescription={endDescription}
                title={title}
                closeTips={onToggle}
                subTitle={subTitle}
                linkForButton={linkForButton}
              />
            )
          )}
        </div>
      )}
    </>
  )
}
