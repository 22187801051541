import React, { useRef } from 'react'
import { joinClasses } from 'utils/joinClasses'
import { Props } from './types'
import styles from './styles.module.scss'

export const CodeInput = ({ values, onChange }: Props) => {
  const length = values.length
  const inputRefs = useRef<Array<HTMLInputElement>>([])

  const handleKeyDown = (event: React.KeyboardEvent, index: number) => {
    if (event.key === 'Backspace') {
      event.preventDefault()
      const newValues = [...values]
      newValues[index] = ''
      onChange(newValues)
      if (index > 0) inputRefs.current[index - 1].focus()
    }
  }

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const oldValue = values[index]
    const newValue = event.target.value.trim().replace(oldValue, '')
    const newValues = [...values]
    newValues[index] = newValue
    onChange(newValues)
    if (index < length - 1) {
      inputRefs.current[index + 1].focus()
    } else {
      inputRefs.current[index].blur()
    }
  }

  return (
    <div className={styles.wrapper}>
      {values.map((value: string, i: number) => (
        <input
          key={i}
          className={joinClasses(styles['input-wrapper'], styles['H5'])}
          onChange={(event) => handleChange(event, i)}
          onKeyDown={(event) => handleKeyDown(event, i)}
          maxLength={1}
          value={value}
          ref={(element: HTMLInputElement) => (inputRefs.current[i] = element)}
        />
      ))}
    </div>
  )
}
