import { URLS } from './../../../constants/urls'
import {
  UserIcon,
  CompassIcon,
  FleetIcon,
  FileTextIcon,
  RotateTimeIcon,
  ProfileArchiveIcon,
} from 'ui/icons'
import { MENU_NAMES } from 'texts/menuNames'
import { MenuItemsType, SubmenuItemsType } from './types'

export const MENU_PATHS = {
  [MENU_NAMES.ACTIVITY_HISTORY]: URLS.ACTIVITY_HISTORY,
  [MENU_NAMES.PROFILE_LIST.NAME]: URLS.PROFILE + URLS.PROFILE_LIST,
  [MENU_NAMES.PROFILE_ARCHIVE.NAME]: URLS.PROFILE + URLS.ARCHIVE,
  [MENU_NAMES.PROFILE_DETAILS.NAME]: URLS.PROFILE + URLS.DETAILS,
  [MENU_NAMES.FLEET.SUBMENU_NAMES.VEHICLES]: URLS.FLEET + URLS.VEHICLES,
  [MENU_NAMES.FLEET.SUBMENU_NAMES.EQUIPMENTS]: URLS.FLEET + URLS.EQUIPMENTS,
  [MENU_NAMES.FLEET.SUBMENU_NAMES.PRICE_LISTS]: URLS.FLEET + URLS.PRICE_LIST,
  [MENU_NAMES.FLEET.SUBMENU_NAMES.STOP_SALES]: URLS.FLEET + URLS.STOP_SALES,
  [MENU_NAMES.LOCATIONS.NAME]: URLS.LOCATIONS + URLS.LIST_OF_LOCATIONS,
  [MENU_NAMES.TERMS_AND_CONDITIONS]: URLS.TERMS_AND_CONDITIONS,
}

export const OPERATOR_MENU_ITEMS: MenuItemsType = [
  {
    name: MENU_NAMES.PROFILE_LIST.NAME,
    path: MENU_PATHS[MENU_NAMES.PROFILE_LIST.NAME],
    icon: UserIcon,
    withSubmenu: false,
  },
  {
    name: MENU_NAMES.PROFILE_ARCHIVE.NAME,
    icon: ProfileArchiveIcon,
    path: MENU_PATHS[MENU_NAMES.PROFILE_ARCHIVE.NAME],
    withSubmenu: false,
  },
  // {
  //   name: MENU_NAMES.APPLICATIONS.NAME,
  //   icon: FileTextIcon,
  //   withSubmenu: true,
  // }, // TODO: will be added in the second release
  {
    name: MENU_NAMES.ACTIVITY_HISTORY,
    path: MENU_PATHS[MENU_NAMES.ACTIVITY_HISTORY],
    icon: RotateTimeIcon,
    withSubmenu: false,
  },
]

export const MENU_ITEM_HEIGHT = 45

export const OPERATOR_SUBMENU_ITEMS: SubmenuItemsType = {
  // APPLICATIONS: [
  //   {
  //     name: MENU_NAMES.APPLICATIONS.SUBMENU_NAMES.APPLICATIONS,
  //     path: URLS.APPLICATIONS_LIST,
  //   },
  //   {
  //     name: MENU_NAMES.APPLICATIONS.SUBMENU_NAMES.ARCHIVE,
  //     path: URLS.APPLICATIONS_ARCHIVE,
  //   },
  // ], // TODO: will be added in second release
}

export const SUPPLIER_MENU_ITEMS: MenuItemsType = [
  {
    name: MENU_NAMES.PROFILE_DETAILS.NAME,
    icon: UserIcon,
    withSubmenu: false,
    path: MENU_PATHS[MENU_NAMES.PROFILE_DETAILS.NAME],
  },
  {
    name: MENU_NAMES.LOCATIONS.NAME,
    path: MENU_PATHS[MENU_NAMES.LOCATIONS.NAME],
    icon: CompassIcon,
    withSubmenu: false,
  },
  {
    name: MENU_NAMES.FLEET.NAME,
    icon: FleetIcon,
    withSubmenu: true,
  },
  {
    name: MENU_NAMES.TERMS_AND_CONDITIONS,
    path: URLS.TERMS_AND_CONDITIONS,
    icon: FileTextIcon,
    withSubmenu: false,
  },
]

export const SUPPLIER_SUBMENU_ITEMS: SubmenuItemsType = {
  FLEET: [
    {
      name: MENU_NAMES.FLEET.SUBMENU_NAMES.VEHICLES,
      path: MENU_PATHS[MENU_NAMES.FLEET.SUBMENU_NAMES.VEHICLES],
    },
    {
      name: MENU_NAMES.FLEET.SUBMENU_NAMES.EQUIPMENTS,
      path: MENU_PATHS[MENU_NAMES.FLEET.SUBMENU_NAMES.EQUIPMENTS],
    },
    {
      name: MENU_NAMES.FLEET.SUBMENU_NAMES.PRICE_LISTS,
      path: MENU_PATHS[MENU_NAMES.FLEET.SUBMENU_NAMES.PRICE_LISTS],
    },
    {
      name: MENU_NAMES.FLEET.SUBMENU_NAMES.STOP_SALES,
      path: MENU_PATHS[MENU_NAMES.FLEET.SUBMENU_NAMES.STOP_SALES],
    },
  ],
}
