export type QueryParamsType = Record<
  string,
  string | number | boolean | string[] | number[]
>

export type Filter = {
  name: string
  type: 'Eq' | 'Like' | 'In' | 'ILike' | 'Ge' | 'Lt'
  value: string | number
}

const addQueryParam = (query: QueryParamsType, key: string) => {
  const encodedKey = encodeURIComponent(key)
  const queryKey = query[key]

  const prepareQueryParam = (key: string | number | boolean) =>
    `${encodedKey}=${encodeURIComponent(
      typeof key === 'number' ? key : `${key}`
    )}`

  if (Array.isArray(queryKey)) {
    return queryKey.map((el) => prepareQueryParam(el)).join('&')
  } else {
    return prepareQueryParam(queryKey)
  }
}

const addFilter = (filters: Filter[]) => {
  const filterString = filters
    .map(
      (filter) =>
        `[${filter.name}]${filter.type}[${encodeURIComponent(filter.value)}]`
    )
    .join('&filter=')
  return `&filter=${filterString}`
}

export const addQueryString = (
  url: string,
  queryParams: QueryParamsType,
  filters?: Filter[],
  sorting?: string
) => {
  const keys = Object.keys(queryParams).filter(
    (key) => queryParams[key] !== '' && typeof queryParams[key] !== 'undefined'
  )

  const queryString = keys
    .map((key) => addQueryParam(queryParams, key))
    .join('&')

  let finalUrl = `${url}?${queryString}`

  if (sorting) {
    finalUrl += `&order=${sorting}`
  }
  if (filters) {
    finalUrl += addFilter(filters)
  }
  return finalUrl
}
