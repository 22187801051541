import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'redux/hooks'
import { RefreshIcon } from 'ui/icons'
import { Pagination } from '@frontend/design_system'
import { Table } from 'ui/molecules/Table'
import { MENU_NAMES } from 'texts/menuNames'
import { headItems } from './constants'
import { Confirmation } from 'ui/molecules/Confirmation'
import { ErrorResponse } from 'api/types'
import { profileApi, ProfileApiTypes } from 'api'
import checkImage from 'assets/img/checkIcon.webp'
import { AUTOCOMPLETE_LABEL, PAGINATION_DROPDOWN_LABEL } from 'texts/uiTexts'
import {
  PROFILE_ARCHIVE,
  PROFILE_ARCHIVE_CONFIRMATION,
} from 'texts/profileArchive'
import { URLS } from 'constants/urls'
import { Container } from 'ui/molecules/Container'
import { PROFILE_LIST } from 'texts/profileList'
import { Autocomplete } from 'ui/atoms/Autocomplete'
import { AutocompleteItem } from 'ui/atoms/Autocomplete/types'
import { Company } from 'api/profile/types'
import { setNotificationMessage } from 'redux/notifications/slice'
import {
  DEFAULT_INITIAL_PAGE,
  DEFAULT_INITIAL_PAGE_SIZE,
} from 'constants/pagination'
import { useApiRequest } from 'hooks/useApiRequest'
import styles from './styles.module.scss'
import { Typography } from 'ui/atoms/Typography'

export const ArchiveCompanyList = () => {
  const [page, setPage] = useState(DEFAULT_INITIAL_PAGE)
  const [pageSize, setPageSize] = useState(DEFAULT_INITIAL_PAGE_SIZE)
  const [companyList, setCompanyList] =
    useState<ProfileApiTypes.GetCompanyListResponse | null>(null)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [selectedCompany, setSelectedCompany] = useState<Company>()
  const [filtredCompanies, setFiltredCompanies] = useState<AutocompleteItem[]>(
    []
  )

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { apiRequest } = useApiRequest(
    (id) => profileApi.restoreCompany(id),
    '',
    true
  )

  const fetchCompanyList = async () => {
    const companyListResponse = await profileApi.getCompanyList(
      page - 1,
      Number(pageSize),
      true
    )
    setCompanyList(companyListResponse.data)
  }

  useEffect(() => {
    fetchCompanyList()
  }, [page, pageSize])

  const handleChange = (pageNumber: number) => {
    setPage(pageNumber)
  }

  const changePageSize = (value: string) => {
    setPageSize(value)
    setPage(DEFAULT_INITIAL_PAGE)
  }

  const handleRestore = (company: Company) => {
    setSelectedCompany(company)
    setIsConfirmationModalOpen(true)
  }

  const handleSelect = async (value: string) => {
    const companyListResponse = await profileApi.getCompanyList(0, 0, true, [
      { name: 'id', type: 'Eq', value },
    ])
    setCompanyList(companyListResponse.data)
  }

  const handleConfirm = async (id: number) => {
    const response = await apiRequest(id)

    if (response) {
      fetchCompanyList()
    } else {
      setIsConfirmationModalOpen(false)
    }
  }

  const handleSearch = async (value: string) => {
    try {
      const companyListResponse = await profileApi.getCompanyList(0, 0, true, [
        { name: 'name', type: 'ILike', value },
      ])
      const preparedData = companyListResponse.data.companies.pageItems.map(
        (company) => ({ name: company.name, value: String(company.id) })
      )
      setFiltredCompanies(preparedData)
    } catch (e) {
      dispatch(
        setNotificationMessage({
          notificationMessage: (e as ErrorResponse).responseStatus.message,
          type: 'error',
        })
      )
    }
  }

  const handleModalClose = () => {
    setIsConfirmationModalOpen(false)
  }

  const handleRowClick = (id: number) => {
    navigate(`${URLS.PROFILE}${URLS.ARCHIVE}/${id}`)
  }

  const handleRedirectToSupplier = () => {
    if (selectedCompany?.id) {
      navigate(`${URLS.PROFILE}${URLS.PROFILE_LIST}/${selectedCompany.id}/edit`)
    }
  }

  const formattedBodyItems = companyList?.companies.pageItems?.map(
    (company) => ({
      items: [
        <Typography name="Subtitle7" Tag="div" className={styles['company-id']}>
          {company.id}
          {company.isRegistered && (
            <img src={checkImage} width={16} height={16} />
          )}
        </Typography>,
        company.name,
        company.country,
        <RefreshIcon
          className={styles['refresh-icon']}
          color="blue700"
          size="small"
          onClick={(e) => {
            e.stopPropagation()
            handleRestore(company)
          }}
        />,
      ],
      onClick: () => handleRowClick(company.id),
    })
  )

  const formattedHeadItems = headItems.map((header) => ({ value: header }))

  return (
    <div>
      {isConfirmationModalOpen && selectedCompany && (
        <Confirmation
          confirmModalProps={{
            question: `${PROFILE_ARCHIVE_CONFIRMATION.CONFIRMATION_MODAL.QUESTION} ${selectedCompany?.name}?`,
            onConfirm: () => handleConfirm(selectedCompany.id),
            onClose: handleModalClose,
            title: `${PROFILE_ARCHIVE.RESTORE} ${selectedCompany?.name}?`,
          }}
          successModalProps={{
            onClose: handleModalClose,
            title: PROFILE_ARCHIVE_CONFIRMATION.SUCCESS_MODAL.TITLE,
            buttonLabel:
              PROFILE_ARCHIVE_CONFIRMATION.SUCCESS_MODAL.BUTTON_LABEL,
            onConfirm: handleRedirectToSupplier,
            subTitle: `${PROFILE_ARCHIVE_CONFIRMATION.SUCCESS_MODAL.SUPPLIER} ${selectedCompany?.name} ${PROFILE_ARCHIVE_CONFIRMATION.SUCCESS_MODAL.SUBTITLE}`,
          }}
        />
      )}
      <Container
        title={MENU_NAMES.PROFILE_ARCHIVE.NAME}
        tooltip={PROFILE_LIST.TOOLTIP_CONTENT}
        rightBlock={
          <div className={styles['autocomplete-wrapper']}>
            <Autocomplete
              dropdownEmptyText={PROFILE_LIST.SEARCH_DROPDOWN_EMPTY_VALUE}
              handleSelect={handleSelect}
              placeholder={PROFILE_LIST.SEARCH_INPUT_PLACEHOLDER}
              data={filtredCompanies}
              setData={setFiltredCompanies}
              ofLabel={AUTOCOMPLETE_LABEL}
              handleSearch={handleSearch}
              onIconClick={fetchCompanyList}
            />
          </div>
        }
        withCompanyName={false}
      >
        <div className={styles.content}>
          <div className={styles.list}>
            <div className={styles.table}>
              {formattedBodyItems && (
                <Table
                  headItems={formattedHeadItems}
                  bodyItems={formattedBodyItems}
                />
              )}
            </div>
          </div>
          <footer className={styles.pagination}>
            <Pagination
              current={page}
              pageItemCount={companyList?.companies.pageItems.length}
              total={companyList?.companies?.totalItems || 0}
              pageSize={pageSize}
              changePageSize={changePageSize}
              changePage={handleChange}
              pageSizeLabel={PAGINATION_DROPDOWN_LABEL}
            />
          </footer>
        </div>
      </Container>
    </div>
  )
}
