export const WELCOME_BLOCK = {
  HEADER: {
    RESET_PASSWORD: {
      TITLE: "Don't",
      MARK_TITLE: ' worry!',
      SUBTITLE: 'We’ll email you instructions on how to reset your password.',
    },
    SIGN_IN: {
      TITLE: 'Welcome to the \n',
      MARK_TITLE: 'Supplier Portal',
      SUBTITLE:
        'Please enter and confirm your password to complete registration.',
    },
    FINISH_REGISTRATION: {
      TITLE: 'Hello,',
      SUBTITLE:
        'Let’s get you all set up so you can verify your personal account and begin setting up your profile',
    },
  },
  FOOTER: {
    TEXT: '© 2024 Booking Group Corporation Ltd. ',
    MARK_TEXT: 'All rights reserved',
  },
}

export const sliderItems = [
  {
    title: 'Supplier Portal gives you',
    items: [
      '24/7 online access to all your data',
      'Dedicated account manager',
      'Self-serve flexibility',
    ],
  },
  {
    title: 'What you can do',
    items: [
      'Update key information',
      'Manage your locations and fleet',
      'Set prices and add stop sales',
    ],
  },
  {
    title: 'Benefits of cooperation with us',
    items: ['Boost bookings', 'Grow your audience', 'Increase brand awareness'],
  },
]
