import { format } from 'date-fns'

export const formatDateFromIso = (date: string, dateFormat: string) =>
  format(Date.parse(date), dateFormat)

export const getDay = (date: string) => new Date(date).getDate()

export const getMonth = (date: string) => new Date(date).getMonth()

export const getYear = (date: string) => new Date(date).getFullYear()
