import { DriverAgeRangeType } from 'api/fleet/types'
import { DRIVER_AGE_RANGE } from 'texts/driverAgeRange'

export const RADIO_BUTTONS = [
  {
    label: DRIVER_AGE_RANGE.RADIO_BUTTONS.PER_RENTAL,
    id: DriverAgeRangeType.PerRental,
  },
  {
    label: DRIVER_AGE_RANGE.RADIO_BUTTONS.PER_DAY,
    id: DriverAgeRangeType.PerDay,
  },
]

export const initialRange = {
  ageStart: '',
  ageEnd: '',
  price: '',
  maxPrice: '',
}
