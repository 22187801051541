import { useMemo } from 'react'
import { useAppSelector } from 'redux/hooks'
import { userDataSelector } from 'redux/login/selectors'

export const useAvatar = () => {
  const { displayName, userName } = useAppSelector(userDataSelector)

  const avatar = useMemo(() => {
    if (displayName) {
      return displayName[0].toUpperCase()
    }
    if (userName) {
      return userName[0].toUpperCase()
    }

    return ''
  }, [displayName, userName])
  return avatar
}
