export default [
  {
    code: 'AF',
    phoneCode: 93,
    id: 1,
    name: 'Afghanistan',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: '',
  },
  {
    code: 'AX',
    phoneCode: 358,
    id: 2,
    name: 'Aland Islands',
    p: 0,
    language: 'en',
    currency: 'EUR',
    continent: '',
  },
  {
    code: 'AL',
    phoneCode: 355,
    id: 3,
    name: 'Albania',
    p: 0,
    language: 'en',
    currency: 'EUR',
    continent: 'Europe',
  },
  {
    code: 'DZ',
    phoneCode: 213,
    id: 4,
    name: 'Algeria',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: 'Africa',
  },
  {
    code: 'AS',
    phoneCode: 1684,
    id: 5,
    name: 'American Samoa',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: '',
  },
  {
    code: 'AD',
    phoneCode: 376,
    id: 6,
    name: 'Andorra',
    p: 0,
    language: 'es',
    currency: 'eur',
    continent: 'Europe',
  },
  {
    code: 'AO',
    phoneCode: 244,
    id: 7,
    name: 'Angola',
    p: 0,
    language: 'pt',
    currency: 'USD',
    continent: 'Africa',
  },
  {
    code: 'AI',
    phoneCode: 1264,
    id: 8,
    name: 'Anguilla',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: 'The Caribbean',
  },
  {
    code: 'AQ',
    phoneCode: 672,
    id: 9,
    name: 'Antarctica',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: '',
  },
  {
    code: 'AG',
    phoneCode: 1268,
    id: 10,
    name: 'Antigua And Barbuda',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: 'South America',
  },
  {
    code: 'AR',
    phoneCode: 54,
    id: 11,
    name: 'Argentina',
    p: 0,
    language: 'es',
    currency: 'ARS',
    continent: 'South America',
  },
  {
    code: 'AM',
    phoneCode: 374,
    id: 12,
    name: 'Armenia',
    p: 0,
    language: 'ru',
    currency: 'AMD',
    continent: 'Europe',
  },
  {
    code: 'AW',
    phoneCode: 297,
    id: 13,
    name: 'Aruba',
    p: 0,
    language: 'nl',
    currency: 'USD',
    continent: 'South America',
  },
  {
    code: 'AU',
    phoneCode: 61,
    id: 14,
    name: 'Australia',
    p: 244,
    language: 'en',
    currency: 'AUD',
    continent: 'Australia and Oceania',
  },
  {
    code: 'AT',
    phoneCode: 43,
    id: 15,
    name: 'Austria',
    p: 0,
    language: 'de',
    currency: 'EUR',
    continent: 'Europe',
  },
  {
    code: 'AZ',
    phoneCode: 994,
    id: 16,
    name: 'Azerbaijan',
    p: 0,
    language: 'ru',
    currency: 'RUB',
    continent: 'Europe',
  },
  {
    code: 'BS',
    phoneCode: 1242,
    id: 17,
    name: 'Bahamas',
    p: 0,
    language: 'en',
    currency: 'BSD',
    continent: 'South America',
  },
  {
    code: 'BH',
    phoneCode: 973,
    id: 18,
    name: 'Bahrain',
    p: 0,
    language: 'en',
    currency: 'BHD',
    continent: 'Africa',
  },
  {
    code: 'BD',
    phoneCode: 880,
    id: 19,
    name: 'Bangladesh',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: 'Asia',
  },
  {
    code: 'BB',
    phoneCode: 1246,
    id: 20,
    name: 'Barbados',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: 'South America',
  },
  {
    code: 'BY',
    phoneCode: 375,
    id: 21,
    name: 'Belarus',
    p: 0,
    language: 'ru',
    currency: 'RUB',
    continent: 'Europe',
  },
  {
    code: 'BE',
    phoneCode: 32,
    id: 22,
    name: 'Belgium',
    p: 233,
    language: 'fr',
    currency: 'EUR',
    continent: 'Europe',
  },
  {
    code: 'BZ',
    phoneCode: 501,
    id: 23,
    name: 'Belize',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: 'Central America',
  },
  {
    code: 'BJ',
    phoneCode: 229,
    id: 24,
    name: 'Benin',
    p: 0,
    language: 'fr',
    currency: 'EUR',
    continent: 'Africa',
  },
  {
    code: 'BM',
    phoneCode: 1441,
    id: 25,
    name: 'Bermuda',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: '',
  },
  {
    code: 'BT',
    phoneCode: 975,
    id: 26,
    name: 'Bhutan',
    p: 0,
    language: 'en',
    currency: 'IDR',
    continent: '',
  },
  {
    code: 'BO',
    phoneCode: 591,
    id: 27,
    name: 'Bolivia',
    p: 0,
    language: 'es',
    currency: 'EUR',
    continent: 'South America',
  },
  {
    code: 'BA',
    phoneCode: 387,
    id: 28,
    name: 'Bosnia and Herzegowina',
    p: 0,
    language: 'en',
    currency: 'EUR',
    continent: 'Europe',
  },
  {
    code: 'BW',
    phoneCode: 267,
    id: 29,
    name: 'Botswana',
    p: 0,
    language: 'en',
    currency: 'BWP',
    continent: 'Africa',
  },
  {
    code: 'BV',
    phoneCode: 55,
    id: 30,
    name: 'Bouvet Island',
    p: 0,
    language: 'en',
    currency: 'NOK',
    continent: '',
  },
  {
    code: 'BR',
    phoneCode: 55,
    id: 31,
    name: 'Brazil',
    p: 247,
    language: 'pt',
    currency: 'BRL',
    continent: 'South America',
  },
  {
    code: 'IO',
    phoneCode: 246,
    id: 32,
    name: 'British Indian Ocean Territory',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: '',
  },
  {
    code: 'BN',
    phoneCode: 673,
    id: 33,
    name: 'Brunei Darussalam',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: 'Asia',
  },
  {
    code: 'BG',
    phoneCode: 359,
    id: 34,
    name: 'Bulgaria',
    p: 0,
    language: 'en',
    currency: 'EUR',
    continent: 'Europe',
  },
  {
    code: 'BF',
    phoneCode: 226,
    id: 35,
    name: 'Burkina Faso',
    p: 0,
    language: 'fr',
    currency: 'EUR',
    continent: 'Africa',
  },
  {
    code: 'BI',
    phoneCode: 257,
    id: 36,
    name: 'Burundi',
    p: 0,
    language: 'en',
    currency: 'EUR',
    continent: '',
  },
  {
    code: 'KH',
    phoneCode: 855,
    id: 37,
    name: 'Cambodia',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: 'Asia',
  },
  {
    code: 'CM',
    phoneCode: 237,
    id: 38,
    name: 'Cameroon',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: 'Africa',
  },
  {
    code: 'CA',
    phoneCode: 1,
    id: 39,
    name: 'Canada',
    p: 245,
    language: 'en',
    currency: 'CAD',
    continent: 'North America',
  },
  {
    code: 'CV',
    phoneCode: 238,
    id: 40,
    name: 'Cape Verde',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: '',
  },
  {
    code: 'KY',
    phoneCode: 1345,
    id: 41,
    name: 'Cayman Islands',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: 'South America',
  },
  {
    code: 'CF',
    phoneCode: 236,
    id: 42,
    name: 'Central African Republic',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: '',
  },
  {
    code: 'TD',
    phoneCode: 235,
    id: 43,
    name: 'Chad',
    p: 0,
    language: 'fr',
    currency: 'EUR',
    continent: '',
  },
  {
    code: 'CL',
    phoneCode: 56,
    id: 44,
    name: 'Chile',
    p: 0,
    language: 'es',
    currency: 'CLP',
    continent: 'South America',
  },
  {
    code: 'CN',
    phoneCode: 86,
    id: 45,
    name: 'China',
    p: 0,
    language: 'zh',
    currency: 'CNY',
    continent: 'Asia',
  },
  {
    code: 'CX',
    phoneCode: 61,
    id: 46,
    name: 'Christmas Island',
    p: 0,
    language: 'en',
    currency: 'AUD',
    continent: '',
  },
  {
    code: 'CC',
    phoneCode: 61,
    id: 47,
    name: 'Cocos (Keeling) Islands',
    p: 0,
    language: 'en',
    currency: 'AUD',
    continent: '',
  },
  {
    code: 'CO',
    phoneCode: 57,
    id: 48,
    name: 'Colombia',
    p: 0,
    language: 'es',
    currency: 'COP',
    continent: 'South America',
  },
  {
    code: 'KM',
    phoneCode: 269,
    id: 49,
    name: 'Comoros',
    p: 0,
    language: 'en',
    currency: 'EUR',
    continent: '',
  },
  {
    code: 'CD',
    phoneCode: 243,
    id: 51,
    name: 'Congo Democratic Republic',
    p: 0,
    language: 'en',
    currency: 'EUR',
    continent: '',
  },
  {
    code: 'CG',
    phoneCode: 242,
    id: 50,
    name: 'Congo Republic',
    p: 0,
    language: 'fr',
    currency: 'EUR',
    continent: 'Africa',
  },
  {
    code: 'CK',
    phoneCode: 682,
    id: 52,
    name: 'Cook Islands',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: '',
  },
  {
    code: 'CR',
    phoneCode: 506,
    id: 53,
    name: 'Costa Rica',
    p: 0,
    language: 'es',
    currency: 'USD',
    continent: 'South America',
  },
  {
    code: 'HR',
    phoneCode: 385,
    id: 55,
    name: 'Croatia',
    p: 0,
    language: 'en',
    currency: 'EUR',
    continent: 'Europe',
  },
  {
    code: 'CU',
    phoneCode: 53,
    id: 56,
    name: 'Cuba',
    p: 0,
    language: 'es',
    currency: 'EUR',
    continent: '',
  },
  {
    code: 'CY',
    phoneCode: 357,
    id: 57,
    name: 'Cyprus',
    p: 85,
    language: 'en',
    currency: 'EUR',
    continent: 'Europe',
  },
  {
    code: 'CZ',
    phoneCode: 420,
    id: 58,
    name: 'Czech Republic',
    p: 0,
    language: 'en',
    currency: 'CZK',
    continent: 'Europe',
  },
  {
    code: 'DK',
    phoneCode: 45,
    id: 59,
    name: 'Denmark',
    p: 0,
    language: 'en',
    currency: 'DKK',
    continent: 'Europe',
  },
  {
    code: 'DJ',
    phoneCode: 253,
    id: 60,
    name: 'Djibouti',
    p: 0,
    language: 'fr',
    currency: 'EUR',
    continent: 'Africa',
  },
  {
    code: 'DM',
    phoneCode: 1767,
    id: 61,
    name: 'Dominica',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: '',
  },
  {
    code: 'DO',
    phoneCode: 18,
    id: 62,
    name: 'Dominican Republic',
    p: 0,
    language: 'es',
    currency: 'USD',
    continent: 'South America',
  },
  {
    code: 'EC',
    phoneCode: 593,
    id: 63,
    name: 'Ecuador',
    p: 0,
    language: 'es',
    currency: 'USD',
    continent: 'South America',
  },
  {
    code: 'EG',
    phoneCode: 20,
    id: 64,
    name: 'Egypt',
    p: 0,
    language: 'en',
    currency: 'EGP',
    continent: 'Africa',
  },
  {
    code: 'SV',
    phoneCode: 503,
    id: 65,
    name: 'El Salvador',
    p: 0,
    language: 'es',
    currency: 'USD',
    continent: 'South America',
  },
  {
    code: 'GQ',
    phoneCode: 240,
    id: 66,
    name: 'Equatorial Guinea',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: '',
  },
  {
    code: 'ER',
    phoneCode: 291,
    id: 67,
    name: 'Eritrea',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: '',
  },
  {
    code: 'EE',
    phoneCode: 372,
    id: 68,
    name: 'Estonia',
    p: 0,
    language: 'en',
    currency: 'EUR',
    continent: 'Europe',
  },
  {
    code: 'ET',
    phoneCode: 251,
    id: 69,
    name: 'Ethiopia',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: '',
  },
  {
    code: 'FK',
    phoneCode: 500,
    id: 70,
    name: 'Falkland Islands (Malvinas)',
    p: 0,
    language: 'en',
    currency: 'GBP',
    continent: '',
  },
  {
    code: 'FO',
    phoneCode: 298,
    id: 71,
    name: 'Faroe Islands',
    p: 0,
    language: 'en',
    currency: 'EUR',
    continent: 'Europe',
  },
  {
    code: 'FJ',
    phoneCode: 679,
    id: 72,
    name: 'Fiji',
    p: 0,
    language: 'en',
    currency: 'FJD',
    continent: 'Australia and Oceania',
  },
  {
    code: 'FI',
    phoneCode: 358,
    id: 73,
    name: 'Finland',
    p: 0,
    language: 'fi',
    currency: 'EUR',
    continent: 'Europe',
  },
  {
    code: 'FR',
    phoneCode: 33,
    id: 74,
    name: 'France',
    p: 246,
    language: 'fr',
    currency: 'EUR',
    continent: 'Europe',
  },
  {
    code: 'GF',
    phoneCode: 594,
    id: 75,
    name: 'French Guiana',
    p: 0,
    language: 'fr',
    currency: 'EUR',
    continent: 'South America',
  },
  {
    code: 'PF',
    phoneCode: 689,
    id: 76,
    name: 'French Polynesia',
    p: 0,
    language: 'fr',
    currency: 'EUR',
    continent: '',
  },
  {
    code: 'TF',
    phoneCode: 262,
    id: 77,
    name: 'French Southern Territories',
    p: 0,
    language: 'fr',
    currency: 'EUR',
    continent: '',
  },
  {
    code: 'GA',
    phoneCode: 241,
    id: 78,
    name: 'Gabon',
    p: 0,
    language: 'fr',
    currency: 'EUR',
    continent: 'Africa',
  },
  {
    code: 'GM',
    phoneCode: 220,
    id: 79,
    name: 'Gambia',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: '',
  },
  {
    code: 'GE',
    phoneCode: 995,
    id: 80,
    name: 'Georgia',
    p: 0,
    language: 'en',
    currency: 'EUR',
    continent: 'Europe',
  },
  {
    code: 'DE',
    phoneCode: 49,
    id: 81,
    name: 'Germany',
    p: 240,
    language: 'de',
    currency: 'EUR',
    continent: 'Europe',
  },
  {
    code: 'GH',
    phoneCode: 233,
    id: 82,
    name: 'Ghana',
    p: 0,
    language: 'en',
    currency: 'GHS',
    continent: 'Africa',
  },
  {
    code: 'GI',
    phoneCode: 350,
    id: 83,
    name: 'Gibraltar',
    p: 0,
    language: 'en',
    currency: 'GBP',
    continent: '',
  },
  {
    code: 'GR',
    phoneCode: 30,
    id: 84,
    name: 'Greece',
    p: 89,
    language: 'en',
    currency: 'EUR',
    continent: 'Europe',
  },
  {
    code: 'GL',
    phoneCode: 299,
    id: 85,
    name: 'Greenland',
    p: 0,
    language: 'en',
    currency: 'EUR',
    continent: '',
  },
  {
    code: 'GD',
    phoneCode: 1473,
    id: 86,
    name: 'Grenada',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: 'The Caribbean',
  },
  {
    code: 'GP',
    phoneCode: 590,
    id: 87,
    name: 'Guadeloupe',
    p: 0,
    language: 'fr',
    currency: 'EUR',
    continent: 'Central America',
  },
  {
    code: 'GU',
    phoneCode: 1671,
    id: 88,
    name: 'Guam',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: 'Australia and Oceania',
  },
  {
    code: 'GT',
    phoneCode: 502,
    id: 89,
    name: 'Guatemala',
    p: 0,
    language: 'es',
    currency: 'USD',
    continent: 'South America',
  },
  {
    code: 'GG',
    phoneCode: 44,
    id: 90,
    name: 'Guernsey',
    p: 0,
    language: 'en',
    currency: 'GBP',
    continent: '',
  },
  {
    code: 'GN',
    phoneCode: 224,
    id: 91,
    name: 'Guinea',
    p: 0,
    language: 'es',
    currency: 'EUR',
    continent: '',
  },
  {
    code: 'GW',
    phoneCode: 245,
    id: 92,
    name: 'Guinea-Bissau',
    p: 0,
    language: 'en',
    currency: 'EUR',
    continent: '',
  },
  {
    code: 'GY',
    phoneCode: 592,
    id: 93,
    name: 'Guyana',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: 'South America',
  },
  {
    code: 'HT',
    phoneCode: 509,
    id: 94,
    name: 'Haiti',
    p: 0,
    language: 'fr',
    currency: 'EUR',
    continent: 'The Caribbean',
  },
  {
    code: 'HM',
    phoneCode: 672,
    id: 95,
    name: 'Heard Island and Mcdonald Islands',
    p: 0,
    language: 'en',
    currency: 'AUD',
    continent: '',
  },
  {
    code: 'VA',
    phoneCode: 39,
    id: 96,
    name: 'Holy See (Vatican City State)',
    p: 0,
    language: 'en',
    currency: 'EUR',
    continent: '',
  },
  {
    code: 'HN',
    phoneCode: 504,
    id: 97,
    name: 'Honduras',
    p: 0,
    language: 'es',
    currency: 'USD',
    continent: 'South America',
  },
  {
    code: 'HK',
    phoneCode: 852,
    id: 98,
    name: 'Hong Kong',
    p: 0,
    language: 'en',
    currency: 'HKD',
    continent: '',
  },
  {
    code: 'HU',
    phoneCode: 36,
    id: 99,
    name: 'Hungary',
    p: 0,
    language: 'en',
    currency: 'HUF',
    continent: 'Europe',
  },
  {
    code: 'IS',
    phoneCode: 354,
    id: 100,
    name: 'Iceland',
    p: 0,
    language: 'en',
    currency: 'ISK',
    continent: 'Europe',
  },
  {
    code: 'IN',
    phoneCode: 91,
    id: 101,
    name: 'India',
    p: 0,
    language: 'en',
    currency: 'INR',
    continent: 'Asia',
  },
  {
    code: 'ID',
    phoneCode: 62,
    id: 102,
    name: 'Indonesia',
    p: 0,
    language: 'en',
    currency: 'IDR',
    continent: 'Asia',
  },
  {
    code: 'IR',
    phoneCode: 98,
    id: 103,
    name: 'Iran',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: 'Middle-East',
  },
  {
    code: 'IQ',
    phoneCode: 964,
    id: 104,
    name: 'Iraq',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: '',
  },
  {
    code: 'IE',
    phoneCode: 353,
    id: 105,
    name: 'Ireland',
    p: 230,
    language: 'en',
    currency: 'EUR',
    continent: 'Europe',
  },
  {
    code: 'IM',
    phoneCode: 44,
    id: 106,
    name: 'Isle Of Man',
    p: 0,
    language: 'en',
    currency: 'GBP',
    continent: '',
  },
  {
    code: 'IL',
    phoneCode: 972,
    id: 107,
    name: 'Israel',
    p: 241,
    language: 'en',
    currency: 'ILS',
    continent: 'Europe',
  },
  {
    code: 'IT',
    phoneCode: 39,
    id: 108,
    name: 'Italy',
    p: 242,
    language: 'it',
    currency: 'EUR',
    continent: 'Europe',
  },
  {
    code: 'CI',
    phoneCode: 225,
    id: 54,
    name: 'Ivory Coast',
    p: 0,
    language: 'fr',
    currency: 'EUR',
    continent: 'Africa',
  },
  {
    code: 'JM',
    phoneCode: 1876,
    id: 109,
    name: 'Jamaica',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: 'Central America',
  },
  {
    code: 'JP',
    phoneCode: 81,
    id: 110,
    name: 'Japan',
    p: 0,
    language: 'en',
    currency: 'JPY',
    continent: 'Asia',
  },
  {
    code: 'JE',
    phoneCode: 44,
    id: 111,
    name: 'Jersey',
    p: 0,
    language: 'en',
    currency: 'GBP',
    continent: '',
  },
  {
    code: 'JO',
    phoneCode: 962,
    id: 112,
    name: 'Jordan',
    p: 0,
    language: 'en',
    currency: 'EUR',
    continent: 'Asia',
  },
  {
    code: 'KZ',
    phoneCode: 7,
    id: 113,
    name: 'Kazakhstan',
    p: 0,
    language: 'ru',
    currency: 'EUR',
    continent: 'Asia',
  },
  {
    code: 'KE',
    phoneCode: 254,
    id: 114,
    name: 'Kenya',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: 'Africa',
  },
  {
    code: 'KI',
    phoneCode: 686,
    id: 115,
    name: 'Kiribati',
    p: 0,
    language: 'en',
    currency: 'AUD',
    continent: '',
  },
  {
    code: 'KW',
    phoneCode: 965,
    id: 118,
    name: 'Kuwait',
    p: 0,
    language: 'en',
    currency: 'KWD',
    continent: 'Asia',
  },
  {
    code: 'KG',
    phoneCode: 996,
    id: 119,
    name: 'Kyrgyzstan',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: 'Asia',
  },
  {
    code: 'LA',
    phoneCode: 856,
    id: 120,
    name: 'Laos',
    p: 0,
    language: 'en',
    currency: 'EUR',
    continent: 'Asia',
  },
  {
    code: 'LV',
    phoneCode: 371,
    id: 121,
    name: 'Latvia',
    p: 0,
    language: 'lv',
    currency: 'EUR',
    continent: 'Europe',
  },
  {
    code: 'LB',
    phoneCode: 961,
    id: 122,
    name: 'Lebanon',
    p: 0,
    language: 'en',
    currency: 'EUR',
    continent: 'Africa',
  },
  {
    code: 'LS',
    phoneCode: 266,
    id: 123,
    name: 'Lesotho',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: 'Africa',
  },
  {
    code: 'LR',
    phoneCode: 231,
    id: 124,
    name: 'Liberia',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: 'Africa',
  },
  {
    code: 'LY',
    phoneCode: 218,
    id: 125,
    name: 'Libya',
    p: 0,
    language: 'en',
    currency: 'LYD',
    continent: 'Africa',
  },
  {
    code: 'LI',
    phoneCode: 423,
    id: 126,
    name: 'Liechtenstein',
    p: 0,
    language: 'de',
    currency: 'EUR',
    continent: 'Europe',
  },
  {
    code: 'LT',
    phoneCode: 370,
    id: 127,
    name: 'Lithuania',
    p: 0,
    language: 'lt',
    currency: 'EUR',
    continent: 'Europe',
  },
  {
    code: 'LU',
    phoneCode: 352,
    id: 128,
    name: 'Luxembourg',
    p: 0,
    language: 'de',
    currency: 'EUR',
    continent: 'Europe',
  },
  {
    code: 'MO',
    phoneCode: 853,
    id: 129,
    name: 'Macau',
    p: 0,
    language: 'zh',
    currency: 'USD',
    continent: '',
  },
  {
    code: 'MK',
    phoneCode: 389,
    id: 130,
    name: 'Macedonia',
    p: 0,
    language: 'en',
    currency: 'EUR',
    continent: 'Europe',
  },
  {
    code: 'MG',
    phoneCode: 261,
    id: 131,
    name: 'Madagascar',
    p: 0,
    language: 'fr',
    currency: 'EUR',
    continent: 'Africa',
  },
  {
    code: 'MW',
    phoneCode: 265,
    id: 132,
    name: 'Malawi',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: '',
  },
  {
    code: 'MY',
    phoneCode: 60,
    id: 133,
    name: 'Malaysia',
    p: 229,
    language: 'en',
    currency: 'MYR',
    continent: 'Asia',
  },
  {
    code: 'MV',
    phoneCode: 960,
    id: 134,
    name: 'Maldives',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: '',
  },
  {
    code: 'ML',
    phoneCode: 223,
    id: 135,
    name: 'Mali',
    p: 0,
    language: 'en',
    currency: 'EUR',
    continent: '',
  },
  {
    code: 'MT',
    phoneCode: 356,
    id: 136,
    name: 'Malta',
    p: 0,
    language: 'en',
    currency: 'EUR',
    continent: 'Europe',
  },
  {
    code: 'MH',
    phoneCode: 692,
    id: 137,
    name: 'Marshall Islands',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: '',
  },
  {
    code: 'MQ',
    phoneCode: 596,
    id: 138,
    name: 'Martinique',
    p: 0,
    language: 'fr',
    currency: 'EUR',
    continent: 'South America',
  },
  {
    code: 'MR',
    phoneCode: 222,
    id: 139,
    name: 'Mauritania',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: 'Africa',
  },
  {
    code: 'MU',
    phoneCode: 230,
    id: 140,
    name: 'Mauritius',
    p: 0,
    language: 'en',
    currency: 'EUR',
    continent: 'Africa',
  },
  {
    code: 'YT',
    phoneCode: 262,
    id: 141,
    name: 'Mayotte',
    p: 0,
    language: 'en',
    currency: 'EUR',
    continent: '',
  },
  {
    code: 'MX',
    phoneCode: 52,
    id: 142,
    name: 'Mexico',
    p: 231,
    language: 'es',
    currency: 'MXN',
    continent: 'South America',
  },
  {
    code: 'FM',
    phoneCode: 691,
    id: 143,
    name: 'Micronesia',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: '',
  },
  {
    code: 'MD',
    phoneCode: 373,
    id: 144,
    name: 'Moldova',
    p: 0,
    language: 'en',
    currency: 'EUR',
    continent: 'Europe',
  },
  {
    code: 'MC',
    phoneCode: 377,
    id: 145,
    name: 'Monaco',
    p: 0,
    language: 'fr',
    currency: 'EUR',
    continent: 'Europe',
  },
  {
    code: 'MN',
    phoneCode: 976,
    id: 146,
    name: 'Mongolia',
    p: 0,
    language: 'en',
    currency: 'EUR',
    continent: 'Asia',
  },
  {
    code: 'ME',
    phoneCode: 382,
    id: 147,
    name: 'Montenegro',
    p: 0,
    language: 'en',
    currency: 'EUR',
    continent: 'Europe',
  },
  {
    code: 'MS',
    phoneCode: 1664,
    id: 148,
    name: 'Montserrat',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: '',
  },
  {
    code: 'MA',
    phoneCode: 212,
    id: 149,
    name: 'Morocco',
    p: 0,
    language: 'fr',
    currency: 'EUR',
    continent: 'Africa',
  },
  {
    code: 'MZ',
    phoneCode: 258,
    id: 150,
    name: 'Mozambique',
    p: 0,
    language: 'pt',
    currency: 'EUR',
    continent: 'Africa',
  },
  {
    code: 'MM',
    phoneCode: 95,
    id: 151,
    name: 'Myanmar',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: '',
  },
  {
    code: 'NA',
    phoneCode: 264,
    id: 152,
    name: 'Namibia',
    p: 0,
    language: 'en',
    currency: 'NAD',
    continent: 'Africa',
  },
  {
    code: 'NR',
    phoneCode: 674,
    id: 153,
    name: 'Nauru',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: '',
  },
  {
    code: 'NP',
    phoneCode: 977,
    id: 154,
    name: 'Nepal',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: 'Asia',
  },
  {
    code: 'NL',
    phoneCode: 31,
    id: 155,
    name: 'Netherlands',
    p: 235,
    language: 'nl',
    currency: 'EUR',
    continent: 'Europe',
  },
  {
    code: 'AN',
    phoneCode: 599,
    id: 156,
    name: 'Netherlands Antilles',
    p: 0,
    language: 'en',
    currency: 'EUR',
    continent: '',
  },
  {
    code: 'NC',
    phoneCode: 687,
    id: 157,
    name: 'New Caledonia',
    p: 0,
    language: 'fr',
    currency: 'EUR',
    continent: 'Australia and Oceania',
  },
  {
    code: 'NZ',
    phoneCode: 64,
    id: 158,
    name: 'New Zealand',
    p: 0,
    language: 'en',
    currency: 'NZD',
    continent: 'Australia and Oceania',
  },
  {
    code: 'NI',
    phoneCode: 505,
    id: 159,
    name: 'Nicaragua',
    p: 0,
    language: 'es',
    currency: 'USD',
    continent: 'South America',
  },
  {
    code: 'NE',
    phoneCode: 227,
    id: 160,
    name: 'Niger',
    p: 0,
    language: 'en',
    currency: 'EUR',
    continent: '',
  },
  {
    code: 'NG',
    phoneCode: 234,
    id: 161,
    name: 'Nigeria',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: 'Africa',
  },
  {
    code: 'NU',
    phoneCode: 683,
    id: 162,
    name: 'Niue',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: '',
  },
  {
    code: 'NF',
    phoneCode: 672,
    id: 163,
    name: 'Norfolk Island',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: '',
  },
  {
    code: 'KP',
    phoneCode: 850,
    id: 116,
    name: 'North Korea',
    p: 0,
    language: 'ko',
    currency: 'USD',
    continent: '',
  },
  {
    code: 'MP',
    phoneCode: 1670,
    id: 164,
    name: 'Northern Mariana Islands',
    p: 0,
    language: 'en',
    currency: 'EUR',
    continent: 'Australia and Oceania',
  },
  {
    code: 'NO',
    phoneCode: 47,
    id: 165,
    name: 'Norway',
    p: 0,
    language: 'en',
    currency: 'NOK',
    continent: 'Europe',
  },
  {
    code: 'OM',
    phoneCode: 968,
    id: 166,
    name: 'Oman',
    p: 0,
    language: 'en',
    currency: 'EUR',
    continent: 'Africa',
  },
  {
    code: 'PK',
    phoneCode: 92,
    id: 168,
    name: 'Pakistan',
    p: 0,
    language: 'en',
    currency: 'PKR',
    continent: '',
  },
  {
    code: 'PW',
    phoneCode: 680,
    id: 169,
    name: 'Palau',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: '',
  },
  {
    code: 'PS',
    phoneCode: 970,
    id: 170,
    name: 'Palestine',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: '',
  },
  {
    code: 'PA',
    phoneCode: 507,
    id: 171,
    name: 'Panama',
    p: 0,
    language: 'es',
    currency: 'PAB',
    continent: 'South America',
  },
  {
    code: 'PG',
    phoneCode: 675,
    id: 172,
    name: 'Papua New Guinea',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: '',
  },
  {
    code: 'PY',
    phoneCode: 595,
    id: 173,
    name: 'Paraguay',
    p: 0,
    language: 'es',
    currency: 'USD',
    continent: 'South America',
  },
  {
    code: 'PE',
    phoneCode: 51,
    id: 174,
    name: 'Peru',
    p: 0,
    language: 'es',
    currency: 'PEN',
    continent: 'South America',
  },
  {
    code: 'PH',
    phoneCode: 63,
    id: 175,
    name: 'Philippines',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: 'Asia',
  },
  {
    code: 'PN',
    phoneCode: 870,
    id: 176,
    name: 'Pitcairn',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: '',
  },
  {
    code: 'PL',
    phoneCode: 48,
    id: 177,
    name: 'Poland',
    p: 232,
    language: 'pl',
    currency: 'PLN',
    continent: 'Europe',
  },
  {
    code: 'PT',
    phoneCode: 351,
    id: 178,
    name: 'Portugal',
    p: 236,
    language: 'pt',
    currency: 'EUR',
    continent: 'Europe',
  },
  {
    code: 'PR',
    phoneCode: 1,
    id: 179,
    name: 'Puerto Rico',
    p: 0,
    language: 'es',
    currency: 'USD',
    continent: 'South America',
  },
  {
    code: 'QA',
    phoneCode: 974,
    id: 180,
    name: 'Qatar',
    p: 0,
    language: 'en',
    currency: 'QAR',
    continent: 'Africa',
  },
  {
    code: 'RE',
    phoneCode: 262,
    id: 181,
    name: 'Reunion',
    p: 0,
    language: 'fr',
    currency: 'EUR',
    continent: 'Central America',
  },
  {
    code: 'RO',
    phoneCode: 40,
    id: 182,
    name: 'Romania',
    p: 0,
    language: 'en',
    currency: 'EUR',
    continent: 'Europe',
  },
  {
    code: 'RU',
    phoneCode: 7,
    id: 183,
    name: 'Russian Federation',
    p: 248,
    language: 'ru',
    currency: 'RUB',
    continent: 'Europe',
  },
  {
    code: 'RW',
    phoneCode: 250,
    id: 184,
    name: 'Rwanda',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: 'Africa',
  },
  {
    code: 'BL',
    phoneCode: 590,
    id: 185,
    name: 'Saint Barthélemy',
    p: 0,
    language: 'fr',
    currency: 'EUR',
    continent: 'South America',
  },
  {
    code: 'SH',
    phoneCode: 290,
    id: 186,
    name: 'Saint Helena',
    p: 0,
    language: 'en',
    currency: 'EUR',
    continent: '',
  },
  {
    code: 'KN',
    phoneCode: 1869,
    id: 187,
    name: 'Saint Kitts And Nevis',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: 'The Caribbean',
  },
  {
    code: 'LC',
    phoneCode: 1758,
    id: 188,
    name: 'Saint Lucia',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: 'South America',
  },
  {
    code: 'MF',
    phoneCode: 721,
    id: 189,
    name: 'Saint Martin',
    p: 0,
    language: 'fr',
    currency: 'EUR',
    continent: 'South America',
  },
  {
    code: 'PM',
    phoneCode: 508,
    id: 190,
    name: 'Saint Pierre and Miquelon',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: '',
  },
  {
    code: 'VC',
    phoneCode: 1784,
    id: 191,
    name: 'Saint Vincent And The Grenadines',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: '',
  },
  {
    code: 'WS',
    phoneCode: 685,
    id: 192,
    name: 'Samoa',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: 'Australia and Oceania',
  },
  {
    code: 'SM',
    phoneCode: 378,
    id: 193,
    name: 'San Marino',
    p: 0,
    language: 'it',
    currency: 'EUR',
    continent: 'Europe',
  },
  {
    code: 'ST',
    phoneCode: 239,
    id: 194,
    name: 'Sao Tome And Principe',
    p: 0,
    language: 'en',
    currency: 'EUR',
    continent: '',
  },
  {
    code: 'SA',
    phoneCode: 966,
    id: 195,
    name: 'Saudi Arabia',
    p: 234,
    language: 'en',
    currency: 'SAR',
    continent: 'Africa',
  },
  {
    code: 'SN',
    phoneCode: 221,
    id: 196,
    name: 'Senegal',
    p: 0,
    language: 'fr',
    currency: 'USD',
    continent: 'Africa',
  },
  {
    code: 'RS',
    phoneCode: 381,
    id: 197,
    name: 'Serbia',
    p: 0,
    language: 'en',
    currency: 'EUR',
    continent: 'Europe',
  },
  {
    code: 'SC',
    phoneCode: 248,
    id: 198,
    name: 'Seychelles',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: 'Africa',
  },
  {
    code: 'SL',
    phoneCode: 232,
    id: 199,
    name: 'Sierra Leone',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: '',
  },
  {
    code: 'SG',
    phoneCode: 65,
    id: 200,
    name: 'Singapore',
    p: 0,
    language: 'en',
    currency: 'SGD',
    continent: 'Asia',
  },
  {
    code: 'SK',
    phoneCode: 421,
    id: 201,
    name: 'Slovakia',
    p: 0,
    language: 'en',
    currency: 'EUR',
    continent: 'Europe',
  },
  {
    code: 'SI',
    phoneCode: 386,
    id: 202,
    name: 'Slovenia',
    p: 0,
    language: 'en',
    currency: 'EUR',
    continent: 'Europe',
  },
  {
    code: 'SB',
    phoneCode: 677,
    id: 203,
    name: 'Solomon Islands',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: '',
  },
  {
    code: 'SO',
    phoneCode: 252,
    id: 204,
    name: 'Somalia',
    p: 0,
    language: 'en',
    currency: 'EUR',
    continent: '',
  },
  {
    code: 'ZA',
    phoneCode: 27,
    id: 205,
    name: 'South Africa',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: 'Africa',
  },
  {
    code: 'GS',
    phoneCode: 500,
    id: 206,
    name: 'South Georgia and the South Sandwich Islands',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: '',
  },
  {
    code: 'KR',
    phoneCode: 82,
    id: 117,
    name: 'South Korea',
    p: 0,
    language: 'ko',
    currency: 'KRW',
    continent: 'Asia',
  },
  {
    code: 'ES',
    phoneCode: 34,
    id: 207,
    name: 'Spain',
    p: 243,
    language: 'es',
    currency: 'EUR',
    continent: 'Europe',
  },
  {
    code: 'LK',
    phoneCode: 94,
    id: 208,
    name: 'Sri Lanka',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: 'Asia',
  },
  {
    code: 'SD',
    phoneCode: 249,
    id: 209,
    name: 'Sudan',
    p: 0,
    language: 'en',
    currency: 'SDG',
    continent: 'Africa',
  },
  {
    code: 'SR',
    phoneCode: 597,
    id: 210,
    name: 'Suriname',
    p: 0,
    language: 'nl',
    currency: 'EUR',
    continent: 'South America',
  },
  {
    code: 'SJ',
    phoneCode: 47,
    id: 211,
    name: 'Svalbard And Jan Mayen Islands',
    p: 0,
    language: 'en',
    currency: 'NOK',
    continent: '',
  },
  {
    code: 'SZ',
    phoneCode: 268,
    id: 212,
    name: 'Swaziland',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: '',
  },
  {
    code: 'SE',
    phoneCode: 46,
    id: 213,
    name: 'Sweden',
    p: 0,
    language: 'sv',
    currency: 'SEK',
    continent: 'Europe',
  },
  {
    code: 'CH',
    phoneCode: 41,
    id: 214,
    name: 'Switzerland',
    p: 0,
    language: 'de',
    currency: 'CHF',
    continent: 'Europe',
  },
  {
    code: 'SY',
    phoneCode: 963,
    id: 215,
    name: 'Syria',
    p: 0,
    language: 'en',
    currency: 'SYP',
    continent: 'Asia',
  },
  {
    code: 'TW',
    phoneCode: 886,
    id: 216,
    name: 'Taiwan',
    p: 0,
    language: 'zh',
    currency: 'TWD',
    continent: 'Asia',
  },
  {
    code: 'TJ',
    phoneCode: 992,
    id: 217,
    name: 'Tajikistan',
    p: 0,
    language: 'ru',
    currency: 'EUR',
    continent: 'Europe',
  },
  {
    code: 'TZ',
    phoneCode: 255,
    id: 218,
    name: 'Tanzania',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: 'Africa',
  },
  {
    code: 'TH',
    phoneCode: 66,
    id: 219,
    name: 'Thailand',
    p: 228,
    language: 'en',
    currency: 'THB',
    continent: 'Asia',
  },
  {
    code: 'TL',
    phoneCode: 670,
    id: 220,
    name: 'Timor-Leste',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: '',
  },
  {
    code: 'TG',
    phoneCode: 228,
    id: 221,
    name: 'Togo',
    p: 0,
    language: 'fr',
    currency: 'EUR',
    continent: 'Africa',
  },
  {
    code: 'TK',
    phoneCode: 690,
    id: 222,
    name: 'Tokelau',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: '',
  },
  {
    code: 'TO',
    phoneCode: 676,
    id: 223,
    name: 'Tonga',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: '',
  },
  {
    code: 'TT',
    phoneCode: 1868,
    id: 224,
    name: 'Trinidad And Tobago',
    p: 0,
    language: 'en',
    currency: 'TTD',
    continent: 'The Caribbean',
  },
  {
    code: 'TN',
    phoneCode: 216,
    id: 225,
    name: 'Tunisia',
    p: 0,
    language: 'en',
    currency: 'TND',
    continent: 'Africa',
  },
  {
    code: 'TR',
    phoneCode: 90,
    id: 226,
    name: 'Turkey',
    p: 239,
    language: 'tr',
    currency: 'TRY',
    continent: 'Europe',
  },
  {
    code: 'TM',
    phoneCode: 993,
    id: 227,
    name: 'Turkmenistan',
    p: 0,
    language: 'en',
    currency: 'EUR',
    continent: 'Europe',
  },
  {
    code: 'TC',
    phoneCode: 1649,
    id: 228,
    name: 'Turks And Caicos Islands',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: 'The Caribbean',
  },
  {
    code: 'TV',
    phoneCode: 688,
    id: 229,
    name: 'Tuvalu',
    p: 0,
    language: 'en',
    currency: 'AUD',
    continent: '',
  },
  {
    code: 'UG',
    phoneCode: 256,
    id: 230,
    name: 'Uganda',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: 'Africa',
  },
  {
    code: 'UA',
    phoneCode: 380,
    id: 231,
    name: 'Ukraine',
    p: 238,
    language: 'ru',
    currency: 'UAH',
    continent: 'Europe',
  },
  {
    code: 'AE',
    phoneCode: 971,
    id: 232,
    name: 'United Arab Emirates',
    p: 237,
    language: 'en',
    currency: 'AED',
    continent: 'Asia',
  },
  {
    code: 'GB',
    phoneCode: 44,
    id: 233,
    name: 'United Kingdom',
    p: 249,
    language: 'en',
    currency: 'GBP',
    continent: 'Europe',
  },
  {
    code: 'US',
    phoneCode: 1,
    id: 234,
    name: 'United States',
    p: 250,
    language: 'en',
    currency: 'USD',
    continent: 'North America',
  },
  {
    code: 'UM',
    phoneCode: 1,
    id: 235,
    name: 'United States Minor Outlying Islands',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: '',
  },
  {
    code: 'UY',
    phoneCode: 598,
    id: 236,
    name: 'Uruguay',
    p: 0,
    language: 'es',
    currency: 'USD',
    continent: 'South America',
  },
  {
    code: 'UZ',
    phoneCode: 998,
    id: 237,
    name: 'Uzbekistan',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: 'Asia',
  },
  {
    code: 'VU',
    phoneCode: 678,
    id: 238,
    name: 'Vanuatu',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: '',
  },
  {
    code: 'VE',
    phoneCode: 58,
    id: 239,
    name: 'Venezuela',
    p: 0,
    language: 'es',
    currency: 'VEF',
    continent: 'South America',
  },
  {
    code: 'VN',
    phoneCode: 84,
    id: 240,
    name: 'Vietnam',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: 'Asia',
  },
  {
    code: 'VG',
    phoneCode: 1284,
    id: 241,
    name: 'Virgin Islands (British)',
    p: 0,
    language: 'en',
    currency: 'GBP',
    continent: 'The Caribbean',
  },
  {
    code: 'VI',
    phoneCode: 1340,
    id: 242,
    name: 'Virgin Islands (U.S.)',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: 'The Caribbean',
  },
  {
    code: 'WF',
    phoneCode: 681,
    id: 243,
    name: 'Wallis And Futuna Islands',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: '',
  },
  {
    code: 'EH',
    phoneCode: 212,
    id: 244,
    name: 'Western Sahara',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: '',
  },
  {
    code: 'YE',
    phoneCode: 967,
    id: 245,
    name: 'Yemen',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: 'Middle-East',
  },
  {
    code: 'ZM',
    phoneCode: 260,
    id: 246,
    name: 'Zambia',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: 'Africa',
  },
  {
    code: 'ZW',
    phoneCode: 263,
    id: 247,
    name: 'Zimbabwe',
    p: 0,
    language: 'en',
    currency: 'USD',
    continent: 'Africa',
  },
]
