import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'
import { NotFound } from '../NotFound'
import { ReactNode } from 'react'

interface ErrorBoundaryProps {
  children: ReactNode
}

export const ErrorBoundary = ({ children }: ErrorBoundaryProps) => (
  <ReactErrorBoundary fallback={<NotFound />}>{children}</ReactErrorBoundary>
)
