export const LOCATION_ADD = {
  TITLE: 'Location',
  BREADCRUMB: 'Add location',
  FORM_HEADING: 'Contact information',
  DISCLAIMER: 'Disclaimer: Indicates a required field.',
  BUTTONS: {
    CANCEL: 'Cancel',
    SAVE: 'Save',
  },
  MODAL_TITLE: 'Location has been successfully added',
}
