import { createSlice } from '@reduxjs/toolkit'
import { sendRegistrationCode, registration } from './thunks'
import { RegistrationState } from './types'

const initialState: RegistrationState = {
  registration: {
    companyName: '',
    registrationCode: '',
    email: '',
    isRegistrationCodeValid: false,
  },
  isLoading: false,
  errorMessage: '',
}

export const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendRegistrationCode.pending, (state) => {
        state.isLoading = true
        state.errorMessage = ''
      })
      .addCase(sendRegistrationCode.fulfilled, (state, action) => {
        state.isLoading = false
        state.registration = {
          ...action.payload,
          isRegistrationCodeValid: true,
        }
      })
      .addCase(sendRegistrationCode.rejected, (state, action) => {
        state.isLoading = false
        state.errorMessage = action.payload as string
      })
      .addCase(registration.pending, (state) => {
        state.isLoading = true
        state.errorMessage = ''
      })
      .addCase(registration.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(registration.rejected, (state, action) => {
        state.isLoading = false
        state.errorMessage = action.payload as string
      })
  },
})
export const registrationReducer = registrationSlice.reducer
