import { APPLICATION, OVERVIEW } from 'texts/applications'
import { ContentWrapper } from 'ui/atoms/ContentWrapper'
import { Container } from 'ui/molecules/Container'
import { breadcrumbs } from 'constants/breadcrumbs'
import { ContactInformation } from './components/ContactInformation'
import { Form } from 'ui/molecules/Form'
import { SupplierLocation } from './components/SupplierLocation'
import { Fleet } from './components/Fleet'
import { StatusInfo } from './components/StatusInfo'
import { OtherInformation } from './components/OtherInformation'
import { Button } from 'ui/atoms/Button'
import { useNavigate, useParams } from 'react-router-dom'
import { CANCEL, SAVE } from 'texts/uiTexts'
import { StatusBadge } from 'ui/atoms/StatusBadge'
import { getApplicationStatusType } from './utils'
import { initialValues } from './constants'
import {
  APPLICATION_SHOWN_STATUS,
  APPLICATION_STATUS,
} from 'modules/Applications/constants'
import { ApplicationStatus } from '../../types'
import { ProfileDetailsForm } from 'modules/Profile/ProfileList/components/ProfileDetailsForm'
import { Typography } from 'ui/atoms/Typography'
import styles from './styles.module.scss'

export const Application = () => {
  const navigate = useNavigate()
  const { status } = useParams()
  const breadcrumbList = [breadcrumbs.applications]

  const handleBack = () => navigate(-1)

  return (
    <Container
      title={
        <div className={styles.title}>
          <Typography Tag="h5" name="H5">
            {OVERVIEW}
          </Typography>
          <StatusBadge
            status={getApplicationStatusType(status as ApplicationStatus)}
            label={APPLICATION_SHOWN_STATUS[status as ApplicationStatus]}
          />
        </div>
      }
      breadcrumbList={breadcrumbList}
      currentPageLabel={OVERVIEW}
    >
      <Form
        className={styles.form}
        onSubmit={() => {}}
        initValues={initialValues}
      >
        {({ isDirty }) => (
          <>
            <ContentWrapper
              className={styles.wrapper}
              title={APPLICATION.CONTACT_INFORMATION}
            >
              <ContactInformation />
            </ContentWrapper>
            <ContentWrapper
              className={styles.wrapper}
              title={APPLICATION.SUPPLIER_LOCATION}
            >
              <SupplierLocation />
            </ContentWrapper>
            <ContentWrapper
              className={styles.wrapper}
              title={APPLICATION.FLEET}
            >
              <Fleet />
            </ContentWrapper>
            <ContentWrapper
              className={styles.wrapper}
              title={APPLICATION.OTHER}
            >
              <OtherInformation />
            </ContentWrapper>
            {status !== APPLICATION_STATUS.APPROVE && (
              <ContentWrapper
                className={styles.wrapper}
                title={APPLICATION.STATUS_INFO}
              >
                <StatusInfo status={status} />
              </ContentWrapper>
            )}
            {status === APPLICATION_STATUS.WAITING_FOR_SIGN && (
              <ProfileDetailsForm handleSubmit={() => {}} isLoading={false} />
            )}
            {status !== APPLICATION_STATUS.ARCHIVE && (
              <div className={styles.buttons}>
                <div className={styles['button-wrapper']}>
                  <Button
                    variant="outline"
                    onClick={handleBack}
                    label={CANCEL}
                  />
                </div>
                <div className={styles['button-wrapper']}>
                  <Button htmlType="submit" disabled={!isDirty} label={SAVE} />
                </div>
              </div>
            )}
          </>
        )}
      </Form>
    </Container>
  )
}
