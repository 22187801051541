import { useEffect, useRef } from 'react'

export const useDidUpdate = (
  callback: VoidFunction,
  dependencies: unknown[]
) => {
  const isMountRef = useRef(false)

  useEffect(() => {
    if (isMountRef.current) {
      callback()
    } else {
      isMountRef.current = true
    }
  }, dependencies)
}
