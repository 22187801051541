import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppSelector } from 'redux/hooks'
import {
  STOP_SELLS_DETAILS,
  STOP_SELLS_DETAILS_FORM_VALUES,
} from 'texts/stopSellsDetails'
import { parseISO, isSameYear } from 'date-fns'
import { RADIO_BUTTONS } from './constants'
import { StopSalesRange } from 'api/fleet/types'
import { Button } from 'ui/atoms/Button'
import { ContentWrapper } from 'ui/atoms/ContentWrapper'
import { RadioButton } from 'ui/atoms/RadioButton'
import { Form } from 'ui/molecules/Form'
import { SELECT_ALL_ITEM } from 'ui/atoms/Multiselect'
import { findSelectAllItem } from 'ui/atoms/Multiselect/utils'
import { FormValues, SelectValue, StopSellsDetailsFormProps } from './types'
import { supplierIdSelector } from 'redux/common/selectors'
import styles from './styles.module.scss'
import { StopSaleLocationForm } from './components/StopSaleLocationForm'
import { Textarea } from 'ui/atoms/Textarea'
import { FormItem } from 'ui/molecules/Form/components/FormItem'
import { useApiRequest } from 'hooks/useApiRequest'
import { referencesApi } from 'api'
import { Country } from 'api/references/types'
import { allSymbolsAndEnglishLettersRegex } from 'constants/regex'
import { Rule } from 'ui/molecules/Form/components/FormItem/types'

export const StopSaleDetailsForm = ({
  initialValues,
  isAddForm,
  handleSubmit,
  isLoading,
}: StopSellsDetailsFormProps) => {
  const params = useParams()
  const supplierCompanyId = useAppSelector(supplierIdSelector)
  const [activeRadioButton, setRadioButton] = useState(initialValues.applicable)
  const [countries, setCountries] = useState<Country[]>([])
  const isReadonlyMode = initialValues.to
    ? !isSameYear(parseISO(initialValues.to), new Date())
    : false

  const navigate = useNavigate()

  const supplierId = supplierCompanyId || params.companyId

  const { apiRequest, loading } = useApiRequest((companyId) =>
    referencesApi.getCountries(companyId)
  )

  useEffect(() => {
    if (supplierId) {
      const getCountries = async () => {
        const countriesResponse = await apiRequest(String(supplierId))
        if (countriesResponse) {
          setCountries(countriesResponse.data.items)
        }
      }
      getCountries()
    }
  }, [supplierId])

  const onSubmit = (data: FormValues, validate: boolean) => {
    if (validate) {
      const countriesValue = data.countries as SelectValue[]
      const citiesValue = data.cities as SelectValue[]
      const locationsValue = data.locations as SelectValue[]
      const classesValue = data.carClasses as SelectValue[]

      const isAllCountriesSelected = findSelectAllItem(countriesValue)
      const isAllCitiesSelected =
        isAllCountriesSelected ||
        countriesValue.length > 1 ||
        findSelectAllItem(citiesValue)
      const isAllLocationsSelected =
        isAllCountriesSelected ||
        countriesValue.length > 1 ||
        findSelectAllItem(locationsValue)
      const isAllClassesSelected =
        isAllCountriesSelected || findSelectAllItem(classesValue)

      const filteredCountries = countriesValue.filter(
        (item) => item.value !== SELECT_ALL_ITEM.value
      )

      const filteredCities = citiesValue.filter(
        (item) => item.value !== SELECT_ALL_ITEM.value
      )

      const filteredLocations = locationsValue.filter(
        (item) => item.value !== SELECT_ALL_ITEM.value
      )

      const filteredCarClasses = classesValue.filter(
        (item) => item.value !== SELECT_ALL_ITEM.value
      )

      handleSubmit({
        ...data,
        countries: isAllCountriesSelected
          ? null
          : filteredCountries.map((el) => Number(el.value)) || [],
        cities: isAllCitiesSelected
          ? null
          : filteredCities.map((el) => Number(el.value)) || [],
        locations: isAllLocationsSelected
          ? null
          : filteredLocations.map((el) => Number(el.value)) || [],
        carClasses: isAllClassesSelected
          ? null
          : filteredCarClasses.map((el) => el.value) || [],
        applicable: activeRadioButton,
        companyID: Number(supplierId),
      })
    }
  }

  const handleChangeRadioButton = (id: string) => {
    setRadioButton(id as StopSalesRange)
  }

  const commentValidationRule: Rule[] = useMemo(
    () => [
      {
        type: 'pattern',
        value: allSymbolsAndEnglishLettersRegex,
        message: STOP_SELLS_DETAILS_FORM_VALUES.COMMENTS.VALIDATION_MESSAGE,
      },
    ],
    []
  )

  return !loading ? (
    <Form
      initValues={initialValues}
      onSubmit={onSubmit}
      className={styles['form-wrapper']}
    >
      {({ values, isDirty }) => (
        <>
          <ContentWrapper
            className={styles.form}
            title={STOP_SELLS_DETAILS.FORM_TITLE}
          >
            <div>
              <div className={styles['details-block']}>
                <StopSaleLocationForm
                  values={values}
                  isReadonlyMode={isReadonlyMode}
                  countries={countries}
                  isAddForm={isAddForm}
                  companyId={String(supplierId)}
                />
                {RADIO_BUTTONS.map(({ label, id }) => (
                  <RadioButton
                    labelTypographyName="Button2"
                    key={id}
                    id={id}
                    label={label}
                    onChange={handleChangeRadioButton}
                    checked={id === activeRadioButton}
                    disabled={isReadonlyMode}
                  />
                ))}
              </div>
              <FormItem
                id={STOP_SELLS_DETAILS_FORM_VALUES.COMMENTS.ID}
                className={styles['form-item']}
                rules={commentValidationRule}
              >
                {({ value, onChange, error }) => (
                  <Textarea
                    placeholder={
                      STOP_SELLS_DETAILS_FORM_VALUES.COMMENTS.PLACEHOLDER
                    }
                    label={STOP_SELLS_DETAILS_FORM_VALUES.COMMENTS.LABEL}
                    value={value}
                    error={error}
                    onChange={onChange}
                    disabled={isReadonlyMode}
                  />
                )}
              </FormItem>
            </div>
          </ContentWrapper>
          {!isReadonlyMode && (
            <div className={styles.buttons}>
              <div className={styles['button-wrapper']}>
                <Button
                  variant="outline"
                  onClick={() => navigate(-1)}
                  label={STOP_SELLS_DETAILS.BUTTONS.CANCEL_BUTTON_LABEL}
                />
              </div>
              <div className={styles['button-wrapper']}>
                <Button
                  htmlType="submit"
                  label={STOP_SELLS_DETAILS.BUTTONS.SAVE_BUTTON_LABEL}
                  loading={isLoading}
                  disabled={!isDirty}
                />
              </div>
            </div>
          )}
        </>
      )}
    </Form>
  ) : null
}
