import React, { ReactElement, useMemo, useRef } from 'react'
import { joinClasses } from 'utils/joinClasses'
import { TabsProps } from './types'
import { Typography } from 'ui/atoms/Typography'
import { TypographyName } from 'ui/atoms/Typography/types'
import { isChildActive } from './utils'
import styles from './styles.module.scss'

const TAB_TYPOGRAPHY: { [key: string]: { desktop: TypographyName } } = {
  primary: {
    desktop: 'H5',
  },
  secondary: {
    desktop: 'Button1',
  },
  tertiary: {
    desktop: 'Button1',
  },
  quaternary: {
    desktop: 'Button1',
  },
}

export const Tabs = ({
  activeKey,
  onChange,
  children,
  type,
  renderTabs,
}: TabsProps) => {
  const navButtons = useRef<HTMLDivElement[] | null[]>([])
  const stickyDummy = useRef<HTMLDivElement | null>(null)

  const cloneChildren = React.Children.map(
    children,
    (child: ReactElement) =>
      child &&
      React.cloneElement(child, {
        id: child.key,
        activeKey,
      })
  )?.filter((child) => child)

  const renderContent = useMemo(() => {
    if (cloneChildren) {
      const itemsWithFirstActive = cloneChildren.reduce(
        (newItems: ReactElement[], child) => {
          if (isChildActive(child, activeKey)) {
            newItems.push(child)
          } else {
            newItems.unshift(child)
          }

          return newItems
        },
        []
      )

      return (
        <div className={styles['tabs-content']}>{itemsWithFirstActive}</div>
      )
    }
  }, [activeKey, cloneChildren])

  const navNode = (
    <div className={styles['tabs-wrap']}>
      <div className={styles['tabs-nav-wrap']}>
        <div className={styles['tabs-nav']}>
          {cloneChildren.map((child, i) => {
            const key = i
            const { id } = child.props
            const isActive = isChildActive(child, activeKey)
            const onClick = () => onChange(id as string)
            const className = joinClasses(styles['tabs-tab'], [
              styles['tabs-tab-active'],
              isActive,
            ])
            const content = (
              <div className={styles['tabs-tab-inner']}>
                {typeof child.props.tab === 'function'
                  ? child.props.tab(isActive)
                  : child.props.tab}
              </div>
            )
            const ref = (el: HTMLDivElement) => {
              navButtons.current[id] = el
              return el
            }

            return (
              <Typography
                ref={ref}
                name={TAB_TYPOGRAPHY[type].desktop}
                key={key}
                className={className}
                onClick={onClick}
                Tag="div"
              >
                {content}
              </Typography>
            )
          })}
        </div>
      </div>
    </div>
  )

  return (
    <div className={joinClasses(styles['tabs-container'], styles[type])}>
      <div ref={stickyDummy} className={styles['tabs-dummy-hide']} />
      {renderTabs ? renderTabs(navNode) : navNode}
      {renderContent}
    </div>
  )
}
