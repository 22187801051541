import { Carousel } from 'ui/molecules/Carousel'
import { Typography } from 'ui/atoms/Typography'
import Logo from 'assets/img/Logo.svg'
import IconCard from 'assets/img/IconCard.png'
import { WELCOME_BLOCK, sliderItems } from 'texts/welcomeBlock'
import { CheckIcon } from 'ui/icons'
import styles from './styles.module.scss'
import { Props } from './types'

const mockSlide = (title: string, items: string[]) => (
  <div className={styles['mock-slide']}>
    <img src={IconCard} width="96px" height="96px" />
    <Typography className={styles['slide-header']} name="H5">
      {title}
    </Typography>
    <div className={styles.benefits}>
      {items.map((el, index) => (
        <div key={index} className={styles.benefit}>
          <CheckIcon color="green700Master" />
          <Typography name="Button2" color="grey500">
            {el}
          </Typography>
        </div>
      ))}
    </div>
  </div>
)

const slides = sliderItems.map((el) => mockSlide(el.title, el.items))

export const WelcomeBlockView = ({ Header, withCarousel = true }: Props) => (
  <div className={styles.wrapper}>
    <div className={styles.header}>
      <img src={Logo} width="48px" height="24px" />
      <div className={styles['header-text']}>
        <Header />
      </div>
    </div>
    {withCarousel && (
      <Carousel autoScrollInterval={5000} settings={{ shownSlides: 1 }}>
        {slides.map((slide, index) => (
          <div key={index} className={styles['carousel-item']}>
            {slide}
          </div>
        ))}
      </Carousel>
    )}
    <div className={styles.footer}>
      <Typography
        color="whiteMaster"
        markColor="blueSecondary"
        name="Subtitle4"
      >
        {WELCOME_BLOCK.FOOTER.TEXT}
        <mark>{WELCOME_BLOCK.FOOTER.MARK_TEXT}</mark>
      </Typography>
    </div>
  </div>
)
