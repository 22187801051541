import { Input } from 'ui/atoms/Input'
import { InputPassword } from 'ui/atoms/InputPassword'
import { FormItem } from 'ui/molecules/Form/components/FormItem'
import { FORM_FIELDS_VALUES } from 'texts/formFields'
import { FormFieldsProps } from './types'
import {
  confirmPasswordRules,
  emailRules,
  passwordRules,
} from 'texts/validateRules'
import styles from './styles.module.scss'

export const AuthFormFields = ({
  formType,
  passwordScale,
  isError,
}: FormFieldsProps) => {
  const signinEmailRules = emailRules.slice(0, 1)
  const getRules = () => (formType === 'signup' ? emailRules : signinEmailRules)

  return (
    <div className={styles.form}>
      {formType === 'signin' && (
        <div className={styles['input-wrapper']}>
          <FormItem id={FORM_FIELDS_VALUES.EMAIL.ID} rules={getRules()}>
            {({ value, error, onChange }) => (
              <Input
                value={value}
                size="large"
                iconLeft="MailIcon"
                label={FORM_FIELDS_VALUES.EMAIL.LABEL}
                onChange={onChange}
                isError={isError}
                iconSize="small"
                spaceForError="none"
                placeholder={FORM_FIELDS_VALUES.EMAIL.PLACEHOLDER}
                typographyName="Button2"
                error={error}
              />
            )}
          </FormItem>
        </div>
      )}
      <div className={styles['input-wrapper']}>
        <FormItem id={FORM_FIELDS_VALUES.PASSWORD.ID} rules={passwordRules}>
          {({ value, error, onChange }) => (
            <InputPassword
              value={value}
              size="large"
              label={FORM_FIELDS_VALUES.PASSWORD.LABEL}
              onChange={onChange}
              isError={isError}
              placeholder={FORM_FIELDS_VALUES.PASSWORD.PLACEHOLDER}
              error={error}
              passwordScale={passwordScale}
              withCompare
            />
          )}
        </FormItem>
      </div>
      {formType === 'signup' && (
        <div className={styles['input-wrapper']}>
          <FormItem
            id={FORM_FIELDS_VALUES.CONFIRM_PASSWORD.ID}
            rules={confirmPasswordRules}
          >
            {({ value, error, onChange }) => (
              <InputPassword
                size="large"
                value={value}
                error={error}
                isError={isError}
                onChange={onChange}
                label={FORM_FIELDS_VALUES.CONFIRM_PASSWORD.LABEL}
                placeholder={FORM_FIELDS_VALUES.CONFIRM_PASSWORD.PLACEHOLDER}
              />
            )}
          </FormItem>
        </div>
      )}
    </div>
  )
}
