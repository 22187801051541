import { joinClasses } from 'utils/joinClasses'
import { FormAlertLabel } from '../FormAlertLabel'
import { Label } from '@frontend/design_system'
import { TextareaProps } from './types'
import styles from './styles.module.scss'

export const Textarea = ({
  placeholder,
  height = 89,
  typographyName = 'body2WMedium',
  label,
  error,
  value,
  onChange,
  isRequired,
  hint,
  disabled,
}: TextareaProps) => {
  const handlerOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value)
  }

  return (
    <div
      className={joinClasses(
        styles.textarea,
        [styles['textarea-error'], error],
        [styles['textarea-disabled'], disabled]
      )}
    >
      {label && (
        <Label typographyName="Button2" label={label} isRequired={isRequired} />
      )}
      <textarea
        placeholder={!disabled ? placeholder : ''}
        style={{ height }}
        className={styles[typographyName]}
        value={value}
        disabled={disabled}
        onChange={handlerOnChange}
      />
      {(error || hint) && (
        <FormAlertLabel label={error || hint} type={error ? 'error' : 'hint'} />
      )}
    </div>
  )
}
