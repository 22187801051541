export const headItems = [
  'Car model',
  'Company class',
  'SIPP',
  'Car type',
  'Refers to location',
]

export const NOT_AVAILABLE = 'N/A'
export const SHORT_NOT_AVAILABLE = 'NA'
