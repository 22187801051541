import { activityHistoryReducer } from './activityHistory/slice'
import { filtersReducer } from './filters/slice'
import { notificationReducer } from './notifications/slice'
import { locationsReducer } from './locations/slice'
import { fleetReducer } from './fleet/slice'
import { profileReducer } from './profile/slice'
import { authReducer } from './login/slice'
import { registrationReducer } from './registration/slice'
import { commonReducer } from './common/slice'
import { configureStore } from '@reduxjs/toolkit'

export const store = configureStore({
  reducer: {
    registrationReducer,
    authReducer,
    profileReducer,
    fleetReducer,
    locationsReducer,
    notificationReducer,
    filtersReducer,
    activityHistoryReducer,
    commonReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
