import { useEffect } from 'react'
import {
  DESKTOP_WRAPPER_WIDTH,
  WRAPPER_CLASS_NAME,
} from 'ui/atoms/Wrapper/constants'

const getScrollbarWidth = () => {
  const outer = document.createElement('div')
  outer.style.visibility = 'hidden'
  outer.style.overflow = 'scroll'
  document.body.appendChild(outer)

  const inner = document.createElement('div')
  outer.appendChild(inner)

  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth

  outer.parentNode?.removeChild(outer)

  return scrollbarWidth
}

export const useHideScroll = (disable = false) => {
  useEffect(() => {
    if (disable) {
      return () => {}
    }

    document.body.style.overflow = 'hidden'
    const body = document.getElementsByTagName('body')[0]
    const isScrollBar = body.scrollHeight > body.clientHeight

    if (!isScrollBar) {
      return () => {
        document.body.style.overflow = 'auto'
      }
    }

    const wrappers = document.querySelectorAll<HTMLElement>(WRAPPER_CLASS_NAME)
    const scrollBarWidth = getScrollbarWidth()

    for (let i = 0; i < wrappers.length; i++) {
      const wrapper = wrappers[i]
      const paddingRight = parseInt(
        window.getComputedStyle(wrapper).getPropertyValue('padding-right'),
        10
      )
      const wrapperWidth = wrapper.getBoundingClientRect().width

      if (wrapperWidth === DESKTOP_WRAPPER_WIDTH) {
        wrapper.style.width = `${
          wrapper.getBoundingClientRect().width + scrollBarWidth
        }px`
      }

      wrapper.style.paddingRight = `${paddingRight + scrollBarWidth}px`
    }

    const isMultipleModals = document.getElementsByClassName('modal').length > 1

    return () => {
      if (!isMultipleModals) {
        document.body.style.overflow = 'auto'
        for (let i = 0; i < wrappers.length; i++) {
          const wrapper = wrappers[i]
          wrapper.style.width = ''
          wrapper.style.paddingRight = ''
        }
      }
    }
  }, [disable])
}
