import { DriverAgeRange, DriverAgeRangeType } from 'api/fleet/types'
import { WEEKDAYS } from 'texts/uiTexts'
import { Cor } from 'types'
import { initialRange } from 'ui/components/DriverAgeRanges/constants'

export const initialFormValues = {
  locationName: '',
  latitude: '',
  longitude: '',
  region: '',
  building: '',
  countryName: '',
  countryId: '',
  cityId: '',
  currencyID: '',
  phone: '',
  locationId: '',
  street: '',
  waitingHours: '',
  waitingHourPrice: '',
  waitingHoursPeriod: '',
  postalCode: '',
  phoneCountryCode: '',
  phoneAreaCode: '',
  hirePointName: '',
  phoneCor: {} as Cor,
  email: '',
  maximumPeriodDay: '',
  minimumPeriodDay: '1',
  pickupOption: 'Default',
  specialInstructions: '',
  startReservationHour: '',
  driverAgeRange: {
    items: [initialRange],
    rangeType: DriverAgeRangeType.PerRental,
  } as DriverAgeRange,
}

export const WORKING_HOURS_INITIAL_VALUES = [
  {
    dayStart: '',
    dayStart2: '',
    dayEnd: '',
    dayEnd2: '',
    dayOfWeek: WEEKDAYS.MONDAY,
  },
  {
    dayStart: '',
    dayStart2: '',
    dayEnd: '',
    dayEnd2: '',
    dayOfWeek: WEEKDAYS.TUESDAY,
  },
  {
    dayStart: '',
    dayStart2: '',
    dayEnd: '',
    dayEnd2: '',
    dayOfWeek: WEEKDAYS.WEDNESDAY,
  },
  {
    dayStart: '',
    dayStart2: '',
    dayEnd: '',
    dayEnd2: '',
    dayOfWeek: WEEKDAYS.THURSDAY,
  },
  {
    dayStart: '',
    dayStart2: '',
    dayEnd: '',
    dayEnd2: '',
    dayOfWeek: WEEKDAYS.FRIDAY,
  },
  {
    dayStart: '',
    dayStart2: '',
    dayEnd: '',
    dayEnd2: '',
    dayOfWeek: WEEKDAYS.SATURDAY,
  },
  {
    dayStart: '',
    dayStart2: '',
    dayEnd: '',
    dayEnd2: '',
    dayOfWeek: WEEKDAYS.SUNDAY,
  },
]
