import { createSlice } from '@reduxjs/toolkit'
import { FiltersState } from './types'
import { getSelectedFiltersCount, getSelectedFiltersList } from 'utils/filters'

const initialState: FiltersState = {
  filters: {},
  selectedFiltersCount: 0,
  selectedFilters: {},
}

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setFilterItems(state, action) {
      state.filters = action.payload
      state.selectedFiltersCount = getSelectedFiltersCount(state.filters)
      state.selectedFilters = getSelectedFiltersList(state.filters)
    },
    selectAllFiltersByType(state, action) {
      state.filters[action.payload.key] = state.filters[action.payload.key].map(
        (el) => ({ value: el.value, selected: action.payload.isSelect })
      )
      state.selectedFiltersCount = getSelectedFiltersCount(state.filters)
      state.selectedFilters = getSelectedFiltersList(state.filters)
    },
    resetSelectedFilters(state) {
      state.selectedFilters = {}
      state.selectedFiltersCount = 0
    },
  },
})

export const filtersReducer = filtersSlice.reducer
export const { resetSelectedFilters, setFilterItems, selectAllFiltersByType } =
  filtersSlice.actions
