import { useMemo } from 'react'
import { LOCATION_ADDING_FORM_VALUES } from 'texts/locationDetails'
import { minMaxPeriodDayValidation } from '../utils'
import { Rule } from 'ui/molecules/Form/components/FormItem/types'

export const useReservationRules = (formValues: Record<string, unknown>) => {
  const rules: { [key: string]: Rule[] } = useMemo(
    () => ({
      dailyRangeRules: [
        {
          type: 'required',
          message:
            LOCATION_ADDING_FORM_VALUES.DRIVER_AGE_RANGE.REQUIRED_MESSAGE,
        },
      ],
      minimumPeriodDay: [
        {
          type: 'required',
          message:
            LOCATION_ADDING_FORM_VALUES.MINIMUM_PERIOD_DAY.VALIDATION_MESSAGE,
        },
        {
          type: 'custom',
          message:
            LOCATION_ADDING_FORM_VALUES.MINIMUM_PERIOD_DAY.VALIDATION_MESSAGE,
          value: (value: string) => Number(value) < 1,
        },
      ],
      minMaxPeriodDay: [
        {
          type: 'custom',
          message:
            LOCATION_ADDING_FORM_VALUES.MIN_MAX_RENTAL_DAYS.VALIDATION_MESSAGE,
          value: (value: string) =>
            minMaxPeriodDayValidation(value, formValues),
        },
      ],
    }),
    [formValues]
  )

  return rules
}
