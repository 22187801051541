export const getBase64 = (file: Blob) => {
  const reader = new FileReader()

  return new Promise<string>((resolve) => {
    reader.onload = (ev) => {
      if (ev.target) {
        resolve(ev.target.result as string)
      }
    }

    reader.readAsDataURL(file)
  })
}
