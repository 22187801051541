import { joinClasses } from 'utils/joinClasses'
import { Typography } from '../../../Typography'
import { DropdownItemProps } from './types'
import styles from './styles.module.scss'

export const DropdownItem = ({
  item,
  selectedItem,
  onClick,
}: DropdownItemProps) => {
  const handleClick = () => {
    onClick(item)
  }

  return (
    <Typography
      Tag="div"
      name="Button2"
      className={joinClasses(styles['dropdown-items-item'], [
        styles['dropdown-items-item-selected'],
        item === selectedItem,
      ])}
      onClick={handleClick}
    >
      {item}
    </Typography>
  )
}
