import { FleetApiTypes } from 'api'
import { DriverAgeRangeType } from 'api/fleet/types'
import { RadioButton } from 'ui/atoms/RadioButton'
import { DriverAgeRange } from './components/DriverAgeRange'
import { RADIO_BUTTONS, initialRange } from './constants'
import { DriverAgeRangesFormProps } from './types'
import { FormAlertLabel } from '@frontend/design_system'
import { Button } from 'ui/atoms/Button'
import { PlusIcon } from 'ui/icons'
import { Badge } from 'ui/atoms/Badge'
import { DRIVER_AGE_RANGE } from 'texts/driverAgeRange'
import styles from './styles.module.scss'

export const DriveAgeRanges = ({
  driverAgeRangeData,
  onDriverAgeRangeDataChange,
  error,
}: DriverAgeRangesFormProps) => {
  const handleChange = (
    index: number,
    value: FleetApiTypes.DriverAgeRangeItem
  ) => {
    const newRanges = [...driverAgeRangeData.items]
    newRanges.splice(index, 1, value)
    onDriverAgeRangeDataChange({ ...driverAgeRangeData, items: newRanges })
  }

  const handleAddRange = () => {
    const newRanges = [...driverAgeRangeData.items]
    newRanges.push(initialRange)
    onDriverAgeRangeDataChange({ ...driverAgeRangeData, items: newRanges })
  }

  const handleRemoveRange = (index: number) => {
    const newRanges = [...driverAgeRangeData.items]
    newRanges.splice(index, 1)
    onDriverAgeRangeDataChange({ ...driverAgeRangeData, items: newRanges })
  }

  const handleChangeRadioButton = (id: string) => {
    onDriverAgeRangeDataChange({
      ...driverAgeRangeData,
      items: driverAgeRangeData.items.map((range) => ({
        ...range,
        maxPrice: '',
      })),
      rangeType: id as DriverAgeRangeType,
    })
  }

  return (
    <div>
      {!!driverAgeRangeData.items?.length ? (
        <>
          {driverAgeRangeData.items.map((el, index) => (
            <DriverAgeRange
              handleRemoveRange={() => handleRemoveRange(index)}
              key={index}
              rangeType={driverAgeRangeData.rangeType}
              index={index}
              handleChange={handleChange}
              range={el}
            />
          ))}
          {RADIO_BUTTONS.map(({ label, id }) => (
            <RadioButton
              labelTypographyName="Button2"
              key={id}
              id={id}
              label={label}
              onChange={handleChangeRadioButton}
              checked={id === driverAgeRangeData.rangeType}
            />
          ))}
        </>
      ) : (
        <Badge message={DRIVER_AGE_RANGE.ADD_RANGE_BADGE} />
      )}
      {error && (
        <FormAlertLabel
          typographyName="Button2"
          label={error}
          position="static"
          type="error"
        />
      )}
      <div className={styles['button-wrapper']}>
        <Button
          label={DRIVER_AGE_RANGE.ADD_RANGE_BUTTON}
          size="large"
          icon={<PlusIcon color="whiteMaster" size="medium" />}
          iconPosition="right"
          onClick={handleAddRange}
        />
      </div>
    </div>
  )
}
