import { APPLICATION_STATUS } from 'modules/Applications/constants'
import { ApplicationStatus } from '../../types'

const { WAITING_FOR_CALL, WAITING_FOR_SIGN, APPROVE, REJECTED, WAITING_LIST } =
  APPLICATION_STATUS

export const getApplicationStatusType = (status?: ApplicationStatus) => {
  if (
    status === WAITING_FOR_CALL ||
    status === WAITING_FOR_SIGN ||
    status === WAITING_LIST
  ) {
    return 'warning'
  }
  if (status === APPROVE) {
    return 'ok'
  }
  if (status === REJECTED) {
    return 'error'
  }
  return 'warning'
}
