import { TYPOGRAPHY_COLORS } from 'constants/TypographyColors'
import { SVGIcon } from './SvgIcon/SvgIcon'
import { IconProps } from './types'

export const CalendarIcon = ({ size, color = 'grey400' }: IconProps) => (
  <SVGIcon size={size} color={color} viewBox="0 0 20 20">
    <path
      d="M16.3889 0C18.3832 0 20 1.61674 20 3.61111V16.3889C20 18.3832 18.3832 20 16.3889 20H3.61111C1.61674 20 0 18.3832 0 16.3889V3.61111C0 1.61674 1.61674 0 3.61111 0H16.3889ZM18.3333 6.11111H1.66667V16.3889C1.66667 17.4628 2.53722 18.3333 3.61111 18.3333H16.3889C17.4628 18.3333 18.3333 17.4628 18.3333 16.3889V6.11111ZM5.27778 12.7778C6.04484 12.7778 6.66667 13.3996 6.66667 14.1667C6.66667 14.9338 6.04484 15.5556 5.27778 15.5556C4.51071 15.5556 3.88889 14.9338 3.88889 14.1667C3.88889 13.3996 4.51071 12.7778 5.27778 12.7778ZM10 12.7778C10.7671 12.7778 11.3889 13.3996 11.3889 14.1667C11.3889 14.9338 10.7671 15.5556 10 15.5556C9.23289 15.5556 8.61111 14.9338 8.61111 14.1667C8.61111 13.3996 9.23289 12.7778 10 12.7778ZM5.27778 8.33333C6.04484 8.33333 6.66667 8.95511 6.66667 9.72222C6.66667 10.4893 6.04484 11.1111 5.27778 11.1111C4.51071 11.1111 3.88889 10.4893 3.88889 9.72222C3.88889 8.95511 4.51071 8.33333 5.27778 8.33333ZM10 8.33333C10.7671 8.33333 11.3889 8.95511 11.3889 9.72222C11.3889 10.4893 10.7671 11.1111 10 11.1111C9.23289 11.1111 8.61111 10.4893 8.61111 9.72222C8.61111 8.95511 9.23289 8.33333 10 8.33333ZM14.7222 8.33333C15.4893 8.33333 16.1111 8.95511 16.1111 9.72222C16.1111 10.4893 15.4893 11.1111 14.7222 11.1111C13.9551 11.1111 13.3333 10.4893 13.3333 9.72222C13.3333 8.95511 13.9551 8.33333 14.7222 8.33333ZM16.3889 1.66667H3.61111C2.53722 1.66667 1.66667 2.53722 1.66667 3.61111V4.44444H18.3333V3.61111C18.3333 2.53722 17.4628 1.66667 16.3889 1.66667Z"
      fill={TYPOGRAPHY_COLORS[color]}
    />
  </SVGIcon>
)
