export const TYPOGRAPHY_COLORS = {
  whiteMaster: '#ffffff',
  blackMaster: '#000000',
  grey200: '#D9D9D9',
  grey300: '#C4C4C4',
  grey400: '#9D9D9D',
  grey450: '#8E8E95',
  grey500: '#7B7B7B',
  grey650: '#332B0F',
  grey900: '#000000',
  yellow400Master: '#FFC926',
  yellow700: '#FFA201',
  blue700: '#2468E5',
  blue900Master: '#1048AD',
  green600: '#68B943',
  green700Master: '#54A539',
  red600Master: '#FF002E',
  blueSecondary: '#97ABD7',
  yellow900: '#FF6E00',
  green900: '#166E1D',
} as const
