import { TypographyColor } from 'types'
import { TitleNames } from './types'

export const titleNames: TitleNames = {
  medium: {
    name: 'H5',
  },
  large: {
    name: 'H4',
  },
}

export const closeColors: { [key: string]: TypographyColor } = {
  primary: 'grey400',
  secondary: 'whiteMaster',
}
