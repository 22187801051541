import { joinClasses } from 'utils/joinClasses'
import { Typography } from '../Typography'
import { InfoIcon } from 'ui/icons'
import { Props } from './types'
import styles from './styles.module.scss'
import { TypographyName } from '../Typography/types'
import { TypographyColor } from 'types'
import { IconSize } from '../../icons/types'

const icons = (size: IconSize) => ({
  error: (
    <InfoIcon
      size={size}
      color="red600Master"
      className={styles['badge-icon']}
    />
  ),
  info: (
    <InfoIcon size={size} color="blue700" className={styles['badge-icon']} />
  ),
  question: (
    <InfoIcon size={size} color="green900" className={styles['badge-icon']} />
  ),
  note: (
    <InfoIcon size={size} color="yellow900" className={styles['badge-icon']} />
  ),
  warning: (
    <InfoIcon
      size={size}
      color="red600Master"
      className={styles['badge-icon']}
    />
  ),
})

const colors: { [key: string]: TypographyColor } = {
  error: 'red600Master',
  warning: 'yellow900',
}

const names: { [key: string]: TypographyName } = {
  warning: 'Button1',
  label: 'Subtitle3',
  info: 'Body1',
}

export const Badge = ({
  message,
  className,
  typographyName,
  iconSize = 'large',
  type = 'info',
}: Props) => (
  <Typography
    name={typographyName || names[type] || 'Body2'}
    color={colors[type] || 'blue700'}
    Tag="div"
    className={joinClasses(styles.badge, styles[type], className || '')}
  >
    {icons(iconSize)[type]}
    {message}
  </Typography>
)
