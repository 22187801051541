import { addQueryString, Filter } from 'utils/addQueryString'
import { EmptyResponse, ErrorResponse, ContentType } from '../types'
import { request } from '../utils'
import { PATH } from './constants'
import {
  GetCompanyInfoResponse,
  GetCompanyListResponse,
  UpdateCompanyInfoRequest,
} from './types'

class Api {
  public baseUrl: string = process.env.REACT_APP_API_URL || ''

  async getCompanyList(
    pageIndex: number,
    pageSize: number,
    archived = false,
    filters?: Filter[]
  ) {
    return request<GetCompanyListResponse, ErrorResponse>(
      this.baseUrl,
      addQueryString(
        PATH.COMPANY_LIST,
        {
          archived,
          pageSize,
          pageIndex,
        },
        filters
      ),
      { method: 'GET' }
    )
  }

  async closeCompany(companyId: number) {
    return request<EmptyResponse, ErrorResponse>(
      this.baseUrl,
      `${PATH.COMPANY}${companyId}`,
      { method: 'DELETE' }
    )
  }

  async getCompanyInfo(companyId: number) {
    return request<GetCompanyInfoResponse, ErrorResponse>(
      this.baseUrl,
      `${PATH.COMPANY}${companyId}`,
      { method: 'GET' }
    )
  }

  async createNewCompany(companyData: UpdateCompanyInfoRequest) {
    return request<GetCompanyInfoResponse, ErrorResponse>(
      this.baseUrl,
      PATH.NEW_COMPANY,
      { method: 'POST' },
      companyData,
      'json',
      ContentType.FORM_DATA
    )
  }

  async updateCompany(id: number, companyData: UpdateCompanyInfoRequest) {
    return request<GetCompanyInfoResponse, ErrorResponse>(
      this.baseUrl,
      `${PATH.COMPANY}${id}`,
      { method: 'PUT' },
      companyData,
      'json',
      ContentType.FORM_DATA
    )
  }

  async restoreCompany(companyId: number) {
    return request<GetCompanyInfoResponse, ErrorResponse>(
      this.baseUrl,
      `company/${companyId}/restore`,
      { method: 'POST' }
    )
  }

  async getCompanyName(companyId: number) {
    return request<{ companyname: string }, ErrorResponse>(
      this.baseUrl,
      `company/${companyId}/name`
    )
  }
}

export const profileApi = new Api()
