import { AuthLayout } from 'layouts/AuthLayout'
import { ResetPassword } from 'modules/auth/ResetPassword'
import { WelcomeBlockView } from 'modules/auth/WelcomeBlockView'
import { Header } from 'modules/auth/WelcomeBlockView/components/Header'
import { WELCOME_BLOCK } from 'texts/welcomeBlock'

export const ResetPasswordPage = () => (
  <AuthLayout
    WelcomeBlock={
      <WelcomeBlockView
        withCarousel={false}
        Header={() => (
          <Header
            title={WELCOME_BLOCK.HEADER.RESET_PASSWORD.TITLE}
            markTitle={WELCOME_BLOCK.HEADER.RESET_PASSWORD.MARK_TITLE}
            subtitle={WELCOME_BLOCK.HEADER.RESET_PASSWORD.SUBTITLE}
          />
        )}
      />
    }
    Form={<ResetPassword />}
  />
)
