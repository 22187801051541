import { UserRoles } from 'constants/roles'
import { RootState } from 'redux/store'

export const userDataSelector = (state: RootState) => state.authReducer.userData

export const errorMessageSelector = (state: RootState) =>
  state.authReducer.errorMessage

export const userRoleSelector = (state: RootState) =>
  state.authReducer.userData.roles[0]

export const supplierCompanyIdSelector = (state: RootState) =>
  state.authReducer.userData.companyId

export const isOperatorSelector = (state: RootState) =>
  state.authReducer.userData.roles[0] === UserRoles.Operator

export const authIsLoadedSelector = (state: RootState) =>
  state.authReducer.isLoaded
