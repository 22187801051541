import { useCallback, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { Typography } from 'ui/atoms/Typography'
import { useHideScroll } from 'ui/hooks/useHideScroll'
import { Wrapper } from 'ui/atoms/Wrapper'
import { ArrowUpRightIcon } from 'ui/icons'
import { ModalCloseIcon } from 'ui/icons/ModalCloseIcon'
import { joinClasses } from 'utils/joinClasses'
import { GLOBAL_MODAL } from 'constants/DOM'
import { Props } from './types'
import { TYPOGRAPHY_COLORS } from 'constants/TypographyColors'
import { closeColors, titleNames } from './constants'
import styles from './styles.module.scss'

export const Modal = ({
  children,
  onClose,
  disableOverlayClose,
  className,
  position = 'default',
  offsetVariant = 'primary',
  invisibleHeader,
  goBack,
  title,
  invisibleClose,
  closeVariant = 'primary',
  fullWidth = false,
  contentRef,
  isDelimiter = false,
  backgroundColor = 'whiteMaster',
  withoutScroll = false,
  headerSize = 'medium',
  invisibleTitle = false,
  borderVariant = 'default',
  isRoundedScroll,
}: Props) => {
  const [node, setNode] = useState(document.getElementById(GLOBAL_MODAL))

  const handleModal = (e: React.MouseEvent) => {
    e?.stopPropagation()
  }

  const handleClose = useCallback(() => {
    if (!disableOverlayClose) {
      onClose()
    }
  }, [disableOverlayClose])

  useEffect(() => {
    if (!node) {
      setNode(document.getElementById(GLOBAL_MODAL))
    }
  }, [])

  useHideScroll()

  const renderBackBtn = () => {
    if (goBack) {
      return (
        <>
          <ArrowUpRightIcon
            size="large"
            color="blue700"
            className={styles['modal-back']}
            onClick={goBack}
          />
          <ArrowUpRightIcon
            size="large"
            color="whiteMaster"
            className={styles['modal-back-mobile']}
            onClick={goBack}
          />
        </>
      )
    }

    return null
  }

  if (!node) {
    return null
  }

  return createPortal(
    <div
      onClick={handleModal}
      className={joinClasses(
        styles.modal,
        className || '',
        [styles[`position-${position}`], position],
        [styles['full-width-desktop'], fullWidth]
      )}
    >
      <div className={styles['modal-overlay']} onClick={handleClose} />
      <Wrapper className={styles['modal-wrapper']}>
        <div className={styles['modal-inner']}>
          <div
            className={joinClasses(
              styles['modal-popup'],
              styles[`offset-${offsetVariant}`],
              styles[`header-size-${headerSize}`],
              styles[`border-variant-${borderVariant}`],
              [styles['rounded-scroll'], isRoundedScroll]
            )}
            style={{
              backgroundColor: TYPOGRAPHY_COLORS[backgroundColor],
            }}
          >
            <div
              className={joinClasses(
                styles['modal-header'],
                [styles['no-header-desktop'], invisibleHeader],
                [styles['delimiter'], isDelimiter]
              )}
            >
              {renderBackBtn()}
              {title && (
                <Typography
                  name={titleNames[headerSize].name}
                  markColor="whiteMaster"
                  className={joinClasses(styles['modal-title'], [
                    styles['no-title-desktop'],
                    invisibleTitle,
                  ])}
                >
                  {title}
                </Typography>
              )}
            </div>
            {onClose && (
              <div
                className={joinClasses(styles['modal-close'], [
                  styles['no-close-desktop'],
                  invisibleClose,
                ])}
                onClick={onClose}
              >
                <ModalCloseIcon
                  size="large"
                  color={closeColors[closeVariant]}
                />
              </div>
            )}
            <div
              ref={contentRef}
              className={joinClasses(styles['modal-content'], [
                styles['with-overflow'],
                !withoutScroll,
              ])}
            >
              {children}
            </div>
          </div>
        </div>
      </Wrapper>
    </div>,
    node
  )
}
