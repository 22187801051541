import { joinClasses } from 'utils/joinClasses'
import { WRAPPER_CLASS_NAME } from './constants'
import { Props } from './types'
import styles from './styles.module.scss'

export const Wrapper = ({ children, className }: Props) => (
  <div className={joinClasses(styles[WRAPPER_CLASS_NAME], className || '')}>
    {children}
  </div>
)
