import { createAsyncThunk } from '@reduxjs/toolkit'
import { authApi, AuthApiTypes, HTTPClientTypes } from 'api'
import { RegistrationRequest, RejectValue } from './types'

export const sendRegistrationCode = createAsyncThunk<
  AuthApiTypes.CheckRegistrationCodeResponse,
  string,
  RejectValue
>('registration/sendCode', async (code, { rejectWithValue }) => {
  try {
    const registrationCodeResponse = await authApi.checkRegistrationCode(code)
    return registrationCodeResponse.data
  } catch (error) {
    return rejectWithValue(
      (error as HTTPClientTypes.ErrorResponse).responseStatus.message
    )
  }
})

export const registration = createAsyncThunk<
  AuthApiTypes.RegistrationResponse,
  RegistrationRequest,
  RejectValue
>(
  'registration',
  async ({ code, password, onSuccess }, { rejectWithValue }) => {
    try {
      await authApi.register(code, password)
      if (onSuccess) {
        onSuccess()
      }
    } catch (error) {
      return rejectWithValue(
        (error as HTTPClientTypes.ErrorResponse).responseStatus.message
      )
    }
  }
)
