import { forwardRef } from 'react'
import { ClassNames, DayPicker } from 'react-day-picker'
import { DropdownProps } from './type'
import { getMonth, getYear } from 'utils/dateFormat'
import rdpStyles from 'react-day-picker/dist/style.module.css'
import styles from './styles.module.scss'

export const DateDropdown = forwardRef<HTMLDivElement, DropdownProps>(
  ({ changeValue, selectedDay, handleClose }, ref) => {
    const classNames: ClassNames = {
      ...rdpStyles,
      nav_button_previous: styles['calendar-nav'],
      nav_button_next: styles['calendar-nav-right'],
      caption_label: styles['calendar-caption'],
      cell: styles['calendar-cell'],
      button: styles['calendar-button'],
      day_range_start: styles['selected-start'],
      day_range_end: styles['selected-end'],
      day_selected: styles.selected,
      day_today: styles['today'],
    }

    const handleSelect = (day?: Date) => {
      changeValue(day)
      handleClose()
    }

    return (
      <div className={styles.dropdown} ref={ref}>
        <DayPicker
          pagedNavigation
          mode="single"
          defaultMonth={
            selectedDay &&
            new Date(
              getYear(String(selectedDay)),
              getMonth(String(selectedDay))
            )
          }
          selected={selectedDay}
          numberOfMonths={1}
          captionLayout="buttons"
          classNames={classNames}
          onSelect={handleSelect}
        />
      </div>
    )
  }
)
