import { onlyNumbersRegex } from 'constants/regex'

export const onChangeNumber = (
  value: string,
  callback: (value: string) => void
) => {
  if (!value || onlyNumbersRegex.test(value)) {
    callback(value)
  }
}
