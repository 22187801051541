import { useEffect } from 'react'

export const useOutsideClick = (
  element: Element | null,
  onClose: VoidFunction,
  options = {
    enabled: true,
    additionalElements: [] as Element[],
  }
) => {
  useEffect(() => {
    if (options.enabled) {
      const handleClickOutside = (event: MouseEvent) => {
        if (element && !element.contains(event.target as Node)) {
          if (
            !(
              options.additionalElements.length &&
              options.additionalElements.some((additionalElement) =>
                additionalElement.contains(event.target as Node)
              )
            )
          ) {
            onClose()
          }
        }
      }

      document.addEventListener('mouseup', handleClickOutside)

      return () => {
        document.removeEventListener('mouseup', handleClickOutside)
      }
    }

    return () => {}
  }, [element, options.enabled, onClose])
}
