import { joinClasses } from 'utils/joinClasses'
import { MultiselectHeaderProps } from './types'
import { ArrowTopIcon } from 'ui/icons'
import { Typography } from 'ui/atoms/Typography'
import styles from './styles.module.scss'

export const MultiselectHeader = ({
  renderChips,
  onToggle,
  openDropdown,
  error,
  readonly,
  placeholder,
  withoutPlaceholder,
}: MultiselectHeaderProps) => (
  <div
    className={joinClasses(
      styles['multiselect-header'],
      [styles['multiselect-header-error'], error],
      [styles['multiselect-header-active'], openDropdown],
      [styles['readonly'], readonly]
    )}
    onClick={readonly ? undefined : onToggle}
  >
    <div className={styles['multiselect-header-item-block']}>
      {renderChips ? (
        renderChips
      ) : (
        <div className={styles['empty']}>
          <div className={styles['multiselect-header-item-input-inner']}>
            {!withoutPlaceholder && !readonly && (
              <Typography
                className={styles.timestamp}
                name="Button2"
                color="grey400"
              >
                {placeholder}
              </Typography>
            )}
          </div>
        </div>
      )}
    </div>
    <div className={styles['multiselect-header-item-icons']}>
      <div className={styles['multiselect-header-item-icon-arrow']}>
        <ArrowTopIcon color="grey400" size="medium" />
      </div>
    </div>
  </div>
)
