import { Input } from 'ui/atoms/Input'
import { FormItem } from 'ui/molecules/Form/components/FormItem'
import { APPLICATION_FORM_VALUES } from 'texts/applications'
import styles from './styles.module.scss'

export const ContactInformation = () => (
  <>
    <div className={styles['form-items-row']}>
      <FormItem
        id={APPLICATION_FORM_VALUES.NAME.ID}
        className={styles['form-item']}
      >
        {({ value, error, onChange }) => (
          <Input
            value={value}
            placeholder=""
            size="large"
            error={error}
            readonly
            spaceForError="none"
            onChange={onChange}
            label={APPLICATION_FORM_VALUES.NAME.LABEL}
          />
        )}
      </FormItem>
      <FormItem
        id={APPLICATION_FORM_VALUES.COUNTRY.ID}
        className={styles['form-item']}
      >
        {({ value, error, onChange }) => (
          <Input
            value={value}
            placeholder=""
            size="large"
            error={error}
            readonly
            spaceForError="none"
            onChange={onChange}
            label={APPLICATION_FORM_VALUES.COUNTRY.LABEL}
          />
        )}
      </FormItem>
    </div>
    <div className={styles['form-items-row']}>
      <FormItem
        id={APPLICATION_FORM_VALUES.CITY.ID}
        className={styles['form-item']}
      >
        {({ value, error, onChange }) => (
          <Input
            value={value}
            placeholder=""
            size="large"
            error={error}
            readonly
            spaceForError="none"
            onChange={onChange}
            label={APPLICATION_FORM_VALUES.CITY.LABEL}
          />
        )}
      </FormItem>
      <FormItem
        id={APPLICATION_FORM_VALUES.STATE.ID}
        className={styles['form-item']}
      >
        {({ value, error, onChange }) => (
          <Input
            value={value}
            placeholder=""
            size="large"
            error={error}
            readonly
            spaceForError="none"
            onChange={onChange}
            label={APPLICATION_FORM_VALUES.STATE.LABEL}
          />
        )}
      </FormItem>
    </div>
    <div className={styles['form-items-row']}>
      <FormItem
        id={APPLICATION_FORM_VALUES.STREET.ID}
        className={styles['form-item']}
      >
        {({ value, error, onChange }) => (
          <Input
            value={value}
            placeholder=""
            size="large"
            error={error}
            readonly
            spaceForError="none"
            onChange={onChange}
            label={APPLICATION_FORM_VALUES.STREET.LABEL}
          />
        )}
      </FormItem>
      <FormItem
        id={APPLICATION_FORM_VALUES.BUILDING.ID}
        className={styles['form-item']}
      >
        {({ value, error, onChange }) => (
          <Input
            value={value}
            placeholder=""
            size="large"
            error={error}
            readonly
            spaceForError="none"
            onChange={onChange}
            label={APPLICATION_FORM_VALUES.BUILDING.LABEL}
          />
        )}
      </FormItem>
    </div>
    <div className={styles['form-items-row']}>
      <FormItem
        id={APPLICATION_FORM_VALUES.WEBSITE.ID}
        className={styles['form-item']}
      >
        {({ value, error, onChange }) => (
          <Input
            value={value}
            placeholder=""
            size="large"
            error={error}
            readonly
            spaceForError="none"
            onChange={onChange}
            label={APPLICATION_FORM_VALUES.WEBSITE.LABEL}
          />
        )}
      </FormItem>
      <FormItem
        id={APPLICATION_FORM_VALUES.FOUNDED.ID}
        className={styles['form-item']}
      >
        {({ value, error, onChange }) => (
          <Input
            value={value}
            placeholder=""
            size="large"
            error={error}
            readonly
            spaceForError="none"
            onChange={onChange}
            label={APPLICATION_FORM_VALUES.FOUNDED.LABEL}
          />
        )}
      </FormItem>
    </div>
    <div className={styles['form-items-row']}>
      <FormItem
        id={APPLICATION_FORM_VALUES.CONTACT_NAME.ID}
        className={styles['form-item']}
      >
        {({ value, error, onChange }) => (
          <Input
            value={value}
            placeholder=""
            size="large"
            error={error}
            readonly
            spaceForError="none"
            onChange={onChange}
            label={APPLICATION_FORM_VALUES.POSITION.LABEL}
          />
        )}
      </FormItem>
      <FormItem
        id={APPLICATION_FORM_VALUES.POSITION.ID}
        className={styles['form-item']}
      >
        {({ value, error, onChange }) => (
          <Input
            value={value}
            placeholder=""
            size="large"
            error={error}
            readonly
            spaceForError="none"
            onChange={onChange}
            label={APPLICATION_FORM_VALUES.EMAIL.LABEL}
          />
        )}
      </FormItem>
    </div>
    <div className={styles['form-items-row']}>
      <FormItem
        id={APPLICATION_FORM_VALUES.EMAIL.ID}
        className={styles['form-item']}
      >
        {({ value, error, onChange }) => (
          <Input
            value={value}
            placeholder=""
            size="large"
            error={error}
            readonly
            spaceForError="none"
            onChange={onChange}
            label={APPLICATION_FORM_VALUES.EMAIL.LABEL}
          />
        )}
      </FormItem>
      <FormItem
        id={APPLICATION_FORM_VALUES.PHONE.ID}
        className={styles['form-item']}
      >
        {({ value, error, onChange }) => (
          <Input
            value={value}
            placeholder=""
            size="large"
            error={error}
            readonly
            spaceForError="none"
            onChange={onChange}
            label={APPLICATION_FORM_VALUES.PHONE.LABEL}
          />
        )}
      </FormItem>
    </div>
  </>
)
