import { URLS } from 'constants/urls'
import { MENU_NAMES } from 'texts/menuNames'

export const headItems = ['Company ID', 'Company name', 'Country', 'Actions']

export const ACTION_ITEMS = [
  {
    value: MENU_NAMES.LOCATIONS.NAME,
    URL: `../..${URLS.LOCATIONS}${URLS.LIST_OF_LOCATIONS}`,
  },
  {
    value: MENU_NAMES.FLEET.SUBMENU_NAMES.VEHICLES,
    URL: `../..${URLS.FLEET}${URLS.VEHICLES}`,
  },
  {
    value: MENU_NAMES.FLEET.SUBMENU_NAMES.EQUIPMENTS,
    URL: `../..${URLS.FLEET}${URLS.EQUIPMENTS}`,
  },
  {
    value: MENU_NAMES.FLEET.SUBMENU_NAMES.PRICE_LISTS,
    URL: `../..${URLS.FLEET}${URLS.PRICE_LIST}`,
  },
  {
    value: MENU_NAMES.FLEET.SUBMENU_NAMES.STOP_SALES,
    URL: `../..${URLS.FLEET}${URLS.STOP_SALES}`,
  },
  {
    value: MENU_NAMES.TERMS_AND_CONDITIONS,
    URL: `../..${URLS.PROFILE}${URLS.TERMS_AND_CONDITIONS}`,
  },
]
