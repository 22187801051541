import { useEffect, useMemo, useState } from 'react'
import { ReferencesApiTypes, fleetApi, referencesApi } from 'api'
import { Input } from 'ui/atoms/Input'
import { Select } from 'ui/atoms/Select'
import { ItemType } from 'ui/atoms/Select/types'
import { Form } from 'ui/molecules/Form'
import { FormItem } from 'ui/molecules/Form/components/FormItem'
import { Button } from 'ui/atoms/Button'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppSelector } from 'redux/hooks'
import { joinClasses } from 'utils/joinClasses'
import { supplierIdSelector } from 'redux/common/selectors'
import { useSelectValues } from 'hooks/useSelectValues'
import { useDispatch } from 'react-redux'
import { setFleetErrorMessage } from 'redux/fleet/slice'
import { ErrorResponse } from 'api/types'
import {
  FormValues,
  VehicleDetailsData,
  VehicleDetailsFormProps,
  OnChangeType,
  VehicleData,
} from './types'
import {
  VEHICLE_DETAILS,
  VEHICLE_DETAILS_FORM_VALUES,
} from 'texts/vehicleDetails'
import {
  initialFormValues,
  SIPP_CODE_LENGTH,
  VEHICLE_DETAILS_VALIDATION_RULES,
} from './constants'
import { SHORT_NOT_AVAILABLE, NOT_AVAILABLE } from '../../constants'
import { ContentWrapper } from 'ui/atoms/ContentWrapper'
import { Multiselect } from 'ui/atoms/Multiselect'
import { AirConditioning, Fuel, Transmission } from 'api/fleet/types'
import { useApiRequest } from 'hooks/useApiRequest'
import { useDidUpdate } from 'hooks/useDidUpdate'
import { DriveAgeRanges } from 'ui/components/DriverAgeRanges'
import { useDriverAgeRangeValidation } from 'ui/components/DriverAgeRanges/hooks/useDriverAgeRangeValidation'
import styles from './styles.module.scss'

export const VehicleDetailsForm = ({
  handleSubmit,
  formValues,
  isLoading,
}: VehicleDetailsFormProps) => {
  const {
    carGroupId,
    companyClass,
    sippCode,
    car,
    hirePoints,
    airConditioning,
    transmission,
    fuel,
    sits,
    doors,
    driverAgeRange,
  } = initialFormValues
  const params = useParams()
  const [vehicleDetailsData, setVehicleDetailsData] =
    useState<VehicleDetailsData>({
      carGroups: [],
      cars: [],
    })
  const [isFetching, setIsFetching] = useState(false)
  const [locations, setLocations] = useState<ReferencesApiTypes.Location[]>([])
  const [sipp, setSipp] = useState('')
  const [formKey, setFormKey] = useState(0)
  const [seatsList, setSeatsList] = useState<ItemType[]>([])
  const [doorsList, setDoorsList] = useState<ItemType[]>([])
  const [vehicleData, setVehicleData] = useState<VehicleData>()
  const [formData, setFormData] = useState({})
  const [driverAgeError, setDriverAgeError] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const supplierCompanyId = useAppSelector(supplierIdSelector)

  const supplierId = supplierCompanyId || (params.companyId as string)

  const fetchVehicleBySippResponse = useApiRequest((sipp) =>
    fleetApi.getVehicleInfoBySipp(sipp)
  )

  const { apiRequest: carSeatsRequest, loading: seatLoading } = useApiRequest(
    () => referencesApi.getCarSeats()
  )

  const { apiRequest: carDoorsRequest, loading: doorsLoading } = useApiRequest(
    () => referencesApi.getCarDoors()
  )

  const handleOnChange = ({ onChange, value, key }: OnChangeType) => {
    onChange(value)
    if (key) {
      setFormData((prev) => ({ ...prev, [key]: value }))
    }
  }

  useEffect(() => {
    if (supplierId) {
      const fetchLocations = async () => {
        const locations = await referencesApi.getAllCompanyHirePoints(
          String(supplierId)
        )
        setLocations(locations.data.items)
      }
      fetchLocations()
    }
  }, [supplierId])

  useEffect(() => {
    Promise.all([referencesApi.getAllCarGroups(), referencesApi.getAllCars()])
      .then((items) => {
        setVehicleDetailsData((prev) => ({
          ...prev,
          carGroups: items[0].data.items,
          cars: items[1].data.items,
        }))
        setIsFetching(true)
      })
      .catch((error) =>
        dispatch(
          setFleetErrorMessage((error as ErrorResponse).responseStatus.message)
        )
      )
  }, [])

  const replaceShortNotAvailable = (value?: string) =>
    value === NOT_AVAILABLE ? SHORT_NOT_AVAILABLE : value

  const replaceNotAvailable = (value?: string) =>
    value === SHORT_NOT_AVAILABLE ? NOT_AVAILABLE : value

  const checkDriverAgeRange = useDriverAgeRangeValidation(false)

  const onSubmit = (data: Partial<FormValues>, validate: boolean) => {
    let driverAgeError
    const isFilledFieldExist = data.driverAgeRange?.items.some(
      ({ ageStart, ageEnd, price, maxPrice }) =>
        ageStart || ageEnd || price || maxPrice
    )
    if (isFilledFieldExist) {
      driverAgeError = checkDriverAgeRange(data.driverAgeRange)
      if (driverAgeError) {
        setDriverAgeError(driverAgeError)
      }
    }

    if (validate && !driverAgeError) {
      handleSubmit({
        ...data,
        driverAgeRange: isFilledFieldExist ? data.driverAgeRange : undefined,
        carGroupId: Number(data.carGroupId),
        carId: Number(data.car),
        hirePoints: data.hirePoints?.map((el) => Number(el?.value)),
        airConditioning: replaceShortNotAvailable(data.airConditioning),
        fuel: replaceShortNotAvailable(data.fuel),
        transmission: replaceShortNotAvailable(data.transmission),
      })
    }
  }

  const carGroupsSelectValues = useSelectValues(vehicleDetailsData.carGroups)

  const carsSelectValues = useSelectValues(vehicleDetailsData.cars)

  const locationsSelectValues = useSelectValues(locations)

  const initValues = useMemo(() => {
    const initData = {
      carGroupId:
        vehicleData?.carGroupId || formValues?.carGroupId || carGroupId,
      companyClass:
        vehicleData?.companyClass || formValues?.companyClass || companyClass,
      sippCode: sipp || formValues?.sippCode || sippCode,
      car:
        vehicleDetailsData.cars.find(
          (el) =>
            Number(el.id) === Number(vehicleData?.car || formValues?.carId)
        )?.id || car,
      hirePoints:
        vehicleData?.hirePoints
          ?.map((el) =>
            locations.find(
              (location) => Number(location.id) === Number(el?.value)
            )
          )
          .map((el) => ({ label: el?.name, value: el?.id })) ||
        formValues?.hirePoints
          ?.map((el) =>
            locations.find((location) => Number(location.id) === Number(el))
          )
          .map((el) => ({ label: el?.name, value: el?.id })) ||
        hirePoints,
      airConditioning:
        replaceNotAvailable(vehicleData?.airConditioning) ||
        replaceNotAvailable(formValues?.airConditioning) ||
        airConditioning,
      transmission:
        replaceNotAvailable(vehicleData?.transmission) ||
        replaceNotAvailable(formValues?.transmission) ||
        transmission,
      fuel:
        replaceNotAvailable(vehicleData?.fuel) ||
        replaceNotAvailable(formValues?.fuel) ||
        fuel,
      sits: vehicleData?.sits || formValues?.sits || sits,
      doors: vehicleData?.doors || formValues?.doors || doors,
      driverAgeRange:
        vehicleData?.driverAgeRange ||
        formValues?.driverAgeRange ||
        driverAgeRange,
    }
    return initData
  }, [formValues, vehicleDetailsData, vehicleData])

  useEffect(() => {
    if (sipp) {
      const fetchVehicleInfo = async () => {
        const response = await fetchVehicleBySippResponse.apiRequest(sipp)
        if (response) {
          setVehicleData({ ...formData, ...response.data })
        }
      }
      fetchVehicleInfo()
    }
  }, [sipp])

  useEffect(() => {
    const fetchSeatsList = async () => {
      const response = await carSeatsRequest()
      if (response) {
        setSeatsList(
          response.data.items.map((item) => ({
            value: item.name,
            label: item.name,
          }))
        )
      }
    }
    const fetchDoorsList = async () => {
      const response = await carDoorsRequest()
      if (response) {
        setDoorsList(
          response.data.items.map((item) => ({
            value: item.name,
            label: item.name,
          }))
        )
      }
    }
    fetchDoorsList()
    fetchSeatsList()
  }, [])

  useDidUpdate(() => {
    setFormKey((prev) => prev + 1)
  }, [initValues])

  return isFetching ? (
    <Form
      initValues={initValues}
      key={formKey}
      onSubmit={onSubmit}
      className={styles.form}
    >
      {({ isDirty }) => (
        <>
          <ContentWrapper
            title={VEHICLE_DETAILS.FORM_TITLE}
            className={styles.wrapper}
          >
            <>
              <div className={styles['form-items-row']}>
                <FormItem
                  id={VEHICLE_DETAILS_FORM_VALUES.CAR.ID}
                  className={styles['form-item']}
                  rules={VEHICLE_DETAILS_VALIDATION_RULES.CAR}
                >
                  {({ value, error, onChange }) => (
                    <Select
                      selectedValue={value}
                      items={carsSelectValues}
                      placeholder={VEHICLE_DETAILS_FORM_VALUES.CAR.PLACEHOLDER}
                      size="large"
                      error={error}
                      onChange={(newValue) =>
                        handleOnChange({
                          value: newValue,
                          onChange,
                          key: VEHICLE_DETAILS_FORM_VALUES.CAR.ID,
                        })
                      }
                      isRequired
                      label={VEHICLE_DETAILS_FORM_VALUES.CAR.LABEL}
                      search={{
                        placeholder:
                          VEHICLE_DETAILS_FORM_VALUES.CAR.SEARCH_PLACEHOLDER,
                      }}
                    />
                  )}
                </FormItem>
                <FormItem
                  id={VEHICLE_DETAILS_FORM_VALUES.COMPANY_CLASS.ID}
                  className={styles['form-item']}
                  rules={VEHICLE_DETAILS_VALIDATION_RULES.COMPANY_CLASS}
                >
                  {({ value, error, onChange }) => (
                    <Input
                      size="large"
                      value={value}
                      error={error}
                      placeholder={
                        VEHICLE_DETAILS_FORM_VALUES.COMPANY_CLASS.PLACEHOLDER
                      }
                      onChange={(newValue) =>
                        handleOnChange({
                          value: newValue,
                          onChange,
                          key: VEHICLE_DETAILS_FORM_VALUES.COMPANY_CLASS.ID,
                        })
                      }
                      spaceForError="none"
                      isRequired
                      label={VEHICLE_DETAILS_FORM_VALUES.COMPANY_CLASS.LABEL}
                    />
                  )}
                </FormItem>
              </div>
              <div className={styles['form-items-row']}>
                <FormItem
                  id={VEHICLE_DETAILS_FORM_VALUES.SIPP.ID}
                  className={styles['form-item']}
                  rules={VEHICLE_DETAILS_VALIDATION_RULES.SIPP}
                >
                  {({ value, error, onChange }) => (
                    <Input
                      value={value}
                      placeholder={VEHICLE_DETAILS_FORM_VALUES.SIPP.PLACEHOLDER}
                      size="large"
                      error={error}
                      spaceForError="none"
                      isRequired
                      onChange={(value) => {
                        const upperCaseValue = value.toUpperCase()
                        onChange(upperCaseValue)
                        if (value.length === SIPP_CODE_LENGTH) {
                          setSipp(upperCaseValue)
                        }
                      }}
                      label={VEHICLE_DETAILS_FORM_VALUES.SIPP.LABEL}
                    />
                  )}
                </FormItem>
                <FormItem
                  id={VEHICLE_DETAILS_FORM_VALUES.CAR_GROUP.ID}
                  className={styles['form-item']}
                  rules={VEHICLE_DETAILS_VALIDATION_RULES.CAR_GROUP}
                >
                  {({ value, error, onChange }) => (
                    <Select
                      items={carGroupsSelectValues}
                      placeholder={
                        VEHICLE_DETAILS_FORM_VALUES.CAR_GROUP.PLACEHOLDER
                      }
                      size="large"
                      selectedValue={String(value)}
                      error={error}
                      isRequired
                      onChange={onChange}
                      label={VEHICLE_DETAILS_FORM_VALUES.CAR_GROUP.LABEL}
                      search={{
                        placeholder:
                          VEHICLE_DETAILS_FORM_VALUES.CAR_GROUP
                            .SEARCH_PLACEHOLDER,
                      }}
                    />
                  )}
                </FormItem>
              </div>
              <div className={styles['form-items-row']}>
                <FormItem
                  id={VEHICLE_DETAILS_FORM_VALUES.HIRE_POINTS.ID}
                  className={styles['location-multiselect']}
                  rules={VEHICLE_DETAILS_VALIDATION_RULES.HIRE_POINTS}
                >
                  {({ value, error, onChange }) => (
                    <Multiselect
                      value={value}
                      onChange={(newValue) =>
                        handleOnChange({
                          value: newValue,
                          onChange,
                          key: VEHICLE_DETAILS_FORM_VALUES.HIRE_POINTS.ID,
                        })
                      }
                      placeholder={
                        VEHICLE_DETAILS_FORM_VALUES.HIRE_POINTS.PLACEHOLDER
                      }
                      isRequired
                      error={error}
                      unitName={{
                        plural: VEHICLE_DETAILS.SEARCH_VALUE,
                      }}
                      label={VEHICLE_DETAILS_FORM_VALUES.HIRE_POINTS.LABEL}
                      items={locationsSelectValues}
                    />
                  )}
                </FormItem>
              </div>
              <div className={styles['form-items-row']}>
                <FormItem
                  id={VEHICLE_DETAILS_FORM_VALUES.AIR_CONDITIONING.ID}
                  className={styles['form-item']}
                >
                  {({ onChange, value }) => (
                    <Select
                      selectedValue={value}
                      items={Object.values(AirConditioning).map((el) => ({
                        value: el,
                        label: el,
                      }))}
                      placeholder=""
                      size="large"
                      onChange={onChange}
                      label={VEHICLE_DETAILS_FORM_VALUES.AIR_CONDITIONING.LABEL}
                    />
                  )}
                </FormItem>
                <FormItem
                  id={VEHICLE_DETAILS_FORM_VALUES.TRANSMISSION.ID}
                  className={styles['form-item']}
                >
                  {({ onChange, value }) => (
                    <Select
                      selectedValue={value}
                      items={Object.values(Transmission).map((el) => ({
                        value: el,
                        label: el,
                      }))}
                      placeholder=""
                      size="large"
                      onChange={onChange}
                      label={VEHICLE_DETAILS_FORM_VALUES.TRANSMISSION.LABEL}
                    />
                  )}
                </FormItem>
                <FormItem
                  id={VEHICLE_DETAILS_FORM_VALUES.FUEL.ID}
                  className={styles['form-item']}
                >
                  {({ onChange, value }) => (
                    <Select
                      selectedValue={value}
                      items={Object.values(Fuel).map((el) => ({
                        value: el,
                        label: el,
                      }))}
                      placeholder=""
                      size="large"
                      onChange={onChange}
                      label={VEHICLE_DETAILS_FORM_VALUES.FUEL.LABEL}
                    />
                  )}
                </FormItem>
              </div>
              <div
                className={joinClasses(
                  styles['form-items-row'],
                  styles['form-items-seats-doors']
                )}
              >
                <FormItem
                  id={VEHICLE_DETAILS_FORM_VALUES.SEATS.ID}
                  className={styles['form-item']}
                >
                  {({ onChange, value }) => (
                    <Select
                      selectedValue={value}
                      items={seatsList}
                      placeholder=""
                      size="large"
                      loading={seatLoading}
                      onChange={onChange}
                      label={VEHICLE_DETAILS_FORM_VALUES.SEATS.LABEL}
                    />
                  )}
                </FormItem>
                <FormItem
                  id={VEHICLE_DETAILS_FORM_VALUES.DOORS.ID}
                  className={styles['form-item']}
                >
                  {({ onChange, value }) => (
                    <Select
                      selectedValue={value}
                      items={doorsList}
                      placeholder=""
                      loading={doorsLoading}
                      size="large"
                      onChange={onChange}
                      label={VEHICLE_DETAILS_FORM_VALUES.DOORS.LABEL}
                    />
                  )}
                </FormItem>
              </div>
            </>
          </ContentWrapper>
          <ContentWrapper
            title={VEHICLE_DETAILS.DRIVER_AGE_RANGE_TITLE}
            className={styles.wrapper}
          >
            <FormItem id={VEHICLE_DETAILS_FORM_VALUES.DRIVER_AGE_RANGE.ID}>
              {({ onChange, value }) => (
                <DriveAgeRanges
                  driverAgeRangeData={value}
                  error={driverAgeError}
                  onDriverAgeRangeDataChange={(newValue) => {
                    setDriverAgeError('')
                    handleOnChange({
                      value: newValue,
                      onChange,
                      key: VEHICLE_DETAILS_FORM_VALUES.DRIVER_AGE_RANGE.ID,
                    })
                  }}
                />
              )}
            </FormItem>
          </ContentWrapper>
          <div className={styles.buttons}>
            <div className={styles['button-wrapper']}>
              <Button
                variant="outline"
                onClick={() => navigate(-1)}
                size="large"
                label={VEHICLE_DETAILS.BUTTONS.CANCEL_BUTTON_LABEL}
              />
            </div>
            <div className={styles['button-wrapper']}>
              <Button
                htmlType="submit"
                size="large"
                label={VEHICLE_DETAILS.BUTTONS.SAVE_BUTTON_LABEL}
                loading={isLoading}
                disabled={!(isDirty || sipp)}
              />
            </div>
          </div>
        </>
      )}
    </Form>
  ) : null
}
