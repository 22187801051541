import { SignInForm } from 'modules/auth/Signin/SigninForm'
import { WelcomeBlockView } from 'modules/auth/WelcomeBlockView'
import { Header } from 'modules/auth/WelcomeBlockView/components/Header'
import { AuthLayout } from 'layouts/AuthLayout'
import { WELCOME_BLOCK } from 'texts/welcomeBlock'

export const SignInPage = () => (
  <AuthLayout
    WelcomeBlock={
      <WelcomeBlockView
        Header={() => (
          <Header
            title={WELCOME_BLOCK.HEADER.SIGN_IN.TITLE}
            markTitle={WELCOME_BLOCK.HEADER.SIGN_IN.MARK_TITLE}
          />
        )}
      />
    }
    Form={<SignInForm />}
  />
)
