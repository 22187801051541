import React, {
  useMemo,
  useState,
  useRef,
  ReactNode,
  useEffect,
  useCallback,
} from 'react'
import { createPortal } from 'react-dom'
import { GLOBAL_MODAL } from 'constants/DOM'
import { joinClasses } from 'utils/joinClasses'
import { FilterItem } from './FilterItem/FilterItem'
import { FILTER, SEARCH } from 'texts/uiTexts'
import { useOutsideClick } from 'ui/hooks/useOutsideClick'
import { FilterProps } from './types'
import {
  FilterIcon,
  SortAscendingIcon,
  SortDescendingIcon,
  SortIcon,
} from 'ui/icons'
import { Input, Checkbox, Typography } from '@frontend/design_system'
import { getMatch } from 'utils/match'
import { ResetIcon } from 'ui/icons/ResetIcon'
import { resetSorting } from 'redux/activityHistory/slice'
import { useDispatch } from 'react-redux'
import { ASCENDING, DESCENDING } from 'texts/common'
import styles from './styles.module.scss'
import { ACTIVITY_HISTORY } from 'texts/activityHistory'

export const Filter = ({
  items,
  onSelectAllItems,
  listItemWidth,
  onReset,
  withSearch,
  selectAllLabel = FILTER.SELECT_ALL,
  withSorting,
  withFiltering = true,
  onSort,
  sortingType,
  isLastChild,
}: FilterProps) => {
  const [isFilterListOpen, setFilterListOpen] = useState(false)
  const filterIconRef = useRef<HTMLDivElement | null>(null)
  const [dropdownElement, setDropdownElement] = useState<HTMLDivElement | null>(
    null
  )
  const [isAllSelected, setAllSelected] = useState(false)
  const [searchedValue, setSearchedValue] = useState('')

  const dispatch = useDispatch()

  const handleClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation()
    setFilterListOpen((prev) => !prev)
  }, [])

  const handleSelectAll = (e: React.ChangeEvent | React.MouseEvent) => {
    e.stopPropagation()
    setAllSelected(!isAllSelected)
    onSelectAllItems?.(isAllSelected)
  }

  const handleClose = () => setFilterListOpen(false)

  useEffect(() => {
    setAllSelected(!items?.some((el) => !el.selected))
  }, [items])

  useOutsideClick(dropdownElement, handleClose, {
    enabled: true,
    additionalElements: filterIconRef?.current ? [filterIconRef.current] : [],
  })

  const isSelectedFilterExist = useMemo(
    () => !!items?.filter((el) => el.selected).length,
    [items]
  )

  const filteredItems = useMemo(
    () =>
      searchedValue
        ? items.filter((el) => getMatch(searchedValue, String(el.value)))
        : items,
    [searchedValue, items]
  )

  const handleReset = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      onReset?.()
    },
    [onReset]
  )

  const handleSortingReset = () => {
    dispatch(resetSorting())
  }

  const isDisabled = items?.length === 0

  const isOnlySorting = withSorting && !items?.length && !withSearch

  const renderDropdown = useCallback((children: ReactNode) => {
    const iconElement = filterIconRef.current?.getBoundingClientRect()

    if (!iconElement) {
      return null
    }

    let leftOffset

    const { left, top, height, width } = iconElement
    if (isLastChild) {
      const HALF = 2
      const halfItemWidth = listItemWidth
        ? parseInt(listItemWidth, 10) / HALF
        : 0
      leftOffset = left - halfItemWidth + width
    } else {
      leftOffset = left
    }

    return createPortal(
      <div
        style={{
          left: leftOffset,
          top: top + height,
        }}
        className={styles['dropdown-wrapper']}
      >
        {children}
      </div>,
      document.getElementById(GLOBAL_MODAL) as HTMLElement
    )
  }, [])

  useEffect(() => {
    let lastPosition = filterIconRef?.current?.getBoundingClientRect()

    const handleScrollOrResize = () => {
      if (filterIconRef.current) {
        const currentPosition = filterIconRef.current.getBoundingClientRect()
        if (
          lastPosition &&
          (currentPosition.top !== lastPosition.top ||
            currentPosition.left !== lastPosition.left)
        ) {
          setFilterListOpen(false)
        }
        lastPosition = currentPosition
      }
    }

    window.addEventListener('scroll', handleScrollOrResize, true)
    window.addEventListener('resize', handleScrollOrResize)

    return () => {
      window.removeEventListener('scroll', handleScrollOrResize, true)
      window.removeEventListener('resize', handleScrollOrResize)
    }
  }, [])

  const handleAsc = () => {
    handleSortingReset()
    onSort?.(ASCENDING)
  }

  const handleDesc = () => {
    onSort?.(DESCENDING)
  }

  return (
    <div className={styles.filter} role="button">
      {withSorting && (
        <>
          {!sortingType && <SortIcon onClick={handleAsc} />}
          {sortingType === ASCENDING && (
            <SortAscendingIcon onClick={handleDesc} />
          )}
          {sortingType === DESCENDING && (
            <SortDescendingIcon onClick={handleSortingReset} />
          )}
        </>
      )}
      {withFiltering && (
        <div
          className={styles['filter-icon']}
          ref={filterIconRef}
          onClick={isDisabled ? undefined : handleClick}
        >
          <FilterIcon
            color={isDisabled ? 'grey500' : 'blue700'}
            className={joinClasses(
              [styles['filter-active'], isFilterListOpen],
              [styles.disabled, isDisabled]
            )}
          />
          {isSelectedFilterExist && <div className={styles.selected} />}
        </div>
      )}
      {isFilterListOpen && (!!items?.length || withSorting) && (
        <>
          {renderDropdown(
            <div
              className={joinClasses(styles['list-item'], [
                styles['only-sorting'],
                isOnlySorting,
              ])}
              style={{ width: listItemWidth }}
              ref={setDropdownElement}
            >
              {withSearch && withFiltering && (
                <div className={styles.search}>
                  <Input
                    onChange={setSearchedValue}
                    value={searchedValue}
                    iconLeft="SearchIcon"
                    iconColor="grey500"
                    iconSize="small"
                    size="medium"
                    spaceForError="none"
                    placeholder={SEARCH}
                    onClick={(e: React.MouseEvent) => e.stopPropagation()}
                  />
                </div>
              )}
              {withFiltering && (
                <div className={styles['filter-items']}>
                  {!!filteredItems.length && !searchedValue && (
                    <div
                      className={joinClasses(styles['select-all'], [
                        styles.checked,
                        isAllSelected,
                      ])}
                      onClick={handleSelectAll}
                    >
                      <div className={styles['checkbox-wrapper']}>
                        <Checkbox
                          onChange={handleSelectAll}
                          checked={isAllSelected}
                          type="checkbox"
                          borderVariant="grey"
                          label={
                            <Typography
                              className={styles['checkbox-content']}
                              name="Button2"
                              color={isAllSelected ? 'blue700' : 'blackMaster'}
                            >
                              Select {selectAllLabel}
                            </Typography>
                          }
                        />
                      </div>
                    </div>
                  )}
                  {filteredItems.length ? (
                    filteredItems.map((el, i) => (
                      <FilterItem
                        key={i}
                        selected={el.selected}
                        counter={el.counter}
                        value={el.value}
                        handleSelect={el.onClick}
                      />
                    ))
                  ) : (
                    <Typography
                      name="Button2"
                      color="blueSecondary"
                      className={styles['not-found']}
                    >
                      Not found...
                    </Typography>
                  )}
                </div>
              )}
              {isSelectedFilterExist && (
                <Typography
                  Tag="div"
                  color="blue700"
                  className={styles['reset-button']}
                  onClick={handleReset}
                >
                  <ResetIcon color="blue700" size="small" />
                  {ACTIVITY_HISTORY.RESET}
                </Typography>
              )}
            </div>
          )}
        </>
      )}
    </div>
  )
}
