import { useState, useEffect } from 'react'
import { TimerProps } from './types'
import { secondToTimer } from 'utils/secondToTimer'

const SECOND = 1000

export const Timer = ({ children, seconds, renderLabel }: TimerProps) => {
  const [counter, setCounter] = useState(seconds)
  const [shown, setShown] = useState(true)

  useEffect(() => {
    let timeoutId: NodeJS.Timeout

    if (counter) {
      setShown(false)
      timeoutId = setTimeout(() => {
        setCounter((prevCounter: number) => prevCounter - 1)
      }, SECOND)
    } else {
      setShown(true)
    }

    return () => {
      clearTimeout(timeoutId)
    }
  }, [counter])

  return shown ? children : <div>{renderLabel(secondToTimer(counter))}</div>
}
