import { LocationsApiTypes } from 'api'
import { WeekDay } from './components/WeekDay/WeekDay'
import { WorkingHoursFormProps } from './types'
import { FormAlertLabel } from 'ui/atoms/FormAlertLabel'
import { forwardRef } from 'react'

export const WorkingHoursForm = forwardRef<
  HTMLDivElement,
  WorkingHoursFormProps
>(({ onWorkingHoursChange, workingHours, error }, ref) => {
  const handleChange = (
    index: number,
    hours: LocationsApiTypes.WorkingHour
  ) => {
    const copiedWorkingHours = [...workingHours]
    copiedWorkingHours.splice(index, 1, hours)
    onWorkingHoursChange(copiedWorkingHours)
  }

  const onBlur = (hours: LocationsApiTypes.WorkingHour) => {
    const copiedWorkingHours = [...workingHours]
    const a = copiedWorkingHours.map((workingHour, i) => {
      const SATURDAY_INDEX = 5
      if (i < SATURDAY_INDEX) {
        return {
          ...workingHour,
          dayStart: workingHour.dayStart || hours.dayStart,
          dayEnd: workingHour.dayEnd || hours.dayEnd,
          dayStart2: workingHour.dayStart2 || hours.dayStart2,
          dayEnd2: workingHour.dayEnd2 || hours.dayEnd2,
        }
      } else {
        return workingHour
      }
    })
    onWorkingHoursChange(a)
  }

  return (
    <div ref={ref}>
      {workingHours.map((day, i) => {
        const isMonday = i === 0
        return (
          <WeekDay
            workingHours={day}
            key={i}
            day={day.dayOfWeek}
            onBlur={isMonday ? () => onBlur(day) : undefined}
            handleChange={(hours) => handleChange(i, hours)}
          />
        )
      })}
      {error && <FormAlertLabel label={error} position="static" type="error" />}
    </div>
  )
})
