import { TYPOGRAPHY_COLORS } from 'constants/TypographyColors'
import { SVGIcon } from './SvgIcon'
import { IconProps } from './types'

export const ArrowUpIcon = ({
  color = 'grey400',
  size,
  className,
  rotate,
}: IconProps) => (
  <SVGIcon
    color={color}
    viewBox="0 0 20 20"
    size={size}
    className={className}
    rotate={rotate}
  >
    <path
      d="M15.8334 13.4167L10 7.58341L4.16669 13.4167"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVGIcon>
)
