import { profileApi } from 'api'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { supplierIdSelector } from 'redux/common/selectors'
import { useAppSelector } from 'redux/hooks'
import { supplierCompanyNameSelector } from 'redux/profile/selectors'
import { setCompanyName } from 'redux/profile/slice'

export const useCompanyInfo = (isOperator: boolean) => {
  const dispatch = useDispatch()
  const companyName = useSelector(supplierCompanyNameSelector)
  const params = useParams()
  const supplierCompanyId = useAppSelector(supplierIdSelector)

  const companyId = supplierCompanyId || params.companyId

  useEffect(() => {
    if (isOperator && companyId) {
      const getCompanyName = async () => {
        const companyNameResponse = await profileApi.getCompanyName(
          Number(companyId)
        )
        dispatch(setCompanyName(companyNameResponse.data.companyname || ''))
      }
      getCompanyName()
    }
  }, [isOperator, companyId])

  return { companyName, companyId }
}
