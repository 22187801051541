export const PUBLIC_URLS: { [key: string]: string } = {
  SIGNIN: '/signin',
  FORGOT_PASSWORD: '/forgot-password',
  REGISTER: '/register',
}

export const URLS: { [key: string]: string } = {
  ...PUBLIC_URLS,
  MAIN: '/main',
  PROFILE: '/profile',
  PROFILE_LIST: '/profile-list',
  DETAILS: '/details',
  PROFILE_ADD: '/profile-list/add',
  EDIT: ':companyId/:id/edit',
  DASHBOARD: '/dashboard',
  LOCATIONS: '/locations',
  LIST_OF_LOCATIONS: '/list-of-locations',
  ARCHIVE: '/archive',
  ACTIVITY_HISTORY: '/activity-history',
  RESTORE: '/archive',
  FLEET: '/fleet',
  VEHICLES: '/vehicles',
  STOP_SALES: '/stop-sales',
  EQUIPMENTS: '/equipments',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  APPLICATIONS: '/applications',
  APPLICATIONS_LIST: '/applications-list',
  APPLICATIONS_ARCHIVE: '/archive',
  APPLICATION: '/:status/:id',
  PRICE_LIST: '/price-list',
  ADD: '/add',
}
