import { RootState } from 'redux/store'

export const filterItemsSelector = (state: RootState) =>
  state.filtersReducer.filters

export const selectedFiltersSelector = (state: RootState) =>
  state.filtersReducer.selectedFilters

export const selectedFiltersCountSelector = (state: RootState) =>
  state.filtersReducer.selectedFiltersCount
