export const getMatch = (match: string, text?: string) => {
  if (!text) return ''

  const begin = text.toLowerCase().indexOf(match.toLowerCase())
  const end = match.length + begin

  return begin >= 0 && match.length ? text.substring(begin, end) : ''
}

export const boldMatch = (match: string, text?: string) => {
  if (!text) return ''

  const originalMatch = getMatch(match, text)

  return `${text.replace(originalMatch, `<b>${originalMatch}</b>`)}`
}
