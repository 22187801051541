export const mockApplicationsList = [
  // TODO: delete after backend implementation
  {
    user: 'Aertz',
    submission: '21.02.22 10:00',
    email: 'hertz.supplier@email.com',
    wbsite: 'https://hertz.com',
    processTime: '21.02.22 10:00',
    id: '1',
  },
  {
    user: 'Bertz',
    submission: '21.02.22 10:00',
    email: 'hertz.supplier@email.com',
    wbsite: 'https://hertz.com',
    processTime: '21.02.22 10:00',
    id: '1',
  },
  {
    user: 'Certz',
    submission: '21.02.22 10:00',
    email: 'hertz.supplier@email.com',
    wbsite: 'https://hertz.com',
    processTime: '21.02.22 10:00',
    id: '1',
  },
  {
    user: 'Dertz',
    submission: '21.02.22 10:00',
    email: 'hertz.supplier@email.com',
    wbsite: 'https://hertz.com',
    processTime: '21.02.22 10:00',
    id: '1',
  },
  {
    user: 'Fertz',
    submission: '21.02.22 10:00',
    email: 'hertz.supplier@email.com',
    wbsite: 'https://hertz.com',
    processTime: '21.02.22 10:00',
    id: '1',
  },
  {
    user: 'Hertz',
    submission: '21.02.22 10:00',
    email: 'hertz.supplier@email.com',
    wbsite: 'https://hertz.com',
    processTime: '21.02.22 10:00',
    id: '1',
  },
  {
    user: 'Jertz',
    submission: '21.02.22 10:00',
    email: 'hertz.supplier@email.com',
    wbsite: 'https://hertz.com',
    processTime: '21.02.22 10:00',
    id: '1',
  },
  {
    user: 'Mertz',
    submission: '21.02.22 10:00',
    email: 'hertz.supplier@email.com',
    wbsite: 'https://hertz.com',
    processTime: '21.02.22 10:00',
    id: '1',
  },
  {
    user: 'Qertz',
    submission: '21.02.22 10:00',
    email: 'hertz.supplier@email.com',
    wbsite: 'https://hertz.com',
    processTime: '21.02.22 10:00',
    id: '1',
  },
  {
    user: 'Zertz',
    submission: '21.02.22 10:00',
    email: 'hertz.supplier@email.com',
    wbsite: 'https://hertz.com',
    processTime: '21.02.22 10:00',
    id: '1',
  },
  {
    user: 'Pertz',
    submission: '21.02.22 10:00',
    email: 'hertz.supplier@email.com',
    wbsite: 'https://hertz.com',
    processTime: '21.02.22 10:00',
    id: '1',
  },
  {
    user: 'Hertz',
    submission: '21.02.22 10:00',
    email: 'hertz.supplier@email.com',
    wbsite: 'https://hertz.com',
    processTime: '21.02.22 10:00',
    id: '1',
  },
  {
    user: 'Hertz',
    submission: '21.02.22 10:00',
    email: 'hertz.supplier@email.com',
    wbsite: 'https://hertz.com',
    processTime: '21.02.22 10:00',
    id: '1',
  },
]
