export const SEND_CODE = {
  BUTTON: 'Send registration code',
  SEND: 'Send',
}

export const SEND_CODE_CONFIRMATION = {
  CONFIRMATION_MODAL: {
    TITLE: 'Send registration code?',
    QUESTION: 'Are you sure that you want to send a registration code to ',
    SUCCESS_MESSAGE: 'Registration code has been successfully sent.',
  },
}
