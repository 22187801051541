import { ReferencesApiTypes, referencesApi } from 'api'
import { useEffect, useState } from 'react'
import { ItemType } from 'ui/atoms/Select/types'
import { useSelectValues } from '../../../hooks/useSelectValues'
import { useCompanyInfo } from 'ui/hooks/useCompanyInfo'
import { useApiRequest } from 'hooks/useApiRequest'

export type Location = {
  [key: string]: {
    name: string
    id: string
    code?: string
  }
}

const EMPTY_ITEM = { name: '', id: '' }

export const useSelectLocation = (
  currentLocation: Location,
  changeLocation: (location: Location) => void,
  locationId?: string
) => {
  const [countries, setCountries] =
    useState<ReferencesApiTypes.CountriesResponse>({ items: [] })
  const [cities, setCities] = useState<ReferencesApiTypes.CitiesResponse>({
    items: [],
  })
  const [locations, setLocations] =
    useState<ReferencesApiTypes.LocationsResponse>({ items: [] })
  const { companyId } = useCompanyInfo(false)

  const availableCountriesRequest = useApiRequest(() =>
    referencesApi.getAvailableCountries(String(companyId), locationId)
  )

  const availableCitiesRequest = useApiRequest((countryId) =>
    referencesApi.getAvailableCities(String(companyId), countryId, locationId)
  )

  const availableLocationsRequest = useApiRequest((cityId) =>
    referencesApi.getAvailableLocations({
      cityId,
      companyId: String(companyId),
      currentLocationId: locationId,
    })
  )

  const handleChangeLocation = (type: string, value: ItemType) => {
    changeLocation({
      ...currentLocation,
      [type]: { name: value.label, id: value.value },
    })
  }

  useEffect(() => {
    const fetchCountries = async () => {
      const fetchCountriesResponse =
        await availableCountriesRequest.apiRequest()
      if (fetchCountriesResponse) {
        setCountries(fetchCountriesResponse.data)
      }
    }
    if (companyId) fetchCountries()
  }, [companyId])

  const fetchCities = async (countryId: number) => {
    const fetchCitiesResponse = await availableCitiesRequest.apiRequest(
      countryId
    )
    if (fetchCitiesResponse) {
      setCities(fetchCitiesResponse.data)
    }
  }

  const fetchLocations = async (cityId: string) => {
    const fetchLocationsResponse = await availableLocationsRequest.apiRequest(
      cityId
    )
    if (fetchLocationsResponse) {
      setLocations(fetchLocationsResponse.data)
    }
  }

  useEffect(() => {
    if (currentLocation.country.id) {
      fetchCities(Number(currentLocation.country.id))
    }

    if (currentLocation.city.id) {
      fetchLocations(currentLocation.city.id)
    }
  }, [currentLocation.country.id, currentLocation.city.id])

  const handleCountryChange = (country: ItemType) => {
    changeLocation({
      country: { name: country.label, id: country.value },
      city: EMPTY_ITEM,
      location: EMPTY_ITEM,
    })
    fetchCities(Number(country.value))
  }

  const handleCityChange = (city: ItemType) => {
    handleChangeLocation('city', city)
    fetchLocations(city.value)
  }

  const handleLocationChange = (location: ItemType) => {
    handleChangeLocation('location', location)
  }

  const countriesSelectValues = useSelectValues(countries.items)
  const citiesSelectValues = useSelectValues(cities.items)
  const locationsSelectValues = useSelectValues(locations.items)

  return {
    handleCountryChange,
    handleCityChange,
    handleLocationChange,
    countriesSelectValues,
    citiesSelectValues,
    locationsSelectValues,
    countries,
    loading: {
      countries: availableCountriesRequest.loading,
      cities: availableCitiesRequest.loading,
      locations: availableLocationsRequest.loading,
    },
  }
}
