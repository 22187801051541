import { TYPOGRAPHY_COLORS } from 'constants/TypographyColors'
import { SVGIcon } from './SvgIcon'
import { IconProps } from './types'

export const ResetIcon = ({ size, color = 'grey400', onClick }: IconProps) => (
  <SVGIcon size={size} color={color} viewBox="0 0 16 16" onClick={onClick}>
    <g clipPath="url(#clip0_8259_5036)">
      <path
        d="M8 4.28497V8.53069L10.7597 9.91054"
        stroke={TYPOGRAPHY_COLORS[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M14.7147 9.59214C13.9959 12.6349 11.2624 14.8993 7.99999 14.8993C4.18962 14.8993 1.10071 11.8104 1.10071 7.99999C1.10071 4.18962 4.18962 1.10071 7.99999 1.10071C10.6618 1.10071 12.9715 2.60805 14.1221 4.81571"
        stroke={TYPOGRAPHY_COLORS[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        fill="none"
      />
      <path
        d="M14.8993 2.69287V5.34644H12.2457"
        stroke={TYPOGRAPHY_COLORS[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </g>
    <defs>
      <clipPath id="clip0_8259_5036">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </SVGIcon>
)
