import { forwardRef } from 'react'
import { joinClasses } from 'utils/joinClasses'
import { ButtonProps } from './types'
import styles from './styles.module.scss'

export const Button = forwardRef<
  HTMLButtonElement & HTMLDivElement,
  ButtonProps
>(
  (
    {
      size = 'medium',
      label,
      onClick,
      variant = 'primary',
      typographyName = 'Body1',
      icon,
      iconPosition = 'left',
      alignLabel = 'center',
      noBorders,
      disabled,
      htmlType = 'button',
      loading,
      borderRadiusStyle,
      Tag = 'button',
      borderRadius = 'medium',
    },
    ref
  ) => {
    const className = joinClasses(
      styles['button'],
      styles[`button-size-${size}`],
      styles[`button-variant-${variant}`],
      styles[`button-align-${alignLabel}`],
      [styles[`button-noborders-${noBorders}`], noBorders],
      styles[typographyName],
      [styles['button-loading'], loading],
      [styles['button-disabled'], disabled],
      styles[`button-border-radius-${borderRadius}`]
    )
    const content = (
      <>
        {iconPosition === 'left' && icon && (
          <span className={styles['button-icon-left']}>{icon}</span>
        )}
        <span className={styles['button-label']}>{label}</span>
        {iconPosition === 'right' && icon && (
          <span className={styles['button-icon-right']}>{icon}</span>
        )}
        {loading && <div className={styles['button-loader']} />}
      </>
    )
    const commonAttrs = {
      ref,
      className,
      onClick,
      style: { borderRadius: borderRadiusStyle },
    }

    return (
      <Tag
        {...commonAttrs}
        type={Tag !== 'div' ? htmlType : undefined}
        disabled={disabled || loading}
      >
        {content}
      </Tag>
    )
  }
)
