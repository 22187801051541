import { APPLICATION_FORM_VALUES } from 'texts/applications'
import { Input } from 'ui/atoms/Input'
import { FormItem } from 'ui/molecules/Form/components/FormItem'
import { Multiselect } from 'ui/atoms/Multiselect'
import styles from './styles.module.scss'

export const Fleet = () => (
  <>
    <div className={styles['form-items-row']}>
      <FormItem
        id={APPLICATION_FORM_VALUES.VEHICLE_COUNT.ID}
        className={styles['form-item']}
      >
        {({ value, error, onChange }) => (
          <Input
            value={value}
            placeholder=""
            size="large"
            error={error}
            spaceForError="none"
            onChange={onChange}
            label={APPLICATION_FORM_VALUES.VEHICLE_COUNT.LABEL}
          />
        )}
      </FormItem>
      <FormItem
        id={APPLICATION_FORM_VALUES.AVERAGE_AGE.ID}
        className={styles['form-item']}
      >
        {({ value, error, onChange }) => (
          <Input
            value={value}
            placeholder=""
            size="large"
            error={error}
            spaceForError="none"
            onChange={onChange}
            label={APPLICATION_FORM_VALUES.AVERAGE_AGE.LABEL}
          />
        )}
      </FormItem>
    </div>
    <div className={styles['form-items-row']}>
      <FormItem
        id={APPLICATION_FORM_VALUES.VEHICLE_CLASSES.ID}
        className={styles['multiselect-item']}
      >
        {({ onChange }) => (
          <Multiselect
            onChange={onChange}
            items={[]}
            label={APPLICATION_FORM_VALUES.VEHICLE_CLASSES.LABEL}
            value={[]} // TODO: delete mock values
            readonly
          />
        )}
      </FormItem>
    </div>
  </>
)
