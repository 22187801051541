export const headItems = [
  'Country',
  'City',
  'Location',
  'Car class',
  'Created date',
  'Start date',
  'End date',
]

export const FILTER_KEY = 'year'
