import { useEffect, useRef, useState } from 'react'
import { removeSpaces } from '../utils'
import { RULE_TYPES } from '../constants'
import { FormProps } from '../types'
import { Rule } from '../components/FormItem/types'
import { mailRegex, onlyNumbersWithSymbolsRegex } from 'constants/regex'
import { StringObject } from 'types'

export const useValidate = ({
  isMountValidate,
  setErrors,
  values,
}: {
  isMountValidate?: boolean
  setErrors: (cb: (prevErrors: StringObject) => void) => void
  values: FormProps['initValues']
}) => {
  const isMountValidateRef = useRef(false)
  const [rules, setRules] = useState<Record<string, Rule[]>>({})
  const [isMountRules, setIsMountRules] = useState(false)

  const onValidate = (fieldId?: string) => {
    const newErrors: StringObject = {}
    const initValues = fieldId ? { [fieldId]: values[fieldId] } : values
    const updatedValues = removeSpaces(initValues)

    Object.entries(updatedValues).forEach(([id, value]) => {
      const valueRules = rules[id as keyof typeof rules]

      const checkRequiredRule = () =>
        !value ||
        (typeof value.trim === 'function' ? !value.trim() : false) ||
        (Array.isArray(value) && !value.length)

      if (valueRules) {
        const isRequiredRule = valueRules.some(
          ({ type }) => type === RULE_TYPES.required
        )

        for (let i = 0; i < valueRules.length; i++) {
          const rule = valueRules[i]

          const validationRules = (type: keyof typeof RULE_TYPES) => {
            if (type === RULE_TYPES.required) {
              return checkRequiredRule()
            }
            if (type === RULE_TYPES.email) {
              return !new RegExp(mailRegex).test(value)
            }
            if (type === RULE_TYPES.number) {
              return !new RegExp(onlyNumbersWithSymbolsRegex).test(value)
            }
            if (type === RULE_TYPES.min) {
              return rule.value && rule.value > value.length
            }
            if (type === RULE_TYPES.max) {
              return rule.value && rule.value < value.length
            }
            if (type === RULE_TYPES.equal) {
              return rule.value && value !== values[rule.value as number]
            }
            if (type === RULE_TYPES.pattern) {
              return !new RegExp(rule.value as RegExp).test(value)
            }
            if (type === RULE_TYPES.custom) {
              return typeof rule.value === 'function' ? rule.value(value) : null
            }
            return false
          }

          if ((isRequiredRule || value) && validationRules(rule.type)) {
            newErrors[id] = rule.message
            break
          }
        }
      }
    })

    setErrors((prevErrors) => {
      if (fieldId) {
        return {
          ...prevErrors,
          ...newErrors,
        }
      }
      return newErrors
    })

    return newErrors
  }

  useEffect(() => {
    // This hack for validating dynamic field during mounting
    if (!isMountValidateRef.current && isMountRules && isMountValidate) {
      onValidate()
      isMountValidateRef.current = true
    }

    return () => {}
  }, [isMountRules, rules])

  const updateRules = (
    newRules: (rules: Record<string, Rule[]>) => Record<string, Rule[]>
  ) => {
    setRules(newRules)
    setIsMountRules(true)
  }

  return {
    setRules: updateRules,
    onValidate,
  }
}
