import { useSelectValues } from 'hooks/useSelectValues'
import { LOCATION_ADDING_FORM_VALUES } from 'texts/locationDetails'
import { Input } from 'ui/atoms/Input'
import { Select } from 'ui/atoms/Select'
import { FormItem } from 'ui/molecules/Form/components/FormItem'
import { getValidationRules } from 'utils/getValidationRules'
import { ContactInformationFormProps } from './types'
import { useSelectLocation } from '../../../../hooks/useSelectLocation'
import countryOfResidence from 'constants/countryOfResidence'
import { Phone } from 'ui/molecules/Phone'
import { PHONE_NUMBER_LABELS } from 'texts/uiTexts'
import styles from './styles.module.scss'
import { ItemType } from 'ui/atoms/Select/types'
import { useMemo } from 'react'
import { CONTACT_INFORMATION_FORM_VALUES } from 'texts/profileDetails'
import { EMPTY_RULES } from 'constants/form'
import { Rule } from 'ui/molecules/Form/components/FormItem/types'
import { formValuesSetting } from './constants'
import { FormValuesSetting } from 'types/form'
import { PickupOptions } from 'api/locations/types'
import { Textarea } from '@frontend/design_system'
import { onlyNumbersWithOneDotRegex } from 'constants/regex'

export const ContactInformationForm = ({
  currentLocation,
  changeLocation,
  currencies,
  locationId,
}: ContactInformationFormProps) => {
  const currenciesSelectValues = useSelectValues(currencies.items)
  const pickUpOptionsSelectValues = useSelectValues(
    Object.entries(PickupOptions).map(([key, value]) => ({
      id: key,
      name: value,
    }))
  )
  const {
    handleCountryChange,
    handleCityChange,
    handleLocationChange,
    countriesSelectValues,
    citiesSelectValues,
    locationsSelectValues,
    countries,
    loading,
  } = useSelectLocation(currentLocation, changeLocation, locationId)

  const phoneCorRules: Rule[] = useMemo(
    () => [
      {
        type: 'required',
        message: CONTACT_INFORMATION_FORM_VALUES.PHONE_COR.REQUIRED_MESSAGE,
      },
    ],
    []
  )

  const validationRules = (field: FormValuesSetting['key']) =>
    useMemo(() => getValidationRules(field, 'operator'), [])

  const getCor = (country: ItemType) => {
    const countryCode = countries.items.find((el) => el.name === country.label)
      ?.meta?.CountryAlpha2Code
    const selectedCountry = countryOfResidence.find(
      (el) => el.code === countryCode
    )
    return selectedCountry
  }

  return (
    <>
      <div className={styles['form-items-row']}>
        <FormItem
          id={LOCATION_ADDING_FORM_VALUES.COUNTRY.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.countryId)}
        >
          {({ value, error, onChange, onChangeField }) => (
            <Select
              size="large"
              placeholder={LOCATION_ADDING_FORM_VALUES.COUNTRY.PLACEHOLDER}
              onChange={(el: string, item: ItemType) => {
                onChange(el)
                handleCountryChange(item)
                onChangeField(
                  getCor(item),
                  LOCATION_ADDING_FORM_VALUES.PHONE_COR.ID
                )
                onChangeField('', LOCATION_ADDING_FORM_VALUES.LOCATION.ID)
                onChangeField('', LOCATION_ADDING_FORM_VALUES.CITY.ID)
              }}
              className={styles.select}
              label={LOCATION_ADDING_FORM_VALUES.COUNTRY.LABEL}
              items={countriesSelectValues}
              error={error}
              selectedValue={value}
              isRequired
              search={{
                placeholder:
                  LOCATION_ADDING_FORM_VALUES.COUNTRY.SEARCH_PLACEHOLDER,
              }}
              loading={loading.countries}
            />
          )}
        </FormItem>
        <FormItem
          id={LOCATION_ADDING_FORM_VALUES.CITY.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.cityId)}
        >
          {({ value, error, onChange }) => (
            <Select
              size="large"
              placeholder={LOCATION_ADDING_FORM_VALUES.CITY.PLACEHOLDER}
              onChange={(el: string, item: ItemType) => {
                onChange(el)
                handleCityChange(item)
              }}
              className={styles.select}
              label={LOCATION_ADDING_FORM_VALUES.CITY.LABEL}
              items={citiesSelectValues}
              error={error}
              selectedValue={value}
              isRequired
              disabled={!currentLocation.country.name}
              search={{
                placeholder:
                  LOCATION_ADDING_FORM_VALUES.CITY.SEARCH_PLACEHOLDER,
              }}
              loading={loading.cities}
            />
          )}
        </FormItem>
      </div>
      <div className={styles['form-items-row']}>
        <FormItem
          id={LOCATION_ADDING_FORM_VALUES.LOCATION.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.locationId)}
        >
          {({ value, error, onChange }) => (
            <Select
              size="large"
              placeholder={LOCATION_ADDING_FORM_VALUES.LOCATION.PLACEHOLDER}
              onChange={(el: string, item: ItemType) => {
                onChange(el)
                handleLocationChange(item)
              }}
              className={styles.select}
              label={LOCATION_ADDING_FORM_VALUES.LOCATION.LABEL}
              items={locationsSelectValues}
              error={error}
              selectedValue={value}
              disabled={!currentLocation.city.name}
              isRequired
              search={{
                placeholder:
                  LOCATION_ADDING_FORM_VALUES.LOCATION.SEARCH_PLACEHOLDER,
              }}
              loading={loading.locations}
            />
          )}
        </FormItem>
        <FormItem
          id={LOCATION_ADDING_FORM_VALUES.STATE.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.state)}
        >
          {({ value, error, onChange }) => (
            <Input
              value={value}
              placeholder={LOCATION_ADDING_FORM_VALUES.STATE.PLACEHOLDER}
              size="large"
              error={error}
              spaceForError="auto"
              onChange={onChange}
              label={LOCATION_ADDING_FORM_VALUES.STATE.LABEL}
            />
          )}
        </FormItem>
      </div>
      <div className={styles['form-items-row']}>
        <FormItem
          id={LOCATION_ADDING_FORM_VALUES.STREET.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.street)}
        >
          {({ value, error, onChange }) => (
            <Input
              value={value}
              placeholder={LOCATION_ADDING_FORM_VALUES.STREET.PLACEHOLDER}
              size="large"
              error={error}
              spaceForError="auto"
              onChange={onChange}
              label={LOCATION_ADDING_FORM_VALUES.STREET.LABEL}
              isRequired
            />
          )}
        </FormItem>
        <FormItem
          id={LOCATION_ADDING_FORM_VALUES.BUILDING.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.building)}
        >
          {({ value, error, onChange }) => (
            <Input
              value={value}
              placeholder={LOCATION_ADDING_FORM_VALUES.BUILDING.PLACEHOLDER}
              size="large"
              error={error}
              spaceForError="auto"
              onChange={onChange}
              label={LOCATION_ADDING_FORM_VALUES.BUILDING.LABEL}
              isRequired
            />
          )}
        </FormItem>
      </div>
      <div className={styles['form-items-row']}>
        <FormItem
          id={LOCATION_ADDING_FORM_VALUES.LATITUDE.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.latitude)}
        >
          {({ value, error, onChange }) => (
            <Input
              value={value}
              placeholder={LOCATION_ADDING_FORM_VALUES.LATITUDE.PLACEHOLDER}
              size="large"
              error={error}
              spaceForError="auto"
              onChange={(value) =>
                onChange(value.replace(onlyNumbersWithOneDotRegex, ''))
              }
              label={LOCATION_ADDING_FORM_VALUES.LATITUDE.LABEL}
              isRequired
            />
          )}
        </FormItem>
        <FormItem
          id={LOCATION_ADDING_FORM_VALUES.LONGITUDE.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.longitude)}
        >
          {({ value, error, onChange }) => (
            <Input
              value={value}
              placeholder={LOCATION_ADDING_FORM_VALUES.LONGITUDE.PLACEHOLDER}
              size="large"
              error={error}
              spaceForError="auto"
              onChange={(value) =>
                onChange(value.replace(onlyNumbersWithOneDotRegex, ''))
              }
              label={LOCATION_ADDING_FORM_VALUES.LONGITUDE.LABEL}
              isRequired
            />
          )}
        </FormItem>
      </div>
      <div className={styles['form-items-row']}>
        <FormItem
          id={LOCATION_ADDING_FORM_VALUES.CURRENCY.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.currencyID)}
        >
          {({ value, error, onChange }) => (
            <Select
              size="large"
              placeholder={LOCATION_ADDING_FORM_VALUES.CURRENCY.PLACEHOLDER}
              error={error}
              onChange={onChange}
              label={LOCATION_ADDING_FORM_VALUES.CURRENCY.LABEL}
              items={currenciesSelectValues}
              selectedValue={String(value)}
              isRequired
            />
          )}
        </FormItem>
        <FormItem
          id={LOCATION_ADDING_FORM_VALUES.PHONE_NUMBER.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.phone)}
        >
          {({ value: number, onChange: changeNumber, error }) => (
            <FormItem
              id={LOCATION_ADDING_FORM_VALUES.PHONE_COR.ID}
              className={styles.phone}
              rules={number?.length ? phoneCorRules : EMPTY_RULES}
            >
              {({ value: cor, onChange, error: corError }) => (
                <Phone
                  label={LOCATION_ADDING_FORM_VALUES.PHONE_NUMBER.LABEL}
                  number={number}
                  changeNumber={changeNumber}
                  cor={cor}
                  changeCOR={onChange}
                  corList={countryOfResidence}
                  locales={{
                    ofLabel: PHONE_NUMBER_LABELS.OF,
                    searchPlaceholder: PHONE_NUMBER_LABELS.SEARCH,
                    save: PHONE_NUMBER_LABELS.SAVE,
                  }}
                  isRequired
                  spaceForError="auto"
                  error={error || corError}
                  placeholder={
                    LOCATION_ADDING_FORM_VALUES.PHONE_NUMBER.PLACEHOLDER
                  }
                />
              )}
            </FormItem>
          )}
        </FormItem>
      </div>
      <div className={styles['form-items-row']}>
        <FormItem
          id={LOCATION_ADDING_FORM_VALUES.POSTAL_CODE.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.postalCode)}
        >
          {({ value, error, onChange }) => (
            <Input
              value={value}
              placeholder={LOCATION_ADDING_FORM_VALUES.POSTAL_CODE.PLACEHOLDER}
              size="large"
              error={error}
              spaceForError="auto"
              onChange={onChange}
              label={LOCATION_ADDING_FORM_VALUES.POSTAL_CODE.LABEL}
              isRequired
            />
          )}
        </FormItem>
        <FormItem
          id={LOCATION_ADDING_FORM_VALUES.EMAIL.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.email)}
        >
          {({ value, error, onChange }) => (
            <Input
              value={value}
              placeholder={LOCATION_ADDING_FORM_VALUES.EMAIL.PLACEHOLDER}
              size="large"
              error={error}
              spaceForError="auto"
              onChange={onChange}
              label={LOCATION_ADDING_FORM_VALUES.EMAIL.LABEL}
              isRequired
            />
          )}
        </FormItem>
      </div>
      <div className={styles['form-items-row']}>
        <FormItem
          id={LOCATION_ADDING_FORM_VALUES.PICKUP_OPTION.ID}
          className={styles['form-item']}
        >
          {({ value, error, onChange }) => (
            <Select
              size="large"
              placeholder={LOCATION_ADDING_FORM_VALUES.CURRENCY.PLACEHOLDER}
              error={error}
              onChange={onChange}
              label={LOCATION_ADDING_FORM_VALUES.PICKUP_OPTION.LABEL}
              items={pickUpOptionsSelectValues}
              selectedValue={value}
            />
          )}
        </FormItem>
      </div>
      <FormItem
        id={LOCATION_ADDING_FORM_VALUES.SPECIAL_INSTRUCTIONS.ID}
        rules={validationRules(formValuesSetting.specialInstructions)}
        className={styles['form-item']}
      >
        {({ value, error, onChange }) => (
          <Textarea
            value={value}
            onChange={onChange}
            label={LOCATION_ADDING_FORM_VALUES.SPECIAL_INSTRUCTIONS.LABEL}
            placeholder={
              LOCATION_ADDING_FORM_VALUES.SPECIAL_INSTRUCTIONS.PLACEHOLDER
            }
            error={error}
          />
        )}
      </FormItem>
    </>
  )
}
