import { useEffect, useState } from 'react'
import { MenuItem } from 'ui/molecules/Menu/MenuItem'
import { SubmenuItem } from 'ui/molecules/Menu/SubmenuItem'
import {
  MENU_PATHS,
  OPERATOR_MENU_ITEMS,
  OPERATOR_SUBMENU_ITEMS,
  SUPPLIER_MENU_ITEMS,
  SUPPLIER_SUBMENU_ITEMS,
} from './constants'
import { useAppSelector } from 'redux/hooks'
import { userRoleSelector } from 'redux/login/selectors'
import { UserRoles } from 'constants/roles'
import { MenuItemsType } from './types'
import { useLocation } from 'react-router-dom'
import styles from './styles.module.scss'

export const Menu = () => {
  const [activeMenu, setActiveMenu] = useState('')
  const menuItemToUpperCase = (menuItem: MenuItemsType[0]) =>
    menuItem.name.toUpperCase()
  const role = useAppSelector(userRoleSelector)
  const location = useLocation()

  const MENU_CONFIG =
    role === UserRoles.Operator
      ? {
          MENU_ITEMS: OPERATOR_MENU_ITEMS,
          SUBMENU_ITEMS: OPERATOR_SUBMENU_ITEMS,
        }
      : {
          MENU_ITEMS: SUPPLIER_MENU_ITEMS,
          SUBMENU_ITEMS: SUPPLIER_SUBMENU_ITEMS,
        }

  useEffect(() => {
    const activeMenu = Object.entries(MENU_PATHS).find(([, value]) =>
      location.pathname.includes(value)
    )?.[0]

    setActiveMenu(activeMenu || '')
  }, [location.pathname])

  return (
    <nav className={styles.menu}>
      {role
        ? MENU_CONFIG.MENU_ITEMS.map((menuItem) => (
            <MenuItem
              name={menuItem.name}
              path={menuItem.path}
              key={menuItem.name}
              activeItem={activeMenu}
              icon={menuItem.icon}
              onMenuItemClick={setActiveMenu}
              withSubmenu={menuItem.withSubmenu}
            >
              {menuItem.withSubmenu
                ? MENU_CONFIG.SUBMENU_ITEMS[menuItemToUpperCase(menuItem)].map(
                    (submenuItem) => (
                      <SubmenuItem
                        path={submenuItem.path}
                        key={submenuItem.name}
                        name={submenuItem.name}
                        activeItem={activeMenu}
                        onMenuItemClick={setActiveMenu}
                      />
                    )
                  )
                : null}
            </MenuItem>
          ))
        : null}
    </nav>
  )
}
