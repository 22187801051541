import { TYPOGRAPHY_COLORS } from 'constants/TypographyColors'
import { SVGIcon } from './SvgIcon/SvgIcon'
import { IconProps } from './types'

export const ModalCloseIcon = ({
  size,
  color = 'grey400',
  className,
  onClick,
}: IconProps) => (
  <SVGIcon
    size={size}
    onClick={onClick}
    viewBox="0 0 16 16"
    color={color}
    className={className}
  >
    <path
      d="M12 4L4 12"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 4L12 12"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVGIcon>
)
