import { useEffect, useState } from 'react'
import { locationsApi, HTTPClientTypes } from 'api'
import { Modal } from 'ui/molecules/Modal'
import { AVAILABLE_VEHICLES_MODAL } from 'texts/locationList'
import { useDispatch } from 'react-redux'
import { setNotificationMessage } from 'redux/notifications/slice'
import { VehicleListModalProps, AvailableVehicleList } from './types'
import { Vehicle } from './components/Vehicle'
import styles from './styles.module.scss'

export const VehicleListModal = ({
  onClose,
  companyId,
  hirePointId,
}: VehicleListModalProps) => {
  const [availableVehicleList, setAvailableVehicleList] =
    useState<AvailableVehicleList>([])

  const dispatch = useDispatch()

  useEffect(() => {
    const fetchAvailableVehicleList = async () => {
      try {
        const availableVehicleListResponse =
          await locationsApi.getAvailableVehicleList(
            companyId,
            hirePointId,
            0,
            0
          )
        const availableVhicleListItems =
          availableVehicleListResponse.data.vehicles.pageItems
        setAvailableVehicleList(availableVhicleListItems)
      } catch (e) {
        dispatch(
          setNotificationMessage({
            notificationMessage: (e as HTTPClientTypes.ErrorResponse)
              .responseStatus.message,
            type: 'error',
          })
        )
      }
    }
    fetchAvailableVehicleList()
  }, [])

  return (
    <Modal
      position="center-horizontal"
      className={styles.modal}
      onClose={onClose}
      offsetVariant="none"
      title={AVAILABLE_VEHICLES_MODAL.TITLE}
      isDelimiter
      headerSize="large"
    >
      <div className={styles.content}>
        <div className={styles.list}>
          {availableVehicleList.map((vehicle, vehicleIndex) => (
            <Vehicle
              key={vehicleIndex}
              item={vehicle.name}
              imageSrc={vehicle.image}
            />
          ))}
        </div>
      </div>
    </Modal>
  )
}
