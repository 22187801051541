import { Typography } from 'ui/atoms/Typography'
import { forwardRef } from 'react'
import { joinClasses } from 'utils/joinClasses'
import { HtmlTypographyProps } from './types'
import styles from './styles.module.scss'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const HtmlTypography = forwardRef<any, HtmlTypographyProps>(
  (
    props,
    ref //TODO: add type for ref
  ) => (
    <Typography
      {...props}
      ref={ref}
      Tag={props.Tag || 'div'}
      className={joinClasses(
        props.className || '',
        styles['html-typography'],
        styles[`html-typography-variant-${props.variant}`]
      )}
    />
  )
)
