import { fleetApi, FleetApiTypes } from 'api'
import { breadcrumbs } from 'constants/breadcrumbs'
import { Pagination } from '@frontend/design_system'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from 'redux/hooks'
import { isOperatorSelector } from 'redux/login/selectors'
import { MENU_NAMES } from 'texts/menuNames'
import { Button } from 'ui/atoms/Button'
import { PlusIcon } from 'ui/icons'
import { Confirmation } from 'ui/molecules/Confirmation'
import { TableTooltip } from 'ui/molecules/TableTooltip'
import { Table } from 'ui/molecules/Table'
import { Equipment } from './types'
import {
  EQUIPMENTS,
  EQUIPMENTS_HEAD_ITEMS,
  EQUIPMENT_DELETE_CONFIRMATION,
} from 'texts/equipments'
import { useDispatch } from 'react-redux'
import { setNotificationMessage } from 'redux/notifications/slice'
import { Container } from 'ui/molecules/Container'
import { useCompanyInfo } from 'ui/hooks/useCompanyInfo'
import {
  DEFAULT_INITIAL_PAGE,
  DEFAULT_INITIAL_PAGE_SIZE,
} from 'constants/pagination'
import { PAGINATION_DROPDOWN_LABEL } from 'texts/uiTexts'
import { useApiRequest } from 'hooks/useApiRequest'
import { GTM } from 'utils/gtm'
import { DATA_LAYER_EVENT } from 'constants/dataLayerEvents'
import styles from './styles.module.scss'

export const Equipments = () => {
  const [page, setPage] = useState(DEFAULT_INITIAL_PAGE)
  const [pageSize, setPageSize] = useState(DEFAULT_INITIAL_PAGE_SIZE)
  const [equipmentsList, setEquipmentsList] =
    useState<FleetApiTypes.GetEquipmentsListResponse | null>(null)
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const [selectedEquipment, setSelectedEquipment] = useState<Equipment>()
  const isOperator = useAppSelector(isOperatorSelector)

  const { companyId } = useCompanyInfo(isOperator)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const deleteEquipmentResponse = useApiRequest((selectedEquipment) =>
    fleetApi.deleteEquipment(selectedEquipment.groupId)
  )

  const breadcrumbsList = [breadcrumbs.profileList]

  const handleChange = (pageNumber: number) => {
    setPage(pageNumber)
  }

  const changePageSize = (value: string) => {
    setPageSize(value)
    setPage(DEFAULT_INITIAL_PAGE)
  }

  const fetchEquipments = async (companyId: string | number) => {
    const equipmentsListResponse = await fleetApi.getEquipmetsList(
      page - 1,
      Number(pageSize),
      Number(companyId)
    )
    setEquipmentsList(equipmentsListResponse.data)
  }

  useEffect(() => {
    if (companyId) fetchEquipments(companyId)
  }, [page, pageSize, companyId])

  const handleDeleteEquipment = (equipment: Equipment) => {
    setConfirmationModalOpen(true)
    setSelectedEquipment(equipment)
  }

  const handleConfirm = async () => {
    if (selectedEquipment && companyId) {
      const response = await deleteEquipmentResponse.apiRequest(
        selectedEquipment
      )
      if (response) {
        dispatch(
          setNotificationMessage({
            notificationMessage:
              EQUIPMENT_DELETE_CONFIRMATION.SUCCESS_MODAL.TITLE,
          })
        )
        GTM.dataLayer({
          event: DATA_LAYER_EVENT.EQUIPMENT_REMOVED,
          equipment_name: selectedEquipment.name,
          value: selectedEquipment.count,
        })
        fetchEquipments(companyId)
        setConfirmationModalOpen(false)
      }
    }
  }

  const handleModalClose = () => {
    setConfirmationModalOpen(false)
  }

  const handleAddEquipment = () => navigate('add')

  const handleRowClick = (id: string) =>
    navigate(`../equipments/${companyId}/${id}/edit`)

  const formattedHeadItems = Object.values(EQUIPMENTS_HEAD_ITEMS).map(
    (header) => ({ value: header })
  )

  const formattedBodyItems = equipmentsList?.extras.pageItems?.map(
    (equipment) => ({
      items: [
        equipment.name,
        equipment.count,
        equipment.sum,
        equipment.maxSum ?? '-',
        <TableTooltip
          title={equipment.refersToLocation}
          onClick={() => handleRowClick(equipment.groupId)}
        />,
      ],
      onClick: () => handleRowClick(equipment.groupId),
      removeRowClick: () => handleDeleteEquipment(equipment),
    })
  )

  return (
    <>
      {isConfirmationModalOpen && (
        <Confirmation
          confirmModalProps={{
            question: `${EQUIPMENT_DELETE_CONFIRMATION.CONFIRMATION_MODAL.QUESTION} ${selectedEquipment?.name}?`,
            onConfirm: handleConfirm,
            onClose: handleModalClose,
            title: `${EQUIPMENT_DELETE_CONFIRMATION.CONFIRMATION_MODAL.DELETE} ${selectedEquipment?.name}?`,
          }}
        />
      )}
      <Container
        breadcrumbList={isOperator ? breadcrumbsList : []}
        title={MENU_NAMES.FLEET.SUBMENU_NAMES.EQUIPMENTS}
        currentPageLabel={MENU_NAMES.FLEET.SUBMENU_NAMES.EQUIPMENTS}
        tooltip={EQUIPMENTS.TOOLTIP_CONTENT}
        withNavigation={isOperator}
      >
        <div className={styles.list}>
          <div className={styles.header}>
            <div className={styles.button}>
              <Button
                size="small"
                typographyName="Button2"
                variant="outline"
                label={EQUIPMENTS.ADD_BUTTON}
                onClick={handleAddEquipment}
                iconPosition="right"
                icon={<PlusIcon color="blue700" />}
              />
            </div>
          </div>
          {formattedBodyItems && (
            <div className={styles.table}>
              <Table
                headItems={formattedHeadItems}
                bodyItems={formattedBodyItems}
              />
            </div>
          )}
        </div>
        <footer className={styles.pagination}>
          <Pagination
            current={page}
            pageItemCount={equipmentsList?.extras.pageItems.length}
            total={equipmentsList?.extras.totalItems || 0}
            pageSize={pageSize}
            changePageSize={changePageSize}
            changePage={handleChange}
            pageSizeLabel={PAGINATION_DROPDOWN_LABEL}
          />
        </footer>
      </Container>
    </>
  )
}
