export const ACTIVITY_HISTORY = {
  ACTIVITY_COUNTER: 'Activity counter: ',
  AUTOCOMPLETE_PLACEHOLDER: 'Find by company name',
  TITLE: 'History details - Type of activity:',
  GO_TO_RATES: 'Go to prices',
  TIMESTAMP: 'Time stamp:',
  RESET_ALL: 'Reset all',
  PRICE_LIST_ID: 'Price list ID: ',
  RESET: 'Reset',
}

export const listHeadItems = [
  'User',
  'Company name',
  'Category',
  'Element',
  'Element ID',
  'Type of activity',
  'Time stamp',
]

export const detailsHeadItems = ['Parametr', 'Old', 'New']

export const ACTIVITY_HISTORY_FIELDS: { [key: string]: string } = {
  city: 'City',
  logo: 'Logo',
  email: 'E-mail',
  phone: 'Phone number',
  state: 'State',
  markUp: 'Mark up',
  rating: 'Rating',
  street: 'Street',
  building: 'Building',
  isActive: 'Active',
  companyID: 'Company id',
  legalName: 'Legal name',
  swiftCode: 'SWIFT code',
  vatNumber: 'VAT number',
  hasAccount: 'Has account',
  postalCode: 'Postal code',
  companyName: 'Company name',
  reviewCount: 'Review count',
  companyCountryID: 'Company country id',
  bankAccountNumber: 'Bank account number',
  bankName: 'Bank name',
  url: 'URL',
  bankAddress: 'Bank address',
  abaRoutingNumber: 'ABA Routing number',
  accountingEmailAddress: 'Accounting email address',
  registrationNumber: 'Registration number',
  companyCurrencyID: 'Company currency id',
  companyCountryName: 'Company country name',
  companyCurrencyName: 'Company currency name',
  phoneCountryCode: 'Phone country code',
  id: 'Id',
  year: 'Year',
  comment: 'Comment',
  hirePoints: 'Hire points',
  dailyRanges: 'Daily ranges',
  seasonRanges: 'Season ranges',
  priceListType: 'Price list type',
  cityId: 'City id',
  cityName: 'City',
  latitude: 'Latitude',
  countryId: 'Country id',
  longitude: 'Longitude',
  currencyID: 'Currency id',
  locationId: 'Location id',
  countryName: 'Country name',
  currencyName: 'Currency name',
  dropoffHours: 'Dropoff hours',
  locationName: 'Location name',
  waitingHours: 'Waiting hours',
  workingHours: 'Working hours',
  from: 'Start date',
  to: 'End date',
  cities: 'Cities',
  created: 'Created date',
  comments: 'Comments',
  locations: 'Locations',
  applicable: 'Applicable',
  carClasses: 'Car classes',
  price: 'Price',
  extraId: 'Extra id',
  maxCount: 'Max count',
  priceFor: 'Price for',
  extraName: 'Extra name',
  maxAmount: 'Max amount',
  hirePointId: 'Hire point id',
  hirePointName: 'Hire point name',
  fuel: 'Fuel',
  carId: 'Car id',
  carName: 'Car name',
  sippCode: 'SIPP code',
  carGroupId: 'Car group id',
  carGroupName: 'Car group name',
  companyClass: 'Company class',
  transmission: 'Transmission',
  driverAgeRange: 'Driver age range',
  airConditioning: 'Air conditioning',
  phoneAreaCode: 'Phone area code',
  region: 'Region',
  specialInstructions: 'Special instuctions',
  startReservationHour: 'Start reservation hour',
  minimumPeriodDay: 'Minimum period day',
  maximumPeriodDay: 'Maximum period day',
  pickupOption: 'Pick-up option',
  sits: 'Seats',
  doors: 'Doors',
}

export const importRatesHeadItems = {
  files: 'Files',
  download: 'Download',
}
