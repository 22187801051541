import { CDN_PUBLIC_IMAGES_URL } from 'constants/storages'

export const getSmallFlagSrc = (countryCode: string) =>
  `${CDN_PUBLIC_IMAGES_URL}/flags/${countryCode}_20.png`

export const getSmallFlagWebpSrc = (countryCode: string) =>
  `${CDN_PUBLIC_IMAGES_URL}/flags/${countryCode}_20.webp`

export const getMediumFlagSrc = (countryCode: string) =>
  `${CDN_PUBLIC_IMAGES_URL}/flags/${countryCode}_32.webp`
