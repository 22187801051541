import { breadcrumbs } from 'constants/breadcrumbs'

export const getDetailsBreadcrumbsList = (
  isOperator: boolean,
  companyId: string,
  page: keyof typeof breadcrumbs
) =>
  isOperator
    ? [
        breadcrumbs.profileList,
        {
          ...breadcrumbs[page],
          link: `${breadcrumbs[page].link}/${companyId}`,
        },
      ]
    : [
        {
          ...breadcrumbs[page],
          link: `${breadcrumbs[page].link}`,
        },
      ]
