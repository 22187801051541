import { RootState } from 'redux/store'

export const isRegistrationCodeValidSelector = (state: RootState) =>
  state.registrationReducer.registration.isRegistrationCodeValid

export const registrationEmailSelector = (state: RootState) =>
  state.registrationReducer.registration.email

export const errorMessageSelector = (state: RootState) =>
  state.registrationReducer.errorMessage

export const registrationCodeSelector = (state: RootState) =>
  state.registrationReducer.registration.registrationCode

export const companyNameSelector = (state: RootState) =>
  state.registrationReducer.registration.companyName
