import { createSlice } from '@reduxjs/toolkit'
import { ActivityHistoryState } from './types'

const initialState: ActivityHistoryState = {
  rates: {},
  selectedActivityHistoryRange: {
    from: '',
    to: '',
  },
  sortingData: [],
}

export const activityHistorySlice = createSlice({
  name: 'activityHistory',
  initialState,
  reducers: {
    setRates(state, action) {
      state.rates = action.payload
    },
    setSelectedActivityHistoryRange(state, action) {
      state.selectedActivityHistoryRange = action.payload
    },
    setSorting(state, action) {
      const existedKeyIndex = state.sortingData.findIndex(
        (el) => el.key === action.payload.key
      )
      if (existedKeyIndex !== -1) {
        state.sortingData[existedKeyIndex].type = action.payload.type
      } else {
        state.sortingData.push(action.payload)
      }
    },
    resetSorting(state) {
      state.sortingData = initialState.sortingData
    },
  },
})

export const activityHistoryReducer = activityHistorySlice.reducer
export const {
  setRates,
  setSelectedActivityHistoryRange,
  setSorting,
  resetSorting,
} = activityHistorySlice.actions
