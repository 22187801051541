import { Props } from './types'
import styles from './styles.module.scss'
import { GeneralNotification } from 'ui/molecules/GeneralNotification'

export const AuthLayout = ({ Form, WelcomeBlock }: Props) => (
  <div className={styles['container']}>
    <div className={styles['container-left']}>{WelcomeBlock}</div>
    <div className={styles['container-right']}>{Form}</div>
    <div>
      <GeneralNotification top={40} right="40px" />
    </div>
  </div>
)
