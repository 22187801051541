import { TYPOGRAPHY_COLORS } from 'constants/TypographyColors'
import { SVGIcon } from './SvgIcon/SvgIcon'
import { IconProps } from './types'

export const PeopleOutlineIcon = ({ size, color = 'grey400' }: IconProps) => (
  <SVGIcon size={size} color={color}>
    <path
      d="M12.992 5.5C12.8814 6.91215 11.7422 8 10.5 8C9.25776 8 8.1167 6.9125 8.00796 5.5C7.89468 4.0309 9.00364 3 10.5 3C11.9964 3 13.1053 4.05764 12.992 5.5Z"
      stroke={TYPOGRAPHY_COLORS[color]}
      fill="none"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5 10C8.46338 10 6.50489 11.1561 6.01425 13.4075C5.94925 13.7054 6.11269 14 6.38051 14H14.6198C14.8876 14 15.0501 13.7054 14.986 13.4075C14.4954 11.12 12.5369 10 10.5 10Z"
      stroke={TYPOGRAPHY_COLORS[color]}
      fill="none"
      strokeWidth="1.3"
      strokeMiterlimit="10"
    />
    <path
      d="M5.99532 5.99793C5.9293 7.11793 5.24145 8 4.5 8C3.75855 8 3.06957 7.11828 3.00468 5.99793C2.93725 4.83276 3.60676 4 4.5 4C5.39324 4 6.06275 4.85414 5.99532 5.99793Z"
      stroke={TYPOGRAPHY_COLORS[color]}
      fill="none"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 10.384C6.37762 10.1067 5.69214 10 4.96563 10C3.17263 10 1.44514 10.867 1.01241 12.5556C0.955512 12.779 1.09964 13 1.33549 13H5.207"
      stroke={TYPOGRAPHY_COLORS[color]}
      fill="none"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </SVGIcon>
)
