import { getRequiredMessage } from 'utils/getRequiredMessage'
import { LATIN_LETTER_RULE } from './uiTexts'

export const VEHICLE_TITLE = 'Car'
export const VEHICLE_ADDING_BREADCRUMB = 'Add car'
export const VEHICLE_DETAILS_BREADCRUMB = 'Car details'

export const VEHICLE_DETAILS = {
  FORM_TITLE: 'Car details',
  DRIVER_AGE_RANGE_TITLE: 'Driver age ranges',
  BUTTONS: {
    CANCEL_BUTTON_LABEL: 'Cancel',
    SAVE_BUTTON_LABEL: 'Save',
  },
  SEARCH_VALUE: 'location',
}

export const VEHICLE_DETAILS_FORM_VALUES = {
  CAR_GROUP: {
    ID: 'carGroupId',
    PLACEHOLDER: 'Please select a car type',
    LABEL: 'Car type',
    REQUIRED_MESSAGE: 'Please select a car type.',
    VALIDATION_MESSAGE: 'Invalid characters',
    SEARCH_PLACEHOLDER: 'Search for car type',
  },
  COMPANY_CLASS: {
    ID: 'companyClass',
    PLACEHOLDER: 'Please enter a company class',
    LABEL: 'Company class',
    REQUIRED_MESSAGE: getRequiredMessage('company class'),
    VALIDATION_MESSAGE: LATIN_LETTER_RULE,
  },
  SIPP: {
    ID: 'sippCode',
    PLACEHOLDER: 'Please enter SIPP',
    LABEL: 'SIPP',
    REQUIRED_MESSAGE: 'Please enter SIPP.',
    VALIDATION_MESSAGE:
      'Car class must be 2 - 8 characters long, include letters and numbers',
  },
  DRIVER_AGE_RANGE: {
    ID: 'driverAgeRange',
    PLACEHOLDER: 'Please enter drive age range',
    LABEL: 'Driver age range',
    REQUIRED_MESSAGE: getRequiredMessage('driver age range'),
  },
  CAR: {
    ID: 'car',
    PLACEHOLDER: 'Please select a car model',
    LABEL: 'Car model',
    REQUIRED_MESSAGE: 'Please select a car model.',
    SEARCH_PLACEHOLDER: 'Search for car model',
  },
  HIRE_POINTS: {
    ID: 'hirePoints',
    PLACEHOLDER: 'Please select a location',
    LABEL: 'Refers to location',
    REQUIRED_MESSAGE: 'Please select a location.',
  },
  AIR_CONDITIONING: {
    ID: 'airConditioning',
    LABEL: 'Air Conditioning',
  },
  TRANSMISSION: {
    ID: 'transmission',
    LABEL: 'Transmission',
  },
  FUEL: {
    ID: 'fuel',
    LABEL: 'Fuel',
  },
  SEATS: {
    ID: 'sits',
    LABEL: 'Seats',
  },
  DOORS: {
    ID: 'doors',
    LABEL: 'Doors',
  },
}
