import { useCallback, useEffect, useState } from 'react'
import { nodeDimensions } from '../utils'
import { Props } from './types'

export const useCarouselWidth = ({
  carouselRef,
  shownSlides,
  slidesLength,
}: Props) => {
  const [{ fullWidth, slideWidth }, setWidth] = useState({
    fullWidth: 0,
    slideWidth: 0,
  })

  const setSlidesWidth = useCallback(() => {
    const currentSlideWidth =
      nodeDimensions(carouselRef.current as HTMLDivElement).width / shownSlides
    const slidesWidth = currentSlideWidth * slidesLength
    setWidth({ fullWidth: slidesWidth, slideWidth: currentSlideWidth })
  }, [carouselRef, shownSlides])

  useEffect(() => {
    if (carouselRef.current) {
      setSlidesWidth()
    }
  }, [setSlidesWidth])
  useEffect(() => {
    if (carouselRef.current) {
      const carouselResizeObserver = new ResizeObserver(() => {
        setSlidesWidth()
      })

      carouselResizeObserver.observe(carouselRef.current)

      return () => {
        if (carouselRef.current) {
          carouselResizeObserver.unobserve(carouselRef.current)
        }
      }
    }

    return undefined
  }, [setSlidesWidth])

  return { fullWidth, slideWidth }
}
