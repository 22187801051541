import { TYPOGRAPHY_COLORS } from 'constants/TypographyColors'
import { SVGIcon } from './SvgIcon/SvgIcon'
import { IconProps } from './types'

export const LightIcon = ({
  size,
  color = 'whiteMaster',
  className,
}: IconProps) => (
  <SVGIcon size={size} color={color} className={className} viewBox="0 0 24 24">
    <path
      d="M16.3636 14.5758L16.3633 14.5761C16.1762 14.7585 16.0306 14.9787 15.9361 15.2216L15.9231 15.2552L15.9125 15.2897L15.8778 15.4022L15.8674 15.4361L15.8594 15.4707L14.6113 20.8562L15.5854 21.082L14.6113 20.8563C14.4665 21.481 13.9335 21.9385 13.2979 21.9943L13.1474 22H10.834C10.1858 22 9.62017 21.5824 9.42405 20.9818L9.38468 20.8372L8.1426 15.4712C8.1426 15.4712 8.1426 15.4712 8.14259 15.4712C8.06369 15.1303 7.88837 14.8194 7.63781 14.5755C5.93182 12.9144 5.08641 11.1424 5.00633 9.27232L5.00017 8.97337L5.00401 8.73694C5.12981 4.998 8.21138 2 12 2C15.8689 2 19 5.12594 19 8.97555C19 10.9602 18.1553 12.8304 16.3636 14.5758Z"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="2"
      fill="none"
    />
    <path
      d="M8 18L16 18"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="2"
      fill="none"
    />
  </SVGIcon>
)
