import { LATIN_LETTER_RULE } from './uiTexts'

export const STOP_SELLS_DETAILS = {
  FORM_TITLE: 'Stop sale details',
  BUTTONS: {
    CANCEL_BUTTON_LABEL: 'Cancel',
    SAVE_BUTTON_LABEL: 'Save',
  },
}

export const STOP_SALE_TITLE = 'Stop sale'
export const STOP_SALE_ADD_BREADCRUMB = 'Add stop sale'
export const STOP_SALE_EDIT_BREADCRUMB = 'Stop sale details'

export const STOP_SELLS_DETAILS_FORM_VALUES = {
  COUNTRY: {
    ID: 'countries',
    PLACEHOLDER: 'Please select a country',
    LABEL: 'Country',
    REQUIRED_MESSAGE: 'Please select a country',
    VALIDATION_MESSAGE: 'Invalid characters',
    UNIT_NAME: 'country',
  },
  CITY: {
    ID: 'cities',
    PLACEHOLDER: 'Please select a city',
    LABEL: 'City',
    REQUIRED_MESSAGE: 'Please select a city',
    VALIDATION_MESSAGE: 'Invalid characters',
    UNIT_NAME: 'city',
  },
  LOCATION: {
    ID: 'locations',
    PLACEHOLDER: 'Please select a location',
    LABEL: 'Location',
    REQUIRED_MESSAGE: 'Please select a location',
    VALIDATION_MESSAGE: 'Invalid characters',
    UNIT_NAME: 'location',
  },
  COMMENTS: {
    ID: 'comments',
    PLACEHOLDER: 'Please enter your comment',
    VALIDATION_MESSAGE: LATIN_LETTER_RULE,
    LABEL: 'Your comment',
  },
  START_DATE: {
    ID: 'from',
    PLACEHOLDER: 'Please select a date',
    LABEL: 'Start date',
    REQUIRED_MESSAGE: 'Please select start date',
  },
  END_DATE: {
    ID: 'to',
    PLACEHOLDER: 'Please select a date',
    LABEL: 'End date',
    REQUIRED_MESSAGE: 'Please select end date',
  },
  SIPP: {
    ID: 'carClasses',
    PLACEHOLDER: 'Please select a car class',
    LABEL: 'Car Class',
    REQUIRED_MESSAGE: 'Please select a car class',
    VALIDATION_MESSAGE:
      'Street must be 1 - 50 characters long, include letters, numbers and space',
    UNIT_NAME: 'car class',
  },
}
