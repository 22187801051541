import { TYPOGRAPHY_COLORS } from 'constants/TypographyColors'
import { SVGIcon } from './SvgIcon/SvgIcon'
import { IconProps } from './types'

export const PlusIconWithBg = ({
  size,
  color = 'blue700',
  className,
  onClick,
}: IconProps) => (
  <SVGIcon size={size} color={color} className={className} onClick={onClick}>
    <rect width="24" height="24" rx="4" fill="#F5F9FF" />
    <path
      d="M12.002 6V18"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 12.0005H18"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVGIcon>
)
