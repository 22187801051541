import { SVGIcon } from './SvgIcon/SvgIcon'
import { TYPOGRAPHY_COLORS } from 'constants/TypographyColors'
import { IconProps } from './types'

export const TrashIcon = ({
  size,
  color = 'blue700',
  className,
  onClick,
}: IconProps) => (
  <SVGIcon
    size={size}
    viewBox="0 0 20 20"
    color={color}
    className={className}
    onClick={onClick}
  >
    <path
      d="M5 6.40005H6.11111H15"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M13.8889 6.4V14.8C13.8889 15.1183 13.7718 15.4235 13.5634 15.6485C13.3551 15.8736 13.0724 16 12.7778 16H7.2222C6.92751 16 6.64489 15.8736 6.43652 15.6485C6.22815 15.4235 6.11108 15.1183 6.11108 14.8V6.4M7.77775 6.4V5.2C7.77775 4.88174 7.89481 4.57652 8.10319 4.35147C8.31156 4.12643 8.59418 4 8.88886 4H11.1111C11.4058 4 11.6884 4.12643 11.8968 4.35147C12.1051 4.57652 12.2222 4.88174 12.2222 5.2V6.4"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SVGIcon>
)
