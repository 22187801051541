import React from 'react'
import { joinClasses } from 'utils/joinClasses'
import { DotsProps } from './types'
import styles from './styles.module.scss'

export const Dots = React.memo(
  ({ count, active, onDotClick, shownSlides }: DotsProps) => {
    const dots = Array(count).fill(0)
    const isActive = active - shownSlides

    const onClick = (index: number) => () => {
      onDotClick(index + shownSlides)
    }

    return (
      <div className={styles['carousel-dots']}>
        {dots.map((_, index) => {
          const key = index
          return (
            <div
              key={key}
              className={joinClasses(styles['carousel-dot'], [
                styles['carousel-dot-active'],
                index === isActive,
              ])}
              onClick={onClick(index)}
            />
          )
        })}
      </div>
    )
  }
)
