import { Typography } from '../../../Typography'
import { Checkbox } from '../../../Checkbox'
import { MultiselectItemProps } from './types'
import styles from './styles.module.scss'

export const MultiselectItem = ({
  item,
  isChecked = false,
  onClick,
}: MultiselectItemProps) => {
  const handleClick = () => {
    onClick(item)
  }

  return (
    <div className={styles['multiselect-items-block']}>
      <Typography
        Tag="div"
        name="Button2"
        className={styles['multiselect-items-item']}
        onClick={handleClick}
      >
        <Checkbox
          borderVariant="grey"
          label={item.label}
          checked={isChecked}
          onChange={() => {}}
        />
      </Typography>
    </div>
  )
}
