export const DRIVER_AGE_RANGE = {
  START: {
    LABEL: 'From',
    PLACEHOLDER: 'Enter age',
  },
  END: {
    LABEL: 'To',
    PLACEHOLDER: 'Enter age',
  },
  FEE: {
    LABEL: 'Fee',
    PLACEHOLDER: '0.00',
  },
  MAX_PRICE: {
    LABEL: 'Max price',
    PLACEHOLDER: '0.00',
  },
  RADIO_BUTTONS: {
    PER_DAY: 'Per day',
    PER_RENTAL: 'Per rental',
  },
  ADD_RANGE_BUTTON: 'Add range',
  ADD_RANGE_BADGE: 'To add a driver age range click Add range button.',
}

export const DRIVER_AGE_RANGE_VALIDATIONS = {
  REQUIRED: 'Please add driver age range and complete the fields.',
  MIN_AGE: 'Minimal age is 21.',
  MAX_AGE: 'Maximum age is 99.',
  END_MORE_START: 'Start of range must be less or equal to end of range.',
  RANGES_OVERLAP: 'Ranges cannot overlap.',
  EMPTY_FIELD: 'Please complete all the fields.',
  RANGES_GAPS: 'Please complete the age gaps between ranges.',
  MAX_PRICE_MORE_FEE: 'Max price must be greater or equal to Fee.',
}
