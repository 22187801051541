import { createSlice } from '@reduxjs/toolkit'
import { CommonState } from './types'

const initialState: CommonState = {
  lastPage: '',
}

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setLastPage(state, action) {
      state.lastPage = action.payload
    },
  },
})

export const commonReducer = commonSlice.reducer
export const { setLastPage } = commonSlice.actions
