export const STOP_SALES = {
  ADD_BUTTON: 'Add stop sale',
  DELETE: 'Delete',
  TOOLTIP:
    'Here you can add information about the specific period for which you want to block the booking of your vehicles.',
  CAR_CLASS_TOOLTIP:
    'Car class includes SIPP and Company class (e.g. EFAR-EFAR)',
}

export const STOP_SALES_ADD_CONFIRMATION = {
  CONFIRMATION_MODAL: {
    QUESTION: 'Are you sure that you want to delete?',
  },
  SUCCESS_MODAL: {
    TITLE: 'Stop sale was successfully deleted',
  },
}

export const STOP_SALE_SUCCESS_ADD = 'Stop sale has been successfully adding'
