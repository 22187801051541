import { TYPOGRAPHY_COLORS } from 'constants/TypographyColors'
import { SVGIcon } from './SvgIcon/SvgIcon'
import { IconProps } from './types'

export const ArrowCircleIcon = ({
  size,
  color = 'grey400',
  onClick,
  className,
}: IconProps) => (
  <SVGIcon size={size} color={color} onClick={onClick} className={className}>
    <path
      d="M10.5 18.8333C15.1023 18.8333 18.8333 15.1024 18.8333 10.5C18.8333 5.89762 15.1023 2.16666 10.5 2.16666C5.89759 2.16666 2.16663 5.89762 2.16663 10.5C2.16663 15.1024 5.89759 18.8333 10.5 18.8333Z"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M10.5 7.16666L7.16663 10.5L10.5 13.8333"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M13.8333 10.5H7.16663"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SVGIcon>
)
