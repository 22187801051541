import { TYPOGRAPHY_COLORS } from 'constants/TypographyColors'
import { SVGIcon } from './SvgIcon/SvgIcon'
import { IconProps } from './types'

export const ArrowUpRightIcon = ({ size, color = 'grey400' }: IconProps) => (
  <SVGIcon size={size} color={color}>
    <path
      d="M4.51819 11.0001H17.4818"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 4.51825L17.4818 11.0001L11 17.4819"
      fill="none"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVGIcon>
)
