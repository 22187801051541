import { useCallback } from 'react'
import { createPortal } from 'react-dom'
import { Typography } from 'ui/atoms/Typography'
import { useHideScroll } from 'ui/hooks/useHideScroll'
import { Wrapper } from 'ui/atoms/Wrapper'
import { ModalCloseIcon } from 'ui/icons/ModalCloseIcon'
import { joinClasses } from 'utils/joinClasses'
import { GLOBAL_MODAL } from 'constants/DOM'
import { Props } from './types'
import { closeColors, titleNames } from './constants'
import styles from './styles.module.scss'

export const SideModal = ({
  children,
  onClose,
  disableOverlayClose,
  className,
  title,
  contentRef,
  position = 'right',
  closeVariant = 'primary',
  headerSize = 'medium',
}: Props) => {
  const handleModal = (e: React.MouseEvent) => {
    e?.stopPropagation()
  }

  const handleClose = useCallback(() => {
    if (!disableOverlayClose) {
      onClose()
    }
  }, [disableOverlayClose])

  useHideScroll()

  return createPortal(
    <div
      onClick={handleModal}
      className={joinClasses(
        styles['side-modal'],
        className || '',

        [styles[`header-size-${headerSize}`], headerSize]
      )}
    >
      <div className={styles['modal-overlay']} onClick={handleClose} />
      <Wrapper
        className={joinClasses(
          styles['modal-wrapper'],
          [styles[`header-size-${headerSize}`], headerSize],
          [styles[`position-${position}`], position]
        )}
      >
        <div className={styles['modal-side-inner']}>
          <div className={joinClasses(styles['modal-side-header'])}>
            <div className={styles['modal-side-header-left']}>
              {title && (
                <Typography
                  name={titleNames[headerSize].name}
                  className={styles['modal-side-title']}
                >
                  {title}
                </Typography>
              )}
            </div>
            <div className={styles['modal-side-header-right']}>
              {onClose && (
                <div className={styles['modal-side-close']} onClick={onClose}>
                  <ModalCloseIcon
                    size="large"
                    color={closeColors[closeVariant]}
                  />
                </div>
              )}
            </div>
          </div>
          <div ref={contentRef} className={styles['modal-side-content']}>
            {children}
          </div>
        </div>
      </Wrapper>
    </div>,
    document.getElementById(GLOBAL_MODAL) as HTMLElement
  )
}
