import { useState } from 'react'
import { ConfirmModal } from './components/ConfirmModal'
import { SuccessModal } from './components/SuccessModal'
import { ConfirmationProps } from './types'

export const Confirmation = ({
  successModalProps,
  confirmModalProps,
}: ConfirmationProps) => {
  const [isConfirmed, setIsConfirm] = useState(false)

  const handleConfirm = async () => {
    await confirmModalProps.onConfirm()
    setIsConfirm(true)
  }

  return successModalProps && isConfirmed ? (
    <SuccessModal
      onClose={successModalProps.onClose}
      title={successModalProps.title}
      subTitle={successModalProps.subTitle}
      buttonLabel={successModalProps.buttonLabel}
      onConfirm={successModalProps.onConfirm}
    />
  ) : (
    <ConfirmModal
      title={confirmModalProps.title}
      onConfirm={handleConfirm}
      onClose={confirmModalProps.onClose}
      question={confirmModalProps.question}
      confirmLabel={confirmModalProps.confirmLabel}
      declineLabel={confirmModalProps.declineLabel}
      onDecline={confirmModalProps.onDecline}
    />
  )
}
