import React, { useState, useEffect, useMemo } from 'react'
import { joinClasses } from 'utils/joinClasses'
import { Typography } from 'ui/atoms/Typography'
import { Label, Skeleton } from '@frontend/design_system'
import { Input } from '../../atoms/Input'
import { FormAlertLabel } from '../FormAlertLabel'
import { MultiselectItem } from './components/MultiselectItem'
import { MultiselectHeader } from './components/MultiselectHeader'
import { useDetectClickOutside } from 'ui/hooks/useDetectOutsideClick'
import { MultiselectChip } from './components/MultiselectChip'
import {
  MultiselectItem as MultiselectItemType,
  MultiselectProps,
} from './types'
import { SELECT_ALL, ALL, SEARCH_FOR, SELECT } from 'texts/common'
import {
  SELECT_ALL_ITEM,
  MIN_ITEM_COUNT_FOR_SEARCH,
  NOT_FOUND,
} from './constants'
import styles from './styles.module.scss'

export const Multiselect = ({
  items,
  onChange,
  value,
  label,
  isRequired,
  tooltip,
  labelRight,
  withoutPlaceholder,
  error,
  readonly,
  spaceForError = 'default',
  placeholder,
  withAllSelect = true,
  selectAllByDefault,
  withSelectAllItem,
  autoSelectAll = true,
  loading,
  notFoundTitle,
  unitName = {
    single: '',
    plural: '',
  },
  disableWithSelectAllItem,
  variant = 'primary',
}: MultiselectProps) => {
  const [listItems, setListItems] = useState(items)
  const [selectedAll, setSelectedAll] = useState(false)
  const [autoSelectedAll, setAutoSelectedAll] = useState(!!autoSelectAll)
  const { ref, openDropdown, setOpenDropdown } = useDetectClickOutside()

  const handleSelectItem = () => {
    if (withSelectAllItem) {
      onChange([...items, SELECT_ALL_ITEM])
    } else {
      onChange([...items])
    }
  }

  const handleClickSelectAll = () => {
    setAutoSelectedAll(true)
    handleSelectAll()
  }

  const handleSelectAll = () => {
    if (selectedAll) {
      setSelectedAll(false)
      onChange([])
    } else {
      setSelectedAll(true)
      handleSelectItem()
    }
  }

  const onClickItem = (item: MultiselectItemType) => {
    setAutoSelectedAll(true)
    const isItemSelected = value.find((el) => el.value === item.value)
    const filteredValue = value.filter(
      (item) => item.value !== SELECT_ALL_ITEM.value
    )
    const newValues = isItemSelected
      ? filteredValue.filter((el) => el.value !== item.value)
      : [...filteredValue, item]
    if (newValues.length === items.length) {
      handleSelectItem()
    } else {
      onChange(newValues)
    }
  }
  const clearSelectedItems = () => {
    onChange([])
    setSelectedAll(false)
  }

  const renderChips = useMemo(() => {
    if (
      selectedAll ||
      disableWithSelectAllItem ||
      value.find((item) => item.value === SELECT_ALL_ITEM.value)
    ) {
      return (
        <MultiselectChip
          readonly={readonly || disableWithSelectAllItem}
          title={ALL}
          item={SELECT_ALL_ITEM}
          onClick={clearSelectedItems}
        />
      )
    }
    if (value.length) {
      const otherItems = value.length - 1
      return (
        <div className={styles['multiselect-chips']}>
          <MultiselectChip
            readonly={readonly}
            item={value[0]}
            title={value[0].label}
            onClick={onClickItem}
          />
          {otherItems > 0 && (
            <MultiselectChip
              readonly={readonly}
              item={value[otherItems]}
              color="blue700"
              name="Subtitle3"
              title={`+${otherItems}`}
              onClick={() => onChange([value[0]])}
            />
          )}
        </div>
      )
    }
    return null
  }, [
    value,
    selectedAll,
    readonly,
    items,
    disableWithSelectAllItem,
    selectAllByDefault,
  ])

  const onChangeInput = (inputValue: string) => {
    const filteredItems = items.filter((item) =>
      item.label.toLowerCase().includes(inputValue.toLowerCase())
    )

    setListItems(filteredItems)
  }

  const onToggle = () => {
    setOpenDropdown(!openDropdown)
    setListItems(items)
  }

  useEffect(() => {
    if (withAllSelect && autoSelectedAll) {
      setSelectedAll(
        value.length >= items.length && !!value.length && !!items.length
      )
    }
  }, [value.length, items.length])

  useEffect(() => {
    setListItems(items)
  }, [items])

  useEffect(() => {
    if (selectAllByDefault && items.length) {
      handleSelectItem()
    }
  }, [selectAllByDefault, items.length])

  return (
    <div
      className={joinClasses(
        styles.multiselect,
        styles[`multiselect-error-space-${spaceForError}`]
      )}
    >
      {label && (
        <Label
          typographyName="Button2"
          label={label}
          isRequired={isRequired}
          tooltip={tooltip}
          labelRight={labelRight}
        />
      )}
      <div ref={ref} className={styles['multiselect-inner']}>
        <MultiselectHeader
          renderChips={renderChips}
          onToggle={onToggle}
          error={error}
          openDropdown={openDropdown}
          withoutPlaceholder={withoutPlaceholder}
          readonly={readonly || disableWithSelectAllItem}
          placeholder={placeholder ? placeholder : SELECT}
        />
        <div className={styles['multiselect-block']}>
          {openDropdown && (
            <div className={styles['multiselect-items']}>
              {loading ? (
                <div className={styles.skeleton}>
                  <Skeleton.Spinner size={36} />
                </div>
              ) : (
                <>
                  {items.length > MIN_ITEM_COUNT_FOR_SEARCH && (
                    <div className={styles['multiselect-search']}>
                      <Input
                        size="large"
                        onChange={onChangeInput}
                        iconLeft="SearchIcon"
                        spaceForError="none"
                        placeholder={`${SEARCH_FOR} ${unitName?.plural}`}
                      />
                    </div>
                  )}
                  <div
                    className={joinClasses(styles['multiselect-dropdown'], [
                      styles[variant],
                      variant,
                    ])}
                  >
                    {withAllSelect &&
                      !!listItems.length &&
                      items.length === listItems.length && (
                        <div className={styles['multiselect-all-selected']}>
                          <MultiselectItem
                            isChecked={selectedAll}
                            item={{
                              label: SELECT_ALL,
                              value: SELECT_ALL,
                            }}
                            onClick={handleClickSelectAll}
                          />
                        </div>
                      )}
                    {listItems.length ? (
                      listItems.map((item, index) => (
                        <MultiselectItem
                          key={`${item.label}${index}`}
                          isChecked={
                            !!value.find(
                              (current) => current.value === item.value
                            )
                          }
                          item={item}
                          onClick={onClickItem}
                        />
                      ))
                    ) : (
                      <div className={styles['not-found']}>
                        <Typography name="Button2" color="blueSecondary">
                          {notFoundTitle || NOT_FOUND}
                        </Typography>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      {error && (
        <FormAlertLabel
          label={error}
          position={
            spaceForError === 'none' || spaceForError === 'auto'
              ? 'static'
              : 'absolute'
          }
          type="error"
          isBottomOffset
        />
      )}
    </div>
  )
}
