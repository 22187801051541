import { useState } from 'react'
import { Typography } from '../Typography'
import { DropdownHeader } from './components/DropdownHeader'
import { useDetectClickOutside } from 'ui/hooks/useDetectOutsideClick'
import { DropdownProps } from './types'
import { DropdownItem } from './components/DropdownItem/DropdownItem'
import styles from './styles.module.scss'

export const Dropdown = ({
  defaultValue,
  items,
  onChange,
  renderLabel,
  value,
  borderRadius,
  size,
  noBorder,
}: DropdownProps) => {
  const { ref, openDropdown, setOpenDropdown } = useDetectClickOutside()
  const [selectedItem, setSelectedItem] = useState(value || defaultValue || '')

  const onClickItem = (index: string) => {
    setSelectedItem(index)
    setOpenDropdown(false)
    onChange(index)
  }

  const onToggle = () => {
    setOpenDropdown(!openDropdown)
  }

  return (
    <div ref={ref} className={styles.dropdown}>
      <DropdownHeader
        renderLabel={renderLabel(value || selectedItem)}
        onToggle={onToggle}
        openDropdown={openDropdown}
        borderRadius={borderRadius}
        size={size}
        noBorder={noBorder}
      />
      {openDropdown && (
        <Typography Tag="div" className={styles['dropdown-items']}>
          {items.map((item) => (
            <DropdownItem
              key={item}
              selectedItem={value || selectedItem}
              item={item}
              onClick={onClickItem}
            />
          ))}
        </Typography>
      )}
    </div>
  )
}
