import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { Button } from 'ui/atoms/Button'
import { login } from 'redux/login/thunks'
import { Typography } from 'ui/atoms/Typography'
import { Form } from 'ui/molecules/Form'
import { Badge } from 'ui/atoms/Badge'
import { errorMessageSelector } from 'redux/login/selectors'
import { SIGNIN_TEXT_FIELDS } from 'texts/signin'
import { URLS } from 'constants/urls'
import { handleRedirectToMain } from 'utils/handleRedirectToMain'
import { SignInFormData } from '../types'
import { AuthFormFields } from '../components/FormFields'
import { initialFormValues } from './constants'
import { setErrorMessage } from 'redux/login/slice'
import { UserRoles } from 'constants/roles'
import styles from './styles.module.scss'

export const SignInForm = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const errorMessage = useAppSelector(errorMessageSelector)

  const handleSuccess = (role: UserRoles) => {
    handleRedirectToMain(role, navigate)
  }

  const onSubmit = async (data: SignInFormData, validate: boolean) => {
    dispatch(setErrorMessage(''))
    if (validate) {
      dispatch(
        login({
          email: data.email,
          password: data.password,
          onSuccess: handleSuccess,
        })
      )
    }
  }

  const handleForgotPasswordClick = () => {
    dispatch(setErrorMessage(''))
    navigate(URLS.FORGOT_PASSWORD)
  }

  return (
    <div className={styles['signin-form']}>
      <Typography Tag="h3" name="H3" className={styles.header}>
        {SIGNIN_TEXT_FIELDS.TITLE}
      </Typography>
      <Typography Tag="span" name="Button2" color="grey500">
        {SIGNIN_TEXT_FIELDS.SUBTITLE}
      </Typography>
      <Form
        onSubmit={onSubmit}
        className={styles['form-fields']}
        initValues={initialFormValues}
      >
        <AuthFormFields formType="signin" isError={!!errorMessage} />
        {errorMessage && (
          <Badge message={errorMessage} className={styles.badge} type="error" />
        )}
        <div className={styles.buttons}>
          <div className={styles['button-wrapper']}>
            <Button
              label={SIGNIN_TEXT_FIELDS.BUTTON}
              htmlType="submit"
              size="large"
            />
          </div>
          <div className={styles['button-wrapper']}>
            <Button
              label={SIGNIN_TEXT_FIELDS.LINK}
              onClick={handleForgotPasswordClick}
              variant="link"
            />
          </div>
        </div>
      </Form>
    </div>
  )
}
