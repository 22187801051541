import { CheckIcon } from 'ui/icons'
import { Typography } from '../../../Typography'
import { PasswordChecklistProps } from './types'
import { TypographyColor } from 'types'
import styles from './styles.module.scss'

export const PasswordChecklist = ({
  value,
  passwordScale,
}: PasswordChecklistProps) => (
  <div className={styles['password-must-have']}>
    <Typography name="Button2">{passwordScale.label}:</Typography>
    <ul>
      {passwordScale.rules.map((item, i) => {
        const key = i
        const isValid = new RegExp(item.rule).test(value)
        let checkedClass = ''
        let checkedColor: TypographyColor = 'grey500'
        if (isValid) {
          checkedClass = 'password-must-have-check'
          checkedColor = 'blackMaster'
        }

        return (
          <li className={styles[checkedClass]} key={key}>
            {checkedClass && <CheckIcon color="green700Master" size="small" />}
            <Typography
              color={checkedColor}
              name="body2WMedium"
              className={styles['password-must-have-title']}
            >
              {item.label}
            </Typography>
          </li>
        )
      })}
    </ul>
  </div>
)
