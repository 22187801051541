import { RefObject, useEffect } from 'react'

type Props = {
  showCodes: boolean
  codesRef: RefObject<Element>
  setShowCodes: (value: boolean) => void
  selectRef: RefObject<Element>
}

export const useOutsideClick = ({
  showCodes,
  codesRef,
  selectRef,
  setShowCodes,
}: Props) => {
  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      if (
        showCodes &&
        codesRef.current &&
        !codesRef.current.contains(e.target as Node) &&
        selectRef.current &&
        !selectRef.current.contains(e.target as Node)
      ) {
        setShowCodes(false)
      }
    }

    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [showCodes])
}
