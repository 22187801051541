import { useAppSelector } from 'redux/hooks'
import { fleetApi } from 'api'
import { FormValues } from '../EquipmentDetailsForm/types'
import {
  EQUIPMENT_TITLE,
  EQUIPMENT_ADD_BREADCRUMB,
} from 'texts/equipmentDetails'
import { EQUIPMENT_ADD_CONFIRM_MODAL } from 'texts/fleetConfirmModal'
import { EquipmentDetailsForm } from '../EquipmentDetailsForm'
import { Container } from 'ui/molecules/Container'
import { initialFormValues } from '../EquipmentDetailsForm/constants'
import { useCompanyInfo } from 'ui/hooks/useCompanyInfo'
import { isOperatorSelector } from 'redux/login/selectors'
import { getDetailsBreadcrumbsList } from 'utils/getDetailsBreadcrumbsList'
import { useApiRequest } from 'hooks/useApiRequest'
import { useNavigate } from 'react-router-dom'
import { setNotificationMessage } from 'redux/notifications/slice'
import { useDispatch } from 'react-redux'
import { GTM } from 'utils/gtm'
import { DATA_LAYER_EVENT } from 'constants/dataLayerEvents'

export const EquipmentAdd = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const isOperator = useAppSelector(isOperatorSelector)

  const { companyId } = useCompanyInfo(isOperator)
  const { apiRequest, loading } = useApiRequest(
    (values) => fleetApi.addEquipment(values),
    undefined,
    undefined,
    undefined,
    false
  )

  const breadcrumbList = getDetailsBreadcrumbsList(
    isOperator,
    String(companyId),
    'equipments'
  )

  const handleSubmit = async (data: Partial<FormValues>) => {
    const values = {
      ...data,
      extraId: Number(data.extraId),
      price: Number(data.price),
      companyId: Number(companyId),
      priceFor: Number(data.priceFor),
      maxCount: Number(data.maxCount),
      hirePoints: data.hirePoints?.map((item) => Number(item.value)),
    }

    const response = await apiRequest(values)
    if (response) {
      navigate(-1)
      dispatch(
        setNotificationMessage({
          notificationMessage: EQUIPMENT_ADD_CONFIRM_MODAL.TITLE,
        })
      )
      GTM.dataLayer({
        event: DATA_LAYER_EVENT.EQUIPMENT_ADD,
        equipment_name: response.data.extraInfo.extraName,
        value: response.data.extraInfo.maxCount,
      })
    }
  }

  return (
    <Container
      title={EQUIPMENT_TITLE}
      breadcrumbList={breadcrumbList}
      currentPageLabel={EQUIPMENT_ADD_BREADCRUMB}
      withNavigation={isOperator}
    >
      <EquipmentDetailsForm
        handleSubmit={handleSubmit}
        initialValues={initialFormValues}
        isLoading={loading}
      />
    </Container>
  )
}
