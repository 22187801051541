import { useMemo } from 'react'
import { useAppSelector } from 'redux/hooks'
import { isOperatorSelector } from 'redux/login/selectors'

export const useReadOnlyMode = (year?: string | number) => {
  const isOperator = useAppSelector(isOperatorSelector)

  const isReadonlyMode = useMemo(() => {
    const currentYear = new Date().getFullYear()
    const isCurrentOrFutureYear = year ? Number(year) >= currentYear : true

    return !isCurrentOrFutureYear && !isOperator
  }, [year, isOperator])

  return isReadonlyMode
}
