import { Country, Location } from 'api/references/types'
import { ListItemType } from 'api/types'

export type GetVehiclesListResponse = {
  vehicles: ListItemType<Vehicle>
}

export type Vehicle = {
  id: string
  name: string
  groupName: string
  uniqueId: string
  sippCode: string
  image: string
  selected: boolean
  companyClass?: string
  hirePoints?: number[]
}

export type GetStopSalesListResponse = {
  stopSells: ListItemType<StopSales>
}

type StopSales = {
  id: string
  country: string
  city: string
  location: string
  carClass: string
  from: string
  to: string
  applicable: StopSalesRange
  created: string
}

export type StopSalesAdd = {
  id?: number
  countryId?: number
  contryName?: string
  from?: string
  to?: string
  applicable?: StopSalesRange
  comments?: string
  companyID?: number
  cities: number[] | null
  locations: number[] | null
  countries: number[] | null
  carClasses: string[] | null
}

export type StopSaleEditDto = {
  id: number
  carClasses: string[]
  countryName: string
  countryId: number
  cities: string[]
  locations: string[]
  countries: string[]
  from: string
  to: string
  applicable: StopSalesRange
  created: string
}

export type FullCompanyStopSalesDto = {
  id?: number
  countryId?: number
  countryName?: string
  cities?: Country[]
  locations?: Location[]
  from?: string
  to?: string
  carClasses?: string[]
  applicable?: StopSalesRange
  comments?: string
  created?: string
}

export enum StopSalesRange {
  Both = 'Both',
  PickUp = 'Pick-up',
  DropOff = 'Drop-off',
}

export type AddVehicleRequest = {
  carId?: number
  sippCode?: string
  companyClass?: string
  carGroupId?: number
  transmission?: string
  airConditioning?: string
  fuel?: string
  doors?: string
  sits?: string
  driverAgeRange?: DriverAgeRange
  hirePoints?: number[]
  carGroupName?: string
}

export type FullVehicle = {
  vehicleInfo: AddVehicleRequest & {
    id?: string
    carName?: string
    carGroupName?: string
  }
}

export enum Transmission {
  NA = 'N/A',
  Automatic = 'Automatic',
  Manual = 'Manual',
}

export enum AirConditioning {
  NA = 'N/A',
  Yes = 'Yes',
  No = 'No',
}

export enum Fuel {
  NA = 'N/A',
  Petrol = 'Petrol',
  Diesel = 'Diesel',
  Hybrid = 'Hybrid',
  Electric = 'Electric',
  Gas = 'Gas',
  Hydrogen = 'Hydrogen',
  MultiFuel = 'MultiFuel',
  Ethanol = 'Ethanol',
}

export type DriverAgeRange = {
  rangeType: DriverAgeRangeType
  items: DriverAgeRangeItem[]
}

export enum DriverAgeRangeType {
  PerRental = 'PerRental',
  PerDay = 'PerDay',
}

export type DriverAgeRangeItem = {
  ageStart: string
  ageEnd: string
  price: string
  maxPrice: string
}

type Equipment = {
  id: number
  groupId: string
  uniqueId: string
  name: string
  maxCount: number
  isAvailable: boolean
  count: number
  sum: number
  maxSum: number
  refersToLocation: string
  applyTo: string
}

export type GetEquipmentsListResponse = {
  extras: ListItemType<Equipment>
}

export type SeasonRange = {
  start: string
  end: string
  description?: string
  seasonRangeId?: number
  uniqueId?: string
}

export type DailyRange = {
  rangeStart: string
  rangeEnd: string
  rangeType: 'Fixed' | 'Daily'
  dailyRangeId?: number
  description?: string
  uniqueId?: string
}

export type HirePoint = {
  id: string
  name: string
}

export type FullCompanyEquipmentDto = {
  id?: number
  extraId?: string
  extraName?: string
  seasonRanges?: SeasonRange[]
  priceFor?: PaymentPeriod
  price?: string
  maxAmount?: string
  maxCount?: number
  hirePoints?: HirePoint[]
}

export type EquipmentAdd = Omit<FullCompanyEquipmentDto, 'id' | 'extraName'>

export enum PaymentPeriod {
  Rent = 1,
  Day = 2,
}

export type PriceListResponse = {
  priceList: ListItemType<PriceList>
}

export type PriceList = {
  id: number
  country: string
  location: string
  seasonRange: string
  dailyRange: string
  startPeriod: string
  endPeriod: string
  isEnabled: boolean
}

export type FullPriceList = {
  id?: number
  isActive?: boolean
  year?: string
  seasonRanges?: SeasonRange[]
  dailyRanges?: DailyRange[]
  priceListType: PriceListType
  comment?: string
  hirePoints?: number[]
  rates?: Rate[]
}

type Rate = {
  carClass: string
  seasonRange: string
  dailyRange: string
  price: number
}

export type PriceListAdd = Omit<
  FullPriceList,
  'id' | 'rates' | 'seasonRanges'
> & {
  companyID: number
  seasonRanges: SeasonRangeAdd[]
}

export type SeasonRangeAdd = {
  start: {
    day: number
    month: number
  }
  end: {
    day: number
    month: number
  }
  seasonRangeId?: number
}

export type AddOrEditPriceList = {
  id?: number
  isActive?: boolean
  year?: string
  dailyRanges?: DailyRange[]
  priceListType: PriceListType
  comment?: string
  hirePoints?: number[]
  rates?: []
  seasonRanges: SeasonRangeAdd[]
}

export enum PriceListType {
  MapByCompanyClass = 'MapByCompanyClass',
  MapBySipp = 'MapBySIPP',
}

export type CarClassItem = {
  carClass: string
  carName: string
}

export type PriceListRates = {
  availableCarClasses: CarClassItem[]
  seasonRangeDescription?: string
  dailyRangeDescription?: string
  priceListType?: PriceListType
  comment?: string
  country?: string
  location?: string
  year?: string
  cars: ListItemType<PriceListCar>
  seasonRanges: Required<SeasonRange>[]
  dailyRanges: Required<DailyRange>[]
}

export type PriceListCar = {
  carClass: string
  carType: string
  carName: string
  rates: PriceListRate[]
}

export type PriceListRate = {
  seasonRangeId: number
  dailyRangeId: number
  price: number
}

export type PriceListVehicle = {
  class: string
  model: string
  sipp: string
}

export type PriceListRateEdit = {
  seasonRangeId: number
  carClass: string
  price: number
  dailyRangeId: number
}

export type VehicleInfo = {
  airConditioning: AirConditioning
  transmission: Transmission
  fuel: Fuel
  sits: string
  doors: string
  carGroupId: string
}
