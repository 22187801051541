import { Modal } from 'ui/molecules/Modal'
import { HTTPClientTypes, locationsApi } from 'api'
import { AvailableEquipmentList, EditModalProps } from './types'
import { EquipmentItem } from './components/EquipmentItem'
import { useEffect, useState } from 'react'
import { AVAILABLE_EQUIPMENTS_MODAL } from 'texts/locationList'
import { useDispatch } from 'react-redux'
import { setNotificationMessage } from 'redux/notifications/slice'
import styles from './styles.module.scss'

export const EquipmentListModal = ({
  onClose,
  companyId,
  hirePointId,
}: EditModalProps) => {
  const [availableEquipmentList, setAvailableEquipmentList] =
    useState<AvailableEquipmentList>([])

  const dispatch = useDispatch()

  useEffect(() => {
    const fetchAvailableEquipmentList = async () => {
      try {
        const availableEquipmentListResponse =
          await locationsApi.getAvailableEquipmentList(
            companyId,
            hirePointId,
            0,
            0
          )
        const availableEquipmentListItems =
          availableEquipmentListResponse.data.extras.pageItems
        setAvailableEquipmentList(availableEquipmentListItems)
      } catch (e) {
        dispatch(
          setNotificationMessage({
            notificationMessage: (e as HTTPClientTypes.ErrorResponse)
              .responseStatus.message,
            type: 'error',
          })
        )
      }
    }
    fetchAvailableEquipmentList()
  }, [])

  return (
    <Modal
      position="center-horizontal"
      onClose={onClose}
      offsetVariant="none"
      isDelimiter
      title={AVAILABLE_EQUIPMENTS_MODAL.TITLE}
      headerSize="large"
    >
      <div className={styles.content}>
        <div className={styles.list}>
          {availableEquipmentList.map((equipment, equipmentIndex) => (
            <EquipmentItem key={equipmentIndex} item={equipment.name} />
          ))}
        </div>
      </div>
    </Modal>
  )
}
