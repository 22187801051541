import { TYPOGRAPHY_COLORS } from 'constants/TypographyColors'
import { SVGIcon } from './SvgIcon/SvgIcon'
import { IconProps } from './types'

export const RotateTimeIcon = ({
  size,
  color = 'grey400',
  className,
}: IconProps) => (
  <SVGIcon size={size} color={color} viewBox="0 0 16 16" className={className}>
    <path
      d="M8 5.5V8L6.75 9.25"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M13.5938 7.37529C13.4537 6.00032 12.8123 4.72505 11.792 3.79277C10.7717 2.86049 9.44394 2.33646 8.06195 2.32068C6.67996 2.30489 5.34053 2.79845 4.29921 3.70718C3.25789 4.61591 2.58757 5.87621 2.41614 7.24762C2.24472 8.61902 2.58419 10.0055 3.36978 11.1426C4.15537 12.2797 5.3321 13.0878 6.67545 13.4127C8.0188 13.7376 9.43474 13.5565 10.6531 12.9041C11.8715 12.2516 12.807 11.1735 13.2812 9.87529M13.5938 13.0003V9.87529H10.4688"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SVGIcon>
)
