export const PATH = {
  VEHICLE_LIST: 'vehicle/list',
  VEHICLE_ADD: 'vehicle/add',
  STOP_SELLS_LIST: 'stopsell/list',
  ADD_STOP_SELL: 'stopsell/add',
  FREE_SELLS_LIST: 'freesell/list',
  ADD_FREE_SALE: 'freesell/add',
  ADD_EQUIPMENTS: 'extra/add',
  EQUIPMENTS_LIST: 'extra/list',
  AVAILABLE_VEHICLE_LIST: 'vehicle/availableList',
  PRICE_LISTS: 'rates/priceList',
  PRICE_LIST_ADD: 'rates/priceList/add',
  PRICE_LIST_FILTERS: 'rates/priceList/filtersData',
  PRICE_LIST_VEHICLE_CLASSES: 'vehicle/classes',
  STOP_SALE_ADD: 'stopsell/add',
  STOP_SALE_FILTERS: 'stopsell/filtersData',
  VEHICLE_INFO: 'vehicleInfoBySipp',
}
