import { useEffect, useMemo, useState } from 'react'
import { useAppSelector } from 'redux/hooks'
import { fleetApi } from 'api'
import { useNavigate, useParams } from 'react-router-dom'
import { FullCompanyEquipmentDto, PaymentPeriod } from 'api/fleet/types'
import { FormValues } from '../EquipmentDetailsForm/types'
import {
  EQUIPMENT_DETAILS,
  EQUIPMENT_DETAILS_BREADCRUMB,
  EQUIPMENT_TITLE,
} from 'texts/equipmentDetails'
import { EquipmentDetailsForm } from '../EquipmentDetailsForm'
import { Container } from 'ui/molecules/Container'
import { isOperatorSelector } from 'redux/login/selectors'
import { useCompanyInfo } from 'ui/hooks/useCompanyInfo'
import { getDetailsBreadcrumbsList } from 'utils/getDetailsBreadcrumbsList'
import { useApiRequest } from 'hooks/useApiRequest'
import { setNotificationMessage } from 'redux/notifications/slice'
import { useDispatch } from 'react-redux'

export const EquipmentDetails = () => {
  const [equipmentInfo, setEquipmentInfo] = useState<FullCompanyEquipmentDto>(
    {}
  )
  const { id } = useParams()

  const isOperator = useAppSelector(isOperatorSelector)
  const updateEquipmentRequest = useApiRequest(
    (values) => fleetApi.updateEquipment(id || '', values),
    undefined,
    undefined,
    undefined,
    false
  )
  const getEquipmentInfoRequest = useApiRequest((id) =>
    fleetApi.getEquipmentInfo(id)
  )

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { companyId } = useCompanyInfo(isOperator)

  const breadcrumbList = getDetailsBreadcrumbsList(
    isOperator,
    String(companyId),
    'equipments'
  )

  useEffect(() => {
    const getEquipmentDetails = async () => {
      if (id) {
        const response = await getEquipmentInfoRequest.apiRequest(id)
        if (response) {
          setEquipmentInfo(response.data.extraInfo)
        }
      }
    }
    getEquipmentDetails()
  }, [id])

  const handleSubmit = async (data: Partial<FormValues>) => {
    const values = {
      ...data,
      extraId: Number(data.extraId),
      price: Number(data.price),
      companyId: Number(companyId),
      maxCount: Number(data.maxCount),
      maxAmount: Number(data.maxAmount),
      hirePoints: data.hirePoints?.map((item) => Number(item.value)),
      priceFor: Number(data.priceFor),
    }

    const response = await updateEquipmentRequest.apiRequest(values)
    if (response) {
      dispatch(
        setNotificationMessage({
          notificationMessage: EQUIPMENT_DETAILS.SUCCESS_EDIT,
        })
      )
      navigate(-1)
    }
  }

  const initValues = useMemo(
    () => ({
      maxCount: String(equipmentInfo.maxCount) || '',
      hirePoints: equipmentInfo.hirePoints
        ? equipmentInfo.hirePoints.map((item) => ({
            value: `${item.id}`,
            label: `${item.name}`,
          }))
        : [],
      price: `${equipmentInfo.price}`,
      maxAmount:
        Number(equipmentInfo.priceFor) === PaymentPeriod.Rent
          ? ''
          : `${equipmentInfo.maxAmount}`,
      extraId: String(equipmentInfo.extraId) || '',
      priceFor: String(equipmentInfo.priceFor) || '',
    }),
    [equipmentInfo]
  )

  return !getEquipmentInfoRequest.loading ? (
    <Container
      title={EQUIPMENT_TITLE}
      breadcrumbList={breadcrumbList}
      currentPageLabel={EQUIPMENT_DETAILS_BREADCRUMB}
      withNavigation={isOperator}
    >
      <EquipmentDetailsForm
        handleSubmit={handleSubmit}
        initialValues={initValues}
        isLoading={updateEquipmentRequest.loading}
      />
    </Container>
  ) : null
}
