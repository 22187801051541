import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react'
import { Dots } from './components/Dots'
import {
  getTranslateXValue,
  goToSlide,
  nodeDimensions,
  getCurrentPage,
  getPageX,
  removeTransform,
  copyItems,
} from './utils'
import { useCarouselWidth } from './hooks/useCarouselWidth'
import { joinClasses } from 'utils/joinClasses'
import { CarouselProps } from './types'
import styles from './styles.module.scss'

const DRAG_SPEED = 1
const DEFAULT_OFFSET = 12

export const Carousel = ({
  children,
  speed = 500,
  settings,
  autoScrollInterval = 0,
  disableInfiniteScroll = false,
}: CarouselProps) => {
  const outOfBoundsTimerRef = useRef<NodeJS.Timeout | null>(null)
  const temporaryDisableTimerRef = useRef<NodeJS.Timeout | null>(null)
  const autoScrollIntervalRef = useRef<NodeJS.Timeout | null>(null)

  const isInitRef = useRef(false)
  const draggableContainerRef = useRef<HTMLDivElement>(null)
  const slidesRef = useRef<Array<HTMLDivElement | null>>([])
  const carouselRef = useRef<HTMLDivElement>(null)
  const [isDrag, setIsDrag] = useState(false)
  const [page, setPage] = useState<number>(0)
  const [startX, setStartX] = useState(0)
  const [currentTransLeftOffset, setCurrentTransLeftOffset] = useState(0)
  const [disableDrag, setDisableDrag] = useState(false)
  const [disableEvents, setDisableEvents] = useState(false)
  const shownSlides = useMemo(() => {
    const shown = settings.shownSlides
    setPage(shown)
    return shown
  }, [settings])
  const showPages = shownSlides < children.length
  const shownSlidesWithoutSelected = shownSlides - 1

  const slides = useMemo(() => {
    if (showPages) {
      return [
        ...children.slice(-shownSlides),
        ...children,
        ...copyItems(children, shownSlides + shownSlidesWithoutSelected),
      ]
    }

    return children
  }, [children, shownSlides, showPages, shownSlidesWithoutSelected])
  const slidesWithoutNotSelected = slides.length - shownSlidesWithoutSelected

  const { slideWidth, fullWidth } = useCarouselWidth({
    carouselRef,
    shownSlides,
    slidesLength: slides.length,
  })

  const temporaryDisableDrag = useCallback(() => {
    setDisableDrag(true)

    temporaryDisableTimerRef.current = setTimeout(() => {
      setDisableDrag(false)
    }, speed)
  }, [speed])

  useEffect(() => {
    slidesRef.current = slidesRef.current.slice(0, slides.length)
  }, [slides])

  // scroll to slide
  useEffect(() => {
    const draggableContainer = draggableContainerRef.current
    if (draggableContainer && !isDrag && fullWidth) {
      const slide = (slidesRef.current as Array<HTMLDivElement>)[0]
      const currentSpeed = isInitRef.current ? speed : 0
      goToSlide({
        container: draggableContainer,
        speed: currentSpeed,
        position: showPages
          ? page * nodeDimensions(slide as HTMLDivElement).width
          : 0,
      })
      isInitRef.current = true

      if (showPages) {
        if (outOfBoundsTimerRef.current) {
          clearTimeout(outOfBoundsTimerRef.current)
        }

        // going out of bounds
        outOfBoundsTimerRef.current = setTimeout(() => {
          const newPage = getCurrentPage({
            slidesWithoutNotSelected,
            shownSlides,
            page,
          })

          if (newPage !== page) {
            slide &&
              goToSlide({
                container: draggableContainer,
                speed: 0,
                position: newPage * nodeDimensions(slide).width,
              })
            setPage(newPage)
          }
        }, currentSpeed)
      }
    }
  }, [
    isDrag,
    speed,
    fullWidth,
    shownSlides,
    page,
    slideWidth,
    showPages,
    slidesWithoutNotSelected,
  ])

  useEffect(
    () => () => {
      if (outOfBoundsTimerRef.current) {
        clearTimeout(outOfBoundsTimerRef.current)
      }

      if (temporaryDisableTimerRef.current) {
        clearTimeout(temporaryDisableTimerRef.current)
      }
    },
    []
  )

  useEffect(() => {
    if (autoScrollInterval && !isDrag) {
      autoScrollIntervalRef.current = setInterval(() => {
        setPage((prevPage) => prevPage + 1)
      }, autoScrollInterval)
    }

    return () => {
      clearInterval(autoScrollIntervalRef.current as NodeJS.Timeout)
    }
  }, [isDrag, autoScrollInterval, page])

  const startDrag = (e: React.MouseEvent | React.TouchEvent) => {
    e.preventDefault()
    if (disableDrag || !showPages) {
      return
    }

    const draggableContainer = draggableContainerRef.current
    removeTransform(draggableContainer)

    setStartX(getPageX(e) - (carouselRef.current as HTMLDivElement).offsetLeft)
    setCurrentTransLeftOffset(
      getTranslateXValue((draggableContainer as HTMLDivElement).style.transform)
    )
    setIsDrag(true)
  }

  const endDrag = () => {
    if (!isDrag || !showPages) {
      return
    }

    const draggableContainer = draggableContainerRef.current
    const slide = slidesRef.current[0]
    const newPage = Math.round(
      Math.abs(
        getTranslateXValue(
          (draggableContainer as HTMLDivElement).style.transform
        ) / nodeDimensions(slide as HTMLDivElement).width
      )
    )

    setPage(newPage)
    setIsDrag(false)
    temporaryDisableDrag()
    setDisableEvents(false)
  }

  const drag = (e: React.MouseEvent | React.TouchEvent) => {
    if (!isDrag || !showPages) {
      return
    }
    setDisableEvents(true)

    if (e.type === 'mousemove') {
      e.preventDefault()
    }

    const x = getPageX(e) - (carouselRef.current as HTMLDivElement).offsetLeft
    const walk = (x - startX) * DRAG_SPEED

    if (disableInfiniteScroll) {
      const slide = slidesRef.current[0]
      const currentPage = page - shownSlides
      const countPages = children.length - shownSlides
      const permissibleLength =
        nodeDimensions(slide as HTMLDivElement).width * currentPage
      const defaultLength =
        nodeDimensions(slide as HTMLDivElement).width * countPages

      if (
        walk > DEFAULT_OFFSET + permissibleLength ||
        (walk < 0 &&
          defaultLength + DEFAULT_OFFSET < Math.abs(walk) + permissibleLength)
      ) {
        return
      }
    }
    ;(
      draggableContainerRef.current as HTMLDivElement
    ).style.transform = `translateX(${currentTransLeftOffset + walk}px)`
  }

  const renderSlides = () =>
    slides.map((slide: JSX.Element, index: number) => {
      const key = index

      return (
        <div
          key={key}
          ref={(el) => {
            slidesRef.current[index] = el
            return el
          }}
          style={{ width: slideWidth }}
          className={joinClasses(styles['carousel-slide'], [
            styles['carousel-slide-disable-events'],
            disableEvents,
          ])}
        >
          {slide}
        </div>
      )
    })

  const onDotClick = useCallback(
    (newPage: number) => {
      setPage(
        getCurrentPage({
          slidesWithoutNotSelected,
          page: newPage,
          shownSlides,
        })
      )
    },
    [shownSlides, slides.length, slidesWithoutNotSelected]
  )

  return (
    <div className={styles.carousel}>
      <div
        className={styles['carousel-inner']}
        id="carousel"
        ref={carouselRef}
        onMouseDown={startDrag}
        onMouseUp={endDrag}
        onMouseLeave={endDrag}
        onMouseMove={drag}
        onTouchStart={startDrag}
        onTouchEnd={endDrag}
        onTouchMove={drag}
      >
        <div
          ref={draggableContainerRef}
          className={joinClasses(
            styles['carousel-draggable'],
            [styles['carousel-dragging'], isDrag],
            [styles['carousel-cursor'], showPages]
          )}
          style={{ transform: 'translateX(0px)', width: fullWidth }}
        >
          {renderSlides()}
        </div>
      </div>
      {showPages && (
        <Dots
          count={
            disableInfiniteScroll
              ? children.length - shownSlides + 1
              : children.length
          }
          active={getCurrentPage({
            slidesWithoutNotSelected,
            page,
            shownSlides,
          })}
          onDotClick={onDotClick}
          shownSlides={shownSlides}
        />
      )}
    </div>
  )
}
