import { EnvironmentVariablesService } from './env'

const GTM_KEY = EnvironmentVariablesService.getEnv('REACT_APP_GTM_KEY')

export const GTM = {
  script: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${GTM_KEY}');`,
  noscript: `<iframe src="https://www.googletagmanager.com/ns.html?id=${GTM_KEY}"height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
  initialize: function () {
    const noscript = document.createElement('noscript')
    noscript.innerHTML = this.noscript

    const script = document.createElement('script')
    script.innerHTML = this.script

    document.head.insertBefore(script, document.head.childNodes[0])
    document.body.insertBefore(noscript, document.body.childNodes[0])
  },
  dataLayer: function (
    dataLayer: Record<string, unknown>,
    dataLayerName = 'dataLayer'
  ) {
    if (window[dataLayerName as keyof typeof window]) {
      return window[dataLayerName as keyof typeof window].push(dataLayer)
    }
    const script = `window.${dataLayerName} = window.${dataLayerName} || []; window.${dataLayerName}.push(${JSON.stringify(
      dataLayer
    )})`
    const dataScript = document.createElement('script')
    dataScript.innerHTML = script
    document.head.insertBefore(dataScript, document.head.childNodes[0])
  },
}
