import React, { useCallback, useEffect, useRef, useState } from 'react'
import { joinClasses } from 'utils/joinClasses'
import { TOOLTIP_SCROLLABLE_PARENT } from './constants'
import { TooltipProps } from './types'
import { Popup } from 'ui/molecules/Popup'
import styles from './styles.module.scss'

export const Tooltip = ({
  children,
  popupContent,
  placement,
  arrowPosition,
  size = 'auto',
  alignSelf = 'start',
}: TooltipProps) => {
  const popupRef = useRef(null)
  const [visible, setVisible] = useState(false)

  const onOpen = () => {
    setVisible(true)
  }

  const onClose = useCallback(() => {
    setVisible(false)
  }, [])

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    onOpen()
  }

  useEffect(() => {
    if (visible) {
      const scrollableParent =
        document.getElementById(TOOLTIP_SCROLLABLE_PARENT) || document

      scrollableParent.addEventListener('scroll', onClose)

      return () => {
        scrollableParent.removeEventListener('scroll', onClose)
      }
    }

    return () => {}
  }, [visible, onClose])

  return (
    <>
      <div
        ref={popupRef}
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
        onClick={handleClick}
        className={joinClasses(styles.tooltip, alignSelf)}
      >
        {children}
      </div>
      {visible && (
        <div>
          <Popup
            elementRef={popupRef}
            placement={placement}
            arrowPosition={arrowPosition}
          >
            <div
              className={joinClasses(styles['tooltip-content'], [
                styles[size],
                size,
              ])}
            >
              {popupContent}
            </div>
          </Popup>
        </div>
      )}
    </>
  )
}
