import notFoundSrc from 'assets/img/notFound.png'
import { Typography } from 'ui/atoms/Typography'
import { NOT_FOUND } from 'texts/uiTexts'
import styles from './styles.module.scss'

export const NotFound = () => (
  <div className={styles.content}>
    <img src={notFoundSrc} className={styles.image} />
    <Typography name="subtitleWBold" className={styles.title}>
      {NOT_FOUND.TITLE}
    </Typography>
    <Typography name="body2WMedium" color="grey500">
      {NOT_FOUND.DESCRIPTION}
    </Typography>
  </div>
)
