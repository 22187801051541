const ARROW_HEIGHT = 8
const HALF = 2

export const getPopupPosition = (
  element: Element,
  popupElement: Element | null,
  placement?: string,
  arrowPosition?: 'center' | 'left' | 'right'
) => {
  if (!popupElement) {
    return {}
  }

  const { left, width, top, height } = element.getBoundingClientRect()
  const { width: popupWidth, height: popupHeight } =
    popupElement.getBoundingClientRect()

  let popupTop = top + height + ARROW_HEIGHT
  let popupLeft = left + width / HALF - popupWidth / HALF

  if (arrowPosition === 'left') {
    popupLeft = left
  }

  if (arrowPosition === 'right') {
    popupLeft = left + width * HALF - popupWidth
  }

  if (placement === 'top') {
    popupTop = top - popupHeight - ARROW_HEIGHT
  }

  if (popupLeft < 0) {
    popupLeft = 0
  } else if (popupLeft + popupWidth > window.innerWidth) {
    popupLeft = window.innerWidth - popupWidth
  }

  return {
    popupLeft,
    popupTop,
  }
}
