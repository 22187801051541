import { TYPOGRAPHY_COLORS } from 'constants/TypographyColors'
import { SVGIcon } from './SvgIcon/SvgIcon'
import { IconProps } from './types'

export const FileTextIcon = ({
  size,
  color = 'grey400',
  rotate,
}: IconProps) => (
  <SVGIcon size={size} color={color} rotate={rotate} viewBox="0 0 16 16">
    <path
      d="M9 1V4.2C9 4.41217 9.08428 4.61566 9.23431 4.76569C9.38434 4.91571 9.58783 5 9.8 5H13"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M11.5714 15H4.42857C4.04969 15 3.68633 14.8361 3.41842 14.5444C3.15051 14.2527 3 13.857 3 13.4444V2.55556C3 2.143 3.15051 1.74733 3.41842 1.45561C3.68633 1.16389 4.04969 1 4.42857 1H9.42857L13 4.88889V13.4444C13 13.857 12.8495 14.2527 12.5816 14.5444C12.3137 14.8361 11.9503 15 11.5714 15Z"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M6 10L7.33333 11L10 9"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SVGIcon>
)
