import { FiltersState } from 'redux/filters/types'

export const getSelectedFiltersCount = (filters: FiltersState['filters']) =>
  Object.entries(filters).reduce((acc, [, values]) => {
    let count = 0
    values.forEach((el) => {
      if (el.selected) {
        count++
      }
    })
    return acc + count
  }, 0)

export const getSelectedFiltersList = (filters: FiltersState['filters']) =>
  Object.fromEntries(
    Object.entries(filters).map(([key, values]) => [
      key,
      values.filter(({ selected }) => selected).map(({ value }) => value),
    ])
  )
