export const LOCATION_EDIT_TITLE = 'Location'
export const LOCATION_DETAILS_TITLE = 'Location details'

export const LOCATION_EDIT_CONFIRMATION = {
  CONFIRMATION_MODAL: {
    QUESTION: 'Are you sure that you want to make location ',
    INACTIVE: ' inactive?',
    ACTIVATE: ' active?',
  },
  SUCCESS_MODAL: {
    TITLE: 'Location was successfully inactived',
  },
}

export const SUCCESS_ACTIVATE = 'Location was successfully activated'
export const SUCCESS_INACTIVATE = 'Location was successfully inactivated'
