import { ratesHeadItems } from 'texts/rateDetails'

const PRICE_LIST_TYPE = {
  MapBySIPP: 'SIPP',
  MapByCompanyClass: 'Company class',
}

export const getRatesHeadItems = (
  priceListType?: keyof typeof PRICE_LIST_TYPE
) => {
  if (priceListType) {
    const headItemValue = PRICE_LIST_TYPE[priceListType]
    return [headItemValue, 'Car model']
  } else {
    return ratesHeadItems
  }
}
