import React, { useState, useCallback, useRef } from 'react'
import { DatePickerProps } from './types'
import { useOutsideClick } from 'ui/hooks/useOutsideClick'
import { Input } from 'ui/atoms/Input'
import { format } from 'date-fns'
import { DateDropdown } from './components/DateDropdown'
import { DATEPICKER_DEFAULT_FORMAT } from 'constants/date'
import styles from './styles.module.scss'

export const DatePicker = ({
  size = 'large',
  selectedDay,
  handleDayChange,
  label,
  placeholder,
  tooltip,
  error,
  isRequired,
  disabled,
  dateFormat = DATEPICKER_DEFAULT_FORMAT,
}: DatePickerProps) => {
  const fromInputRef = useRef(null)
  const [showDatePicker, setShowDatePicker] = useState(false)
  const closeDatePicker = useCallback(() => {
    setShowDatePicker(false)
  }, [])

  const onIconClick = () => setShowDatePicker(!showDatePicker)

  useOutsideClick(fromInputRef.current, closeDatePicker, {
    enabled: true,
    additionalElements: [],
  })

  const handleOpen = () => {
    setShowDatePicker(true)
  }

  return (
    <div className={styles['date-picker']} ref={fromInputRef}>
      <Input
        readonly
        value={selectedDay ? format(new Date(selectedDay), dateFormat) : ''}
        iconRight="CalendarIcon"
        iconSize="medium"
        placeholder={placeholder}
        iconSpace="small"
        iconColor="blue700"
        size={size}
        label={label}
        disabled={disabled}
        tooltip={tooltip}
        error={error}
        iconOnClickRight={onIconClick}
        onClick={handleOpen}
        typographyName="body2WMedium"
        isRequired={isRequired}
      />
      {showDatePicker && !disabled && (
        <DateDropdown
          selectedDay={selectedDay ? new Date(selectedDay) : undefined}
          changeValue={handleDayChange}
          handleClose={closeDatePicker}
        />
      )}
    </div>
  )
}
