import { joinClasses } from 'utils/joinClasses'
import { PhoneCodeProps } from './types'
import { Typography } from 'ui/atoms/Typography'
import { ArrowBottomIcon } from 'ui/icons'
import { getSmallFlagSrc } from 'utils/flags'
import styles from './styles.module.scss'

export const PhoneCode = ({
  countryCode,
  code,
  open,
  showCodes,
  selectRef,
  disabled,
}: PhoneCodeProps) => (
  <div className={styles['phone-code']}>
    <div
      className={styles['phone-code-country']}
      onClick={disabled ? undefined : open}
      ref={selectRef}
    >
      {countryCode && (
        <img
          src={getSmallFlagSrc(countryCode.toUpperCase())}
          alt="flag"
          className={styles['phone-code-flag']}
        />
      )}
      {code && (
        <Typography name="Button2" className={styles['phone-code-number']}>
          +{code}
        </Typography>
      )}
      <ArrowBottomIcon
        size="small"
        color="blackMaster"
        className={joinClasses(styles['phone-code-arrow'], [
          styles.up,
          showCodes,
        ])}
      />
    </div>
  </div>
)
