import { ContentWrapperProps } from './types'
import { Typography } from '../Typography'
import { joinClasses } from 'utils/joinClasses'
import styles from './styles.module.scss'

export const ContentWrapper = ({
  title,
  additionalContent,
  contentPosition = 'right',
  children,
  className = '',
}: ContentWrapperProps) => (
  <div className={joinClasses(styles.wrapper, className)}>
    {title && (
      <div
        className={joinClasses(
          styles.header,
          styles[`content-${contentPosition}`]
        )}
      >
        <Typography markColor="red600Master" Tag="h5" name="H5" html={title} />
        {typeof additionalContent === 'string' ? (
          <Typography color="grey400" name="Subtitle4">
            {additionalContent}
          </Typography>
        ) : (
          additionalContent
        )}
      </div>
    )}
    <div className={styles.content}>{children}</div>
  </div>
)
