import React, { useState, useMemo, useCallback } from 'react'
import { createPortal } from 'react-dom'
import { GLOBAL_MODAL } from 'constants/DOM'
import { useHideScroll } from 'ui/hooks/useHideScroll'
import { useOutsideClick } from 'ui/hooks/useOutsideClick'
import { getPopupPosition } from './utils'
import { PopupProps } from './types'
import { useArrowPosition } from 'ui/hooks/useArrowPosition'
import styles from './styles.module.scss'

const OVERLAY_OFFSET = 1

export const Popup = ({
  children,
  onClose,
  elementRef,
  isOverlay,
  placement,
  arrowPosition,
}: PopupProps) => {
  const [popupElement, setPopupElement] = useState<Element | null>(null)
  const { popupLeft, popupTop } = useMemo(
    () =>
      getPopupPosition(
        elementRef.current as Element,
        popupElement,
        placement,
        arrowPosition
      ),
    [popupElement, elementRef.current]
  )

  const { arrowLeft, arrowTop, arrowTransform } = useArrowPosition(
    elementRef.current,
    null,
    placement,
    arrowPosition
  )

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose()
    }
  }, [])

  useHideScroll(!isOverlay)
  useOutsideClick(popupElement, handleClose)

  return createPortal(
    <>
      {isOverlay && (
        <div
          className={styles['popup-overlay']}
          style={{ top: arrowTop - OVERLAY_OFFSET }}
        />
      )}
      <div
        ref={setPopupElement}
        className={styles.popup}
        style={{ left: popupLeft, top: popupTop }}
      >
        {children}
        <div
          className={styles['popup-arrow']}
          style={{ left: arrowLeft, top: arrowTop, transform: arrowTransform }}
        />
      </div>
    </>,
    document.getElementById(GLOBAL_MODAL) as HTMLElement
  )
}
