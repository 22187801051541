import { joinClasses } from 'utils/joinClasses'
import { Typography } from '../Typography'
import { ToggleProps } from './types'
import styles from './styles.module.scss'

export const Toggle = ({ value, statusLabels, onChange }: ToggleProps) => {
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    onChange()
  }

  return (
    <div className={styles.container}>
      {statusLabels && (
        <Typography name="Button2" color={value ? 'blue700' : 'grey500'}>
          {value ? statusLabels.active : statusLabels.inactive}
        </Typography>
      )}
      <div
        className={joinClasses(styles.toggle, [styles.active, value])}
        onClick={handleClick}
      >
        <span className={joinClasses(styles.switch, [styles.active, value])} />
      </div>
    </div>
  )
}
