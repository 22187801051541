import { useState, useMemo, forwardRef } from 'react'
import { joinClasses } from 'utils/joinClasses'
import { DEFAULT_PAGE_START } from 'ui/atoms/Autocomplete/components/Pagination/constants'
import { SearchCodes } from '../SearchCodes'
import { DropdownProps } from './types'
import { useDidUpdate } from 'hooks/useDidUpdate'
import { getMatch } from 'utils/match'
import { Pagination } from 'ui/atoms/Autocomplete/components/Pagination'
import { addPlus } from '../Codes/utils'
import { Codes } from '../Codes'
import styles from './styles.module.scss'

const PAGE_LIMIT = 5

export const Dropdown = forwardRef<HTMLDivElement, DropdownProps>(
  ({ corList, locales, cor, changeCOR, spaceForError }, ref) => {
    const [searchValue, setSearchValue] = useState('')
    const initialPage = useMemo(() => {
      if (!cor) {
        return 1
      }
      const index = corList.findIndex(({ code }) => cor.code === code)
      const page = index / PAGE_LIMIT

      return index % PAGE_LIMIT !== 0 ? Math.ceil(page) : page + 1
    }, [])
    const [page, setPage] = useState(initialPage)

    useDidUpdate(() => {
      setPage(DEFAULT_PAGE_START)
    }, [searchValue])

    const filteredList = useMemo(() => {
      if (searchValue) {
        return corList.filter(
          ({ name, phoneCode }) =>
            getMatch(searchValue, name) ||
            getMatch(searchValue, addPlus(phoneCode as number))
        )
      }

      return corList
    }, [searchValue, corList])

    const pages = useMemo(
      () => Math.ceil(filteredList.length / PAGE_LIMIT),
      [filteredList]
    )

    return (
      <div
        ref={ref}
        className={joinClasses(styles['phone-dropdown'], [
          styles['no-space-for-error'],
          spaceForError === 'none',
        ])}
        data-testid="phone-dropdown"
      >
        <SearchCodes
          placeholder={locales.searchPlaceholder}
          onChange={setSearchValue}
          value={searchValue}
        />
        <div className={styles['phone-dropdown-codes']}>
          <Codes
            corList={filteredList.slice(
              (page - 1) * PAGE_LIMIT,
              page * PAGE_LIMIT
            )}
            changeCOR={changeCOR}
            cor={cor}
            searchValue={searchValue}
          />
        </div>
        <Pagination
          changePage={setPage}
          page={page}
          pages={pages}
          ofLabel={locales.ofLabel}
        />
      </div>
    )
  }
)
