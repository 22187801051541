import { Typography } from 'ui/atoms/Typography'
import { HtmlTypography } from '@frontend/design_system'
import { useNavigate } from 'react-router-dom'
import styles from './styles.module.scss'
import { TipProps } from './types'
import { Button } from 'ui/atoms/Button'

export const Tip = ({
  topic,
  title,
  subTitle,
  startDescription,
  endDescription,
  linkForButton,
  buttonLabel,
  closeTips,
}: TipProps) => {
  const navigate = useNavigate()
  const goToPage = () => {
    if (linkForButton) {
      navigate(linkForButton)
    }
    closeTips()
  }
  return (
    <div className={styles.tip}>
      <Typography
        Tag="div"
        name="Subtitle3"
        className={styles.topic}
        color="blue700"
      >
        {topic}
      </Typography>
      {title && <Typography name="Subtitle1">{title}</Typography>}
      {subTitle && <Typography name="body2WBold">{subTitle}</Typography>}
      {startDescription && (
        <HtmlTypography
          name="body2WMedium"
          variant="default"
          color="blackMaster"
          html={startDescription}
        />
      )}
      {buttonLabel && linkForButton && (
        <Button variant="outline" label={buttonLabel} onClick={goToPage} />
      )}
      {endDescription && (
        <HtmlTypography
          name="body2WMedium"
          variant="primary"
          color="blackMaster"
          html={endDescription}
        />
      )}
    </div>
  )
}
