import { joinClasses } from 'utils/joinClasses'
import { Typography } from '../../../Typography'
import { DropdownHeaderProps } from './types'
import { ArrowTopIcon } from 'ui/icons'
import styles from './styles.module.scss'

export const DropdownHeader = ({
  renderLabel,
  onToggle,
  openDropdown,
  size = 'medium',
  borderRadius,
  noBorder,
}: DropdownHeaderProps) => (
  <Typography
    style={{ borderRadius }}
    Tag="div"
    name="Button1"
    className={joinClasses(
      styles['dropdown-header'],
      styles[size],
      [styles['dropdown-header-active'], openDropdown],
      [styles['dropdown-header-no-border'], noBorder]
    )}
    onClick={onToggle}
  >
    {renderLabel}
    <span className={styles['dropdown-header-item-icon']}>
      <ArrowTopIcon color="blackMaster" size="medium" />
    </span>
  </Typography>
)
