import { toggleFilter } from './../utils/toggleFilter'
import { useDispatch, useSelector } from 'react-redux'
import { filterItemsSelector } from 'redux/filters/selectors'
import { selectAllFiltersByType, setFilterItems } from 'redux/filters/slice'
import { FilterProps } from 'ui/atoms/Filter/types'
import { FILTERS_WITH_SEARCH, FILTERS_WITH_SORTING } from '../constants'
import { sortingSelector } from 'redux/activityHistory/selectors'
import { setSorting } from 'redux/activityHistory/slice'

export const useFilterSettings = (
  handleChangePage?: (page: number) => void
) => {
  const dispatch = useDispatch()
  const filterItems = useSelector(filterItemsSelector)
  const sortingData = useSelector(sortingSelector)

  const getFilterSettings = (key: string): FilterProps => ({
    onSelectAllItems: (isAllSelected) =>
      dispatch(selectAllFiltersByType({ key, isSelect: !isAllSelected })),
    listItemWidth: '240px',
    withSearch: FILTERS_WITH_SEARCH.includes(key),
    withSorting: FILTERS_WITH_SORTING.includes(key),
    withFiltering: key !== 'auditDate',
    onReset: () => dispatch(selectAllFiltersByType({ key, isSelect: false })),
    onSort: (type) => dispatch(setSorting({ key, type })),
    sortingType: sortingData.find((el) => el.key === key)?.type,
    headItem: key,
    items: filterItems[key]?.map((el) => ({
      value: el.value,
      selected: el.selected,
      onClick: () => {
        handleChangePage?.(1)
        dispatch(
          setFilterItems({
            ...filterItems,
            ...toggleFilter(key, el.value, filterItems),
          })
        )
      },
    })),
  })

  return getFilterSettings
}
