import { URLS } from 'constants/urls'
import { Route, Routes } from 'react-router-dom'
import { StopSaleAdd } from './components/StopSales/components/StopSaleAdd'
import { StopSales } from './components/StopSales'
import { Vehicles } from './components/Vehicles'
import { VehicleAdd } from './components/Vehicles/components/VehicleAdd'
import { Equipments } from './components/Equipments/Equipments'
import { EquipmentAdd } from './components/Equipments/components/EquipmentAdd/EquipmentAdd'
import { VehicleEdit } from './components/Vehicles/components/VehicleEdit'
import { EquipmentDetails } from './components/Equipments/components/EquipmentDetails'
import { PriceLists } from './components/PriceLists'
import { PriceListAdd } from './components/PriceLists/components/PriceListAdd'
import { NotFound } from 'ui/molecules/NotFound'
import { PriceListDetails } from './components/PriceLists/components/PriceListDetails'
import { Rates } from './components/PriceLists/components/Rates'
import { StopSaleEdit } from './components/StopSales/components/StopSaleEdit'

export const Fleet = () => (
  <Routes>
    <Route path={`${URLS.VEHICLES}/:companyId`} element={<Vehicles />} />
    <Route path={`${URLS.VEHICLES}`} element={<Vehicles />} />
    <Route path={`${URLS.VEHICLES}/:companyId/add`} element={<VehicleAdd />} />
    <Route path={`${URLS.VEHICLES}/add`} element={<VehicleAdd />} />
    <Route
      path={`${URLS.VEHICLES}/:companyId/:id/edit`}
      element={<VehicleEdit />}
    />
    <Route
      path={`${URLS.STOP_SALES}/:companyId/add`}
      element={<StopSaleAdd />}
    />
    <Route path={`${URLS.STOP_SALES}/add`} element={<StopSaleAdd />} />
    <Route path={`${URLS.STOP_SALES}`} element={<StopSales />} />
    <Route path={`${URLS.STOP_SALES}/:companyId`} element={<StopSales />} />
    <Route
      path={`${URLS.STOP_SALES}/:companyId/:id/edit`}
      element={<StopSaleEdit />}
    />
    <Route path={`${URLS.EQUIPMENTS}`} element={<Equipments />} />
    <Route path={`${URLS.EQUIPMENTS}/:companyId`} element={<Equipments />} />
    <Route
      path={`${URLS.EQUIPMENTS}/:companyId/add`}
      element={<EquipmentAdd />}
    />
    <Route path={`${URLS.EQUIPMENTS}/add`} element={<EquipmentAdd />} />
    <Route
      path={`${URLS.EQUIPMENTS}/:companyId/:id/edit`}
      element={<EquipmentDetails />}
    />
    <Route path={`${URLS.PRICE_LIST}/:companyId`} element={<PriceLists />} />
    <Route path={`${URLS.PRICE_LIST}`} element={<PriceLists />} />
    <Route
      path={`${URLS.PRICE_LIST}/:companyId/add`}
      element={<PriceListAdd />}
    />
    <Route path={`${URLS.PRICE_LIST}/add`} element={<PriceListAdd />} />
    <Route
      path={`${URLS.PRICE_LIST}/:companyId/:priceListId/edit`}
      element={<PriceListDetails />}
    />
    <Route
      path={`${URLS.PRICE_LIST}/:companyId/:priceListId/rates`}
      element={<Rates />}
    />
    <Route path="*" element={<NotFound />} />
  </Routes>
)
