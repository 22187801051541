import { ErrorResponse } from 'api/types'
import errorCodes from 'constants/errorCodes'
import { useState, useEffect, useRef } from 'react'
import { useAppDispatch } from 'redux/hooks'
import { setNotificationMessage } from 'redux/notifications/slice'
import { getErrorMessage } from 'utils/getErrorMessage'

interface ErrorSettings {
  withoutNotification: boolean
  isThrowError: boolean
  onError?: (error: unknown) => void
}

export const useApiRequest = <T>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  apiCall: (...args: any) => Promise<T>,
  errorMessage?: string,
  errorFromMessage?: boolean,
  errorSettings: ErrorSettings = {
    withoutNotification: false,
    isThrowError: false,
  },
  initLoading = true
) => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(initLoading)
  const [error, setError] = useState('')
  const lastRequest = useRef<(() => Promise<void>) | null>(null)
  const isProcessing = useRef(false)

  if (error && errorSettings.isThrowError) {
    throw new Error(error)
  }

  const handleError = (
    error: unknown,
    defaultMessage = errorCodes.Default,
    errorFromMessage?: boolean
  ) => {
    const errorResponse = error as ErrorResponse
    const errorMessageFromCode =
      getErrorMessage(errorResponse.responseStatus?.errorCode) || defaultMessage

    const errorMessage = errorFromMessage
      ? errorResponse.responseStatus.message
      : errorMessageFromCode

    if (errorSettings?.onError) {
      errorSettings?.onError(error)
    }
    if (errorSettings.withoutNotification) {
      setError(errorMessage)
    } else {
      dispatch(
        setNotificationMessage({
          notificationMessage: errorMessage,
          type: 'error',
        })
      )
    }
  }

  const processRequest = async (request: () => Promise<void>) => {
    isProcessing.current = true
    await request()
    isProcessing.current = false

    if (lastRequest.current) {
      const nextRequest = lastRequest.current
      lastRequest.current = null
      processRequest(nextRequest)
    }
  }

  const apiRequest = async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...args: any
  ): Promise<T | null> =>
    new Promise((resolve, reject) => {
      const request = async () => {
        try {
          setLoading(true)
          const response = await apiCall(...args)
          setLoading(false)
          resolve(response)
        } catch (error) {
          setLoading(false)
          handleError(error, errorMessage, errorFromMessage)
          reject(null)
        }
      }

      if (isProcessing.current) {
        lastRequest.current = request
      } else {
        processRequest(request)
      }
    })

  useEffect(() => {
    if (!isProcessing.current && lastRequest.current) {
      const nextRequest = lastRequest.current
      lastRequest.current = null
      processRequest(nextRequest)
    }
  }, [])

  return { apiRequest, loading, error }
}
