import { PUBLIC_URLS, URLS } from 'constants/urls'
import { ContentType, HttpResponse, ResponseFormat } from './types'
import { UNAUTHORIZED } from 'constants/statusCodes'

class UnauthorizedError extends Error {
  responseStatus: { errorCode: string }

  constructor(errorCode: string, message: string) {
    super(message)
    this.responseStatus = { errorCode }
    this.message = message
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const contentFormatter: Record<ContentType, (input: any) => any> = {
  [ContentType.JSON]: (input: unknown) =>
    input !== null && (typeof input === 'object' || typeof input === 'string')
      ? JSON.stringify(input)
      : input,
  [ContentType.FORM_DATA]: (input: Record<string, unknown>) =>
    Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      let value
      if (property instanceof Blob) {
        value = property
      } else if (typeof property === 'object' && property !== null) {
        value = JSON.stringify(property)
      } else {
        value = `${property}`
      }
      formData.append(key, value)
      return formData
    }, new FormData()),
}

export const request = async <T, E>(
  baseUrl: string,
  path: string,
  requestParams: RequestInit = { method: 'GET' },
  body?: unknown,
  format?: ResponseFormat,
  type = ContentType.JSON
): Promise<HttpResponse<T, E>> => {
  const payloadFormatter = contentFormatter[type || ContentType.JSON]

  const response = await fetch(`${baseUrl}${path}`, {
    ...requestParams,
    credentials: 'include',
    headers: {
      ...(type && type !== ContentType.FORM_DATA
        ? { 'Content-Type': type }
        : {}),
      ...(requestParams.headers || {}),
    },
    body: payloadFormatter(body),
  })
  const res = response as HttpResponse<T, E>

  if (
    res.status === UNAUTHORIZED &&
    !Object.values(PUBLIC_URLS).some((url) => url === window.location.pathname)
  ) {
    window.location.href = URLS.SIGNIN
    throw new UnauthorizedError('SessionExpired', 'session expired')
  }

  const data = await res[format || 'json']()
  try {
    if (res.ok) {
      res.data = data
    } else {
      res.error = data
    }
  } catch (e) {
    res.error = e as E
  }

  if (!response.ok) {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw res.error
  }

  return res
}
