import {
  AirConditioning,
  DriverAgeRange,
  DriverAgeRangeType,
  Fuel,
  Transmission,
} from 'api/fleet/types'
import {
  allSymbolsAndEnglishLettersRegex,
  lettersWithNumbersRegex,
} from 'constants/regex'
import { getRequiredRule } from 'utils/getValidationRules'
import { NOT_AVAILABLE } from '../../constants'
import { Rule } from 'ui/molecules/Form/components/FormItem/types'
import { VEHICLE_DETAILS_FORM_VALUES } from 'texts/vehicleDetails'
import { MultiselectItem } from 'ui/atoms/Multiselect/types'

export const initialFormValues = {
  car: '',
  sippCode: '',
  companyClass: '',
  carGroupId: '',
  transmission: Transmission.NA,
  airConditioning: AirConditioning.NA,
  fuel: Fuel.NA,
  hirePoints: [] as MultiselectItem[],
  sits: NOT_AVAILABLE,
  doors: NOT_AVAILABLE,
  driverAgeRange: {
    items: [],
    rangeType: DriverAgeRangeType.PerRental,
  } as DriverAgeRange,
}

export const VEHICLE_DETAILS_VALIDATION_RULES: { [key: string]: Rule[] } = {
  CAR: getRequiredRule(VEHICLE_DETAILS_FORM_VALUES.CAR.REQUIRED_MESSAGE),
  CAR_GROUP: getRequiredRule(
    VEHICLE_DETAILS_FORM_VALUES.CAR_GROUP.REQUIRED_MESSAGE
  ),
  HIRE_POINTS: getRequiredRule(
    VEHICLE_DETAILS_FORM_VALUES.HIRE_POINTS.REQUIRED_MESSAGE
  ),
  COMPANY_CLASS: [
    ...getRequiredRule(
      VEHICLE_DETAILS_FORM_VALUES.COMPANY_CLASS.REQUIRED_MESSAGE
    ),
    {
      type: 'pattern',
      value: allSymbolsAndEnglishLettersRegex,
      message: VEHICLE_DETAILS_FORM_VALUES.COMPANY_CLASS.VALIDATION_MESSAGE,
    },
  ],
  SIPP: [
    ...getRequiredRule(VEHICLE_DETAILS_FORM_VALUES.SIPP.REQUIRED_MESSAGE),

    {
      type: 'max',
      value: 8,
      message: VEHICLE_DETAILS_FORM_VALUES.SIPP.VALIDATION_MESSAGE,
    },
    {
      type: 'min',
      value: 2,
      message: VEHICLE_DETAILS_FORM_VALUES.SIPP.VALIDATION_MESSAGE,
    },
    {
      type: 'pattern',
      value: lettersWithNumbersRegex,
      message: VEHICLE_DETAILS_FORM_VALUES.SIPP.VALIDATION_MESSAGE,
    },
  ],
}

export const SIPP_CODE_LENGTH = 4
