import { Input } from 'ui/atoms/Input'
import { FormItem } from 'ui/molecules/Form/components/FormItem'
import { APPLICATION_FORM_VALUES } from 'texts/applications'
import styles from './styles.module.scss'

export const SupplierLocation = () => (
  <div className={styles['form-items-row']}>
    <FormItem
      id={APPLICATION_FORM_VALUES.COUNTRIES_FLEET.ID}
      className={styles['form-item']}
    >
      {({ value, error, onChange }) => (
        <Input
          value={value}
          placeholder=""
          size="large"
          error={error}
          readonly
          spaceForError="none"
          onChange={onChange}
          label={APPLICATION_FORM_VALUES.COUNTRIES_FLEET.LABEL}
        />
      )}
    </FormItem>
    <FormItem
      id={APPLICATION_FORM_VALUES.LOCATIONS_COUNT.ID}
      className={styles['form-item']}
    >
      {({ value, error, onChange }) => (
        <Input
          value={value}
          placeholder=""
          size="large"
          error={error}
          readonly
          spaceForError="none"
          onChange={onChange}
          label={APPLICATION_FORM_VALUES.LOCATIONS_COUNT.LABEL}
        />
      )}
    </FormItem>
  </div>
)
