import { Typography, Button } from '@frontend/design_system'
import styles from './styles.module.scss'
import { Table } from 'ui/molecules/Table'
import { detailsHeadItems, importRatesHeadItems } from 'texts/activityHistory'
import { useApiRequest } from 'hooks/useApiRequest'
import { auditApi, AuditApiTypes } from 'api'
import { useMemo } from 'react'

export const ImportDetails = ({ auditId }: { auditId: string }) => {
  const { apiRequest } = useApiRequest((state) =>
    auditApi.getChangedStructureRates(auditId, state)
  )

  const fileLink = async (
    state: AuditApiTypes.ChangedStructureRateFileState
  ) => {
    const response = await apiRequest(state)
    if (response) {
      const fileName = response.headers
        .get('content-disposition')
        ?.split('filename=')[1]
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName as string)
      document.body.appendChild(link)
      link.click()
    }
  }

  const formattedHeadItems = detailsHeadItems.map((el) => ({
    value: <Typography name="Subtitle3">{el}</Typography>,
  }))

  const formattedBodyItems = useMemo(
    () => [
      {
        items: [
          importRatesHeadItems.files,
          <Button
            size="auto"
            typographyName="Subtitle7"
            variant="link"
            label={importRatesHeadItems.download}
            onClick={() => fileLink('BeforeChanges')}
          />,
          <Button
            size="auto"
            typographyName="Subtitle7"
            variant="link"
            label={importRatesHeadItems.download}
            onClick={() => fileLink('AfterChanges')}
          />,
        ],
      },
    ],
    []
  )

  return (
    <div className={styles.table}>
      <Table
        headItems={formattedHeadItems}
        className={styles.tables}
        bodyItems={formattedBodyItems}
      />
    </div>
  )
}
