import { MENU_NAMES } from 'texts/menuNames'
import { Container } from 'ui/molecules/Container'
import { ApplicationList } from '../ApplicationList'
import { APPLICATION_STATUS } from 'modules/Applications/constants'
import { SEARCH_VALUES } from 'texts/applications'
import { SearchBy } from '../SearchBy'

export const ApplicationsArchive = () => (
  <Container
    title={MENU_NAMES.APPLICATIONS.SUBMENU_NAMES.ARCHIVE}
    rightBlock={
      <SearchBy
        searchKeys={SEARCH_VALUES}
        setData={() => {}}
        onSearch={() => {}}
        handleSelect={() => {}}
      />
    }
  >
    <ApplicationList status={APPLICATION_STATUS.ARCHIVE} />
  </Container>
)
