import { useMemo } from 'react'
import { PAYMENT_INFORMATION_FORM_VALUES } from 'texts/profileDetails'
import { Input } from 'ui/atoms/Input'
import { Select } from 'ui/atoms/Select/Select'
import { getValidationRules } from 'utils/getValidationRules'
import { paymentInformationFormValuesSetting as formValuesSetting } from './constants'
import { FormItem } from 'ui/molecules/Form/components/FormItem'
import { PaymentInformationFormProps } from './types'
import styles from './styles.module.scss'
import { FormValuesSetting } from 'types/form'

export const PaymentInformationForm = ({
  role,
  currencies,
  readOnly,
}: PaymentInformationFormProps) => {
  const selectValues = useMemo(
    () =>
      currencies.items.map((item) => ({
        label: item.name,
        value: item.id,
      })),
    [currencies]
  )

  const validationRules = (field: FormValuesSetting['key']) =>
    useMemo(() => getValidationRules(field, role), [role])

  return (
    <>
      <div className={styles['form-items-row']}>
        <FormItem
          id={PAYMENT_INFORMATION_FORM_VALUES.LEGAL_NAME.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.legalName)}
        >
          {({ value, error, onChange }) => (
            <Input
              value={value}
              placeholder={
                PAYMENT_INFORMATION_FORM_VALUES.LEGAL_NAME.PLACEHOLDER
              }
              size="large"
              error={error}
              spaceForError="auto"
              onChange={onChange}
              label={PAYMENT_INFORMATION_FORM_VALUES.LEGAL_NAME.LABEL}
              disabled={readOnly || formValuesSetting.legalName.disabled[role]}
              isRequired={formValuesSetting.legalName.required[role]}
            />
          )}
        </FormItem>
        <FormItem
          id={PAYMENT_INFORMATION_FORM_VALUES.CURRENCY.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.currency)}
        >
          {({ value, error, onChange }) => (
            <Select
              size="large"
              error={error}
              onChange={onChange}
              label={PAYMENT_INFORMATION_FORM_VALUES.CURRENCY.LABEL}
              items={selectValues}
              selectedValue={value}
              placeholder={PAYMENT_INFORMATION_FORM_VALUES.CURRENCY.PLACEHOLDER}
              isRequired
              disabled={readOnly || formValuesSetting.currency.disabled[role]}
            />
          )}
        </FormItem>
      </div>
      <div className={styles['form-items-row']}>
        <FormItem
          id={PAYMENT_INFORMATION_FORM_VALUES.REGISTRATION_NUMBER.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.registrationNumber)}
        >
          {({ value, error, onChange }) => (
            <Input
              value={value}
              placeholder={
                PAYMENT_INFORMATION_FORM_VALUES.REGISTRATION_NUMBER.PLACEHOLDER
              }
              size="large"
              error={error}
              spaceForError="auto"
              onChange={onChange}
              disabled={
                readOnly || formValuesSetting.registrationNumber.disabled[role]
              }
              label={PAYMENT_INFORMATION_FORM_VALUES.REGISTRATION_NUMBER.LABEL}
              isRequired={formValuesSetting.registrationNumber.required[role]}
            />
          )}
        </FormItem>
        <FormItem
          id={PAYMENT_INFORMATION_FORM_VALUES.VAT.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.vat)}
        >
          {({ value, error, onChange }) => (
            <Input
              value={value}
              placeholder={PAYMENT_INFORMATION_FORM_VALUES.VAT.PLACEHOLDER}
              size="large"
              error={error}
              spaceForError="auto"
              onChange={onChange}
              disabled={readOnly || formValuesSetting.vat.disabled[role]}
              label={PAYMENT_INFORMATION_FORM_VALUES.VAT.LABEL}
              isRequired={formValuesSetting.vat.required[role]}
            />
          )}
        </FormItem>
      </div>
      <div className={styles['form-items-row']}>
        <FormItem
          id={PAYMENT_INFORMATION_FORM_VALUES.BANK_NAME.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.bankName)}
        >
          {({ value, error, onChange }) => (
            <Input
              value={value}
              placeholder={
                PAYMENT_INFORMATION_FORM_VALUES.BANK_NAME.PLACEHOLDER
              }
              size="large"
              error={error}
              spaceForError="auto"
              onChange={onChange}
              disabled={readOnly || formValuesSetting.bankName.disabled[role]}
              label={PAYMENT_INFORMATION_FORM_VALUES.BANK_NAME.LABEL}
            />
          )}
        </FormItem>
        <FormItem
          id={PAYMENT_INFORMATION_FORM_VALUES.IBAN.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.iban)}
        >
          {({ value, error, onChange }) => (
            <Input
              value={value}
              placeholder={PAYMENT_INFORMATION_FORM_VALUES.IBAN.PLACEHOLDER}
              size="large"
              error={error}
              spaceForError="auto"
              onChange={onChange}
              disabled={readOnly || formValuesSetting.iban.disabled[role]}
              label={PAYMENT_INFORMATION_FORM_VALUES.IBAN.LABEL}
              isRequired={formValuesSetting.iban.required[role]}
            />
          )}
        </FormItem>
      </div>
      <div className={styles['form-items-row']}>
        <FormItem
          id={PAYMENT_INFORMATION_FORM_VALUES.SWIFT.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.swift)}
        >
          {({ value, error, onChange }) => (
            <Input
              value={value}
              placeholder={PAYMENT_INFORMATION_FORM_VALUES.SWIFT.PLACEHOLDER}
              size="large"
              error={error}
              spaceForError="auto"
              onChange={onChange}
              disabled={readOnly || formValuesSetting.swift.disabled[role]}
              label={PAYMENT_INFORMATION_FORM_VALUES.SWIFT.LABEL}
              isRequired={formValuesSetting.swift.required[role]}
            />
          )}
        </FormItem>
        <FormItem
          id={PAYMENT_INFORMATION_FORM_VALUES.BANK_ADDRESS.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.bankAddress)}
        >
          {({ value, error, onChange }) => (
            <Input
              value={value}
              placeholder={
                PAYMENT_INFORMATION_FORM_VALUES.BANK_ADDRESS.PLACEHOLDER
              }
              size="large"
              error={error}
              spaceForError="auto"
              onChange={onChange}
              disabled={
                readOnly || formValuesSetting.bankAddress.disabled[role]
              }
              label={PAYMENT_INFORMATION_FORM_VALUES.BANK_ADDRESS.LABEL}
            />
          )}
        </FormItem>
      </div>
      <div className={styles['form-items-row']}>
        <FormItem
          id={PAYMENT_INFORMATION_FORM_VALUES.ABA.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.aba)}
        >
          {({ value, error, onChange }) => (
            <Input
              value={value}
              placeholder={PAYMENT_INFORMATION_FORM_VALUES.ABA.PLACEHOLDER}
              size="large"
              error={error}
              spaceForError="auto"
              onChange={onChange}
              disabled={readOnly || formValuesSetting.aba.disabled[role]}
              label={PAYMENT_INFORMATION_FORM_VALUES.ABA.LABEL}
            />
          )}
        </FormItem>
        <FormItem
          id={PAYMENT_INFORMATION_FORM_VALUES.EMAIL.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.accountingEmail)}
        >
          {({ value, error, onChange }) => (
            <Input
              value={value}
              placeholder={PAYMENT_INFORMATION_FORM_VALUES.EMAIL.PLACEHOLDER}
              size="large"
              error={error}
              spaceForError="auto"
              onChange={onChange}
              disabled={
                readOnly || formValuesSetting.accountingEmail.disabled[role]
              }
              label={PAYMENT_INFORMATION_FORM_VALUES.EMAIL.LABEL}
            />
          )}
        </FormItem>
      </div>
    </>
  )
}
