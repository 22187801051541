import { TYPOGRAPHY_COLORS } from 'constants/TypographyColors'
import { SVGIcon } from './SvgIcon/SvgIcon'
import { IconProps } from './types'

export const PlusIcon = ({ size, color = 'grey400', className }: IconProps) => (
  <SVGIcon size={size} color={color} className={className} viewBox="0 0 20 20">
    <path
      d="M10.5 4.16626V15.8329"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.66504 10H16.3317"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVGIcon>
)
