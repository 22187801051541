import { URLS } from 'constants/urls'
import { Route, Routes } from 'react-router-dom'
import { LocationAdd } from './components/LocationAdd'
import { LocationEdit } from './components/LocationEdit'
import { LocationsList } from './components/LocationsList'
import { NotFound } from 'ui/molecules/NotFound'

export const Locations = () => (
  <Routes>
    <Route
      path={`${URLS.LIST_OF_LOCATIONS}/:companyId`}
      element={<LocationsList />}
    />
    <Route path={URLS.LIST_OF_LOCATIONS} element={<LocationsList />} />
    <Route
      path={`${URLS.LIST_OF_LOCATIONS}/:companyId/add`}
      element={<LocationAdd />}
    />
    <Route path={`${URLS.LIST_OF_LOCATIONS}/add`} element={<LocationAdd />} />
    <Route
      path={`${URLS.LIST_OF_LOCATIONS}/${URLS.EDIT}`}
      element={<LocationEdit />}
    />
    <Route path="*" element={<NotFound />} />
  </Routes>
)
