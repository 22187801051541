import { URLS } from 'constants/urls'
import { useMemo } from 'react'
import { MENU_NAMES } from 'texts/menuNames'

export const useNavigationItems = () => {
  const items = useMemo(
    () => [
      {
        label: MENU_NAMES.PROFILE_DETAILS.NAME,
        getPath: (id: string) =>
          `${URLS.PROFILE}${URLS.PROFILE_LIST}/${id}/edit`,
      },
      {
        label: MENU_NAMES.LOCATIONS.NAME,
        getPath: (id: string) =>
          `${URLS.LOCATIONS}${URLS.LIST_OF_LOCATIONS}/${id}`,
      },
      {
        label: MENU_NAMES.FLEET.SUBMENU_NAMES.VEHICLES,
        getPath: (id: string) => `${URLS.FLEET}${URLS.VEHICLES}/${id}`,
      },
      {
        label: MENU_NAMES.FLEET.SUBMENU_NAMES.EQUIPMENTS,
        getPath: (id: string) => `${URLS.FLEET}${URLS.EQUIPMENTS}/${id}`,
      },
      {
        label: MENU_NAMES.FLEET.SUBMENU_NAMES.PRICE_LISTS,
        getPath: (id: string) => `${URLS.FLEET}${URLS.PRICE_LIST}/${id}`,
      },
      {
        label: MENU_NAMES.FLEET.SUBMENU_NAMES.STOP_SALES,
        getPath: (id: string) => `${URLS.FLEET}${URLS.STOP_SALES}/${id}`,
      },
      {
        label: MENU_NAMES.TERMS_AND_CONDITIONS,
        getPath: (id: string) =>
          `${URLS.PROFILE}${URLS.TERMS_AND_CONDITIONS}/${id}`,
      },
    ],
    []
  )

  return items
}
