import { profileApi } from 'api'
import { URLS } from 'constants/urls'
import { Confirmation } from 'ui/molecules/Confirmation'
import { SuccessModal } from 'ui/molecules/Confirmation/components/SuccessModal'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MENU_NAMES } from 'texts/menuNames'
import {
  ARCHIVED_PROFILE_TITLE,
  PROFILE_ARCHIVE,
  PROFILE_ARCHIVE_CONFIRMATION,
} from 'texts/profileArchive'
import { SUCCESSFUL_CHANGE_MODAL } from 'texts/profileConfirmModal'
import { breadcrumbs } from 'constants/breadcrumbs'
import { Button } from 'ui/atoms/Button'
import { Container } from 'ui/molecules/Container'
import { RefreshIcon } from 'ui/icons'
import styles from './styles.module.scss'
import { ProfileDetailsForm } from 'modules/Profile/ProfileList/components/ProfileDetailsForm'
import { FormValues } from 'modules/Profile/ProfileList/components/ProfileDetailsForm/types'
import { useAppSelector } from 'redux/hooks'
import { isOperatorSelector } from 'redux/login/selectors'
import { useCompanyInfo } from 'ui/hooks/useCompanyInfo'
import { Typography } from 'ui/atoms/Typography'
import { useApiRequest } from 'hooks/useApiRequest'

export const ProfileRestore = () => {
  const [initialData, setInitialData] = useState<Partial<FormValues>>({})
  const [isFetching, setIsFetching] = useState(false)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)

  const isOperator = useAppSelector(isOperatorSelector)
  const { companyId } = useCompanyInfo(isOperator)
  const { apiRequest } = useApiRequest(
    () => profileApi.restoreCompany(Number(companyId)),
    '',
    true
  )

  const breadcrumbList = [breadcrumbs.archive]

  const navigate = useNavigate()

  const handleRestoreCompany = () => {
    setIsConfirmationModalOpen(true)
  }

  const handleModalClose = () => {
    setIsConfirmationModalOpen(false)
  }

  const handleConfirm = async () => {
    const response = await apiRequest()
    if (!response) {
      setIsConfirmationModalOpen(false)
    }
  }

  const handleRedirectToSupplier = () => {
    navigate(`${URLS.PROFILE}${URLS.PROFILE_LIST}/${companyId}/edit`, {
      state: [
        {
          link: URLS.PROFILE + URLS.PROFILE_LIST,
          label: MENU_NAMES.PROFILE_LIST.NAME,
        },
      ],
    })
  }

  useEffect(() => {
    const getInfo = async () => {
      if (companyId) {
        const response = await profileApi.getCompanyInfo(Number(companyId))
        setInitialData({
          ...response.data.companyInfo,
          companyCountryID: String(response.data.companyInfo.companyCountryID),
          companyCurrencyID: String(
            response.data.companyInfo.companyCurrencyID
          ),
          logo: response.data.companyInfo.logo
            ? [
                {
                  ...new File(['a'], 'logo'),
                  url: response.data.companyInfo.logo,
                },
              ]
            : [],
        })
        setIsFetching(true)
      }
    }
    getInfo()
  }, [])

  const header = (
    <>
      {isConfirmationModalOpen && (
        <Confirmation
          confirmModalProps={{
            question: `${PROFILE_ARCHIVE_CONFIRMATION.CONFIRMATION_MODAL.QUESTION} ${initialData?.companyName}?`,
            onConfirm: handleConfirm,
            onClose: handleModalClose,
            title: `${PROFILE_ARCHIVE.RESTORE} ${initialData.companyName}?`,
          }}
          successModalProps={{
            onClose: handleModalClose,
            title: PROFILE_ARCHIVE_CONFIRMATION.SUCCESS_MODAL.TITLE,
            buttonLabel:
              PROFILE_ARCHIVE_CONFIRMATION.SUCCESS_MODAL.BUTTON_LABEL,
            onConfirm: handleRedirectToSupplier,
            subTitle: `${initialData?.companyName} ${PROFILE_ARCHIVE_CONFIRMATION.SUCCESS_MODAL.SUBTITLE}`,
          }}
        />
      )}
      {isSuccessModalOpen && (
        <SuccessModal
          title={SUCCESSFUL_CHANGE_MODAL.TITLE}
          onClose={() => setIsSuccessModalOpen(false)}
        />
      )}
      <Typography name="H5">{ARCHIVED_PROFILE_TITLE}</Typography>
    </>
  )
  return isFetching ? (
    <Container
      title={header}
      rightBlock={
        <div className={styles['button-wrapper']}>
          <Button
            typographyName="Button2"
            label={PROFILE_ARCHIVE.RESTORE}
            iconPosition="right"
            onClick={handleRestoreCompany}
            icon={<RefreshIcon color="whiteMaster" />}
          />
        </div>
      }
      breadcrumbList={breadcrumbList}
      currentPageLabel={MENU_NAMES.PROFILE_DETAILS.NAME}
    >
      <ProfileDetailsForm
        initialValues={initialData}
        readOnly
        isLoading={false}
      />
    </Container>
  ) : null
}
