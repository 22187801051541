export const HEAD_ITEMS = {
  COMPANY_NAME: 'Company name',
  SUBMISSION: 'Submission',
  COMPANY_EMAIL: 'Company email',
  WEBSITE: 'Website address',
  TIME: 'Process time',
  ACTIONS: 'Actions',
}

export const OVERVIEW = 'Overview'

export const APPLICATIONS_TAB_KEYS = {
  WAITING_LIST: 'Waiting list',
  WAITING_CALL: 'Waiting for call',
  WAITING_SIGN: 'Waiting for sign',
  APPROVE: 'Approve',
  REJECTED: 'Rejected',
}

export const APPLICATION = {
  CONTACT_INFORMATION: 'Contact information',
  SUPPLIER_LOCATION: 'Supplier location',
  FLEET: 'Fleet',
  OTHER: 'Other information',
  STATUS_INFO: 'Status info',
}

export const APPLICATION_FORM_VALUES = {
  NAME: {
    ID: 'companyName',
    LABEL: 'Company name',
  },
  COUNTRY: {
    ID: 'companyCountryName',
    LABEL: 'Country',
  },
  CITY: {
    ID: 'city',
    LABEL: 'City',
  },
  STATE: {
    ID: 'state',
    LABEL: 'State / Region',
  },
  STREET: {
    ID: 'street',
    LABEL: 'Street',
  },
  BUILDING: {
    ID: 'building',
    LABEL: 'Building',
  },
  WEBSITE: {
    ID: 'website',
    LABEL: 'Website',
  },
  FOUNDED: {
    ID: 'founded',
    LABEL: 'Founded by: ',
  },
  CONTACT_NAME: {
    ID: 'contactName',
    LABEL: 'Contact name',
  },
  POSITION: {
    ID: 'position',
    LABEL: 'Position',
  },
  EMAIL: {
    ID: 'email',
    LABEL: 'Email',
  },
  PHONE: {
    ID: 'phone',
    LABEL: 'Phone number',
  },
  VEHICLE_COUNT: {
    ID: 'vehicleCount',
    LABEL: 'Number of vehicles in your fleet (approximate)',
  },
  AVERAGE_AGE: {
    ID: 'averageAge',
    LABEL: 'Average age of vehicles in your fleet',
  },
  VEHICLE_CLASSES: {
    ID: 'vehicleClasses',
    LABEL: 'Classes of vehicles in your fleet',
  },
  API_TYPE: {
    ID: 'apiType',
    LABEL: 'What type of API integration do you have?',
  },
  LOCATIONS_COUNT: {
    ID: 'locationsCount',
    LABEL: 'Number of locations you operate in',
  },
  COUNTRIES_FLEET: {
    ID: 'countriesFleet',
    LABEL: 'Country/ies you have a fleet in',
  },
  STATUS: {
    ID: 'status',
    LABEL: 'Select a status',
  },
  COMMENT: {
    ID: 'comment',
    LABEL: 'Please write a comment',
  },
}

export const ATTACHMENT_TITLE =
  'Please upload images of your pick-up locations, fleet and a file with your rental terms and conditions:'
export const DOWNLOAD_ALL = 'Download all files'

export const APPLICATION_RADIO_BUTTON = {
  API: 'API Integration',
  STATIC: 'Static',
}

const COMPANY_NAME = 'Company name'
const COMPANY_EMAIL = 'Company email'
const APPLICATION_ID = 'Application_Id'

export const SEARCH_VALUES = [COMPANY_NAME, COMPANY_EMAIL, APPLICATION_ID]

export const AUTOCOMPLETE_PLACEHOLDERS: {
  [key: string]: { placeholder: string; dropdownEmpty: string }
} = {
  [COMPANY_NAME]: {
    placeholder: 'Find by company name',
    dropdownEmpty: 'Company name was not found...',
  },
  [COMPANY_EMAIL]: {
    placeholder: 'Find by company email',
    dropdownEmpty: 'Company email was not found...',
  },
  [APPLICATION_ID]: {
    placeholder: 'Find by application id',
    dropdownEmpty: 'Application id was not found...',
  },
}

export const SEARCH_BY = 'Search by:'
