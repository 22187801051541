export const CONFIRM_INVITE_MODAL = {
  TITLE: 'Invite link was sent to Supplier email',
  BUTTON_LABEL: 'OK',
  SUCCESS_NOTIFICATION:
    'The profile has been created and an invitation link has been sent to your email address',
}

export const CONFIRM_DEACTIVATE_MODAL = {
  TITLE: 'Profile deactivation has been successfully.',
  SUBTITLE: 'Profile was moved to the Archive page',
  BUTTON_LABEL: 'Go to Profile archive page',
}

export const CONFIRM_RESTORING_MODAL = {
  TITLE: 'Profile restoring has been successfully.',
  SUBTITLE: 'Supplier Hertz Profile was moved to the Supplier profile page',
  BUTTON_LABEL: 'Go to Profile details page',
}

export const SUCCESSFUL_CHANGE_MODAL = {
  TITLE: 'Your changes have been successfully saved',
}

export const deactivateProfileSubtitle = (companyName = '') =>
  `Profile ${companyName} was archived and moved to Profile archive page`
