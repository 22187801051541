import { Label } from '@frontend/design_system'
import { Typography } from '../Typography'
import { TextFieldProps } from './types'
import styles from './styles.module.scss'

export const TextField = ({ value, label, tooltip }: TextFieldProps) => (
  <div className={styles.wrapper}>
    {label && (
      <Label typographyName="Button2" label={label} tooltip={tooltip} />
    )}
    <Typography name="Button2" Tag="div" className={styles.field}>
      {value}
    </Typography>
  </div>
)
