import { joinClasses } from 'utils/joinClasses'
import { CodesProps } from './types'
import { CODE_ID } from '../../constants'
import { getSmallFlagSrc } from 'utils/flags'
import { Typography } from 'ui/atoms/Typography'
import { addPlus } from './utils'
import { boldMatch } from 'utils/match'
import { CheckIcon } from 'ui/icons'
import styles from './styles.module.scss'

export const Codes = ({ corList, cor, changeCOR, searchValue }: CodesProps) => (
  <div className={styles['phone-codes']}>
    <div className={styles['phone-codes-list']}>
      {corList?.map((item) => {
        const onSelect = () => changeCOR(item)
        const isSelected = cor?.code === item.code

        return (
          <div
            key={item.code}
            id={`${CODE_ID}-${item.code}`}
            className={joinClasses(styles['phone-codes-item'], [
              styles.selected,
              isSelected,
            ])}
            onClick={onSelect}
          >
            <img
              src={getSmallFlagSrc(item?.code?.toUpperCase() as string)}
              alt="flag"
              className={styles['phone-codes-flag']}
            />
            <span>
              <Typography
                name="body2WMedium"
                html={boldMatch(searchValue, item.name)}
                className={styles['phone-codes-country']}
              />
              <Typography name="Button2">(</Typography>
              <Typography
                name="Button2"
                html={boldMatch(searchValue, addPlus(item.phoneCode as number))}
              />
              <Typography name="Button2">)</Typography>
            </span>
            {isSelected && (
              <CheckIcon
                color="green700Master"
                size="small"
                className={styles['phone-codes-selected-icon']}
              />
            )}
          </div>
        )
      })}
    </div>
  </div>
)
