import { DailyRange, PriceListType, SeasonRange } from 'api/fleet/types'
import { MultiselectItem } from 'ui/atoms/Multiselect/types'

export const initialFormValues = {
  hirePoints: [] as MultiselectItem[],
  carGroup: '',
  sipp: '',
  seasonRanges: [] as SeasonRange[],
  dailyRanges: [] as DailyRange[],
  year: '',
  comment: '',
  priceListType: PriceListType.MapBySipp,
  isActive: false,
}

export const APPLY_BY = {
  [PriceListType.MapBySipp]: 'SIPP code',
  [PriceListType.MapByCompanyClass]: 'Company class',
}
