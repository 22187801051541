import { useMemo } from 'react'

type Item = {
  id: string
  name: string
}

export const useSelectValues = (items: Item[]) =>
  useMemo(
    () =>
      items.map((item) => ({
        label: item.name,
        value: item.id,
      })),
    [items]
  )
