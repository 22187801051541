import { TYPOGRAPHY_COLORS } from 'constants/TypographyColors'
import { SVGIcon } from './SvgIcon/SvgIcon'
import { IconProps } from './types'

export const FleetIcon = ({
  size,
  color = 'grey400',
  className,
}: IconProps) => (
  <SVGIcon size={size} color={color} className={className} viewBox="0 0 16 16">
    <path
      d="M2 11.5C2 11.8978 2.15804 12.2794 2.43934 12.5607C2.72064 12.842 3.10218 13 3.5 13C3.89782 13 4.27936 12.842 4.56066 12.5607C4.84196 12.2794 5 11.8978 5 11.5C5 11.1022 4.84196 10.7206 4.56066 10.4393C4.27936 10.158 3.89782 10 3.5 10C3.10218 10 2.72064 10.158 2.43934 10.4393C2.15804 10.7206 2 11.1022 2 11.5Z"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.5"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 11.5C11 11.8978 11.158 12.2794 11.4393 12.5607C11.7206 12.842 12.1022 13 12.5 13C12.8978 13 13.2794 12.842 13.5607 12.5607C13.842 12.2794 14 11.8978 14 11.5C14 11.1022 13.842 10.7206 13.5607 10.4393C13.2794 10.158 12.8978 10 12.5 10C12.1022 10 11.7206 10.158 11.4393 10.4393C11.158 10.7206 11 11.1022 11 11.5Z"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.5"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 11L1 11V7.18182M1 7.18182L2.55556 4H9.55556L12.6667 7.18182M1 7.18182H12.6667M12.6667 7.18182H13.4444C13.857 7.18182 14.2527 7.31591 14.5444 7.55459C14.8361 7.79327 15 8.117 15 8.45455V11L14 11M11 11H5M8 7.18182V4"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.5"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVGIcon>
)
