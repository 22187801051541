import { TYPOGRAPHY_COLORS } from 'constants/TypographyColors'
import { SVGIcon } from './SvgIcon/SvgIcon'
import { IconProps } from './types'

export const EyeClearIcon = ({
  size,
  color = 'grey400',
  className,
}: IconProps) => (
  <SVGIcon size={size} color={color} className={className}>
    <g clipPath="url(#clip0_175_16074)">
      <path
        fill="none"
        d="M0.666626 7.99996C0.666626 7.99996 3.33329 2.66663 7.99996 2.66663C12.6666 2.66663 15.3333 7.99996 15.3333 7.99996C15.3333 7.99996 12.6666 13.3333 7.99996 13.3333C3.33329 13.3333 0.666626 7.99996 0.666626 7.99996Z"
        stroke={TYPOGRAPHY_COLORS[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="none"
        d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
        stroke={TYPOGRAPHY_COLORS[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_175_16074">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </SVGIcon>
)
