import { joinClasses } from 'utils/joinClasses'
import { Typography } from '../Typography'
import { StatusBadgeProps } from './types'
import styles from './styles.module.scss'
import { STATUS_BADGE_COLORS } from './constants'

export const StatusBadge = ({
  status = 'ok',
  label,
  height,
}: StatusBadgeProps) => (
  <Typography
    className={joinClasses(styles.badge, styles[status])}
    Tag="div"
    name="Subtitle3"
    style={{ height }}
    color={STATUS_BADGE_COLORS[status]}
  >
    {label}
  </Typography>
)
