import { useState } from 'react'
import { OriginButton } from '@frontend/design_system'
import { ImportIcon } from 'ui/icons'
import { useApiRequest } from 'hooks/useApiRequest'
import { fleetApi } from 'api'
import { Confirmation } from 'ui/molecules/Confirmation'
import { joinClasses } from 'utils/joinClasses'
import { ExportRateProps } from './types'
import styles from './styles.module.scss'
import { RATES, RATES_EXPORT_MODAL } from 'texts/rateDetails'

export const ExportRate = ({
  priceListId,
  disabled,
  isUpdated,
  saveTableContent,
}: ExportRateProps) => {
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const { apiRequest, loading } = useApiRequest(
    (id) => fleetApi.exportRate(id),
    undefined,
    undefined,
    undefined,
    false
  )

  const handleExport = async () => {
    const response = await apiRequest(priceListId)
    if (response) {
      const fileName = response.headers
        .get('content-disposition')
        ?.split('filename=')[1]
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName as string)
      document.body.appendChild(link)
      link.click()
    }
    setConfirmationModalOpen(false)
  }

  const handleSaveAndExport = async () => {
    await saveTableContent()
    await handleExport()
    setConfirmationModalOpen(false)
  }

  const handleClose = () => {
    setConfirmationModalOpen(false)
  }

  const handleClick = () => {
    if (isUpdated) {
      setConfirmationModalOpen(true)
    } else {
      handleExport()
    }
  }

  return (
    <>
      {isConfirmationModalOpen && (
        <Confirmation
          confirmModalProps={{
            question: RATES_EXPORT_MODAL.QUESTION,
            onConfirm: handleSaveAndExport,
            onClose: handleClose,
            onDecline: handleExport,
            title: RATES_EXPORT_MODAL.TITLE,
            confirmLabel: RATES_EXPORT_MODAL.CONFIRM_LABEL,
            declineLabel: RATES_EXPORT_MODAL.DECLINE_LABEL,
          }}
        />
      )}
      <div className={joinClasses(styles.button, [styles.disabled, disabled])}>
        <OriginButton
          size="small"
          typographyName="Button2"
          disabled={disabled}
          variant="secondary-border"
          label={RATES.EXPORT}
          loading={loading}
          onClick={handleClick}
          iconRight={<ImportIcon color={disabled ? 'grey500' : 'blue700'} />}
        />
      </div>
    </>
  )
}
