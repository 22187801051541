import { createSlice } from '@reduxjs/toolkit'
import { NotificationState } from './types'

const initialState: NotificationState = {
  notificationMessage: '',
  type: 'default',
  timer: 5,
  additionalMessage: '',
}

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotificationMessage(state, action) {
      state.notificationMessage = action.payload.notificationMessage
      state.type = action.payload.type
      state.timer = action.payload.timer
      state.additionalMessage = action.payload.additionalMessage
    },
    hideNotification(state) {
      state.notificationMessage = ''
      state.type = 'default'
    },
  },
})

export const notificationReducer = notificationSlice.reducer
export const { setNotificationMessage, hideNotification } =
  notificationSlice.actions
