import { Rule } from 'ui/molecules/Form/components/FormItem/types'

type FormValuesSetting = {
  [key: string]: {
    required: {
      supplier: boolean
      operator: boolean
    }
    disabled: {
      supplier: boolean
      operator: boolean
    }
    validationRules?: Rule[]
  }
}

export const getValidationRules = (
  formValue: FormValuesSetting['key'],
  role: 'supplier' | 'operator'
) =>
  formValue.required[role]
    ? formValue.validationRules
    : formValue.validationRules?.filter((rule) => rule.type !== 'required')

export const getRequiredRule = (ruleMessage: string): Rule[] => [
  { type: 'required', message: ruleMessage },
]
