import { TypographyColor } from 'types'
import { TitleNames } from './types'

export const popupClasses = {
  right: 'modal-popup-right',
  default: 'modal-popup-default',
  center: 'modal-popup-center',
  'center-horizontal': 'modal-popup-center-horizontal',
}

export const titleNames: TitleNames = {
  medium: {
    name: 'Button1',
  },
  large: {
    name: 'H4',
  },
}

export const closeColors: { [key: string]: TypographyColor } = {
  primary: 'grey400',
  secondary: 'whiteMaster',
}
