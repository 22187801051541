import { Breadcrumbs } from 'ui/atoms/Breadcrumbs'
import { ContainerProps } from './types'
import { InfoIcon } from 'ui/icons'
import { Typography } from 'ui/atoms/Typography'
import { Tooltip } from 'ui/atoms/Tooltip'
import styles from './styles.module.scss'
import { joinClasses } from 'utils/joinClasses'
import { Navigation } from '../Navigation'
import { useCompanyInfo } from 'ui/hooks/useCompanyInfo'
import { useSelector } from 'react-redux'
import { isOperatorSelector } from 'redux/login/selectors'

export const Container = ({
  breadcrumbList,
  children,
  title,
  currentPageLabel,
  tooltip,
  rightBlock,
  withNavigation,
  companyName,
  withCompanyName = true,
}: ContainerProps) => {
  const { companyId, companyName: supplierCompanyName } = useCompanyInfo(true)
  const isOperator = useSelector(isOperatorSelector)

  const displayedCompanyName = companyName || supplierCompanyName

  const isShouldDisplayCompanyName =
    withCompanyName && isOperator && displayedCompanyName

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {!!breadcrumbList?.length && currentPageLabel && (
          <div
            className={joinClasses(styles.breadcrumbs, [
              styles['bottom-offset'],
              !rightBlock,
            ])}
          >
            <Breadcrumbs
              breadcrumbs={breadcrumbList}
              currentPageLabel={currentPageLabel}
            />
          </div>
        )}
        <div className={styles['title-wrapper']}>
          <div className={styles.title}>
            {typeof title === 'string' ? (
              <Typography Tag="h5" name="H5">
                {title}
              </Typography>
            ) : (
              title
            )}
            {tooltip && (
              <div className={styles['tooltip-wrapper']}>
                <Tooltip
                  size="primary"
                  popupContent={
                    <Typography
                      Tag="div"
                      name="Subtitle4"
                      className={styles['popup-content']}
                    >
                      {tooltip}
                    </Typography>
                  }
                  placement="bottom"
                >
                  <InfoIcon color="blue700" size="medium" />
                </Tooltip>
              </div>
            )}
            {isShouldDisplayCompanyName && (
              <Typography
                className={styles['company-badge']}
                name="Subtitle1"
                color="blue700"
              >
                {displayedCompanyName}
              </Typography>
            )}
          </div>
          {rightBlock && <div>{rightBlock}</div>}
        </div>
        {withNavigation && <Navigation id={String(companyId)} />}
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  )
}
