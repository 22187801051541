export const formatSeasonRange = (seasonRange: string, year?: string) =>
  seasonRange
    .split('-')
    .map((el) => (year ? el + year : el))
    .join(' - ')

export const formatSeasonRangeWithOneYear = (
  seasonRange: string,
  year?: string
) => {
  const seasonRangeArray = seasonRange.split('-')
  seasonRangeArray[1] = `${seasonRangeArray[1]}${year ? year : ''}`
  return seasonRangeArray.join(' - ')
}
