import { getRequiredMessage } from './../utils/getRequiredMessage'

export const EQUIPMENT_DETAILS = {
  FORM_TITLE: 'Equipment details',
  BUTTONS: {
    CANCEL_BUTTON_LABEL: 'Cancel',
    SAVE_BUTTON_LABEL: 'Save',
  },
  SUM_LABEL: 'Sum for: ',
  SUCCESS_EDIT: 'Your changes have been successfully saved',
  SEARCH_VALUE: 'location',
}

export const EQUIPMENT_TITLE = 'Equipment'
export const EQUIPMENT_ADD_BREADCRUMB = 'Add equipment'
export const EQUIPMENT_DETAILS_BREADCRUMB = 'Equipment details'

export const EQUIPMENT_DETAILS_FORM_VALUES = {
  EQUIPMENT_NAME: {
    ID: 'extraId',
    PLACEHOLDER: 'Please select equipment',
    LABEL: 'Equipment name',
    REQUIRED_MESSAGE: 'Please select equipment.',
  },
  PRICE: {
    ID: 'price',
    PLACEHOLDER: '0.00',
    LABEL: 'Price',
    REQUIRED_MESSAGE: getRequiredMessage('price'),
  },
  MAX_AMOUNT: {
    ID: 'maxAmount',
    PLACEHOLDER: '0.00',
    LABEL: 'Maximum price',
    REQUIRED_MESSAGE: getRequiredMessage('maximum price'),
    VALIDATION_MESSAGE: 'Price must be less or equal to maximum price.',
  },
  MAX_COUNT: {
    ID: 'maxCount',
    PLACEHOLDER: '0',
    LABEL: 'Quantity',
    REQUIRED_MESSAGE: 'Please enter quantity.',
    VALIDATION_MESSAGE: 'Quantity must be greater than 0.',
  },
  VEHICLE_ID: {
    ID: 'uniqueVehicleId',
    PLACEHOLDER: 'Please select a car model',
    LABEL: 'Applied to car model',
    REQUIRED_MESSAGE: 'Please select a car model.',
  },
  LOCATION: {
    ID: 'hirePoints',
    PLACEHOLDER: 'Please select a location',
    LABEL: 'Refers to location',
    REQUIRED_MESSAGE: 'Please select a location.',
  },
  SUM_FOR: {
    ID: 'priceFor',
    LABEL: 'Sum for',
    REQUIRED_MESSAGE: getRequiredMessage('sum for'),
  },
}

export const RADIO_BUTTON_LABELS = {
  RENT: 'Per rental',
  DAY: 'Per day',
}
