import { useMemo, useState } from 'react'
import { FORM_FIELDS_VALUES } from 'texts/formFields'
import {
  RESET_PASSWORD_TEXT_FIELDS,
  getAttemptsLeftMessage,
} from 'texts/resetPassword'
import { authApi } from 'api'
import { VerifyAccountFormProps } from './types'
import { ErrorResponse } from 'api/types'
import { Badge } from 'ui/atoms/Badge'
import { Button } from 'ui/atoms/Button'
import { Timer } from 'ui/atoms/Timer/Timer'
import { Typography } from 'ui/atoms/Typography'
import { Form } from 'ui/molecules/Form'
import { FormItem } from 'ui/molecules/Form/components/FormItem'
import { VerifyAccountFormData } from 'modules/auth/types'
import { initialFormValues } from './constants'
import { InputPassword } from 'ui/atoms/InputPassword'
import { useDispatch } from 'react-redux'
import { setNotificationMessage } from 'redux/notifications/slice'
import { Rule } from 'ui/molecules/Form/components/FormItem/types'
import styles from './styles.module.scss'

export const VerifyAccountForm = ({
  onSuccess,
  resetPasswordData,
}: VerifyAccountFormProps) => {
  const [errorMessage, setErrorMessage] = useState('')
  const [formKey, setFormKey] = useState(0)
  const [timer, setTimer] = useState(resetPasswordData.timeToResend)
  const [attemptsLeft, setAttemptsLeft] = useState(
    resetPasswordData.verificationAttemptsLeft
  )
  const [timerKey, setTimerKey] = useState(0)
  const dispatch = useDispatch()

  const onSubmit = async (data: VerifyAccountFormData, validate: boolean) => {
    setErrorMessage('')
    if (validate && data.verificationCode) {
      try {
        const response = await authApi.confirmVerificationCode(
          resetPasswordData?.email,
          data.verificationCode
        )
        onSuccess(response.data.passwordResetToken)
      } catch (error) {
        setErrorMessage((error as ErrorResponse).responseStatus.message)
      }
    }
  }

  const handleResend = async () => {
    setErrorMessage('')
    setFormKey(formKey + 1)
    try {
      const verificationCodeResponse =
        await authApi.sendVerificationCodeRequest(resetPasswordData.email)
      if (
        !verificationCodeResponse.data.success &&
        verificationCodeResponse.data.errorMessage
      ) {
        setErrorMessage(verificationCodeResponse.data.errorMessage)
      } else {
        setAttemptsLeft(verificationCodeResponse.data.verificationAttemptsLeft)
      }
      setTimer(verificationCodeResponse.data.timeToResend)
      setTimerKey(timerKey + 1)
    } catch (error) {
      dispatch(
        setNotificationMessage({
          notificationMessage:
            RESET_PASSWORD_TEXT_FIELDS.RECIEVE_CODE_FORM
              .REACHED_MAX_ATTEMPTS_COUNT,
          type: 'error',
          timer: 0,
          additionalMessage:
            RESET_PASSWORD_TEXT_FIELDS.RECIEVE_CODE_FORM.CONTACT_SUPPORT,
        })
      )
    }
  }

  const renderTimerLabel = (time: string) => (
    <div className={styles['timer-label']}>
      <Typography name="Button1">
        {RESET_PASSWORD_TEXT_FIELDS.VERIFY_ACCOUNT_FORM.TIMER_LABLE}
        <Typography name="Button1" color="blue700">
          {time}
        </Typography>
      </Typography>
    </div>
  )

  const verificationCodeRules: Rule[] = useMemo(
    () => [
      {
        type: 'required',
        message: FORM_FIELDS_VALUES.CODE.REQUIRED_MESSAGE,
      },
    ],
    []
  )

  return (
    <>
      <Typography Tag="h3" name="H3" className={styles.header}>
        {RESET_PASSWORD_TEXT_FIELDS.VERIFY_ACCOUNT_FORM.TITLE}
      </Typography>
      <Typography
        Tag="span"
        name="Button2"
        markColor="blackMaster"
        color="grey500"
        html={
          resetPasswordData.isRecieveCodeSkiped
            ? RESET_PASSWORD_TEXT_FIELDS.VERIFY_ACCOUNT_FORM.SUBTITLE_ADDITIONAL
            : RESET_PASSWORD_TEXT_FIELDS.VERIFY_ACCOUNT_FORM.SUBTITLE +
              `<mark> ${resetPasswordData.email}. </mark>` +
              RESET_PASSWORD_TEXT_FIELDS.VERIFY_ACCOUNT_FORM
                .SUBTITLE_ADDITIONAL +
              RESET_PASSWORD_TEXT_FIELDS.VERIFY_ACCOUNT_FORM.EXPIRE_INFO
        }
      />

      <Form
        onSubmit={onSubmit}
        className={styles['form-fields']}
        initValues={initialFormValues}
        key={formKey}
      >
        {({ values }) => (
          <>
            <div className={styles['input-wrapper']}>
              <FormItem
                id={FORM_FIELDS_VALUES.CODE.ID}
                rules={verificationCodeRules}
              >
                {({ error, onChange, value }) => (
                  <InputPassword
                    value={value}
                    error={error}
                    onChange={onChange}
                    size="large"
                    label={FORM_FIELDS_VALUES.CODE.LABEL}
                    placeholder={FORM_FIELDS_VALUES.CODE.PLACEHOLDER}
                  />
                )}
              </FormItem>
            </div>
            {attemptsLeft !== null && !errorMessage && (
              <Badge
                message={getAttemptsLeftMessage(attemptsLeft)}
                className={styles.badge}
                type="info"
              />
            )}
            {errorMessage && (
              <Badge
                message={errorMessage}
                className={styles.badge}
                type="error"
              />
            )}
            <div className={styles.buttons}>
              <div className={styles['button-wrapper']}>
                <Button
                  label={
                    RESET_PASSWORD_TEXT_FIELDS.VERIFY_ACCOUNT_FORM.BUTTON_LABELS
                      .SEND_CODE
                  }
                  htmlType="submit"
                  size="large"
                  disabled={!values.verificationCode}
                />
              </div>
              <div className={styles['button-wrapper']}>
                <Timer
                  renderLabel={renderTimerLabel}
                  seconds={timer}
                  key={timerKey}
                >
                  <Button
                    typographyName="Button1"
                    variant="link"
                    label={
                      RESET_PASSWORD_TEXT_FIELDS.VERIFY_ACCOUNT_FORM
                        .BUTTON_LABELS.RESEND
                    }
                    onClick={handleResend}
                  />
                </Timer>
              </div>
            </div>
          </>
        )}
      </Form>
    </>
  )
}
