import {
  Typography,
  OriginButton,
  Tooltip,
  Modal,
} from '@frontend/design_system'
import { ChangeEvent, useState } from 'react'
import { fleetApi } from 'api'
import { useApiRequest } from 'hooks/useApiRequest'
import { DownloadIcon } from 'ui/icons'
import { joinClasses } from 'utils/joinClasses'
import { ErrorResponseType, ImportRateProps } from './types'
import { RATES, RATES_IMPORT_CONFIRM_MODAL } from 'texts/rateDetails'
import { formats } from './constants'
import { useDispatch } from 'react-redux'
import { setNotificationMessage } from 'redux/notifications/slice'
import errorCodes from 'constants/errorCodes'
import styles from './styles.module.scss'

export const ImportRate = ({
  priceListId,
  updateRates,
  disabled,
}: ImportRateProps) => {
  const dispatch = useDispatch()
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false)
  const handleError = (error: ErrorResponseType) => {
    const errorMessage = error?.errors?.[0] || errorCodes.Default
    dispatch(
      setNotificationMessage({
        notificationMessage: errorMessage,
        type: 'error',
      })
    )
  }

  const importFileRequest = useApiRequest(
    (file) => fleetApi.importRate(priceListId, file),
    undefined,
    false,
    {
      withoutNotification: true,
      onError: (error) => handleError(error as ErrorResponseType),
      isThrowError: false,
    },
    false
  )

  const handleModalClose = () => setConfirmModalOpen(false)

  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setConfirmModalOpen(false)
    const response = await importFileRequest.apiRequest({
      file: e.target.files?.[0],
    })
    if (response) {
      updateRates()
    }
    e.target.value = ''
  }

  return (
    <>
      {isConfirmModalOpen && (
        <Modal
          position="center-horizontal"
          onClose={handleModalClose}
          title={RATES_IMPORT_CONFIRM_MODAL.TITLE}
          invisibleHeader
          headerSize="large"
          isDelimiter
        >
          <div className={styles.content}>
            <Typography
              name="Button1"
              color="grey500"
              className={styles.description}
              Tag="div"
            >
              {RATES_IMPORT_CONFIRM_MODAL.CONTENT}
            </Typography>
            <div className={styles.buttons}>
              <OriginButton
                label="Cancel"
                size="large"
                variant="secondary-border"
                onClick={handleModalClose}
              />
              <div className={styles['button-wrapper']}>
                <input
                  type="file"
                  id="file"
                  accept={formats}
                  className={styles.import}
                  onChange={onChange}
                />
                <label htmlFor="file">{RATES.IMPORT}</label>
              </div>
            </div>
          </div>
        </Modal>
      )}
      <Tooltip
        size="auto"
        popupContent={
          <Typography name="Button2">{RATES.IMPORT_TOOLTIP}</Typography>
        }
        placement="top"
      >
        <div
          className={joinClasses(styles.button, [styles.disabled, disabled])}
        >
          <OriginButton
            variant="secondary-border"
            size="small"
            loading={importFileRequest.loading}
            disabled={disabled}
            onClick={() => setConfirmModalOpen(true)}
            iconRight={
              <DownloadIcon color={disabled ? 'grey500' : 'blue700'} />
            }
            label={
              <Typography
                name="Button2"
                color={disabled ? 'grey500' : 'blue700'}
              >
                {RATES.IMPORT}
              </Typography>
            }
          />
        </div>
      </Tooltip>
    </>
  )
}
