import { useDispatch, useSelector } from 'react-redux'
import { notificationSelector } from 'redux/notifications/selectors'
import { hideNotification } from 'redux/notifications/slice'
import { Notification } from '@frontend/design_system'
import { GeneralNotificationProps } from './types'

export const GeneralNotification = ({
  top,
  right,
}: GeneralNotificationProps) => {
  const dispatch = useDispatch()
  const { notificationMessage, type, timer, additionalMessage } =
    useSelector(notificationSelector)

  if (!notificationMessage) {
    return null
  }

  const onClose = () => dispatch(hideNotification())

  return (
    <Notification
      notificationMessage={notificationMessage}
      onClose={onClose}
      top={top}
      right={right}
      type={type}
      timer={timer}
      additionalMessage={additionalMessage}
      inContainer
    />
  )
}
