import { FormItem } from 'ui/molecules/Form/components/FormItem'
import {
  APPLICATIONS_TAB_KEYS,
  APPLICATION_FORM_VALUES,
} from 'texts/applications'
import { Select } from 'ui/atoms/Select'
import { Textarea } from 'ui/atoms/Textarea/Textarea'
import { useSelectValues } from 'hooks/useSelectValues'
import { RadioButton } from 'ui/atoms/RadioButton'
import { useState } from 'react'
import { StatusInfoProps } from './types'
import { RADIO_BUTTONS } from './constants'
import { APPLICATION_STATUS } from 'modules/Applications/constants'
import styles from './styles.module.scss'

export const StatusInfo = ({ status }: StatusInfoProps) => {
  const [activeRadioButton, setActiveRadioButton] = useState('0')
  const statuses = Object.entries(APPLICATIONS_TAB_KEYS).map(([id, value]) => ({
    name: value,
    id,
  }))

  const preparedStatuses = useSelectValues(statuses)

  const handleChangeRadioButton = (id: string) => {
    setActiveRadioButton(id)
  }

  const renderContent = () => {
    if (status === APPLICATION_STATUS.WAITING_LIST) {
      return (
        <>
          <div className={styles['form-items-row']}>
            <FormItem
              id={APPLICATION_FORM_VALUES.STATUS.ID}
              className={styles['form-item']}
            >
              {({ value, error, onChange }) => (
                <Select
                  selectedValue={value}
                  items={preparedStatuses}
                  placeholder=""
                  size="large"
                  error={error}
                  noSpaceForError
                  onChange={onChange}
                  isRequired
                  label={APPLICATION_FORM_VALUES.STATUS.LABEL}
                />
              )}
            </FormItem>
          </div>
          <FormItem id={APPLICATION_FORM_VALUES.COMMENT.ID}>
            {({ value, error, onChange }) => (
              <Textarea
                value={value}
                onChange={onChange}
                placeholder={APPLICATION_FORM_VALUES.COMMENT.LABEL}
                error={error}
              />
            )}
          </FormItem>
        </>
      )
    } else if (status === APPLICATION_STATUS.WAITING_FOR_CALL) {
      return (
        <>
          <FormItem id={APPLICATION_FORM_VALUES.COMMENT.ID}>
            {({ value, error, onChange }) => (
              <Textarea
                value={value}
                onChange={onChange}
                placeholder={APPLICATION_FORM_VALUES.COMMENT.LABEL}
                disabled
                error={error}
              />
            )}
          </FormItem>
          <div className={styles['form-items-row']}>
            <FormItem
              id={APPLICATION_FORM_VALUES.STATUS.ID}
              className={styles['form-item']}
            >
              {({ value, error, onChange }) => (
                <Select
                  selectedValue={value}
                  items={preparedStatuses}
                  placeholder=""
                  size="large"
                  error={error}
                  noSpaceForError
                  onChange={onChange}
                  isRequired
                  label={APPLICATION_FORM_VALUES.STATUS.LABEL}
                />
              )}
            </FormItem>
          </div>
        </>
      )
    } else if (status === APPLICATION_STATUS.WAITING_FOR_SIGN) {
      return (
        <>
          <div className={styles['form-items-row']}>
            <FormItem
              id={APPLICATION_FORM_VALUES.STATUS.ID}
              className={styles['form-item']}
            >
              {({ value, error, onChange }) => (
                <Select
                  selectedValue={value}
                  items={preparedStatuses}
                  placeholder=""
                  size="large"
                  error={error}
                  noSpaceForError
                  onChange={onChange}
                  isRequired
                  label={APPLICATION_FORM_VALUES.STATUS.LABEL}
                />
              )}
            </FormItem>
          </div>
          <div className={styles['radio-button']}>
            {RADIO_BUTTONS.map(({ label, id }) => (
              <RadioButton
                labelTypographyName="Button2"
                labelColor="grey500"
                key={id}
                id={id}
                label={label}
                onChange={handleChangeRadioButton}
                checked={id === activeRadioButton}
                buttonPosition="left"
              />
            ))}
          </div>
        </>
      )
    } else if (status === APPLICATION_STATUS.REJECTED) {
      return (
        <>
          <div className={styles['form-items-row']}>
            <FormItem
              id={APPLICATION_FORM_VALUES.STATUS.ID}
              className={styles['form-item']}
            >
              {({ value, error, onChange }) => (
                <Select
                  selectedValue={value}
                  items={preparedStatuses}
                  placeholder=""
                  size="large"
                  error={error}
                  disabled
                  noSpaceForError
                  onChange={onChange}
                  isRequired
                  label={APPLICATION_FORM_VALUES.STATUS.LABEL}
                />
              )}
            </FormItem>
          </div>
          <FormItem id={APPLICATION_FORM_VALUES.COMMENT.ID}>
            {({ value, error, onChange }) => (
              <Textarea
                value={value}
                onChange={onChange}
                disabled
                placeholder={APPLICATION_FORM_VALUES.COMMENT.LABEL}
                error={error}
              />
            )}
          </FormItem>
        </>
      )
    }
    return null
  }

  return <>{renderContent()}</>
}
