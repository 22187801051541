import { Table } from 'ui/molecules/Table'
import { Pagination } from '@frontend/design_system'
import { useMemo, useState } from 'react'
import {
  DEFAULT_INITIAL_PAGE,
  DEFAULT_INITIAL_PAGE_SIZE,
} from 'constants/pagination'
import { PAGINATION_DROPDOWN_LABEL } from 'texts/uiTexts'
import { Button } from 'ui/atoms/Button'
import { Typography } from 'ui/atoms/Typography'
import { HEAD_ITEMS, OVERVIEW } from 'texts/applications'
import { useNavigate } from 'react-router-dom'
import { HeadItem } from 'ui/atoms/HeadItem'
import { ApplicationListProps } from './types'
import { mockApplicationsList } from '../AllApplications/mock'
import styles from './styles.module.scss'

export const ApplicationList = ({ status }: ApplicationListProps) => {
  const [page, setPage] = useState(DEFAULT_INITIAL_PAGE)
  const [pageSize, setPageSize] = useState(DEFAULT_INITIAL_PAGE_SIZE)
  const [applications, setApplications] = useState(mockApplicationsList)

  const navigate = useNavigate()

  const changePageSize = (value: string) => {
    setPageSize(value)
    setPage(DEFAULT_INITIAL_PAGE)
  }

  const handleChange = (pageNumber: number) => {
    setPage(pageNumber)
  }

  const sortByName = (type: boolean) => {
    const isAscending = type
    const sortedApplications = [...applications]
    if (isAscending) {
      sortedApplications.sort((a, b) => a.user.localeCompare(b.user))
    } else {
      sortedApplications.sort((a, b) => b.user.localeCompare(a.user))
    }
    setApplications(sortedApplications)
  }

  const sortBySubmission = (type: boolean) => {
    const isAscending = type
    const sortedApplications = [...applications]
    if (isAscending) {
      sortedApplications.sort(
        (a, b) =>
          new Date(b.submission).getTime() - new Date(a.submission).getTime()
      )
    } else {
      sortedApplications.sort(
        (a, b) =>
          new Date(a.submission).getTime() - new Date(b.submission).getTime()
      )
    }
    setApplications(sortedApplications)
  }

  const sortByTime = (type: boolean) => {
    const isAscending = type
    const sortedApplications = [...applications]
    if (isAscending) {
      sortedApplications.sort(
        (a, b) =>
          new Date(b.processTime).getTime() - new Date(a.processTime).getTime()
      )
    } else {
      sortedApplications.sort(
        (a, b) =>
          new Date(a.processTime).getTime() - new Date(b.processTime).getTime()
      )
    }
    setApplications(sortedApplications)
  }

  const handleRedirectToApplication = (id: string) => {
    navigate(`../${status}/${id}`)
  }

  const headItems = [
    <HeadItem label={HEAD_ITEMS.COMPANY_NAME} onClick={sortByName} />,
    <HeadItem label={HEAD_ITEMS.SUBMISSION} onClick={sortBySubmission} />,
    HEAD_ITEMS.COMPANY_EMAIL,
    HEAD_ITEMS.WEBSITE,
    <HeadItem label={HEAD_ITEMS.TIME} onClick={sortByTime} />,
    HEAD_ITEMS.ACTIONS,
  ]

  const formattedBodyItems = useMemo(
    () =>
      applications?.map((company) => ({
        items: [
          company.user,
          company.submission,
          <Button
            variant="link"
            typographyName="Subtitle7"
            size="small"
            label={company.email}
            onClick={(e) => {
              e.stopPropagation()
            }}
          />,
          <Typography
            Tag="a"
            color="blue700"
            href={company.wbsite}
            target="_blank"
            name="Subtitle7"
          >
            {company.wbsite}
          </Typography>,
          company.processTime,
          <Button
            variant="link"
            typographyName="Subtitle7"
            size="small"
            label={OVERVIEW}
            onClick={(e) => {
              handleRedirectToApplication(company.id)
              e.stopPropagation()
            }}
          />,
        ],
      })),
    [applications]
  )

  const formattedHeadItems = headItems.map((header) => ({ value: header }))

  return (
    <div>
      <div className={styles.list}>
        <div className={styles.table}>
          <Table
            headItems={formattedHeadItems}
            bodyItems={formattedBodyItems}
            className={styles.thead}
          />
        </div>
      </div>
      <footer className={styles.pagination}>
        <Pagination
          current={page}
          total={applications?.length || 0}
          pageSize={pageSize}
          changePageSize={changePageSize}
          changePage={handleChange}
          pageSizeLabel={PAGINATION_DROPDOWN_LABEL}
        />
      </footer>
    </div>
  )
}
