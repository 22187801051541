import {
  APPLICATION_FORM_VALUES,
  ATTACHMENT_TITLE,
  DOWNLOAD_ALL,
} from 'texts/applications'
import { Input } from 'ui/atoms/Input'
import { FormItem } from 'ui/molecules/Form/components/FormItem'
import { Typography } from 'ui/atoms/Typography'
import { Button } from 'ui/atoms/Button'
import { DownloadIcon } from 'ui/icons'
import styles from './styles.module.scss'

export const OtherInformation = () => (
  <>
    <div className={styles['form-items-row']}>
      <FormItem
        id={APPLICATION_FORM_VALUES.API_TYPE.ID}
        className={styles['form-item']}
      >
        {({ value, error, onChange }) => (
          <Input
            value={value}
            placeholder=""
            size="large"
            error={error}
            readonly
            spaceForError="none"
            onChange={onChange}
            label={APPLICATION_FORM_VALUES.API_TYPE.LABEL}
          />
        )}
      </FormItem>
    </div>
    <div className={styles['attachments']}>
      <div className={styles['attachments-header']}>
        <Typography name="Subtitle1" className={styles.text}>
          {ATTACHMENT_TITLE}
        </Typography>
        <div className={styles.button}>
          <Button
            variant="link"
            typographyName="Button2"
            icon={<DownloadIcon color="blue700" />}
            iconPosition="right"
            label={DOWNLOAD_ALL}
          />
        </div>
      </div>
    </div>
  </>
)
