import { StopSalesRange } from 'api/fleet/types'

export const initialFormValues = {
  countries: [],
  cities: [],
  locations: [],
  carClasses: [],
  from: '',
  to: '',
  comments: '',
  applicable: StopSalesRange.Both,
}

export const RADIO_BUTTONS = [
  { label: 'All', id: StopSalesRange.Both },
  { label: 'Pick-up', id: StopSalesRange.PickUp },
  { label: 'Drop-off', id: StopSalesRange.DropOff },
]
