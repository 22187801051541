import { TYPOGRAPHY_COLORS } from 'constants/TypographyColors'
import { SVGIcon } from './SvgIcon/SvgIcon'
import { IconProps } from './types'

export const SortIcon = ({
  color = 'blue700',
  size,
  className,
  onClick,
}: IconProps) => (
  <SVGIcon
    color={color}
    viewBox="0 0 21 20"
    size={size}
    className={className}
    onClick={onClick}
  >
    <g clipPath="url(#clip0_9722_15806)">
      <path
        d="M2.15479 6.7464L5.6431 3.00001L9.13142 6.7464"
        stroke={TYPOGRAPHY_COLORS[color]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4569 12.5602L14.9452 16.3066L18.4335 12.5602"
        stroke={TYPOGRAPHY_COLORS[color]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.643 16.0484L5.64324 3.25801"
        stroke={TYPOGRAPHY_COLORS[color]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9451 3.25826L14.9451 16.0483"
        stroke={TYPOGRAPHY_COLORS[color]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_9722_15806">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0.142822)"
        />
      </clipPath>
    </defs>
  </SVGIcon>
)
