import { PRICE_LIST_SEASON_RANGE } from 'texts/priceListDetails'
import { SeasonRangeProps } from './types'
import { TrashIconWithBg } from 'ui/icons'
import { DatePicker } from 'ui/molecules/DatePicker'
import { useState } from 'react'
import { RangeType } from '../../types'
import { SeasonRange as SeasonRangeType } from 'api/fleet/types'
import { format } from 'date-fns'
import {
  PRICE_LIST_SEASON_RANGE_FORMAT,
  STOP_SALES_DATE_FORMAT,
} from 'constants/date'
import styles from './styles.module.scss'

export const SeasonRange = <T extends RangeType>({
  handleChange,
  handleRemoveRange,
  index,
  range,
  readOnly,
}: SeasonRangeProps<T>) => {
  const [firstDate, setFirstDate] = useState<string | Date>(
    (range as SeasonRangeType).start
  )
  const [secondDate, setSecondDate] = useState<string | Date>(
    (range as SeasonRangeType).end
  )

  const handleChangeDay = (day?: Date) => {
    if (day) {
      setFirstDate(day)
      handleChange(index, {
        ...range,
        start: format(day, STOP_SALES_DATE_FORMAT),
      })
    }
  }

  const handleChangeSecondDate = (day?: Date) => {
    if (day) {
      setSecondDate(day)
      handleChange(index, {
        ...range,
        end: format(day, STOP_SALES_DATE_FORMAT),
      })
    }
  }

  return (
    <div className={styles.range}>
      <div className={styles.input}>
        <DatePicker
          size="large"
          dateFormat={PRICE_LIST_SEASON_RANGE_FORMAT}
          handleDayChange={handleChangeDay}
          label={PRICE_LIST_SEASON_RANGE.START}
          selectedDay={firstDate}
          disabled={readOnly}
        />
      </div>
      <div className={styles.input}>
        <DatePicker
          size="large"
          dateFormat={PRICE_LIST_SEASON_RANGE_FORMAT}
          handleDayChange={handleChangeSecondDate}
          label={PRICE_LIST_SEASON_RANGE.END}
          selectedDay={secondDate}
          disabled={readOnly}
        />
      </div>
      {!readOnly && (
        <TrashIconWithBg
          color="blue700"
          size="large"
          className={styles.icon}
          onClick={handleRemoveRange}
        />
      )}
    </div>
  )
}
