import { TYPOGRAPHY_COLORS } from 'constants/TypographyColors'
import { SVGIcon } from './SvgIcon/SvgIcon'
import { IconProps } from './types'

export const EllipsisVerticalIcon = ({
  size,
  color = 'grey400',
  onClick,
}: IconProps) => (
  <SVGIcon size={size} color={color} viewBox="0 0 16 16" onClick={onClick}>
    <circle cx="8.28577" cy="3.5" r="1.25" fill={TYPOGRAPHY_COLORS[color]} />
    <circle cx="8.28577" cy="8" r="1.25" fill={TYPOGRAPHY_COLORS[color]} />
    <circle cx="8.28577" cy="12.5" r="1.25" fill={TYPOGRAPHY_COLORS[color]} />
  </SVGIcon>
)
