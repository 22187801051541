import { SeasonRangeAdd } from 'api/fleet/types'

export const formatSeasonRange = (
  seasonRanges: {
    start: string | SeasonRangeAdd['start']
    end: string | SeasonRangeAdd['end']
    seasonRangeId?: number
  }[]
) =>
  seasonRanges.map((seasonRange) => {
    let { start, end } = seasonRange
    const { seasonRangeId } = seasonRange
    if (typeof start === 'string') {
      const splittedStart = start.toString().split('-')
      start = {
        day: Number(splittedStart[2]),
        month: Number(splittedStart[1]),
      }
    }
    if (typeof end === 'string') {
      const splittedEnd = end.toString().split('-')
      end = {
        day: Number(splittedEnd[2]),
        month: Number(splittedEnd[1]),
      }
    }
    const item: SeasonRangeAdd = {
      start,
      end,
    }
    if (seasonRangeId) {
      item.seasonRangeId = seasonRangeId
    }
    return item
  })
