import React, { useState } from 'react'
import { Typography } from 'ui/atoms/Typography'
import { ModalWrapperProps } from './types'
import { joinClasses } from 'utils/joinClasses'
import styles from './styles.module.scss'

export const ModalWrapper = ({
  label,
  hirePointId,
  companyId,
  isEmptyModal,
  Modal,
}: ModalWrapperProps) => {
  const [isModalOpen, setModalOpen] = useState(false)

  const handleOpen = (e: React.MouseEvent | React.TouchEvent) => {
    e.stopPropagation()
    if (!isEmptyModal) {
      setModalOpen(true)
    }
  }

  const handleClose = (e?: React.MouseEvent) => {
    if (e) e.stopPropagation()
    setModalOpen(false)
  }
  return (
    <div className={styles.content}>
      <Typography
        color="blue700"
        name="Subtitle7"
        Tag="div"
        onClick={handleOpen}
        className={joinClasses(styles['button-wrapper'], [
          styles['disabled'],
          isEmptyModal,
        ])}
      >
        {label}
      </Typography>
      {isModalOpen && (
        <Modal
          companyId={companyId}
          hirePointId={hirePointId}
          onClose={handleClose}
        />
      )}
    </div>
  )
}
