import { TYPOGRAPHY_COLORS } from 'constants/TypographyColors'
import { SVGIcon } from './SvgIcon/SvgIcon'
import { IconProps } from './types'

export const UserIcon = ({ size, color = 'grey400' }: IconProps) => (
  <SVGIcon size={size} viewBox="0 0 16 16" color={color}>
    <g clip-path="url(#clip0_9726_33002)">
      <path
        d="M7.99997 1.16284C11.776 1.16284 14.8372 4.22397 14.8372 8.00003C14.8372 11.7761 11.776 14.8372 7.99997 14.8372C4.22391 14.8372 1.16278 11.7761 1.16278 8.00003C1.16278 4.22397 4.22391 1.16284 7.99997 1.16284Z"
        stroke={TYPOGRAPHY_COLORS[color]}
        fill="none"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.2844 12.3387C13.2844 12.3387 11.7604 10.393 7.99992 10.393C4.23949 10.393 2.71536 12.3387 2.71536 12.3387"
        stroke={TYPOGRAPHY_COLORS[color]}
        fill="none"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00008 8.00002C6.86721 8.00002 5.94889 7.08171 5.94889 5.94883C5.94889 4.81602 6.86721 3.89771 8.00008 3.89771C9.13296 3.89771 10.0513 4.81602 10.0513 5.94883C10.0513 7.08171 9.13296 8.00002 8.00008 8.00002Z"
        stroke={TYPOGRAPHY_COLORS[color]}
        fill="none"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_9726_33002">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="matrix(-1 0 0 1 16 0)"
        />
      </clipPath>
    </defs>
  </SVGIcon>
)
