import { SVGIcon } from './SvgIcon'
import { IconProps } from './types'

export const DeleteIcon = ({
  size,
  color = 'grey400',
  onClick,
  className,
}: IconProps) => (
  <SVGIcon
    size={size}
    color={color}
    viewBox="0 0 24 24"
    onClick={onClick}
    className={className}
  >
    <rect width="24" height="24" rx="4" fill="#F5F9FF" />
    <path
      d="M7 8.40039H8.11115H17.0003"
      stroke="#2468E5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M15.8874 8.40008V16.8004C15.8874 17.1186 15.7703 17.4239 15.562 17.6489C15.3536 17.874 15.071 18.0004 14.7763 18.0004H9.22052C8.92583 18.0004 8.6432 17.874 8.43482 17.6489C8.22644 17.4239 8.10938 17.1186 8.10938 16.8004V8.40008M9.7761 8.40008V7.20004C9.7761 6.88177 9.89316 6.57653 10.1015 6.35148C10.3099 6.12643 10.5925 6 10.8872 6H13.1095C13.4042 6 13.6869 6.12643 13.8952 6.35148C14.1036 6.57653 14.2207 6.88177 14.2207 7.20004V8.40008"
      stroke="#2468E5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SVGIcon>
)
