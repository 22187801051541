export const SUCCESS_UPDATE_MESSAGE =
  'Your changes have been successfully saved'
export const ALL = 'All'
export const SELECT_ALL = 'Select all'
export const SEARCH_FOR = 'Search for'
export const SELECT = 'Select'
export const ASCENDING = 'Asc'
export const DESCENDING = 'Desc'

export const PAGE_NAMES = {
  ACTIVITY_HISTORY_DETAILS: 'Activity history details',
  STOP_SALE: 'Stop sale',
  PRICE_LIST: 'Price list',
  RATES: 'Rates',
}
