import { TYPOGRAPHY_COLORS } from 'constants/TypographyColors'
import { SVGIcon } from './SvgIcon/SvgIcon'
import { IconProps } from './types'

export const CompassIcon = ({ size, color = 'grey400' }: IconProps) => (
  <SVGIcon size={size} color={color}>
    <path
      d="M8.00004 14.6666C11.6819 14.6666 14.6667 11.6819 14.6667 7.99998C14.6667 4.31808 11.6819 1.33331 8.00004 1.33331C4.31814 1.33331 1.33337 4.31808 1.33337 7.99998C1.33337 11.6819 4.31814 14.6666 8.00004 14.6666Z"
      stroke={TYPOGRAPHY_COLORS[color]}
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.8267 5.17334L9.41334 9.41334L5.17334 10.8267L6.58667 6.58667L10.8267 5.17334Z"
      stroke={TYPOGRAPHY_COLORS[color]}
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVGIcon>
)
