import { FiltersState } from 'redux/filters/types'

export const toggleFilter = (
  key: string,
  value: string,
  initialFilters: FiltersState['filters']
) => {
  const currentFilters = structuredClone(initialFilters)
  const index = currentFilters[key].findIndex((el) => el.value === value)
  currentFilters[key][index].selected = !currentFilters[key][index].selected
  return currentFilters
}
