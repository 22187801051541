import { useEffect, useState } from 'react'
import { Pagination } from '../Pagination/Pagination'
import { Typography } from 'ui/atoms/Typography'
import { AutocompleteItem } from '../../types'
import { DEFAULT_PAGE_START } from '../Pagination/constants'
import { DropdownProps } from './types'
import { joinClasses } from 'utils/joinClasses'
import { boldMatch } from 'utils/match'
import styles from './styles.module.scss'

const PAGE_LIMIT = 5

export const Dropdown = ({
  data,
  value,
  onSelect,
  style,
  ofLabel,
  dropdownEmptyText,
}: DropdownProps) => {
  const [page, setPage] = useState(DEFAULT_PAGE_START)
  const [itemsOnPage, setItemsOnPage] = useState<AutocompleteItem[]>([])

  const pages = Math.ceil(data.length / PAGE_LIMIT)

  const isItemsExist = !!data.length

  useEffect(() => {
    const newData = data.filter(
      (_item, index) => Math.floor(index / PAGE_LIMIT) + 1 === page
    )
    setItemsOnPage(newData)
  }, [data, page])

  useEffect(() => {
    setPage(DEFAULT_PAGE_START)
  }, [value])

  return (
    <div
      className={joinClasses(styles['autocomplete-dropdown'], [
        styles.empty,
        !isItemsExist,
      ])}
      style={style}
    >
      {isItemsExist ? (
        <>
          {itemsOnPage.map((item, index) => (
            <Typography
              className={styles['autocomplete-dropdown-item']}
              Tag="div"
              key={index}
              name="body2WMedium"
              onClick={() => onSelect(item.value)}
              html={boldMatch(value, item.name)}
            />
          ))}
          <Pagination
            page={page}
            pages={pages}
            changePage={setPage}
            ofLabel={ofLabel}
          />
        </>
      ) : (
        <div>
          <Typography name="Button2" color="blueSecondary">
            {dropdownEmptyText}
          </Typography>
        </div>
      )}
    </div>
  )
}
