import { DriverAgeRange } from 'api/fleet/types'

export type GetHirePointsResponse = {
  hirePoints: {
    currentPage: number
    pageItems: HirePoint[]
    pageSize: number
    totalItems: number
    totalPages: number
  }
}

export type HirePoint = {
  id: number
  hirePointName: string
  country: string
  city: string
  locationName: string
  isClosed: boolean
  availableCarsCount: number
  availableEquipmentsCount: number
}

export type HirePointData = {
  name?: string
  companyID?: number
  locationId?: string
  countryId?: string
  countryName?: string
  cityId?: string
  cityName?: string
  region?: string
  workingHours?: WorkingHours
  dropoffHours?: WorkingHours
  latitude?: string
  locationName?: string
  longitude?: string
  phone?: string
  apartment?: string
  building?: string
  street?: string
  state?: string
  postalCode?: string
  currencyID?: number | string
  isActive?: boolean
  waitingHours?: WaitingHours
  currencyName?: string
  hirePointName?: string
  phoneCountryCode?: string
  phoneAreaCode?: string
  email?: string
  minimumPeriodDay?: string
  maximumPeriodDay?: string
  pickupOption?: PickupOptions
  specialInstructions?: string
  startReservationHour?: string
  driverAgeRange?: DriverAgeRange
}

export enum PickupOptions {
  Default = 'No option selected',
  DeskInTerminal = 'Desk in terminal',
  ShuttleToLocation = 'Shuttle to location',
  MeetingInAirport = 'Meeting in airport',
}

export type GetHirePointDataResponse = {
  hirePointInfo: HirePointData
}

type WaitingHours = {
  waitingHours?: string
  waitingHourPrice?: string
  waitingHourRange?: WaitingHourRange
}

export enum WaitingHourRange {
  Both = 'Both',
  Pickup = 'Pick-up',
  Dropoff = 'Drop-off',
}

export type AvailableVehicleListResponse = {
  vehicles: {
    currentPage: number
    pageItems: Vehicle[]
    pageSize: number
    totalItems: number
    totalPages: number
  }
}

type Vehicle = {
  selected: boolean
  id: string
  name: string
  groupName: string
  sippCode: string
  image: string
}

export type AvailableEquipmentListResponse = {
  extras: {
    currentPage: number
    pageItems: Extra[]
    pageSize: number
    totalItems: number
    totalPages: number
  }
}

type Extra = {
  id: number
  name: string
  isAvailable: boolean
  selected: boolean
}

export type WorkingHour = {
  dayOfWeek: string
  dayStart?: string
  dayStart2?: string
  dayEnd2?: string
  dayEnd?: string
}

export type WorkingHours = Array<WorkingHour>
