import { breadcrumbs } from 'constants/breadcrumbs'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { MENU_NAMES } from 'texts/menuNames'
import { MaximizeIcon, ModalCloseIcon } from 'ui/icons'
import { Table } from 'ui/molecules/Table'
import { Container } from 'ui/molecules/Container'
import { PAGINATION_DROPDOWN_LABEL } from 'texts/uiTexts'
import {
  DEFAULT_INITIAL_PAGE,
  DEFAULT_INITIAL_PAGE_SIZE,
} from 'constants/pagination'
import { Modal } from 'ui/molecules/Modal'
import { Select } from 'ui/atoms/Select'
import { auditApi } from 'api'
import { ItemType } from 'ui/atoms/Select/types'
import { RATES, ratesHeadItems } from 'texts/rateDetails'
import { useApiRequest } from 'hooks/useApiRequest'
import { OriginButton, Pagination } from '@frontend/design_system'
import styles from './styles.module.scss'
import { TableHeadProps } from 'ui/molecules/Table/TableHead/types'
import { Items } from 'ui/molecules/Table/types'
import { Typography } from 'ui/atoms/Typography'
import { AuditLog } from 'api/audit/types'
import { ACTIVITY_HISTORY } from 'texts/activityHistory'
import { formatSeasonRange } from 'utils/seasonRanges'
import { ImportDetails } from './components/ImportDetails/ImportDetails'

export const Rates = () => {
  const params = useParams()
  const [page, setPage] = useState(DEFAULT_INITIAL_PAGE)
  const [pageSize, setPageSize] = useState(DEFAULT_INITIAL_PAGE_SIZE)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [seasonRanges, setSeasonRanges] = useState<ItemType[]>([])
  const [currentSeasonRange, setCurrentSeasonRange] = useState({
    value: '',
    label: '',
  })
  const [tableContent, setTableContent] = useState<Items[]>([])
  const [columns, setColumns] = useState<TableHeadProps['item'][]>([])
  const [totalItems, setTotalItems] = useState(0)
  const [auditLog, setAuditLog] = useState<AuditLog['auditLog'] | null>(null)
  const [year, setYear] = useState<number>()
  const [isStructureChanged, setStructureChanged] = useState(false)

  const auditId = params.auditId as string
  const navigate = useNavigate()

  const { apiRequest } = useApiRequest((selectedSeasonRange) =>
    auditApi.getAuditRates(auditId, {
      pageIndex: page - 1,
      pageSize,
      seasonRange: selectedSeasonRange,
    })
  )

  const breadcrumbList = [
    {
      ...breadcrumbs.activityHistory,
      link: `${breadcrumbs.activityHistory.link}`,
    },
    {
      link: `${breadcrumbs.activityHistory.link}/${auditId}/details`,
      label: auditLog?.element || '',
    },
  ]

  const getAuditInfo = async (selectedSeasonRange = '') => {
    const response = await apiRequest(selectedSeasonRange)
    if (response) {
      const { data: auditRates } = response

      if (auditRates.structureChanged) {
        setAuditLog(auditRates.auditLog)
        setStructureChanged(true)
        return
      }

      const newSeasonRanges = auditRates.seasonRanges.map((range) => ({
        label: range,
        value: range,
      }))
      setSeasonRanges(newSeasonRanges)
      if (!selectedSeasonRange) {
        setCurrentSeasonRange({
          label: auditRates.selectedSeasonRange,
          value: auditRates.selectedSeasonRange,
        })
      }

      const columnPositions: { [key: string]: number } = {}
      const STATIC_COLUMN_COUNT = 2
      const newColumns = [
        { value: ratesHeadItems[0] },
        { value: ratesHeadItems[1] },
        ...auditRates.dailyRanges.map((range, index) => {
          columnPositions[range] = index + STATIC_COLUMN_COUNT
          return {
            value: range,
          }
        }),
      ]
      setColumns(newColumns)

      setTableContent(
        auditRates.changes.pageItems.map(
          ({ carClass, rates, seasonRange, carName }) => {
            const items = Array(newColumns.length).fill('')
            items[0] = carClass
            items[1] = carName
            rates.forEach((rate) => {
              items[columnPositions[rate.dailyRange]] = (
                <span>
                  <Typography name="Subtitle7" color="grey500">
                    {rate.oldRate || '-'}
                  </Typography>
                  <Typography name="Subtitle7">
                    {' / '}
                    {rate.newRate || '-'}
                  </Typography>
                </span>
              )
            })

            return {
              seasonRange,
              items,
            }
          }
        )
      )

      setTotalItems(auditRates.changes.totalItems)
      setYear(auditRates.year)
      setAuditLog(auditRates.auditLog)
    }
  }
  useEffect(() => {
    if (auditId) {
      getAuditInfo(currentSeasonRange.value)
    }
  }, [page, pageSize])

  const handleChangeRange = (item: ItemType) => {
    setCurrentSeasonRange({
      value: item.value,
      label: item.label,
    })
    setPage(DEFAULT_INITIAL_PAGE)
    getAuditInfo(item.value)
  }

  const changePageSize = (value: string) => {
    setPageSize(value)
    setPage(DEFAULT_INITIAL_PAGE)
  }

  const handleChange = (pageNumber: number) => {
    setPage(pageNumber)
  }

  const renderTable = () => (
    <>
      <div className={styles.list}>
        <div className={styles.header}>
          <div className={styles.buttons}>
            <div className={styles['filter-icon']}>
              {!isModalOpen ? (
                <MaximizeIcon
                  color="blue700"
                  size="large"
                  onClick={() => setIsModalOpen(true)}
                />
              ) : (
                <ModalCloseIcon
                  size="medium"
                  color="blue700"
                  onClick={() => setIsModalOpen(false)}
                />
              )}
            </div>
          </div>
        </div>
        {tableContent && (
          <div className={styles.table}>
            <Table headItems={columns} bodyItems={tableContent} />
          </div>
        )}
      </div>
      <footer className={styles.pagination}>
        <Pagination
          current={page}
          total={totalItems}
          pageSize={pageSize}
          changePageSize={changePageSize}
          changePage={handleChange}
          pageSizeLabel={PAGINATION_DROPDOWN_LABEL}
        />
      </footer>
    </>
  )

  const goToPriceList = () => {
    navigate(
      `/fleet/price-list/${auditLog?.companyId}/${auditLog?.entityId}/edit`
    )
  }
  return (
    <>
      {isModalOpen && (
        <Modal
          position="center-horizontal"
          onClose={() => setIsModalOpen(false)}
          offsetVariant="none"
          invisibleTitle
          invisibleHeader
          invisibleClose
          headerSize="large"
        >
          <div className={styles['rates-modal']}>{renderTable()}</div>
        </Modal>
      )}
      <Container
        title={`${ACTIVITY_HISTORY.TITLE} ${auditLog?.actionType}`}
        rightBlock={
          <Typography
            className={styles.timestamp}
            name="body2WBold"
            markColor="grey500"
          >
            {ACTIVITY_HISTORY.TIMESTAMP} <mark>{auditLog?.auditDate}</mark>
          </Typography>
        }
        breadcrumbList={breadcrumbList}
        currentPageLabel={MENU_NAMES.FLEET.SUBMENU_NAMES.RATES}
        companyName={auditLog?.companyName}
      >
        <div>
          {!isStructureChanged ? (
            <>
              <div className={styles['price-list-id']}>
                <Typography name="body2WBold">
                  {ACTIVITY_HISTORY.PRICE_LIST_ID}
                </Typography>
                <OriginButton
                  variant="link"
                  label={auditLog?.entityId}
                  typographyName="body2WBold"
                  onClick={goToPriceList}
                />
              </div>
              <div className={styles['season-range-wrapper']}>
                <Select
                  className={styles['season-range']}
                  items={
                    seasonRanges?.map((el) => ({
                      label: formatSeasonRange(el.label, String(year)),
                      value: el.value,
                    })) || []
                  }
                  label={RATES.SEASON_RANGES}
                  onChange={(_, item) => handleChangeRange(item)}
                  selectedValue={currentSeasonRange.value}
                  size="large"
                />
              </div>
              {renderTable()}
            </>
          ) : (
            <ImportDetails auditId={String(auditLog?.id)} />
          )}
        </div>
      </Container>
    </>
  )
}
