import { DailyRange, SeasonRange } from 'api/fleet/types'
import { INFINITY } from './components/Ranges/constants'

export const seasonRangeOverlapValidation = (value: SeasonRange[]) => {
  for (let i = 0; i < value.length - 1; i++) {
    for (let j = i + 1; j < value.length; j++) {
      const firstStart = new Date(value[i].start)
      const firstEnd = new Date(value[i].end)
      const secondStart = new Date(value[j].start)
      const secondEnd = new Date(value[j].end)

      if (!(firstEnd < secondStart || secondEnd < firstStart)) {
        return true
      }
    }
  }
  return false
}

export const dailyRangeEmptyValidation = (value: DailyRange[]) => {
  for (let i = 0; i < value.length; i++) {
    const { rangeStart } = value[i]
    if (!rangeStart) {
      return true
    }
  }
  return false
}

export const dailyRangeOverlapValidation = (value: DailyRange[]) => {
  const sorted = value.sort(
    (a, b) => Number(a.rangeStart) - Number(b.rangeStart)
  )

  for (let i = 0; i < sorted.length - 1; i++) {
    for (let j = i + 1; j < sorted.length; j++) {
      const { rangeEnd: firstEnd } = sorted[i]
      const { rangeStart: secondStart, rangeEnd: secondEnd } = sorted[j]
      if (
        !(Number(firstEnd) < Number(secondStart)) ||
        ((!firstEnd || firstEnd === INFINITY) &&
          (!secondEnd || secondEnd === INFINITY))
      ) {
        return true
      }
    }
  }
  return false
}

export const dailyRangeGapsValidation = (ranges: DailyRange[]) => {
  const sorted = ranges.sort(
    (a, b) => Number(a.rangeStart) - Number(b.rangeStart)
  )

  for (let i = 0; i < sorted.length - 1; i++) {
    const currentEnd = sorted[i].rangeEnd
    const nextStart = sorted[i + 1].rangeStart

    if (currentEnd !== INFINITY && Number(currentEnd) + 1 < Number(nextStart)) {
      return true
    }
  }

  return false
}

export const seasonRangeDateValidation = (value: SeasonRange[]) =>
  !!value.filter((el) => new Date(el.start) > new Date(el.end)).length

export const seasonRangeEmptyDateValidation = (value: SeasonRange[]) =>
  value.some((el) => !el.start || !el.end)

export const dailyRangeFirstRangeValidation = (value: DailyRange[]) => {
  if (value.length) {
    const firstRange = value[0]
    return Number(firstRange.rangeStart) !== 1
  }
  return false
}

export const seasonRangeDifferentYearValidation = (value: SeasonRange[]) =>
  value.some(
    (el) => new Date(el.end).getFullYear() > new Date(el.start).getFullYear()
  )
