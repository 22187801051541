import { LocationsApiTypes, referencesApi, ReferencesApiTypes } from 'api'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  LOCATION_ADDING_FORM_VALUES,
  LOCATION_DETAILS,
} from 'texts/locationDetails'
import { Button } from 'ui/atoms/Button'
import { ContentWrapper } from 'ui/atoms/ContentWrapper'
import { Form } from 'ui/molecules/Form'
import { ContactInformationForm } from './components/ContactInformationForm'
import { ContactInformationFormProps } from './components/ContactInformationForm/types'
import { WaitingHoursForm } from './components/WaitingHoursForm'
import { WorkingHoursForm } from './components/WorkingHoursForm'
import { FormValues, LocationDetailsFormProps } from './types'
import { initialFormValues, WORKING_HOURS_INITIAL_VALUES } from './constants'
import { PickupOptions, WaitingHourRange } from 'api/locations/types'
import { Location } from '../../hooks/useSelectLocation'
import countryOfResidence from 'constants/countryOfResidence'
import { ReservationForm } from './components/ReservationForm'
import { useDriverAgeRangeValidation } from 'ui/components/DriverAgeRanges/hooks/useDriverAgeRangeValidation'
import styles from './styles.module.scss'
import { LOCATION_DETAILS_TITLE } from 'texts/locationEdit'
import { useApiRequest } from 'hooks/useApiRequest'

export const LocationDetailsForm = ({
  handleSubmit,
  formValues,
  isLoading,
}: LocationDetailsFormProps) => {
  const {
    latitude,
    longitude,
    phone,
    street,
    waitingHours,
    postalCode,
    waitingHourPrice,
    building,
    currencyID,
    countryId,
    cityId,
    locationId,
    region,
    email,
    minimumPeriodDay,
    maximumPeriodDay,
    pickupOption,
    specialInstructions,
    driverAgeRange,
    startReservationHour,
  } = initialFormValues
  const navigate = useNavigate()
  const workingHoursFormRef = useRef<HTMLDivElement>(null)

  const [isWorkingHoursChanged, setWorkingHoursChanged] = useState(false)
  const [workingHours, setWorkingHours] =
    useState<LocationsApiTypes.WorkingHours>(
      WORKING_HOURS_INITIAL_VALUES.map(
        (workHour) =>
          formValues?.workingHours?.find(
            ({ dayOfWeek }) => dayOfWeek === workHour.dayOfWeek
          ) || workHour
      )
    )
  const [currentLocation, setCurrentLocation] = useState<Location>({
    country: {
      name: formValues?.countryName || '',
      id: formValues?.countryId || countryId,
    },
    city: {
      name: formValues?.cityName || '',
      id: formValues?.cityId || cityId,
    },
    location: {
      name: formValues?.locationName || '',
      id: formValues?.locationId || locationId,
    },
  })
  const [currencies, setCurrencies] =
    useState<ReferencesApiTypes.CurrenciesResponse>({ items: [] })

  const [workingHoursError, setWorkingHoursError] = useState('')
  const [driverAgeRangeError, setDriverAgeRangeError] = useState('')

  const { apiRequest, loading } = useApiRequest(() =>
    referencesApi.getCurrencies()
  )

  useEffect(() => {
    const fetchCurrencies = async () => {
      const fetchCurrenciesResponse = await apiRequest()
      if (fetchCurrenciesResponse) {
        setCurrencies(fetchCurrenciesResponse.data)
      }
    }

    fetchCurrencies()
  }, [])

  const checkDriverAgeRange = useDriverAgeRangeValidation(true)

  const prepareWorkingHours = () => {
    let invalidDate = false
    let workingHoursOverlap = false

    const result = workingHours.filter(
      ({ dayStart, dayEnd, dayStart2, dayEnd2 }) => {
        const getDateHours = (time: string) => {
          const [hour, minute] = time.split(':')

          return new Date().setHours(+hour, +minute)
        }

        if (dayStart && dayEnd) {
          if (
            !invalidDate &&
            getDateHours(dayEnd) - getDateHours(dayStart) <= 0
          ) {
            invalidDate = true
          }
        }

        if (dayStart2 && dayEnd2) {
          if (
            !invalidDate &&
            getDateHours(dayEnd2) - getDateHours(dayStart2) <= 0
          ) {
            invalidDate = true
          }
        }

        if (dayStart && dayEnd && dayStart2 && dayEnd2) {
          if (!invalidDate && !(dayEnd <= dayStart2 || dayEnd2 <= dayStart)) {
            workingHoursOverlap = true
          }
        }

        return dayStart || dayEnd
      }
    )

    if (!result.length) {
      setWorkingHoursError(
        LOCATION_ADDING_FORM_VALUES.WORKING_HOURS.REQUIRED_MESSAGE
      )

      return []
    } else if (invalidDate) {
      setWorkingHoursError(
        LOCATION_ADDING_FORM_VALUES.WORKING_HOURS.VALIDATION_MESSAGE
      )

      return []
    } else if (workingHoursOverlap) {
      setWorkingHoursError(
        LOCATION_ADDING_FORM_VALUES.WORKING_HOURS.OVERLAP_MESSAGE
      )
      return []
    }

    return result
  }

  const onSubmit = (data: Partial<FormValues>, validate: boolean) => {
    const preparedWorkingHours = prepareWorkingHours()
    const driverAgeRangeError = checkDriverAgeRange(data?.driverAgeRange)
    if (driverAgeRangeError) {
      setDriverAgeRangeError(driverAgeRangeError)
    }

    if (!preparedWorkingHours.length) {
      workingHoursFormRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }

    if (validate && preparedWorkingHours.length && !driverAgeRangeError) {
      handleSubmit({
        ...data,
        locationId: currentLocation.location.id,
        phoneAreaCode: data.phoneCor?.code
          ? String(data.phoneCor?.phoneCode)
          : '',
        phoneCountryCode: data.phoneCor?.code
          ? String(data.phoneCor?.code)
          : '',
        dropoffHours: preparedWorkingHours,
        workingHours: preparedWorkingHours,
        pickupOption: data.pickupOption as PickupOptions,
      })
    }
  }

  const initValues = useMemo(
    () => ({
      latitude: String(formValues?.latitude ?? latitude),
      longitude: String(formValues?.longitude ?? longitude),
      countryId: String(currentLocation.country.id),
      cityId: String(currentLocation.city.id),
      locationId: String(currentLocation.location.id),
      building: formValues?.building || building,
      phone: formValues?.phone || phone,
      phoneCor: countryOfResidence.find(
        (el) => el.code === formValues?.phoneCountryCode
      ),
      street: formValues?.street || street,
      waitingHours: formValues?.waitingHours?.waitingHours || waitingHours,
      waitingHourPrice:
        formValues?.waitingHours?.waitingHourPrice || waitingHourPrice,
      waitingHoursPeriod:
        formValues?.waitingHours?.waitingHourRange || WaitingHourRange.Both,
      postalCode: formValues?.postalCode || postalCode,
      currencyID:
        currencies.items.find(
          (currency) => currency.id === String(formValues?.currencyID)
        )?.id || currencyID,
      region: formValues?.region || region,
      email: formValues?.email || email,
      minimumPeriodDay: formValues?.minimumPeriodDay || minimumPeriodDay,
      maximumPeriodDay: formValues?.maximumPeriodDay || maximumPeriodDay,
      pickupOption: formValues?.pickupOption || pickupOption,
      startReservationHour:
        formValues?.startReservationHour || startReservationHour,
      specialInstructions:
        formValues?.specialInstructions || specialInstructions,
      driverAgeRange: formValues?.driverAgeRange || driverAgeRange,
    }),
    [formValues, currentLocation, currencies]
  )

  const handleChangeLocation = (
    location: ContactInformationFormProps['currentLocation']
  ) => {
    setCurrentLocation(location)
  }

  const handleWorkingHoursChange = (
    workingHours: LocationsApiTypes.WorkingHours
  ) => {
    setWorkingHoursError('')
    setWorkingHoursChanged(true)
    setWorkingHours(workingHours)
  }

  const handleResetDriverAgeError = () => setDriverAgeRangeError('')

  return !loading ? (
    <Form
      initValues={initValues}
      onSubmit={onSubmit}
      className={styles['form-wrapper']}
    >
      {({ values, isDirty }) => (
        <>
          <ContentWrapper
            className={styles.form}
            title={LOCATION_DETAILS_TITLE}
          >
            <ContactInformationForm
              currentLocation={currentLocation}
              changeLocation={handleChangeLocation}
              currencies={currencies}
              locationId={formValues?.locationId}
            />
          </ContentWrapper>
          <ContentWrapper
            className={styles.form}
            title={LOCATION_DETAILS.FORM.WORKING_HOURS_TITLE}
          >
            <WorkingHoursForm
              workingHours={workingHours}
              onWorkingHoursChange={handleWorkingHoursChange}
              error={workingHoursError}
              ref={workingHoursFormRef}
            />
          </ContentWrapper>
          <ContentWrapper
            className={styles.form}
            title={LOCATION_DETAILS.FORM.RESERVATION_TITLE}
          >
            <ReservationForm
              formValues={values}
              driverAgeRangeError={driverAgeRangeError}
              resetDriverAgeError={handleResetDriverAgeError}
            />
          </ContentWrapper>
          <ContentWrapper
            className={styles.form}
            title={LOCATION_DETAILS.FORM.WAITING_HOURS_TITLE}
          >
            <WaitingHoursForm />
          </ContentWrapper>
          <div className={styles.buttons}>
            <div className={styles['button-wrapper']}>
              <Button
                variant="outline"
                onClick={() => navigate(-1)}
                label={LOCATION_DETAILS.BUTTONS.CANCEL_BUTTON_LABEL}
              />
            </div>
            <div className={styles['button-wrapper']}>
              <Button
                htmlType="submit"
                label={LOCATION_DETAILS.BUTTONS.SAVE_BUTTON_LABEL}
                loading={isLoading}
                disabled={!(isDirty || isWorkingHoursChanged)}
              />
            </div>
          </div>
        </>
      )}
    </Form>
  ) : null
}
