import React, { useContext, useEffect } from 'react'
import { FormContext } from '../../Form'
import { ContextType, ValueType } from '../../types'
import { FormItemProps, Rule } from './types'
import { joinClasses } from 'utils/joinClasses'
import styles from './styles.module.scss'

export const FormItem = ({
  rules,
  id,
  children,
  errorMessage,
  className,
}: FormItemProps) => {
  const { errors, values, setValues, setRules, setErrors } =
    useContext<ContextType>(FormContext)
  const error = errors[id]
  const value = values[id]

  useEffect(
    () => () => {
      setRules(({ [id]: _, ...prevRules }) => prevRules)
      setValues((prevValues) => ({ ...prevValues, [id]: '' }))
    },
    [id]
  )

  useEffect(() => {
    if (errorMessage) {
      setErrors((prevErrors) => ({ ...prevErrors, [id]: errorMessage }))
    }
  }, [errorMessage, id])

  useEffect(() => {
    if (rules) {
      setRules((prevRules: Record<string, Rule[]>) => ({
        ...prevRules,
        [id]: rules,
      }))
    }
  }, [rules, id])

  const changeValue = (newValue: ValueType, fieldId: string) => {
    if (error) {
      setErrors((prevErrors) => ({ ...prevErrors, [fieldId]: null }))
    }
    setValues((prevValues) => ({ ...prevValues, [fieldId]: newValue }))
  }

  const onChange = (newValue: ValueType) => changeValue(newValue, id)

  const content = children({
    value,
    error,
    onChange,
    onChangeField: changeValue,
  })

  if (className) {
    return (
      <div
        className={joinClasses(className, styles['form-item'], [
          styles['error-field'],
          error,
        ])}
      >
        {content}
      </div>
    )
  }

  return content
}
