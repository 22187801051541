import { Typography } from 'ui/atoms/Typography'
import { joinClasses } from 'utils/joinClasses'
import { useNavigate } from 'react-router-dom'
import { Props } from './types'
import styles from './styles.module.scss'

export const SubmenuItem = ({
  name,
  activeItem,
  onMenuItemClick,
  path,
  disabled,
}: Props) => {
  const navigate = useNavigate()

  const handleClick = () => {
    onMenuItemClick(name)

    if (path) {
      navigate(path)
    }
  }

  return (
    <Typography
      name="Button2"
      Tag="div"
      color={activeItem === name ? 'blue700' : 'grey500'}
      className={joinClasses(
        styles.submenu,
        [styles.active, activeItem === name],
        [styles.disabled, disabled]
      )}
      onClick={handleClick}
    >
      {name}
    </Typography>
  )
}
