import { LOCATION_ADDING_FORM_VALUES } from 'texts/locationDetails'
import styles from './styles.module.scss'
import { Input as InputDS } from '@frontend/design_system'
import { Input } from 'ui/atoms/Input'
import { InfoIcon } from 'ui/icons'
import { Tooltip } from 'ui/atoms/Tooltip'
import { joinClasses } from 'utils/joinClasses'
import { FormItem } from 'ui/molecules/Form/components/FormItem'
import { VEHICLE_DETAILS } from 'texts/vehicleDetails'
import { DriveAgeRanges } from 'ui/components/DriverAgeRanges'
import { Typography } from 'ui/atoms/Typography'
import { getRequiredRule } from 'utils/getValidationRules'
import { useMemo } from 'react'
import { useReservationRules } from './hooks/useReservationRules'
import { ReservationFormProps } from './types'
import { allCharactersExceptNumbers } from 'constants/regex'

export const ReservationForm = ({
  driverAgeRangeError,
  resetDriverAgeError,
  formValues,
}: ReservationFormProps) => {
  const rules = useReservationRules(formValues)

  const validationRules = (message: string) =>
    useMemo(() => getRequiredRule(message), [])

  const renderTooltip = (value: string) => (
    <div className={styles['form-tooltip-wrapper']}>
      <div className={styles['form-tooltip']}>
        <Tooltip
          size="secondary"
          arrowPosition="right"
          popupContent={<Typography name="Button2">{value}</Typography>}
          placement="bottom"
        >
          <InfoIcon color="blue700" size="small" />
        </Tooltip>
      </div>
    </div>
  )

  return (
    <>
      <div className={styles['form-items-row']}>
        <FormItem
          id={LOCATION_ADDING_FORM_VALUES.MINIMUM_PERIOD_DAY.ID}
          className={joinClasses(styles['form-item'], styles['form-item-left'])}
          rules={rules.minimumPeriodDay}
        >
          {({ value, error, onChange }) => (
            <Input
              value={value}
              placeholder={
                LOCATION_ADDING_FORM_VALUES.MINIMUM_PERIOD_DAY.PLACEHOLDER
              }
              size="large"
              error={error}
              spaceForError="auto"
              isRequired
              onChange={(value: string) =>
                onChange(value.replace(allCharactersExceptNumbers, ''))
              }
              label={LOCATION_ADDING_FORM_VALUES.MINIMUM_PERIOD_DAY.LABEL}
            />
          )}
        </FormItem>
        <FormItem
          id={LOCATION_ADDING_FORM_VALUES.MAXIMUM_PERIOD_DAY.ID}
          className={styles['form-item']}
          rules={rules.minMaxPeriodDay}
        >
          {({ value, error, onChange }) => (
            <Input
              value={value}
              placeholder={
                LOCATION_ADDING_FORM_VALUES.MAXIMUM_PERIOD_DAY.PLACEHOLDER
              }
              size="large"
              type="number"
              error={error}
              spaceForError="auto"
              onChange={(value: string) =>
                onChange(value.replace(allCharactersExceptNumbers, ''))
              }
              label={LOCATION_ADDING_FORM_VALUES.MAXIMUM_PERIOD_DAY.LABEL}
            />
          )}
        </FormItem>
      </div>
      <div className={styles['form-items-row']}>
        <FormItem
          id={LOCATION_ADDING_FORM_VALUES.START_RESERVATION_HOUR.ID}
          className={joinClasses(styles['form-item'], styles['form-item-left'])}
          rules={validationRules(
            LOCATION_ADDING_FORM_VALUES.START_RESERVATION_HOUR.REQUIRED_MESSAGE
          )}
        >
          {({ value, error, onChange }) => (
            <InputDS
              value={value}
              placeholder={
                LOCATION_ADDING_FORM_VALUES.START_RESERVATION_HOUR.PLACEHOLDER
              }
              size="large"
              error={error}
              iconRight={renderTooltip(
                LOCATION_ADDING_FORM_VALUES.START_RESERVATION_HOUR.TOOLTIP
              )}
              spaceForError="auto"
              onChange={(value: string) =>
                onChange(value.replace(allCharactersExceptNumbers, ''))
              }
              isRequired
              label={LOCATION_ADDING_FORM_VALUES.START_RESERVATION_HOUR.LABEL}
            />
          )}
        </FormItem>
      </div>
      <div>
        <Typography
          className={styles['driver-age-range-header']}
          markColor="red600Master"
          Tag="h5"
          name="H5"
        >
          {VEHICLE_DETAILS.DRIVER_AGE_RANGE_TITLE} <mark>*</mark>
        </Typography>

        <FormItem
          id={LOCATION_ADDING_FORM_VALUES.DRIVER_AGE_RANGE.ID}
          rules={rules.dailyRangeRules}
        >
          {({ onChange, value }) => (
            <DriveAgeRanges
              driverAgeRangeData={value}
              error={driverAgeRangeError}
              onDriverAgeRangeDataChange={(value) => {
                onChange(value)
                resetDriverAgeError()
              }}
            />
          )}
        </FormItem>
      </div>
    </>
  )
}
