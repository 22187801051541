import { createSlice } from '@reduxjs/toolkit'
import { LocationsState } from './types'

const initialState: LocationsState = {
  errorMessage: '',
}

export const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    setLocationsErrorMessage(state, action) {
      state.errorMessage = action.payload
    },
  },
})

export const locationsReducer = locationsSlice.reducer
export const { setLocationsErrorMessage } = locationsSlice.actions
