export const MENU_NAMES = {
  PROFILE_LIST: {
    NAME: 'List of profiles',
  },
  PROFILE_ARCHIVE: {
    NAME: 'Profile archive',
  },
  PROFILE_DETAILS: {
    NAME: 'Profile details',
  },
  APPLICATIONS: {
    NAME: 'Applications',
    SUBMENU_NAMES: {
      APPLICATIONS: 'Applications',
      ARCHIVE: 'Application archive',
    },
  },
  TERMS_AND_CONDITIONS: 'Terms & Conditions',
  DASHBOARD: 'Dashboard',
  FLEET: {
    NAME: 'Fleet',
    SUBMENU_NAMES: {
      VEHICLES: 'Cars',
      EQUIPMENTS: 'Equipment',
      PRICE_LISTS: 'Price lists',
      STOP_SALES: 'Stop sales',
      RATES: 'Prices',
    },
  },
  LOCATIONS: {
    NAME: 'List of locations',
  },
  ACTIVITY_HISTORY: 'Activity history',
}
