import {
  LOCATION_ADDING_FORM_VALUES,
  WAITING_HOURS_PERIOD,
} from 'texts/locationDetails'
import { Input } from 'ui/atoms/Input'
import { RadioButton } from 'ui/atoms/RadioButton'
import { Typography } from 'ui/atoms/Typography'
import { FormItem } from 'ui/molecules/Form/components/FormItem'
import { WAITING_HOURS_RADIO_BUTTONS } from './constants'
import styles from './styles.module.scss'
import { useMemo } from 'react'
import { Rule } from 'ui/molecules/Form/components/FormItem/types'
import { onlyNumbersWithPlus, onlyNumbersWithDotRegex } from 'constants/regex'

export const WaitingHoursForm = () => {
  const waitingHoursRules: Rule[] = useMemo(
    () => [
      {
        type: 'pattern',
        value: onlyNumbersWithPlus,
        message: LOCATION_ADDING_FORM_VALUES.WAITING_HOURS.VALIDATION_MESSAGE,
      },
    ],
    []
  )
  const priceRules: Rule[] = useMemo(
    () => [
      {
        type: 'pattern',
        value: onlyNumbersWithDotRegex,
        message: LOCATION_ADDING_FORM_VALUES.PRICE.VALIDATION_MESSAGE,
      },
    ],
    []
  )

  return (
    <div>
      <div className={styles['form-items-row']}>
        <FormItem
          id={LOCATION_ADDING_FORM_VALUES.WAITING_HOURS.ID}
          className={styles['form-item']}
          rules={waitingHoursRules}
        >
          {({ value, error, onChange }) => (
            <Input
              value={value}
              size="large"
              error={error}
              spaceForError="auto"
              onChange={onChange}
              label={LOCATION_ADDING_FORM_VALUES.WAITING_HOURS.LABEL}
              placeholder={
                LOCATION_ADDING_FORM_VALUES.WAITING_HOURS.PLACEHOLDER
              }
            />
          )}
        </FormItem>
        <FormItem
          id={LOCATION_ADDING_FORM_VALUES.PRICE.ID}
          className={styles['form-item']}
          rules={priceRules}
        >
          {({ value, error, onChange }) => (
            <Input
              value={value}
              size="large"
              error={error}
              spaceForError="auto"
              onChange={onChange}
              label={LOCATION_ADDING_FORM_VALUES.PRICE.LABEL}
              placeholder={LOCATION_ADDING_FORM_VALUES.PRICE.PLACEHOLDER}
            />
          )}
        </FormItem>
      </div>
      <div>
        <Typography name="Button2" className={styles['radio-button-label']}>
          {WAITING_HOURS_PERIOD}
        </Typography>
        <FormItem id={LOCATION_ADDING_FORM_VALUES.WAITING_HOURS_RANGE.ID}>
          {({ value, onChange }) => (
            <>
              {Object.entries(WAITING_HOURS_RADIO_BUTTONS).map(
                ([key, label]) => (
                  <RadioButton
                    labelTypographyName="Button2"
                    key={key}
                    id={key}
                    label={label}
                    onChange={onChange}
                    checked={key === value}
                  />
                )
              )}
            </>
          )}
        </FormItem>
      </div>
    </div>
  )
}
