export const EQUIPMENTS = {
  ADD_BUTTON: 'Add equipment',
  TOOLTIP_CONTENT:
    'Here you can add information about the equipment you provide for your rental cars.',
}

export const EQUIPMENT_DELETE_CONFIRMATION = {
  CONFIRMATION_MODAL: {
    DELETE: 'Delete',
    QUESTION: 'Are you sure that you want to delete',
  },
  SUCCESS_MODAL: {
    TITLE: 'Equipment was successfully deleted',
  },
}

export const EQUIPMENTS_HEAD_ITEMS = {
  EQUIPMENT: 'Equipment',
  QUANTITY: 'Quantity',
  PRICE: 'Price',
  MAX_PRICE: 'Max price',
  LOCATION: 'Refers to location',
}
