import { CustomLink } from '../CustomLink'
import { Typography } from '../Typography'
import { ArrowRightIcon } from 'ui/icons/ArrowRightIcon'
import { Props } from './types'
import styles from './styles.module.scss'

export const Breadcrumbs = ({ breadcrumbs, currentPageLabel }: Props) => (
  <Typography className={styles.breadcrumbs} Tag="ol">
    {breadcrumbs.map((breadcrumb) => (
      <li key={breadcrumb.label} className={styles['list-item']}>
        {breadcrumb.link ? (
          <CustomLink
            className={styles.link}
            href={breadcrumb.link}
            typographyName="Button2"
          >
            <span>{breadcrumb.label}</span>
          </CustomLink>
        ) : (
          <Typography
            name="Button2"
            color="blackMaster"
            className={styles['current-item']}
          >
            <span>{breadcrumb.label}</span>
          </Typography>
        )}
        <ArrowRightIcon
          className={styles['svg-arrow']}
          color="grey400"
          size="small"
        />
      </li>
    ))}
    <Typography
      name="Button2"
      color="blackMaster"
      className={styles['current-item']}
      Tag="li"
    >
      <span>{currentPageLabel}</span>
    </Typography>
  </Typography>
)
