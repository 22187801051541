import { getRequiredMessage } from 'utils/getRequiredMessage'
import { LATIN_LETTER_RULE } from './uiTexts'

export const PRICE_LIST_TITLE = 'Price list'
export const PRICE_LIST_ADDING_BREADCRUMB = 'Add price list'
export const PRICE_LIST_DETAILS_BREADCRUMB = 'Price list details'

export const PRICE_LIST_DETAILS = {
  TITLE: 'Price list details',
  SEASON_RANGE: 'Season range <mark>*</mark>',
  DAILY_RANGE: 'Daily range <mark>*</mark>',
  ADD_RANGE: 'Add range',
  SEASON_RANGE_BADGE: "Click 'Add range' button to set a season range",
  DAILY_RANGE_BADGE: "Click 'Add range' button to set a daily range",
  GO_TO_RATES: 'Go to prices',
  SEARCH_VALUE: 'location',
  ADD_SUBMIT_BUTTON: 'Save and go to prices',
}

export const PRICE_LIST_SEASON_RANGE = {
  START: 'Start date',
  END: 'End date',
}

export const PRICE_LIST_DAILY_RANGE = {
  START: 'Start',
  END: 'End',
  TYPE: 'Type',
}

export const PRICE_LIST_ADD_CONFIRM_MODAL = {
  TITLE: 'Price list has been successfully added',
}

export const DAILY_RANGE_TYPES = [
  { label: 'Daily', value: 'Daily' },
  { label: 'Fixed', value: 'Fixed' },
]

export const PRICE_LIST_DETAILS_FORM_VALUES = {
  HIRE_POINTS: {
    ID: 'hirePoints',
    PLACEHOLDER: 'Please select a location',
    LABEL: 'Location',
    REQUIRED_MESSAGE: 'Please select a location.',
  },
  YEAR: {
    ID: 'year',
    PLACEHOLDER: 'Please enter a year',
    LABEL: 'Year',
    REQUIRED_MESSAGE: getRequiredMessage('year'),
  },
  SIPP: {
    ID: 'sipp',
    PLACEHOLDER: 'Please enter you internal car class',
    LABEL: 'Internal Car Class (SIPP)',
    REQUIRED_MESSAGE: getRequiredMessage('SIPP'),
  },
  PRICE_LIST_TYPE: {
    ID: 'priceListType',
    LABEL: 'Apply rates by',
    TOOLTIP:
      'Please note that if you make a change, all cars and prices you have previously added on Prices page will be deleted.',
  },
  COMMENT: {
    ID: 'comment',
    LABEL: 'Your comment',
    PLACEHOLDER: 'Please enter your comment',
    VALIDATION_MESSAGE: LATIN_LETTER_RULE,
  },
  SEASON_RANGES: {
    ID: 'seasonRanges',
    REQUIRED_MESSAGE: 'Please select a season range.',
    OVERLAP_MESSAGE: 'Season ranges cannot overlap.',
    COMPARE_DATE_MESSAGE: 'Start date must be less or equal to end date.',
    DIFFERENT_YEAR_MESSAGE: 'Season ranges must be created within one year.',
  },
  DAILY_RANGES: {
    ID: 'dailyRanges',
    REQUIRED_MESSAGE: 'Please set a daily range.',
    EMPTY_MESSAGE: 'Please enter a range.',
    OVERLAP_MESSAGE: 'Daily ranges cannot overlap.',
    FIRST_RANGE_MESSAGE: 'Start period in the first range must be 1.',
    GAP_RANGE_MESSAGE: 'Please complete the gaps between ranges.',
  },
}
