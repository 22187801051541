import { useState, useRef } from 'react'
import { PHONE_CODE_WIDTH } from './constants'
import { Cor, PhoneProps } from './types'
import { Input } from 'ui/atoms/Input'
import { useOutsideClick } from './hooks/useOutsideClick'
import { Label } from '@frontend/design_system'
import { onChangeNumber } from './utils'
import { PhoneCode } from './components/PhoneCode'
import { Dropdown } from './components/Dropdown/Dropdown'
import styles from './styles.module.scss'

export const Phone = ({
  corList,
  changeNumber,
  number,
  locales,
  hint,
  spaceForError,
  placeholder,
  changeCOR,
  cor,
  label,
  isRequired,
  error,
  onBlur,
  tooltip,
  id,
  disabled,
}: PhoneProps) => {
  const codesRef = useRef(null)
  const selectRef = useRef(null)
  const [showCodes, setShowCodes] = useState(false)

  useOutsideClick({
    showCodes,
    setShowCodes,
    codesRef,
    selectRef,
  })

  const openDropdown = () => {
    setShowCodes(true)
  }

  const handleChangeCOR = (newCor: Cor) => {
    changeCOR(newCor)
    setShowCodes(false)
  }

  const handleChangeNumber = (value: string) => {
    onChangeNumber(value, changeNumber)
  }

  const renderCodes = () => {
    if (showCodes) {
      return (
        <Dropdown
          ref={codesRef}
          corList={corList}
          locales={locales}
          changeCOR={handleChangeCOR}
          cor={cor}
          spaceForError={spaceForError}
        />
      )
    }

    return null
  }

  return (
    <div className={styles.phone}>
      {label && (
        <Label
          typographyName="Button2"
          label={label}
          isRequired={isRequired}
          tooltip={tooltip}
        />
      )}
      <Input
        size="large"
        leftEl={
          <PhoneCode
            selectRef={selectRef}
            countryCode={cor?.code}
            disabled={disabled}
            code={cor?.phoneCode}
            open={openDropdown}
            showCodes={showCodes}
          />
        }
        typographyName="body2WMedium"
        onChange={handleChangeNumber}
        value={number}
        disabled={disabled}
        placeholder={placeholder}
        paddingLeft={
          cor
            ? PHONE_CODE_WIDTH[
                `${cor.phoneCode}`.length as keyof typeof PHONE_CODE_WIDTH
              ]
            : PHONE_CODE_WIDTH[1]
        }
        error={error}
        hint={hint}
        spaceForError={spaceForError}
        borderVariant={showCodes ? 'blue' : 'grey'}
        onBlur={onBlur}
        id={id}
      />
      {renderCodes()}
    </div>
  )
}
