import { Menu } from './Menu'
import { Header } from './Header'
import { Props } from './types'
import { Wrapper } from 'ui/atoms/Wrapper'
import { GeneralNotification } from 'ui/molecules/GeneralNotification'
import styles from './styles.module.scss'
import { HEADER_HEIGHT } from 'constants/screen'
import { ErrorBoundary } from 'ui/molecules/ErrorBoundary'
import { authIsLoadedSelector, userDataSelector } from 'redux/login/selectors'
import { useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'
import { URLS } from 'constants/urls'
import { NOTIFICATION_TOP_OFFSET } from './constants'

export const MainLayout = ({ Content }: Props) => {
  const userData = useSelector(userDataSelector)
  const isLoaded = useSelector(authIsLoadedSelector)
  const location = useLocation()

  if (!userData.displayName && isLoaded) {
    return <Navigate to={URLS.SIGNIN} />
  }

  return (
    <div>
      <Header />
      <Wrapper className={styles.layout}>
        <Menu />
        <main className={styles.content}>
          <ErrorBoundary key={location.pathname}>{Content}</ErrorBoundary>
        </main>
        <div>
          <GeneralNotification
            top={`${HEADER_HEIGHT + NOTIFICATION_TOP_OFFSET}px`}
            right="24px"
          />
        </div>
      </Wrapper>
    </div>
  )
}
