import { addQueryString } from 'utils/addQueryString'
import { ErrorResponse } from '../types'
import { request } from '../utils'
import { PATH } from './constants'
import {
  AllCarGroupsResponse,
  AllCarsResponse,
  AllExtraTypesResponse,
  CitiesResponse,
  CountriesResponse,
  CurrenciesResponse,
  LocationsResponse,
  ListResponse,
} from './types'

class Api {
  public baseUrl: string = process.env.REACT_APP_API_URL || ''

  async getCurrencies() {
    return request<CurrenciesResponse, ErrorResponse>(
      this.baseUrl,
      PATH.CURRENCIES,
      { method: 'GET' }
    )
  }

  async getCountries(companyId = '') {
    return request<CountriesResponse, ErrorResponse>(
      this.baseUrl,
      addQueryString(PATH.COUNTRIES, { companyId }),
      { method: 'GET' }
    )
  }

  async getAllCompanyHirePoints(companyId: string) {
    return request<LocationsResponse, ErrorResponse>(
      this.baseUrl,
      addQueryString(PATH.ALL_HIRE_POINTS, { companyId }),
      { method: 'GET' }
    )
  }

  async getAllCarGroups() {
    return request<AllCarGroupsResponse, ErrorResponse>(
      this.baseUrl,
      PATH.ALL_CAR_GROUPS,
      { method: 'GET' }
    )
  }

  async getAllCars() {
    return request<AllCarsResponse, ErrorResponse>(
      this.baseUrl,
      PATH.ALL_CARS,
      { method: 'GET' }
    )
  }

  async getCities(countryId: number, companyId = '') {
    return request<CitiesResponse, ErrorResponse>(
      this.baseUrl,
      addQueryString(PATH.CITIES, { countryId, companyId }),
      { method: 'GET' }
    )
  }

  async getLocations({
    cityId = '',
    companyId = '',
    cities = [],
  }: {
    cityId?: string
    companyId?: string
    cities?: string[]
  }) {
    return request<LocationsResponse, ErrorResponse>(
      this.baseUrl,
      addQueryString(PATH.LOCATIONS, {
        cityId,
        companyId,
        cities,
      })
    )
  }

  async getAvailableLocations({
    cityId,
    companyId,
    currentLocationId = '',
  }: {
    cityId: string
    companyId: string
    currentLocationId?: string
  }) {
    return request<LocationsResponse, ErrorResponse>(
      this.baseUrl,
      addQueryString(PATH.AVAILABLE_LOCATIONS, {
        companyId,
        cityId,
        includeLocationId: currentLocationId,
      })
    )
  }

  async getAllExtraTypes(companyId = '', isSpecificExtraOnly = true) {
    return request<AllExtraTypesResponse, ErrorResponse>(
      this.baseUrl,
      addQueryString(PATH.ALL_EXTRAS, { companyId, isSpecificExtraOnly }),
      { method: 'GET' }
    )
  }

  async getAvailableCountries(companyId: string, currentLocationId = '') {
    return request<CountriesResponse, ErrorResponse>(
      this.baseUrl,
      addQueryString(PATH.AVAILABLE_COUNTRIES, {
        companyId,
        includeLocationId: currentLocationId,
      })
    )
  }

  async getCarSeats() {
    return request<ListResponse, ErrorResponse>(this.baseUrl, PATH.SEATS)
  }

  async getCarDoors() {
    return request<ListResponse, ErrorResponse>(this.baseUrl, PATH.DOORS)
  }

  async getAvailableCities(
    companyId: string,
    countryId: number,
    currentLocationId = ''
  ) {
    return request<CountriesResponse, ErrorResponse>(
      this.baseUrl,
      addQueryString(PATH.AVAILABLE_CITIES, {
        companyId,
        countryId,
        includeLocationId: currentLocationId,
      })
    )
  }
}

export const referencesApi = new Api()
